import { Moment } from "moment";
import { DateUtils } from "utils/dateutils";
import { EntityType } from "../../../../constants/index";
import { TActivitiesV3, TLinkedMaterial, TLinkedSubmittal } from "./models";
import { PrePostElement, TimelineData } from "../compare-schedule-2/utils";
import {
  Milestone,
  GoverningMaterial,
  RiskAssessment
} from "../compare-schedule-2/model";
import { getNormalizedDate } from "../compare-schedule-2/TemporalLayout";

const changeLabelForProjected = true;

export const getRiskAssessmentForEntity = (
  entityDateBlock: any
): RiskAssessment => {
  return entityDateBlock?.new?.risk_assessment;
};

/**
 * This method will give the last milestone data based on the given property prefix.
 * @param dateBlock
 * @param propertyPrefix
 * @returns
 */
export const getLastMileStone = (
  dateBlock: any,
  propertyPrefix = "planned_milestone_"
) => {
  let planned = null;
  for (let index = 1; index <= 10; index = 1 + index) {
    if (!dateBlock[`${propertyPrefix}${index}`]) {
      return planned;
    }
    planned = dateBlock[`${propertyPrefix}${index}`];
  }
  return planned;
};

export function computeTimelineDataMaterial(
  type: "before" | "after",
  material: TLinkedMaterial,
  activityComparison: TActivitiesV3,
  projectId: string
): {
  allDates: Moment[];
  timelineData: TimelineData;
} {
  const allDates: Moment[] = [];
  let materialDetail = material.MDB.old;

  let governTasks = material.old_governing_task;

  if (type === "after") {
    materialDetail = material.MDB.new;
    governTasks = material.new_governing_task;
  }

  const governingTask = governTasks?.length > 0 ? governTasks[0] : null;

  const postElements = material.linked_submittals.map((s) => {
    const milestones: { [type: string]: Milestone } = {};
    return {
      label: s,
      link_url: `/project/${projectId}/submittals/${s}`,
      milestones
    };
  }) as PrePostElement[];

  // const todayDateString =
  //   submittal.SDB.new.SI_calculations.SI_TD ||
  //   submittal.SDB.old.SI_calculations.SI_TD;

  const isProjectedDateExist = !!materialDetail.SI_calculations.SI_PRJLMD;

  let assignedToSCDateString =
    materialDetail.SI_calculations.SI_AFMD ||
    materialDetail.SI_calculations.SI_PRJFMD ||
    materialDetail.SI_calculations.SI_PFMD;
  if (type === "after") {
    assignedToSCDateString =
      materialDetail.SI_calculations.SI_AFMD ||
      material.MDB.old.SI_calculations.SI_AFMD ||
      materialDetail.SI_calculations.SI_PRJFMD ||
      material.MDB.old.SI_calculations.SI_PRJFMD ||
      materialDetail.SI_calculations.SI_PFMD ||
      material.MDB.old.SI_calculations.SI_PFMD;
  }
  let submittalDistributedDateString =
    materialDetail.projected_last_milestone_date;
  if (type === "after") {
    submittalDistributedDateString =
      materialDetail.projected_last_milestone_date ||
      material.MDB.old.projected_last_milestone_date;
  }
  const finalDeadlineDateString = materialDetail.SI_calculations.SI_FDD;
  const nextPlannedMilestoneDateString =
    materialDetail.SI_calculations.SI_PRJNMD ||
    materialDetail.SI_calculations.SI_PNMD;
  const lastPlannedMilestoneDateString =
    materialDetail.SI_calculations.SI_PRJLMD ||
    materialDetail.SI_calculations.SI_PLMD;

  allDates.push(DateUtils.dateTimeObj()); // Client Today's date
  allDates.push(DateUtils.dateTimeObj(assignedToSCDateString));
  allDates.push(DateUtils.dateTimeObj(submittalDistributedDateString));
  allDates.push(DateUtils.dateTimeObj(finalDeadlineDateString));
  allDates.push(DateUtils.dateTimeObj(nextPlannedMilestoneDateString));
  allDates.push(DateUtils.dateTimeObj(lastPlannedMilestoneDateString));

  const timelineData: TimelineData = {
    today: {
      date: DateUtils.dateTimeObj(), // Client Today's date
      label: ""
    },
    siCalculations: {
      effectiveFloat:
        materialDetail.SI_calculations.SI_EF === null
          ? materialDetail.SI_calculations.SI_F
          : materialDetail.SI_calculations.SI_EF,
      currentDelay: materialDetail.SI_calculations.SI_CD
    },
    primaryElement: {
      label: `Material ${materialDetail.material_sequence_id}`,
      link_url: `/project/${projectId}/materials/${material.material_id}`,
      milestones: {}
    },
    preElements: [],
    postElements
  };

  const { milestones } = timelineData.primaryElement;
  if (assignedToSCDateString)
    milestones.assigned_sc = {
      type: "assigned_sc",
      label: materialDetail.name_milestone_1 || "Material Start",
      date: DateUtils.dateTimeObj(assignedToSCDateString),
      normalizedDate: getNormalizedDate(
        DateUtils.dateTimeObj(assignedToSCDateString)
      ),
      otherInfo: ""
    };
  if (lastPlannedMilestoneDateString) {
    milestones.last_planned_milestone = {
      type: "last_planned_milestone",
      label:
        isProjectedDateExist && changeLabelForProjected
          ? "Projected Date of Last Milestone"
          : "Last Planned Milestone",
      date: DateUtils.dateTimeObj(lastPlannedMilestoneDateString),
      normalizedDate: getNormalizedDate(
        DateUtils.dateTimeObj(lastPlannedMilestoneDateString)
      ),
      otherInfo: ""
    };
  }
  if (submittalDistributedDateString)
    milestones.submittal_distributed = {
      type: "submittal_distributed",
      label:
        getLastMileStone(materialDetail, "name_milestone_") ||
        "Required On Job",
      date: DateUtils.dateTimeObj(submittalDistributedDateString),
      normalizedDate: getNormalizedDate(
        DateUtils.dateTimeObj(submittalDistributedDateString)
      ),
      otherInfo: ""
    };
  if (finalDeadlineDateString)
    milestones.final_deadline = {
      type: "final_deadline",
      label: "Final Deadline",
      date: DateUtils.dateTimeObj(finalDeadlineDateString),
      normalizedDate: getNormalizedDate(
        DateUtils.dateTimeObj(finalDeadlineDateString)
      ),
      otherInfo: governingTask
        ? `(${governingTask.activity_id}-${governingTask.name})`
        : ""
    };

  if (nextPlannedMilestoneDateString) {
    milestones.next_planned_milestone = {
      type: "next_planned_milestone",
      label:
        isProjectedDateExist && changeLabelForProjected
          ? "Projected Date of Next Milestone"
          : "Next Planned Milestone", // nextPlannedText(submittalDetails.next_milestone),
      date: DateUtils.dateTimeObj(nextPlannedMilestoneDateString),
      normalizedDate: getNormalizedDate(
        DateUtils.dateTimeObj(nextPlannedMilestoneDateString)
      ),
      otherInfo:
        nextPlannedMilestoneDateString === lastPlannedMilestoneDateString ||
        nextPlannedMilestoneDateString === assignedToSCDateString
          ? ""
          : materialDetail.next_milestone || ""
    };
  }

  return {
    allDates,
    timelineData
  };
}

export function computeTimelineDataForSubmittal(
  type: "before" | "after",
  submittal: TLinkedSubmittal,
  activityComparison: TActivitiesV3,
  projectId: string
): {
  allDates: Moment[];
  timelineData: TimelineData;
} {
  const allDates: Moment[] = [];
  let governingMaterials = submittal.old_governing_material;
  let activityComparisonStartDate = activityComparison.old_start;
  let submittalDetails = submittal.SDB.old;
  let governingTask =
    submittal.old_governing_task?.length > 0
      ? submittal.old_governing_task[0]
      : null;
  if (type === "after") {
    governingMaterials = submittal.new_governing_material;
    activityComparisonStartDate = activityComparison.new_start;
    submittalDetails = submittal.SDB.new;
    governingTask =
      submittal.new_governing_task?.length > 0
        ? submittal.new_governing_task[0]
        : null;
  }

  const postElements = governingMaterials.map((material) => {
    const milestones: { [type: string]: Milestone } = {};
    const materialStartDateString = material.start_date;
    if (materialStartDateString) {
      const date = DateUtils.dateTimeObj(materialStartDateString);
      milestones.start = {
        date,
        label: "Material Start",
        type: "start",
        normalizedDate: getNormalizedDate(date),
        otherInfo: ""
      };
      allDates.push(DateUtils.dateTimeObj(materialStartDateString));
    }
    const materialEndDateString = activityComparisonStartDate;
    if (materialEndDateString) {
      const date = DateUtils.dateTimeObj(materialEndDateString);
      milestones.end = {
        date,
        label: "Required On Job",
        type: "end",
        normalizedDate: getNormalizedDate(date),
        otherInfo: ""
      };
      allDates.push(DateUtils.dateTimeObj(materialEndDateString));
    }
    return {
      label: material.implicit
        ? `Material for submittal ${submittalDetails.submittal_sequence_id}`
        : material.name,
      link_url: `/project/${projectId}/material/${material.id}`,
      milestones
    };
  });

  // const todayDateString =
  //   submittal.SDB.new.SI_calculations.SI_TD ||
  //   submittal.SDB.old.SI_calculations.SI_TD;

  const isProjectedDateExist = !!submittalDetails.SI_calculations.SI_PRJLMD;

  let assignedToSCDateString =
    submittalDetails.SI_calculations.SI_AFMD ||
    submittalDetails.SI_calculations.SI_PRJFMD ||
    submittalDetails.SI_calculations.SI_PFMD;

  if (type === "after") {
    assignedToSCDateString =
      submittalDetails.SI_calculations.SI_AFMD ||
      submittal.SDB.old.SI_calculations.SI_AFMD ||
      submittalDetails.SI_calculations.SI_PRJFMD ||
      submittal.SDB.old.SI_calculations.SI_PRJFMD ||
      submittalDetails.SI_calculations.SI_PFMD ||
      submittal.SDB.old.SI_calculations.SI_PFMD;
  }

  // FDD - Final deadline date. It could be the linked governing material or activity start date.
  // If there is no linking available then it will be manual date.
  const finalDeadlineDateString = submittalDetails.SI_calculations.SI_FDD;

  const nextPlannedMilestoneDateString =
    submittalDetails.SI_calculations.SI_PRJNMD ||
    submittalDetails.SI_calculations.SI_PNMD;
  const lastPlannedMilestoneDateString =
    submittalDetails.SI_calculations.SI_PRJLMD ||
    submittalDetails.SI_calculations.SI_PLMD;

  allDates.push(DateUtils.dateTimeObj()); // Client Today's date
  allDates.push(DateUtils.dateTimeObj(assignedToSCDateString));
  allDates.push(DateUtils.dateTimeObj(finalDeadlineDateString));
  allDates.push(DateUtils.dateTimeObj(nextPlannedMilestoneDateString));
  allDates.push(DateUtils.dateTimeObj(lastPlannedMilestoneDateString));

  const timelineData: TimelineData = {
    today: {
      date: DateUtils.dateTimeObj(), // Client Today's date
      label: ""
    },
    siCalculations: {
      effectiveFloat:
        submittalDetails.SI_calculations.SI_EF === null
          ? submittalDetails.SI_calculations.SI_F
          : submittalDetails.SI_calculations.SI_EF,
      currentDelay: submittalDetails.SI_calculations.SI_CD
    },
    primaryElement: {
      label: `Submittal ${submittalDetails.submittal_sequence_id}`,
      link_url: `/project/${projectId}/submittals/${submittal.submittal_id}`,
      milestones: {}
    },
    preElements: [],
    postElements
  };

  const { milestones } = timelineData.primaryElement;
  if (assignedToSCDateString)
    milestones.assigned_sc = {
      type: "assigned_sc",
      label: "Assigned to Subcontractor",
      date: DateUtils.dateTimeObj(assignedToSCDateString),
      normalizedDate: getNormalizedDate(
        DateUtils.dateTimeObj(assignedToSCDateString)
      ),
      otherInfo: ""
    };
  if (lastPlannedMilestoneDateString) {
    milestones.last_planned_milestone = {
      type: "last_planned_milestone",
      label:
        isProjectedDateExist && changeLabelForProjected
          ? "Projected Date of Last Milestone"
          : "Last Planned Milestone",
      date: DateUtils.dateTimeObj(lastPlannedMilestoneDateString),
      normalizedDate: getNormalizedDate(
        DateUtils.dateTimeObj(lastPlannedMilestoneDateString)
      ),
      otherInfo: submittalDetails.last_milestone_name || ""
    };
  }

  if (finalDeadlineDateString)
    milestones.final_deadline = {
      type: "final_deadline",
      label: "Final Deadline",
      date: DateUtils.dateTimeObj(finalDeadlineDateString),
      normalizedDate: getNormalizedDate(
        DateUtils.dateTimeObj(finalDeadlineDateString)
      ),
      otherInfo: governingTask
        ? `(${governingTask.activity_id}-${governingTask.name})`
        : ""
    };

  if (nextPlannedMilestoneDateString) {
    milestones.next_planned_milestone = {
      type: "next_planned_milestone",
      label:
        isProjectedDateExist && changeLabelForProjected
          ? "Projected Date of Next Milestone"
          : "Next Planned Milestone", // nextPlannedText(submittalDetails.next_milestone),
      date: DateUtils.dateTimeObj(nextPlannedMilestoneDateString),
      normalizedDate: getNormalizedDate(
        DateUtils.dateTimeObj(nextPlannedMilestoneDateString)
      ),
      otherInfo:
        nextPlannedMilestoneDateString === lastPlannedMilestoneDateString ||
        nextPlannedMilestoneDateString === assignedToSCDateString
          ? ""
          : submittalDetails.next_milestone || ""
    };
  }

  return {
    allDates,
    timelineData
  };
}

/**
 * Method to format the deleted activity object to the material V6 visualization object
 * @param activities
 * @returns
 */
export function formatDeletedActivitiesDataForV6(activities: any): any {
  const formattedActivities = { activities: [] };
  if (activities && activities.length > 0) {
    formattedActivities.activities = activities.map((activity: any) => {
      const formattedActivity = {
        id: activity.id,
        new_start: null,
        new_finish: null,
        metadata: {
          Start: null,
          Finish: null
        },
        new_end_date: null,
        text: activity.text,
        old_start: activity.start_date,
        old_end_date: activity.end_date,
        delta: null,
        deleted: true,
        linked_materials: activity.unlinked_materials,
        linked_submittals: activity.unlinked_submittals
      };
      return formattedActivity;
    });
  }
  return formattedActivities;
}

/**
 * Method to categories the deleted activities in submittals and material category.
 * After categorisation format the data for rendering.
 * @param deletedActivitiesImpact
 * @returns
 */
export function getDeletedActivitiesCategory(deletedActivitiesImpact: any) {
  const deletedActivitiesCategory = {
    affectedSubmittals: {},
    affectedMaterials: {}
  };

  if (deletedActivitiesImpact && deletedActivitiesImpact) {
    const deletedActivities = deletedActivitiesImpact;
    const submittals: any = [];
    const materials: any = [];

    deletedActivities.forEach((activity: any) => {
      if (activity.unlinked_submittals.length > 0) {
        submittals.push(activity);
      }
      if (activity.unlinked_materials.length > 0) {
        materials.push(activity);
      }
    });

    deletedActivitiesCategory.affectedSubmittals =
      formatDeletedActivitiesDataForV6(submittals);
    deletedActivitiesCategory.affectedMaterials =
      formatDeletedActivitiesDataForV6(materials);
  }
  return deletedActivitiesCategory;
}

export function filterImpactedMaterial(impactedMaterial: any, text: string) {
  const value = text?.toLowerCase();
  const results: any = {};

  if (!value || value.length === 0) {
    return impactedMaterial;
  }
  const findMaterial = (material: any) => {
    return (
      material?.MDB?.old?.name?.toLowerCase()?.includes(value) ||
      `${material?.MDB?.old?.material_id}`?.toLowerCase()?.includes(value) ||
      material?.MDB?.new?.name?.toLowerCase()?.includes(value) ||
      `${material?.MDB?.new?.material_sequence_id}`
        ?.toLowerCase()
        ?.includes(value) ||
      // Test new governing task
      material?.new_governing_task?.some(
        (task: { activity_id: any; name: string; text: string }) =>
          `${task?.activity_id}`?.toLowerCase()?.includes(value) ||
          task?.name?.toLowerCase()?.includes(value) ||
          task?.text?.toLowerCase()?.includes(value)
      ) ||
      // Test old governing task
      material?.old_governing_task?.some(
        (task: { activity_id: any; name: string; text: string }) =>
          `${task?.activity_id}`?.toLowerCase()?.includes(value) ||
          task?.name?.toLowerCase()?.includes(value) ||
          task?.text?.toLowerCase()?.includes(value)
      )
    );
  };

  const findSubmittals = (submittal: any) => {
    return (
      submittal?.SDB?.old?.title?.toLowerCase()?.includes(value) ||
      `${submittal?.SDB?.old?.submittal_sequence_id}`
        ?.toLowerCase()
        ?.includes(value) ||
      submittal?.SDB?.new?.title?.toLowerCase()?.includes(value) ||
      `${submittal?.SDB?.new?.submittal_sequence_id}`
        ?.toLowerCase()
        ?.includes(value)
    );
  };

  // Search inside the activity
  Object.keys(impactedMaterial).forEach((key) => {
    const { activities } = impactedMaterial[key];
    results[key] = [];

    if (activities && activities.length) {
      // Iterate each activity
      const filteredActivities = activities.filter((activity: any) => {
        // Search activity properties
        if (`${activity?.id}`?.toLowerCase()?.includes(value)) {
          return true;
        }
        if (`${activity?.text}`?.toLowerCase()?.includes(value)) {
          return true;
        }

        if (
          activity?.linked_materials?.some(findMaterial) ||
          activity?.linked_submittals?.some(findSubmittals)
        ) {
          return true;
        }
        return false;
      }); // filter end

      results[key] = { activities: filteredActivities };
    } // if end
  }); // end of for loop
  return results;
}

/**
 * Method to filter the activity which has given search text
 * @param impactedSubmittal
 * @param text
 * @returns
 */
export const filterImpactedSubmittal = (
  impactedSubmittal: any,
  text: string
) => {
  const results: any = filterImpactedMaterial(impactedSubmittal, text);
  return results;
};

/**
 * Method to format the impacted category data
 * @param categoryData
 * @returns
 */
export function formatCategoryData(categoryData: any, entityType: EntityType) {
  const getImpactedEntitiesCount = (activities: any, type: EntityType) => {
    let count = 0;
    if (type === EntityType.Submittal) {
      activities?.forEach((activity: any) => {
        count += activity?.linked_submittals?.length || 0;
      });
    } else if (type === EntityType.Material) {
      activities?.forEach((activity: any) => {
        count += activity?.linked_materials?.length || 0;
      });
    }
    return count;
  };
  const results: any = {};
  if (categoryData) {
    Object.keys(categoryData).forEach((key) => {
      const { activities } = categoryData[key];
      results[key] = {
        categoryType: (key || "").toLowerCase(),
        count: getImpactedEntitiesCount(activities, entityType),
        showCategory: true,
        data: activities || [],
        entityType
      };
    });
  }

  return results;
}

const MaterialExtraInfoHelper = {
  getIsLinkedWithEndDate: (material: any) => {
    if (!material) {
      return false;
    }
    return material?.old_governing_task?.length > 0
      ? material?.old_governing_task[0]?.linked_to_end_date
      : false;
  },
  getMaterialROJ: (material: any) => {
    if (!material) {
      return null;
    }
    return {
      new: material?.new?.ROJ_date,
      old: material?.old?.ROJ_date
    };
  },
  getMaterialROJLabel: (material: any) => {
    if (!material) {
      return null;
    }
    const dateBlock = material?.new;
    let lastMilestoneName = null;
    const propertyPrefix = "name_milestone_";

    for (let index = 1; index <= 10; index = 1 + index) {
      if (!dateBlock[`${propertyPrefix}${index}`]) {
        return lastMilestoneName;
      }
      lastMilestoneName = dateBlock[`${propertyPrefix}${index}`];
    }
    return lastMilestoneName;
  },

  getFormattedMaterial: (
    material: any,
    allMaterialDateBlock: any,
    isOld: boolean = false
  ) => {
    const materialDB = allMaterialDateBlock[material.id];
    const rojLabel = MaterialExtraInfoHelper.getMaterialROJLabel(materialDB);
    const rojDate = MaterialExtraInfoHelper.getMaterialROJ(materialDB);

    const materialExtraInfo = {
      end_date: isOld ? rojDate?.old : rojDate?.new,
      roj_label: rojLabel,
      ROJ: rojDate,
      ROJ_Label: rojLabel,
      linked_to_end_date:
        MaterialExtraInfoHelper.getIsLinkedWithEndDate(materialDB)
    };
    const newMaterial = {
      ...material,
      ...materialExtraInfo
    } as GoverningMaterial;
    return newMaterial;
  }
};

/**
 * Method to format the linked submittal data to add more info in governing material data
 */
export function formatLinkedSubmittalDataForGoverningMaterial(
  categorywiseImpactedActivityWithSubmittal: any,
  allMaterialDateBlock: any
) {
  const formattedData: any = {};
  if (categorywiseImpactedActivityWithSubmittal) {
    // Iterate on each category of impacts
    Object.keys(categorywiseImpactedActivityWithSubmittal).forEach(
      (category) => {
        const { activities } =
          categorywiseImpactedActivityWithSubmittal[category];

        // Iterate the each activity and get the linked submittals
        const formattedActivities = activities?.map((activity: any) => {
          const linkedSubmittals = activity?.linked_submittals;

          // Iterate the each impacted submittal
          const newFormattedLinkedSubmittals = linkedSubmittals.map(
            (linkedSubmittal: any) => {
              // Get the each old governing material and format the data
              const formattedOldGoverningMaterials =
                linkedSubmittal?.old_governing_material.map((material: any) => {
                  // Format the each material with all missing fields to comply with type GoverningMaterial
                  return MaterialExtraInfoHelper.getFormattedMaterial(
                    material,
                    allMaterialDateBlock,
                    true
                  );
                });

              // Get the each new governing material and format the data
              const formattedNewGoverningMaterials =
                linkedSubmittal?.new_governing_material.map((material: any) => {
                  // Format the each material with all missing fields to comply with type GoverningMaterial
                  return MaterialExtraInfoHelper.getFormattedMaterial(
                    material,
                    allMaterialDateBlock,
                    false
                  );
                });

              const lnkSubmittal = {
                ...linkedSubmittal,
                ...{
                  oldGoverningMaterials: formattedOldGoverningMaterials,
                  newGoverningMaterials: formattedNewGoverningMaterials
                }
              };
              return lnkSubmittal;
            }
          );
          const frmtActivity = {
            ...activity,
            ...{ linked_submittals: newFormattedLinkedSubmittals }
          };
          return frmtActivity;
        });

        formattedData[category] = { activities: formattedActivities };
      }
    );
  }

  return formattedData;
}

export function getFormattedMaterialImpactData(
  categorywiseImpactedActivityWithMaterial: any,
  allSubmittalDateBlock: any
) {
  const formattedData: any = {};
  if (categorywiseImpactedActivityWithMaterial) {
    // Iterate on each category of impacts
    Object.keys(categorywiseImpactedActivityWithMaterial).forEach(
      (category) => {
        const { activities } =
          categorywiseImpactedActivityWithMaterial[category];

        // Iterate the each activity and get the linked submittals
        const formattedActivities = activities?.map((activity: any) => {
          const linkedMaterials = activity?.linked_materials;

          // Iterate the each impacted submittal
          const newFormattedLinkedMaterials = linkedMaterials.map(
            (linkedMaterial: any) => {
              const isImplicit = linkedMaterial?.MDB?.old?.implicit;
              let name = "";
              if (isImplicit) {
                const linkedSubmittalId =
                  linkedMaterial?.linked_submittals.length > 0 &&
                  linkedMaterial?.linked_submittals[0];

                const submittal = allSubmittalDateBlock[linkedSubmittalId];
                name = `${submittal?.old?.submittal_sequence_id} - ${submittal?.old?.title}`;
              }

              const lnkMaterial = {
                ...{ ...linkedMaterial, ...{ name } }
              };

              return lnkMaterial;
            }
          );
          const frmtActivity = {
            ...activity,
            ...{ linked_materials: newFormattedLinkedMaterials }
          };
          return frmtActivity;
        });

        formattedData[category] = { activities: formattedActivities };
      }
    );
  }
  return formattedData;
}
