import { Button, message, Result } from "antd";
import { useState } from "react";
import { resendInvite } from "./services";
import { LeadTimeErrorCodes } from "./widgets";

function GenerateNewLink({
  error,
  onEmailNewLinkSuccess,
  leadTimeRequestToken
}: {
  error: { response: { email: string } };
  onEmailNewLinkSuccess: () => void;
  leadTimeRequestToken: string;
}) {
  const [loading, setLoading] = useState(false);

  const onEmailNewLink = async () => {
    setLoading(true);
    const resendInviteResponse = await resendInvite(leadTimeRequestToken!);
    if (resendInviteResponse?.success) {
      if (
        resendInviteResponse.data?.error &&
        resendInviteResponse.data?.error ===
          LeadTimeErrorCodes.REQUEST_ALREADY_SENT
      ) {
        message.info(
          "A request with a new link has already been sent to the recipient",
          7
        );
        setLoading(false);
        return;
      }

      message.success("New link sent to the recipient");
      onEmailNewLinkSuccess();
    } else {
      message.error("An error occurred");
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col items-center justify-center h-[100dvh] bg-white">
      <Result
        status="403"
        title="Generate a New Link"
        subTitle={
          <div>
            For security reasons, this link has expired. A new link can be sent
            to the recipient of this request.
            {error?.response?.email && (
              <div>&lt;{error?.response?.email}&gt;</div>
            )}
          </div>
        }
        extra={
          <Button
            type="primary"
            onClick={onEmailNewLink}
            loading={loading}
            disabled={loading}
          >
            Email New Link
          </Button>
        }
      />
    </div>
  );
}

export default GenerateNewLink;
