export default function ScheduleCiqIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.91667 0.166748V1.33341H10.6705C10.9905 1.33341 11.25 1.59297 11.25 1.9129V11.2539C11.25 11.5739 10.9905 11.8334 10.6705 11.8334H1.32948C1.00944 11.8334 0.75 11.5739 0.75 11.2539V1.9129C0.75 1.59286 1.00955 1.33341 1.32948 1.33341H3.08333V0.166748H8.91667ZM3.08333 2.50008H1.91667V10.6667H10.0833V2.50008H8.91667V3.66675H3.08333V2.50008ZM4.25 8.33342V9.50008H3.08333V8.33342H4.25ZM4.25 6.58342V7.75008H3.08333V6.58342H4.25ZM4.25 4.83342V6.00008H3.08333V4.83342H4.25ZM7.75 1.33341H4.25V2.50008H7.75V1.33341Z"
        fill="black"
        fillOpacity="0.6"
      />
    </svg>
  );
}
