import { ColDef, ColGroupDef, ValueFormatterParams } from "ag-grid-community";
import { DateFilter, DateUtils } from "utils/dateutils";
import {
  criticalActivityHeaderComponent,
  criticalActivityIndicatorCellRenderer
} from "components/cell-renders";
import { GanttActivityTypes } from "constants/index";
import { TActivityTask } from "../models";
import {
  getLinkedMaterialGetterData,
  getLinkedSubmittalGetterData
} from "../utils/value-getters";

export const activityColDefs = [
  {
    colId: "source_task_id",
    field: "source_task_id",
    headerName: "ACTIVITY ID",
    headerTooltip: "ACTIVITY ID",
    cellRenderer: "agGroupCellRenderer",
    tooltipField: "source_task_id",
    filter: true,
    getQuickFilterText: (params) => {
      const { value, data, context } = params;
      let allValues = [value];
      data.linked_materials.forEach((m) => {
        allValues = allValues.concat(getLinkedMaterialGetterData({ data: m }));
        m.linked_submittals.forEach((s) => {
          allValues = allValues.concat(
            getLinkedSubmittalGetterData({ data: s.submittal, context })
          );
        });
      });

      data.linked_submittals.forEach((s) => {
        allValues = allValues.concat(
          getLinkedSubmittalGetterData({ data: s, context })
        );
      });
      return allValues.join(" ");
    }
  },
  {
    colId: "text",
    field: "text",
    headerName: "ACTIVITY NAME",
    headerTooltip: "ACTIVITY NAME",
    tooltipField: "text"
  },
  {
    colId: "start_date",
    field: "start_date",
    headerName: "START DATE",
    headerTooltip: "START DATE",
    comparator: DateFilter.comparator,
    valueGetter: ({ data }) =>
      data?.start_date ? DateUtils.format(data.start_date) : "",
    tooltipValueGetter: ({ value }) => value,
    sort: "asc"
  },
  {
    colId: "end_date",
    field: "end_date",
    headerName: "END DATE",
    headerTooltip: "END DATE",
    comparator: DateFilter.comparator,
    valueGetter: ({ data }) =>
      data?.end_date ? DateUtils.format(data.end_date) : "",
    tooltipValueGetter: ({ value }) => value
  },
  {
    colId: "duration",
    field: "duration",
    headerName: "DURATION",
    headerTooltip: "DURATION",
    valueGetter: ({ data }) => {
      if (data?.duration) {
        return data.duration / 8;
      }
      return "";
    },
    tooltipValueGetter: ({ value }) => value
  },
  {
    colId: "critical",
    field: "critical",
    headerName: "",
    headerTooltip: "Critical Activity",
    headerComponentParams: criticalActivityHeaderComponent,
    cellRenderer: criticalActivityIndicatorCellRenderer,
    filter: "agSetColumnFilter",
    menuTabs: ["filterMenuTab"],
    filterParams: {
      valueFormatter: (params: ValueFormatterParams) => {
        return params?.value === "true" ? "Critical" : "Non Critical";
      },
      comparator: (valA: any, valB: any) => {
        if (valA === valB) return 0;
        return valA > valB ? -1 : 1;
      }
    },
    minWidth: 90,
    maxWidth: 90
  },
  {
    colId: "total_slack",
    field: "total_slack",
    headerName: "Float",
    headerTooltip: "Float",
    cellStyle: (params) => {
      if (params?.data?.critical) {
        return { fontWeight: "bold" };
      }
      return { fontWeight: "normal" };
    },
    valueGetter: (params: any) => {
      if (
        !params?.data?.total_slack ||
        params.data.type === GanttActivityTypes.Wps
      )
        return "";
      const float = Math.floor((params?.data?.total_slack || 0) / 8);
      return `${float} days`;
    },
    tooltipValueGetter: (params: any) => params?.value
  }
] as (ColDef<TActivityTask> | ColGroupDef<TActivityTask>)[];
