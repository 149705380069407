import { Tabs } from "antd";
import { ProjectContext } from "context/ProjectProvider";
import "../module-settings.scss";

import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router";
import { isProjectInIntegrationMode } from "utils/utils";
import FeatureProjectRiskThresholdSettings from "components/feature-project-risk-threshold-settings";
import { FeatureTypes } from "entity-app/constants";
import FeatureRenamedFieldSettings from "components/feature-rename-fields-settings";
import SubmittalNumbering from "./components/submittal-numbering";
import MilestoneActionSetting from "./components/milestone-actions";
import DateBlockDurationSetting from "./components/submittal-dateblock-duration";

function SubmittalSettings(props: {
  disabled: boolean;
  projectId: string;
  submittalheader: any;
}) {
  const { disabled, projectId, submittalheader } = props;
  const { projectDetails: projectInfo } = useContext(ProjectContext);

  const history = useHistory();
  const { tab3Id } = useParams() as any;
  const [selectedTab, setSelectedTab] = useState<string>(tab3Id || "dateblock");
  const isIntegrationMode = isProjectInIntegrationMode(
    projectInfo ? projectInfo.mode : 0
  );

  const tabItems = [
    {
      label: "Date Block Duration",
      key: "dateblock",
      children: (
        <DateBlockDurationSetting
          featureId={FeatureTypes.SUBMITTALS}
          disabled={disabled}
        />
      )
    },
    {
      label: "Milestone Actions",
      key: "actions",
      children: (
        <MilestoneActionSetting
          featureId={FeatureTypes.SUBMITTALS}
          disabled={disabled}
        />
      )
    },
    {
      label: "Rename Fields",
      key: "rename-field",
      children: (
        <FeatureRenamedFieldSettings
          featureId={FeatureTypes.SUBMITTALS}
          disabled={disabled}
          columnHeader={submittalheader}
        />
      )
    },
    {
      label: "Risk Threshold",
      key: "risk-threshold",
      children: (
        <FeatureProjectRiskThresholdSettings
          featureId={FeatureTypes.SUBMITTALS}
        />
      )
    },
    ...(!isIntegrationMode
      ? [
          {
            label: "Submittal Numbering",
            key: "numbering",
            children: <SubmittalNumbering disabled={disabled} />
          }
        ]
      : [])
  ];

  return (
    <div className="w-full h-full bg-white module-settings--tabs">
      <Tabs
        animated
        tabPosition="left"
        tabBarStyle={{ width: "180px" }}
        onChange={(activeKey) => {
          history.push(
            `/project/${projectId}/settings/modules/submittals/${activeKey.toString()}`
          );
          setSelectedTab(activeKey);
        }}
        activeKey={selectedTab}
        items={tabItems}
        tabBarGutter={2}
        destroyInactiveTabPane
      />
    </div>
  );
}
export default SubmittalSettings;
