import { MailOutlined } from "@ant-design/icons";
import { Button } from "antd";

function ReportCardV2({
  icon,
  title,
  detail,
  status,
  onViewClick,
  onConfigureClick
}: {
  icon: any;
  title: string;
  detail: any;
  status?: React.ReactNode | string;
  onViewClick: () => void;
  onConfigureClick: () => void;
}) {
  return (
    <div className="flex flex-col w-[309px] h-[380px] rounded border-solid border-[#F0F0F0] border mt-5">
      <div className="w-full bg-gray-100 font-medium text-sm items-center py-2 px-3">
        {title}
      </div>
      <div className="grow">
        <div className="h-full px-4">
          <div className="p-1 py-6 text-center">{icon}</div>
          <div className="px-1 text-sm">{detail}</div>
        </div>
      </div>
      {status ? <div className="px-4">{status}</div> : null}
      <div className={`p-4 flex gap-2 ${status ? "pt-2" : ""}`}>
        <Button
          type="primary"
          className="flex-1"
          onClick={onViewClick}
          data-test-id="report-card-view-button"
          disabled
          title="This feature is in Progress"
        >
          View
        </Button>
        <Button
          className="flex-1"
          onClick={onConfigureClick}
          data-test-id="report-card-configure-button"
        >
          <MailOutlined className="text-[#00000073]" /> Configure
        </Button>
      </div>
    </div>
  );
}
export default ReportCardV2;
