import { Form, Button, Input, FormRule, message } from "antd";
import {
  isPermissionNotGrantted,
  ProjectContext,
  TProjectContext
} from "context/ProjectProvider";
import { FeatureTypes } from "entity-app/constants";

import { useCIQMutation, useCIQQuerySubscription } from "hooks/ciq-gql-hooks";
import { ThresholdType } from "pages/project-setting-menu/module-settings/model";
import { useContext, useState, useMemo, useEffect } from "react";
import { GET_PROJECT_FEATURE_CONFIGURATION } from "services/graphQL/ciq-queries";
import { MUTATION_PROJECT_FEATURE_CONFIG } from "services/graphQL/mutations";
import { ProjectPermissionEnum } from "../../constants";

const riskThresholdInstructions: Partial<
  Record<
    FeatureTypes,
    {
      instructions: string;
      hints: string[];
    }
  >
> = {
  [FeatureTypes.BID_PACKAGE]: {
    instructions:
      "Indicate the value of float at which you would consider the workflow to be at High, Medium and Low risk. These values can later be set for individual Bid Package.",
    hints: [
      "Bid Package is at high risk if Float <= High Risk Threshold",
      "Bid Package is at medium risk if High Risk Threshold < Float <= Low Risk Threshold",
      "Bid Package is at low risk if Float > Low Risk Threshold"
    ]
  },
  [FeatureTypes.MATERIALS]: {
    instructions:
      "Indicate the value of float at which you would consider the workflow to be at High, Medium and Low risk. These values can later be set for individual Material.",
    hints: [
      "Material is at high risk if Float <= High Risk Threshold",
      "Material is at medium risk if High Risk Threshold < Float <= Low Risk Threshold",
      "Material is at low risk if Float > Low Risk Threshold"
    ]
  },
  [FeatureTypes.SUBMITTALS]: {
    instructions:
      "Indicate the value of float at which you would consider the workflow to be at High, Medium and Low risk. These values can later be set for individual Submittal.",
    hints: [
      "Submittal is at high risk if Float <= High Risk Threshold",
      "Submittal is at medium risk if High Risk Threshold < Float <= Low Risk Threshold",
      "Submittal is at low risk if Float > Low Risk Threshold"
    ]
  }
};

function FeatureProjectRiskThresholdSettings({
  featureId
}: {
  featureId: FeatureTypes;
}) {
  const [form] = Form.useForm();

  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);
  const [isFieldsEditable, setFieldsEditable] = useState<boolean>(false);

  const { gqlClientForProject, tokenContents }: TProjectContext =
    useContext(ProjectContext);

  const { data: featureConfigurationsData } = useCIQQuerySubscription<{
    project_feature_configurations: Array<ThresholdType>;
  }>(GET_PROJECT_FEATURE_CONFIGURATION, {
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });

  // console.log("featureConfigurationsData", featureConfigurationsData);

  const [updateProjectFeatureConfigMutation] = useCIQMutation(
    MUTATION_PROJECT_FEATURE_CONFIG,
    {
      client: gqlClientForProject
    }
  );

  const disabled = isPermissionNotGrantted(
    ProjectPermissionEnum.EditProjectDetail,
    tokenContents?.role!
  );

  const initialValues = useMemo(() => {
    return featureConfigurationsData?.project_feature_configurations.find(
      (x) => x.feature_id === featureId
    );
  }, [featureConfigurationsData?.project_feature_configurations, featureId]);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [form, initialValues]);

  const thresholdRules: FormRule[] = [
    {
      required: true,
      message: "Please enter threshold"
    },
    {
      validator: (_, value) => {
        const number = Number(value);
        if (!Number.isInteger(number) || Number.isNaN(number)) {
          return Promise.reject(
            new Error("Please enter threshold in numbers ( + or - ) only.")
          );
        }
        return Promise.resolve();
      }
    },
    {
      warningOnly: true,
      message: (
        <div className="text-[#3B3B3BCC]">
          Please note that negative value of float indicates the workflow is
          already delayed.
        </div>
      ),
      validator: async (_, value) => {
        const number = Number(value);
        if (number < 0) {
          return Promise.reject();
        }
        return Promise.resolve();
      }
    },
    {
      validateTrigger: "onSubmit",
      message:
        "High risk threshold has to be lower than the low risk threshold.",
      validator: () => {
        const highRiskThreshold = form.getFieldValue("high_risk_threshold");
        const lowRiskThreshold = form.getFieldValue("low_risk_threshold");

        if (Number(lowRiskThreshold) <= Number(highRiskThreshold)) {
          return Promise.reject();
        }
        return Promise.resolve();
      }
    }
  ];

  const onFinishForm = async (data: any) => {
    setSubmitInProgress(true);
    const updatePayload = {
      feature_id: featureId,
      high_risk_threshold: parseInt(data.high_risk_threshold, 10),
      low_risk_threshold: parseInt(data.low_risk_threshold, 10)
    };

    const response = await updateProjectFeatureConfigMutation({
      variables: updatePayload
    });
    // console.log("response", response);
    if (response.success) {
      setSubmitInProgress(false);
      setFieldsEditable(false);
      message.success("Risk Threshold updated successfully");
    }
  };

  const cancelAction = () => {
    form.setFieldsValue(initialValues);
    form.validateFields();
    setFieldsEditable(false);
    setSubmitInProgress(false);
  };

  if (!initialValues) {
    return null;
  }

  // console.log({ disabled, submitInProgress, isFieldsEditable });

  return (
    <div className="flex-col">
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinishForm}
        disabled={disabled || submitInProgress}
        validateTrigger={["onSubmit", "onChange"]}
      >
        <div className="flex w-full items-center justify-between py-1 px-4">
          <div className=" text-[#3B3B3B] font-semibold text-sm">
            RISK THRESHOLD
          </div>
          {isFieldsEditable ? (
            <div className="flex space-x-4">
              <Button
                className="w-24"
                type="primary"
                htmlType="submit"
                loading={submitInProgress}
                disabled={submitInProgress}
                data-testid="save-button"
              >
                Save
              </Button>
              <Button
                className="w-24"
                disabled={submitInProgress}
                onClick={cancelAction}
                data-testid="cancel-button"
              >
                Cancel
              </Button>
            </div>
          ) : (
            <Button
              type="primary"
              className="w-24"
              disabled={disabled}
              onClick={() => setFieldsEditable(true)}
              data-testid="edit-button"
            >
              Edit
            </Button>
          )}
        </div>
        <div className="flex items-start w-full info-label pl-4">
          <div className="space-y-1">
            <div className="flex text-justify">
              {riskThresholdInstructions[featureId]?.instructions}
            </div>
            <ul className="list-disc space-y-2">
              <li>{riskThresholdInstructions[featureId]?.hints[0]}</li>
              <li>{riskThresholdInstructions[featureId]?.hints[1]}</li>
              <li>{riskThresholdInstructions[featureId]?.hints[2]}</li>
            </ul>
          </div>
        </div>
        <div className="flex justify-center w-full mt-10 bg-white">
          <div className="w-full flex justify-center">
            <div className="grid grid-cols-2 gap-x-20 w-3/5">
              <Form.Item
                label="HIGH RISK THRESHOLD"
                name="high_risk_threshold"
                rules={thresholdRules}
              >
                <Input
                  type="text"
                  disabled={!isFieldsEditable}
                  data-testid="high-risk-threshold"
                />
              </Form.Item>
              <Form.Item
                label="LOW RISK THRESHOLD"
                name="low_risk_threshold"
                rules={thresholdRules}
              >
                <Input
                  type="text"
                  disabled={!isFieldsEditable}
                  data-testid="low-risk-threshold"
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default FeatureProjectRiskThresholdSettings;
