import { Button, Divider, Form, Modal, Select, Tooltip, message } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import "./bulk-edit-submittal-details.css";
import { useMutation, useQuery } from "@apollo/client";

import {
  MUTATION_UPDATE_MANY_NEW_MATERIAL_DATBLOCK,
  MUTATION_UPDATE_SUBMITTALS
} from "services/graphQL/mutations";

import { compareObjectPropertyValues } from "utils/utils";
import { useProjectParticipants } from "hooks/project-participants";
import { QUERY_SUBMITTAL_TYPES } from "services/graphQL/queries";
import SpecNumberNameDropDown from "components/spec-number-name";
import SelectSearchNotFoundContent from "components/widgets/select-search-notfound-content";
import CreateSpecSectionModal from "components/submittal-details/create-spec-section";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import DisplayNameListPopover from "components/bulk-edit-material/display-name-list";
import UserTag from "components/user-tag/user-tag";
import {
  AcceptanceStatus,
  ESubmittalStatus,
  SidepanelStyles
} from "../../constants";

type TEditSubmittal = {
  spec_section_id: any;
  spec_name: any;
  spec_no: any;
  type: undefined | number;
  raw_type: string | undefined;
  responsible_contractor: undefined | string;
  submitter: undefined | string | null;
  submitter_unregistered: undefined | string | null;
  gc_reviewer: undefined | string | null;
  design_reviewer: undefined | string | null;
  design_reviewer_unregistered: undefined | string | null;
  design_reviewer_vendor_unregistered: undefined | string | null;
  float: any | undefined;
};

export type EditSubmittalLogs = {
  onUpdateDone: any;
  onCancelClick: any;
  selectedRows: Array<any>;
  gqlClient: any;
  showSubmittalEdit: boolean;
  setShowSubmittalEdit: any;
  projectId: string;
};

function BulkEditSubmittalDetails(props: EditSubmittalLogs) {
  const {
    onUpdateDone,
    onCancelClick,
    selectedRows,
    gqlClient,
    showSubmittalEdit,
    setShowSubmittalEdit,
    projectId
  } = props;

  const createSpecRef = useRef<any>(null);
  const [isDiff, setIsDiff] = useState(false);
  const [initialData, setInitialData] = useState<TEditSubmittal>();
  const [submittalListIds, setSubmittalListIds] = useState<{
    NotStarted: Array<{ id: string; name: string }>;
    Started: Array<{ id: string; name: string }>;
    Closed: Array<{ id: string; name: string }>;
  }>({
    NotStarted: [],
    Started: [],
    Closed: []
  });

  const [editSubmittalData, setEditSubmittalData] = useState<TEditSubmittal>({
    spec_section_id: undefined,
    spec_name: undefined,
    spec_no: undefined,
    type: undefined,
    raw_type: undefined,
    responsible_contractor: undefined,
    submitter: undefined,
    submitter_unregistered: "",
    gc_reviewer: undefined,
    design_reviewer: undefined,
    design_reviewer_unregistered: undefined,
    design_reviewer_vendor_unregistered: undefined,
    float: undefined
  });

  const [showCreateSpecSectionModal, setShowCreateSpecSectionModal] =
    useState(false);

  const { data: submittalTypes } = useQuery(QUERY_SUBMITTAL_TYPES);
  const {
    columnHeaders: { submittalHeaderMap }
  }: TProjectContext = useContext(ProjectContext);

  const { projectParticipants } = useProjectParticipants();
  const { Option } = Select;

  const [updateSubmittals, { loading }] = useMutation<any>(
    MUTATION_UPDATE_SUBMITTALS,
    {
      client: gqlClient
    }
  );

  const [updateNewDateBlock, { loading: dboffsetloading }] = useMutation(
    MUTATION_UPDATE_MANY_NEW_MATERIAL_DATBLOCK,
    {
      client: gqlClient
    }
  );

  useEffect(() => {
    if (initialData) {
      const compare = compareObjectPropertyValues(
        [initialData, editSubmittalData],
        Object.getOwnPropertyNames(initialData).map((x) => ({
          field: x
        }))
      );

      setIsDiff(compare.diffFoundSummary);
    }
  }, [initialData, editSubmittalData]);

  const onFinish = async () => {
    const compare = compareObjectPropertyValues(
      [initialData, editSubmittalData],
      Object.getOwnPropertyNames(initialData).map((x) => ({
        field: x
      }))
    );
    const formData = editSubmittalData as any;
    const setForNotStarted = {} as any;
    const setForStarted = {} as any;
    const setForOffset = {} as any;
    const fieldsForStartedOnly = [
      "spec_section_id",
      "spec_name",
      "spec_no",
      "type",
      "raw_type"
    ];
    const fieldsForDateBlock = ["float"];

    compare.fieldsAsArray.forEach((x) => {
      if (x.diffFound) {
        if (fieldsForDateBlock.includes(x.field)) {
          setForOffset[x.field] = formData[x.field];
        } else {
          setForNotStarted[x.field] = formData[x.field];
          if (fieldsForStartedOnly.includes(x.field)) {
            setForStarted[x.field] = formData[x.field];
          }
        }
      }
    });

    const submittalsResponse = [];
    if (
      submittalListIds.Started.length > 0 &&
      Object.keys(setForStarted).length > 0
    ) {
      const variables = {
        where: {
          id: {
            _in: submittalListIds.Started.map((x) => x.id)
          }
        },
        set: setForStarted
      };
      submittalsResponse.push(updateSubmittals({ variables }));
    }

    if (submittalListIds.NotStarted.length > 0) {
      if (Object.keys(setForNotStarted).length > 0) {
        const variables = {
          where: {
            id: {
              _in: submittalListIds.NotStarted.map((x) => x.id)
            }
          },
          set: setForNotStarted
        };

        submittalsResponse.push(updateSubmittals({ variables }));
      }
      if (Object.keys(setForOffset).length > 0) {
        const variables = {
          where: {
            submittal_id: {
              _in: submittalListIds.NotStarted.map((x) => x.id)
            }
          },
          set: setForOffset
        };
        submittalsResponse.push(updateNewDateBlock({ variables }));
      }
    }

    const res = await Promise.all(submittalsResponse);
    if (res.every((r) => r.data)) {
      message.success("Submittals are updated successfully");
      onUpdateDone();
    }
    if (res.some((r) => r.errors)) {
      const errors = res.find((r) => r.errors)?.errors;
      if (errors) message.error(errors[0].message);
    }
  };

  useEffect(() => {
    const compareFields = [
      "spec_name",
      "spec_no",
      "type",
      "raw_type",
      "responsible_contractor_id",
      "submitter_user_id",
      "submitter_unregistered",
      "gc_reviewer_user_id",
      "design_reviewer_user_id",
      "design_reviewer_unregistered",
      "float"
    ];

    const firstElement = {} as any;
    compareFields.forEach((field) => {
      firstElement[field] = selectedRows[0][field] ?? "";
    });

    if (selectedRows.length > 1) {
      const compare = compareObjectPropertyValues(
        selectedRows,
        compareFields.map((x) => ({
          field: x,
          label: x
        }))
      );
      compare.fieldsAsArray.forEach((x) => {
        if (x.diffFound) firstElement[x.field] = undefined;
      });
    }
    const intialValue = {
      spec_section_id:
        firstElement.spec_name && firstElement.spec_no
          ? `${firstElement.spec_no} - ${firstElement.spec_name}`
          : undefined,
      spec_name: undefined,
      spec_no: undefined,
      type: firstElement.type,
      raw_type: firstElement.type,
      responsible_contractor: firstElement.responsible_contractor_id,
      submitter: firstElement.submitter_user_id,
      submitter_unregistered: firstElement.submitter_unregistered,
      gc_reviewer: firstElement.gc_reviewer_user_id,
      design_reviewer: firstElement.design_reviewer_user_id,
      design_reviewer_unregistered: firstElement.design_reviewer_unregistered,
      design_reviewer_vendor_unregistered: undefined,
      float: firstElement.float
    } as TEditSubmittal;
    setInitialData(intialValue);
    setEditSubmittalData(intialValue);

    const closedSubmittals = selectedRows
      .filter((x: any) => x.state_numeric === 10)
      .map((x) => ({ id: x.id, name: x.title }));
    const workflowStartedSubmittals = selectedRows
      .filter(
        (x: any) =>
          x.state_numeric !== ESubmittalStatus.CREATE && x.state_numeric !== 10
      )
      .map((x) => ({ id: x.id, name: x.title }));
    const workflowNotStartedSubmittals = selectedRows
      .filter((x: any) => x.state_numeric === ESubmittalStatus.CREATE)
      .map((x) => ({ id: x.id, name: x.title }));

    setSubmittalListIds({
      NotStarted: workflowNotStartedSubmittals,
      Started: workflowStartedSubmittals,
      Closed: closedSubmittals
    });
  }, [selectedRows]);

  const optionLabel = (user: any, selectedUserId: any) => {
    if (
      user.status_id === AcceptanceStatus.DEACTIVATED &&
      user.id !== selectedUserId
    )
      return "";

    const selectedAndInactive =
      user.status_id === AcceptanceStatus.DEACTIVATED &&
      user.id === selectedUserId;

    return (
      <Option
        key={user.id}
        value={user.id}
        label={`${user.first_name} ${user.last_name}`}
        className={selectedAndInactive ? "hidden" : ""}
      >
        {user.status_id === AcceptanceStatus.DEACTIVATED && (
          <div className="absolute flex w-full items-center justify-end pr-3 -mt-[1px]">
            <UserTag label="Inactive" />
          </div>
        )}
        <div>
          {user.first_name} {user.last_name}
        </div>
        <div className="text-sm text-gray-500">{user.company.name}</div>
      </Option>
    );
  };

  const fieldsForStartedSubmittalOnly = (
    <div>
      {submittalListIds.Started.length > 0 &&
        submittalListIds.NotStarted.length > 0 && (
          <div className="py-3">
            <Divider className="px-0 mt-0 mb-1 pb-1" />

            <div className="text-xs">
              The fields below will only be updated in submittals for which
              workflow has not started.
              <DisplayNameListPopover list={submittalListIds.NotStarted}>
                {submittalListIds.NotStarted.length}
              </DisplayNameListPopover>
              out of
              <DisplayNameListPopover
                list={[
                  ...submittalListIds.NotStarted,
                  ...submittalListIds.Started
                ]}
              >
                {submittalListIds.NotStarted.length +
                  submittalListIds.Started.length}
              </DisplayNameListPopover>
              submittals selected, will be updated.
            </div>
          </div>
        )}

      {submittalListIds.NotStarted.length > 0 && (
        <div className="space-y-3">
          <Form.Item
            label={submittalHeaderMap?.responsible_contractor?.toUpperCase()}
          >
            <Select
              showSearch
              disabled={submittalListIds.NotStarted.length === 0}
              value={editSubmittalData.responsible_contractor}
              optionFilterProp="label"
              className="constructionSelect"
              onChange={(value) => {
                setEditSubmittalData((pre) => ({
                  ...pre,
                  responsible_contractor: value,
                  submitter: null,
                  submitter_unregistered: ""
                }));
              }}
              notFoundContent={
                <SelectSearchNotFoundContent
                  notFoundMsg={
                    projectParticipants?.responsibleContractors?.length > 0
                      ? "Company not found. To add, go to "
                      : "No companies added to this project. To add, go to "
                  }
                  linkTitle="Project Companies"
                  linkPath={`/project/${projectId}/settings/general/project-companies`}
                />
              }
              options={
                projectParticipants
                  ? projectParticipants?.responsibleContractors.map(
                      (company: any) => ({
                        label: company.subscription_vendor.name,
                        value: company.vendor_id
                      })
                    )
                  : []
              }
            />
          </Form.Item>
          <Tooltip
            title={
              editSubmittalData.responsible_contractor
                ? ""
                : "First select a Responsible Contractor"
            }
            placement="rightBottom"
          >
            <Form.Item label={submittalHeaderMap?.submitter?.toUpperCase()}>
              <Select
                showSearch
                disabled={
                  submittalListIds.NotStarted.length === 0 ||
                  !editSubmittalData.responsible_contractor
                }
                value={
                  editSubmittalData.submitter ||
                  editSubmittalData.submitter_unregistered
                }
                optionFilterProp="label"
                className="constructionSelect"
                onChange={(value: string) => {
                  const selectedUser = projectParticipants?.submitterUsers.find(
                    (user: any) => user.id === value
                  );
                  if (selectedUser.type === "actual") {
                    setEditSubmittalData((pre) => ({
                      ...pre,
                      submitter: value,
                      submitter_unregistered: ""
                    }));
                  } else {
                    setEditSubmittalData((pre) => ({
                      ...pre,
                      submitter_unregistered: value,
                      submitter: null
                    }));
                  }
                }}
              >
                {projectParticipants
                  ? projectParticipants.submitterUsers
                      .filter((user: any) => {
                        return (
                          user.company.vendor_id ===
                          editSubmittalData.responsible_contractor
                        );
                      })
                      .map((user: any) =>
                        optionLabel(user, editSubmittalData.submitter)
                      )
                  : []}
              </Select>
            </Form.Item>
          </Tooltip>
          <Form.Item label={submittalHeaderMap?.gc_reviewer?.toUpperCase()}>
            <Select
              showSearch
              disabled={submittalListIds.NotStarted.length === 0}
              optionFilterProp="label"
              value={editSubmittalData.gc_reviewer}
              onChange={(value) => {
                setEditSubmittalData((pre) => ({
                  ...pre,
                  gc_reviewer: value
                }));
              }}
            >
              {projectParticipants
                ? projectParticipants.gcReviewers.map((user: any) =>
                    optionLabel(user, editSubmittalData.gc_reviewer)
                  )
                : []}
            </Select>
          </Form.Item>
          <Form.Item label={submittalHeaderMap?.design_reviewer?.toUpperCase()}>
            <Select
              showSearch
              disabled={submittalListIds.NotStarted.length === 0}
              optionFilterProp="label"
              className="constructionSelect"
              value={
                editSubmittalData.design_reviewer ||
                editSubmittalData.design_reviewer_unregistered ||
                editSubmittalData.design_reviewer_vendor_unregistered
              }
              onChange={(value: string) => {
                const foundUser: any = projectParticipants.designReviewers.find(
                  (user: any) => {
                    return user.id === value;
                  }
                );
                if (foundUser.type === "actual") {
                  setEditSubmittalData((pre) => ({
                    ...pre,
                    design_reviewer: value,
                    design_reviewer_unregistered: "",
                    design_reviewer_vendor_unregistered: ""
                  }));
                } else {
                  setEditSubmittalData((pre) => ({
                    ...pre,
                    design_reviewer_unregistered: value,
                    design_reviewer_vendor_unregistered: foundUser.company.name,
                    design_reviewer: null
                  }));
                }
              }}
            >
              {projectParticipants
                ? projectParticipants.designReviewers.map((user: any) =>
                    optionLabel(user, editSubmittalData.design_reviewer)
                  )
                : []}
            </Select>
          </Form.Item>
        </div>
      )}
    </div>
  );

  return (
    <Modal
      title="BULK EDIT DETAILS"
      className="custom-drawer"
      style={SidepanelStyles.style}
      bodyStyle={{ minHeight: "calc(100vh - 148px)" }}
      width={420}
      footer={[
        <section className="submittal-modal-footer " key={100}>
          <Button onClick={onCancelClick} disabled={loading || dboffsetloading}>
            Cancel
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            disabled={!isDiff}
            loading={loading || dboffsetloading}
            onClick={onFinish}
          >
            Apply
          </Button>
        </section>
      ]}
      open={showSubmittalEdit}
      onCancel={() => {
        setShowSubmittalEdit(false);
      }}
      destroyOnClose
    >
      <div className="pb-3 px-3 -mt-2">
        <div className="font-semibold">
          <span className="tracking-wide">Selected Submittals:</span>{" "}
          {selectedRows.length}
        </div>
        {submittalListIds.Closed.length > 0 ? (
          <div className="mt-2 text-xs">
            Closed submittals will not be updated.
            <DisplayNameListPopover
              list={[
                ...submittalListIds.NotStarted,
                ...submittalListIds.Started
              ]}
            >
              {submittalListIds.NotStarted.length +
                submittalListIds.Started.length}
            </DisplayNameListPopover>
            out of
            <DisplayNameListPopover
              list={[
                ...submittalListIds.NotStarted,
                ...submittalListIds.Started,
                ...submittalListIds.Closed
              ]}
            >
              {submittalListIds.NotStarted.length +
                submittalListIds.Started.length +
                submittalListIds.Closed.length}
            </DisplayNameListPopover>
            Submittals selected, will be updated.
          </div>
        ) : null}
      </div>
      <Form
        layout="vertical"
        className="text-base space-y-3 px-3 h-full"
        disabled={showCreateSpecSectionModal}
      >
        <div className="flex-col overflow-y overflow-x-hidden h-full px-1">
          <Form.Item label={submittalHeaderMap?.spec_no?.toUpperCase()}>
            <SpecNumberNameDropDown
              allowCreate
              currentSpecSection={{
                specSectionId: editSubmittalData.spec_section_id
                  ? editSubmittalData.spec_section_id
                  : undefined,
                enable: false
              }}
              onChange={(
                id: string | null,
                number: string | null,
                name: string | null
              ) => {
                setEditSubmittalData((pre) => ({
                  ...pre,
                  spec_section_id: id,
                  spec_no: number,
                  spec_name: name
                }));
              }}
              onCreateSpecSectionClick={(showSpecSectionView) => {
                setShowCreateSpecSectionModal(showSpecSectionView);
                setTimeout(() => {
                  if (createSpecRef.current)
                    createSpecRef.current.scrollIntoView({
                      behavior: "smooth",
                      block: "end"
                    });
                }, 100);
              }}
            />
          </Form.Item>
          <Form.Item label={submittalHeaderMap?.type?.toUpperCase()}>
            <Select
              optionFilterProp="label"
              value={editSubmittalData.type}
              onChange={(value) => {
                const type = Number(value);
                const typeObj = submittalTypes?.configurations_value?.find(
                  (t: any) => t.id === type || t.value === type
                );
                setEditSubmittalData((pre) => ({
                  ...pre,
                  type: typeObj?.id,
                  raw_type: typeObj?.value
                }));
              }}
              options={
                submittalTypes
                  ? submittalTypes.configurations_value.map((type: any) => ({
                      value: type.id,
                      label: type.value
                    }))
                  : []
              }
            />
          </Form.Item>
          {fieldsForStartedSubmittalOnly}
        </div>
      </Form>
      {showCreateSpecSectionModal && (
        <div ref={createSpecRef}>
          <Divider style={{ margin: "15px 0" }} />
          <div>
            <CreateSpecSectionModal
              isModalOpen={showCreateSpecSectionModal}
              onDoneCb={(newSpecSectionId: string, name: any, number: any) => {
                if (newSpecSectionId) {
                  setEditSubmittalData((pre) => ({
                    ...pre,
                    spec_section_id: newSpecSectionId,
                    spec_no: number,
                    spec_name: name
                  }));
                  setShowCreateSpecSectionModal(false);
                } else {
                  setShowCreateSpecSectionModal(false);
                }
              }}
            />
          </div>
        </div>
      )}
    </Modal>
  );
}

export default BulkEditSubmittalDetails;
