import jwtDecode from "jwt-decode";
// eslint-disable-next-line import/no-cycle
import {
  LoginRequest,
  SignupRequest,
  ForgetPasswordRequest,
  PasswordRequest,
  SetupAccountRequest,
  ChangePasswordRequest
} from "models/user";
// eslint-disable-next-line import/no-cycle
import { client, noAuthClient } from "./axios";
import { BASE_URL } from "./endpoints";
import CIQClient from "./ciq-client";

const ACCESS_TOKEN_KEY = "accToken";
const SUB_ID = "subId";
const ASSO_SUBS = "assoSubs";

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const setAccessToken = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
};

export const getAssociatedSubscriptions = (): Array<any> => {
  const associatedSubscriptions = localStorage.getItem(ASSO_SUBS);
  try {
    return JSON.parse(associatedSubscriptions!);
  } catch {
    return [];
  }
};

export const setAssociatedSubscriptions = (
  associatedSubscriptions: Array<any>
) => {
  try {
    localStorage.setItem(ASSO_SUBS, JSON.stringify(associatedSubscriptions));
  } catch (err) {
    console.log(err);
  }
};

export const getCurrentSubscriptionId = () => {
  return localStorage.getItem(SUB_ID);
};

export const setCurrentSubscriptionId = (subscriptionId: string) => {
  localStorage.setItem(SUB_ID, subscriptionId);
};

/**
 * Method to set the access token and subscription id from the query params.
 * There are some report pages which will be called from diffrent services like jsreport service to generate the report.
 */
// ?accessToken=<token>&subscriptionId=<...>
export const setAccessTokenAndSubscriptionId = () => {
  const accessToken =
    new URLSearchParams(window.location.search).get("accessToken") || "";
  const subscriptionId =
    new URLSearchParams(window.location.search).get("subscriptionId") || "";
  if (accessToken) {
    setAccessToken(accessToken);
  }
  if (subscriptionId) {
    setCurrentSubscriptionId(subscriptionId);
  }
};

export const getUser = (): any => {
  const token = getAccessToken();
  if (!token) {
    return null;
  }
  return jwtDecode(token);
};

export const getLoggedinUserId = (): any => {
  const userDetails: any = getUser();
  if (userDetails)
    return userDetails["https://hasura.io/jwt/claims"]["x-hasura-user-id"];
  return null;
};

export const login = async (request: LoginRequest) => {
  try {
    const response = await noAuthClient.post(`${BASE_URL}/user/login`, request);
    return response;
  } catch (error) {
    return error;
  }
};

export const signup = async (request: SignupRequest) => {
  try {
    const response = await noAuthClient.post(`${BASE_URL}/user/register`, {
      email: request.email,
      company: request.company,
      subscription: request.subscription
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const setupAccount = async (request: SetupAccountRequest) => {
  const headers = {
    Authorization: `Bearer ${request.token}`
  };
  const body = {
    password: request.password,
    first_name: request.first_name,
    last_name: request.last_name,
    phone: request.phone
  };
  try {
    const response = await noAuthClient.put(
      `${BASE_URL}/user/setupAccount`,
      body,
      {
        headers
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const upadatepassword = async (request: PasswordRequest) => {
  const headers = {
    Authorization: `Bearer ${request.token}`
  };
  const body = {
    password: request.password
  };
  try {
    const response = await noAuthClient.put(`${BASE_URL}/user/password`, body, {
      headers
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const changepassword = async (request: ChangePasswordRequest) => {
  try {
    const response = await client.put(`${BASE_URL}/user/modifyPassword`, {
      old_password: request.old_password,
      new_password: request.new_password
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const forgetpassword = async (request: ForgetPasswordRequest) => {
  const body = { email: request.email };
  try {
    const response = await noAuthClient.post(
      `${BASE_URL}/user/forgotPassword`,
      body
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getTokenForSubscription = async (request: any) => {
  let response = null;
  if (request.loginInfo) {
    // to optionally pass the config object
    const config = {
      headers: {
        Authorization: `Bearer ${request.loginInfo.token}`
      }
    };
    response = await noAuthClient.post(
      `${BASE_URL}/user/login/subscriptionExchange`,
      { subscription_id: request.subscriptionId },
      config
    );
  } else {
    response = await client.post(
      `${BASE_URL}/user/login/subscriptionExchange`,
      { subscription_id: request.subscriptionId }
    );
  }
  setAccessToken(response.data?.success?.token);
  setAssociatedSubscriptions(response.data?.success?.associated_subscriptions);
  return response;
};

export const getSubscriptionId = () => {
  try {
    const userDetails: any = getUser();
    const subscriptionId =
      userDetails["https://hasura.io/jwt/claims"]["x-hasura-subscription-id"];

    return subscriptionId || "";
  } catch (ex) {
    return "";
  }
};

export const getTokenForProject = async (request: { projectId: string }) => {
  try {
    const response = await client.post(`${BASE_URL}/user/login/tokenExchange`, {
      project_id: request.projectId,
      dynamic_permission: true
    });
    return { data: response.data };
  } catch (error) {
    return { error };
  }
};

export const getTokenForProjectNoAuth = async (request: {
  projectId: string;
  token: string;
}) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    };
    const response = await noAuthClient.post(
      `${BASE_URL}/user/login/tokenExchange`,
      {
        project_id: request.projectId,
        dynamic_permission: true
      },
      config
    );
    return { data: response.data };
  } catch (error) {
    return { error };
  }
};

export const logout = () => {
  try {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(SUB_ID);
  } catch (ex) {
    console.error(ex);
  }
  setTimeout(() => {
    window.location.href = "/";
  }, 200);
};

export const isTokenExpired = (jwtToken?: string) => {
  if (!jwtToken) {
    return null;
  }

  try {
    const jwt = JSON.parse(atob(jwtToken.split(".")[1]));
    const exp = (jwt && jwt.exp && jwt.exp * 1000) || null;

    if (!exp) {
      return false;
    }
    return Date.now() > exp;
  } catch (error) {
    return "Invalid Token";
  }
};

export const heartbeatAPI = async () => {
  return client.post(`${BASE_URL}/user/heartbeat`);
};

export const getDashBoardData = async (token: string, input = {}) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  return CIQClient.post({
    url: `${BASE_URL}/report-engine/dashboard`,
    data: input,
    config
  });
};

export const getActivitiesModifiedCount = async (token: string, input = {}) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  return CIQClient.post({
    url: `${BASE_URL}/v2/scheduleImpactActivitiesCount`,
    data: input,
    config
  });
};

export const getScheduleLookupReport = async (token: string, input = {}) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  return CIQClient.post({
    url: `${BASE_URL}/scheduleReport`,
    data: input,
    config
  });
};

type SendEmailInvitedUserType = {
  env_admin: boolean;
  input: {
    email: string;
    subscription_id: string;
  };
};
export const sendEmailToInvitedUserInSubscription = async (
  request: SendEmailInvitedUserType
) => {
  const response = await client.post(
    `${BASE_URL}/user/addToSubscription`,
    request
  );
  return response;
};

export const validateUser = async (
  user_id: string,
  token: string,
  subscription_id?: string,
  project_id?: string
) => {
  const body = { user_id, subscription_id, project_id };
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };

  try {
    const response = await noAuthClient.post(
      `${BASE_URL}/user/validate`,
      body,
      config
    );
    return response;
  } catch (error) {
    return error;
  }
};
