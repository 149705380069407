/* eslint-disable jsx-a11y/label-has-associated-control */
// import { DropDownData } from "constants/index.js";
import {
  EditOutlined,
  InfoCircleOutlined,
  LinkOutlined,
  SwapOutlined
} from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import {
  Button,
  message,
  Popover,
  Radio,
  Select,
  Switch,
  Tag,
  Tooltip
} from "antd";
import {
  AcceptanceStatus,
  ESubmittalStatus,
  EUserRoleName,
  EWorkflowStatusDataBlock,
  EmailInviteStatus,
  ErrorMessages,
  GanttActivityTypes,
  IntegrationAuthStates,
  IntegrationSystemNameMap,
  SourceNames,
  SubmittalStatusToStrMap
} from "constants/index";
import { ProjectContext } from "context/ProjectProvider";
import {
  SubscriptionContext,
  RoleSubscriptionEnum
} from "context/SubscriptionProvider";
import {
  forwardRef,
  ReactElement,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";
import { Link, useParams } from "react-router-dom";
import {
  MUTATION_CANCEL_SUBSCRIPTION_INVITATION,
  MUTATION_RESEND_EMAIL_INVITE,
  MUTATION_SEND_ACC_PROJECT_LEVEL_EMAIL_INVITE,
  MUTATION_SEND_ACC_SUBSCRIPTION_LEVEL_EMAIL_INVITE
} from "services/graphQL/mutations";
import SubmittalMicroVisualiser from "components/submittal-details/submittal-micro-viz";
import {
  getProjectUserSources,
  getSubscriptionUserSources,
  getUserCompany,
  sortAlphanumeric,
  sortLinkedItems
} from "utils/utils";
import "./cell-renders.css";
import { agGridCheckFieldEditable } from "components/submittal-details/helpers";
import { ICellRendererParams } from "ag-grid-community";

import "@react-pdf-viewer/core/lib/styles/index.css";
import { DateUtils } from "utils/dateutils";
import ErrorBoundary from "components/error-boundary";
import DateBlockSubmittalImpactVisualisation from "components/date-block/submittal-impact-visualise";
import SubmittalMicroVizDateBlock from "components/submittal-details/submittal-microviz-dateblock";
import SpecSectionIcon from "components/svg-icons/spec-section-icon";
import ArrowUpBox from "components/svg-icons/arrow-right-up-box-line";
import {
  CopyIcon,
  SubmittalCiqIcon,
  MaterialCiqIcon,
  ScheduleCiqIcon
} from "components/svg-icons";
import DefaultTag from "components/widgets/default-tag";
import InfoIconPath from "assets/svg/i-icon.svg";
import CriticalActivityIconPath from "assets/svg/critical_activity.svg";
import QuestionMarkIcon from "assets/svg/questionmark-icon.svg";
import CopyDBTemplate from "popups/copy-db-template";
import UserTag from "components/user-tag/user-tag";
import modal from "antd/lib/modal";
import ProcoreLogoIcon from "components/svg-icons/procore-logo-icon";
import { IntegrationType } from "pages/subscription-settings/integrations-tab-new/utils";
import AutodeskLogoIcon from "components/svg-icons/autodesk-logo-icon";
import AuthenticationExpiredIcon from "components/svg-icons/authentication-expired-icon";
import { RiskLevelType } from "components/date-block/models";
import FeatureSetStatus from "components/feature-status";
import PersonEditIcon from "components/svg-icons/person-edit-icon";
import PersonCheckIcon from "components/svg-icons/person-check-icon";
import LinkIconLogPage from "components/svg-icons/link-icon-log-page";
import VoidSubmittalIcon from "components/svg-icons/void-submittal-icon";
import TickIcon from "components/svg-icons/tick-icon";
import ClockIcon from "components/svg-icons/clock-icon";
import TitleDescriptionTooltip from "components/widgets/title-description-tooltip";
import CriticalActivityIcon from "components/svg-icons/critical-activity-icon";
import CriticalActivityTag from "pages/schedule/schedule-page/critical-activity-tag";
import CriticalActivityIndicator from "components/links/critical-activity-indicator";
import BIM360logo from "../../assets/image/bim360-logo.png";

interface TruncatedTextProps {
  text: string;
  onMouseEvent: (
    mouseEventType: "enter" | "leave",
    isTruncated: boolean
  ) => void;
  className?: string;
  disableMouseEvent?: boolean;
}

export const TruncatedText = forwardRef<HTMLDivElement, TruncatedTextProps>(
  ({ text, onMouseEvent, className = "", disableMouseEvent = false }, ref) => {
    const innerRef = useRef<HTMLDivElement | null>(null);

    const checkTruncation = useCallback(() => {
      let isTruncated = false;
      if (innerRef.current) {
        isTruncated =
          innerRef.current.scrollWidth > innerRef.current.clientWidth;
        return isTruncated;
      }
      return isTruncated;
    }, []);

    if (disableMouseEvent) {
      return (
        <div
          className={`tooltip-disabled truncated-text-container truncate ${className}`}
        >
          {text}
        </div>
      );
    }

    return (
      <div
        ref={(node) => {
          if (typeof ref === "function") {
            ref(node);
          } else if (ref) {
            // eslint-disable-next-line no-param-reassign
            ref.current = node;
          }
          innerRef.current = node;
        }}
        className={`truncated-text-container truncate ${className}`}
        onMouseEnter={() => {
          onMouseEvent("enter", checkTruncation());
        }}
        onMouseLeave={() => {
          onMouseEvent("leave", checkTruncation());
        }}
      >
        {text}
      </div>
    );
  }
);

export function TruncatedTextWithTooltip(props: {
  text: string | null | undefined;
  tooltipContent?: any;
  className?: string;
  disableTooltip?: boolean;
}) {
  const {
    text,
    tooltipContent,
    disableTooltip = false,
    className = ""
  } = props;
  const [showTooltip, setShowTooltip] = useState(false);
  const [makeTooltipVisible, setMakeTooltipVisible] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const handleMouseEvent = (mouseEvent: string, isTruncated: boolean) => {
    clearTimeout(timeoutRef.current);

    if (mouseEvent === "enter" && isTruncated) {
      setShowTooltip(true);
      // give slight delay for ant.design to place the tooltip at the right location, else it will flash at the top-left corner of the page for a moment
      timeoutRef.current = setTimeout(() => {
        setMakeTooltipVisible(true);
      }, 0);
    } else if (showTooltip) {
      setShowTooltip(false);
      setMakeTooltipVisible(false);
    }
  };

  const isTooltipDisabled =
    typeof disableTooltip === "boolean" && disableTooltip === true;

  return (
    <Tooltip
      title={
        tooltipContent || <div className="text-xs p-0.5">{text}</div> || ""
      }
      open={showTooltip}
      placement="top"
      overlayClassName={`${makeTooltipVisible ? "" : "hidden"}`}
      destroyTooltipOnHide
      autoAdjustOverflow
    >
      <TruncatedText
        className={`max-w-full w-full ${className}`}
        text={text ?? ""}
        onMouseEvent={handleMouseEvent}
        disableMouseEvent={isTooltipDisabled}
      />
    </Tooltip>
  );
}

export function truncatedTextCellRenderer(params: any) {
  return <TruncatedTextWithTooltip text={params.value} />;
}

export function IdLinkComponent(props: any, disableTooltip?: boolean) {
  const { value, data, projectId, context } = props;
  const cellText =
    context.projectDetails?.spec_section && data.spec_no
      ? `${data.spec_no} - ${value}`
      : `${value}`;

  return (
    <Link
      className="gridIdLink"
      to={{
        pathname: `/project/${projectId}/submittals/${data.id}`
      }}
    >
      <TruncatedTextWithTooltip
        text={cellText}
        disableTooltip={disableTooltip}
      />
    </Link>
  );
}

export function MaterialIdLinkCellRenderer(params: any) {
  const { data, value, context } = params;
  return (
    <Link
      className="gridIdLink"
      to={{
        pathname: `/project/${context.projectId}/materials/${data.id}`
      }}
    >
      {value}
    </Link>
  );
}

export function SubmittalIdLinkCellRenderer(params: any) {
  const { data, value, projectId } = params;
  return (
    <Link
      className="gridIdLink"
      to={{
        pathname: `/project/${projectId}/submittals/${data.id}`
      }}
    >
      {value}
    </Link>
  );
}

export function IdLinkProjectSumbittalListComponent(props: any) {
  const { value, data, disabled = false, showToolTipMsg, tooltipMsg } = props;

  if (disabled && showToolTipMsg) {
    return (
      <Tooltip title={tooltipMsg} placement="right">
        <label>{value}</label>
      </Tooltip>
    );
  }
  if (disabled && !showToolTipMsg) {
    return <label>{value}</label>;
  }
  return (
    <Link
      to={{
        pathname: `project/${data.id}/dashboard`
      }}
    >
      {value}
    </Link>
  );
}

export const submittalCellRenderer = (props: any) => {
  const { data, context, projectId } = props;

  if (data?.workflow_status === ESubmittalStatus.VOID) {
    return (
      <div className="w-full pl-3">
        <div className="grow truncate text-left">
          <Link
            className="gridIdLink grow truncate text-left"
            to={{
              pathname: `/project/${projectId}/submittals/${data.id}`
            }}
          >
            <TruncatedTextWithTooltip
              text={data.title || " "}
              tooltipContent={
                <div>
                  <div>{data.title}</div>
                  <div>{data.description}</div>
                </div>
              }
            />
          </Link>
        </div>
        {data.description && (
          <div className="colSecondValue">
            <TruncatedTextWithTooltip
              text={data.description || " "}
              tooltipContent={
                <div>
                  <div>{data.title}</div>
                  <div>{data.description}</div>
                </div>
              }
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="pl-3 !flex !items-center space-x-1">
        <Link
          className="gridIdLink grow truncate text-left"
          to={{
            pathname: `/project/${projectId}/submittals/${data.id}`
          }}
        >
          <TruncatedTextWithTooltip
            text={data.title || " "}
            tooltipContent={
              <TitleDescriptionTooltip
                title={data.title}
                description={data.description}
              />
            }
          />
        </Link>
        <EditOutlined
          className="cell-edit-icon px-2"
          onClick={() => {
            context.onEditCell({
              rowIndex: props.node.rowIndex!,
              colKey: props.column!.getId(),
              data
            });
          }}
        />
      </div>
      {data.description && (
        <p className="colSecondValue pl-3">
          <TruncatedTextWithTooltip
            text={data.description || ""}
            tooltipContent={
              <TitleDescriptionTooltip
                title={data.title}
                description={data.description}
              />
            }
          />
        </p>
      )}
    </div>
  );
};

// export const formatDate = (dateStr: string) => {
//   const possibleFormats = [DATE_FORMAT_MMDDYYYY, "YYYY-MM-DD"];
//   if (dateStr) {
//     const autoFormatted = moment(dateStr).format(DATE_FORMAT_MMDDYYYY);

//     if (autoFormatted !== "Invalid date") return autoFormatted;

//     for (let p = 0; p < possibleFormats.length; p += 1) {
//       const formatted = moment(dateStr, possibleFormats[p]).format(
//         DATE_FORMAT_MMDDYYYY
//       );
//       if (formatted !== "Invalid date") return formatted;
//     }
//   }
//   return "";
// };

export const dateCellRenderer = (params: any) => {
  const formattedDate = DateUtils.format(params.value);
  return (
    <div className="w-full flex items-center">
      <div className="grow min-w-0">
        <TruncatedTextWithTooltip text={formattedDate} />
      </div>
      <div className="px-1">
        <EditOutlined className="cell-edit-icon" />
      </div>
    </div>
  );
};

export const dueDateCellRenderer = (params: any, disableTooltip: boolean) => {
  const todayStartDateObject = DateUtils.dateTimeObj().startOf("day");
  const dueDateObject = DateUtils.dateTimeObj(params.value);
  const isPast = dueDateObject.isBefore(todayStartDateObject);
  const cellValueClassnames = isPast ? "date-block-date-risk" : "";
  return (
    <div className="w-full flex items-center">
      <div className="grow min-w-0">
        <TruncatedTextWithTooltip
          text={params.value}
          className={cellValueClassnames}
          disableTooltip={disableTooltip}
        />
      </div>
      <div className="px-1">
        <EditOutlined className="cell-edit-icon" />
      </div>
    </div>
  );
};

const dueDateHeaderTemplate = (title: string) => ({
  template: `<div class="ag-cell-label-container" role="presentation">
              <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                  <img src='${InfoIconPath}' title='${title}' class="info-icon">
                  <span ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`
});

export const logHeaderTemplateWithQuestionMark = () => ({
  template: `<div class="ag-cell-label-container" role="presentation">
              <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div ref="eLabel" class="ag-header-cell-label" role="presentation">

                  <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                  <span class="grow text-right"><img src='${QuestionMarkIcon}' class="-mt-0.5 mr-1"/></span>
                  <span ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>

                  <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`
});

const criticalActivityHeaderTemplate = () => ({
  template: `<div class="ag-cell-label-container" role="presentation">
              <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <span ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
              <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                  <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                  <img src='${CriticalActivityIconPath}' title='' class="info-icon">
                  <span ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                  <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                  <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                  <span ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                  <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                  <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              </div>
          </div>`
});

export const submittalDueDateHeaderComponent = (params: any) => {
  const { context } = params;
  const headerTitle = context?.submittalHeaderMap?.due_date || "Due Date";
  const camelCaseHeaderTitle = `${headerTitle} is the minimum of Projected Date of Last Milestone and Final Deadline.`;
  return dueDateHeaderTemplate(camelCaseHeaderTitle);
};

export const materialDueDateHeaderComponent = (params: any) => {
  const { context } = params;
  const headerTitle = context?.materialHeaderMap?.due_date || "Due Date";
  const camelCaseHeaderTitle = `${headerTitle} is the minimum of Projected Date of Last Milestone and Final Deadline.`;

  return dueDateHeaderTemplate(camelCaseHeaderTitle);
};

export const bidPackageDueDateHeaderComponent = (params: any) => {
  const { displayName } = params;
  const headerTitle = displayName || "Due Date";
  const camelCaseHeaderTitle = `${headerTitle} is the minimum of Projected Date of Last Milestone and Final Deadline.`;

  return dueDateHeaderTemplate(camelCaseHeaderTitle);
};

export const criticalActivityHeaderComponent = () => {
  return criticalActivityHeaderTemplate();
};

export const projectDateCellRenderer = (params: any) => {
  if (params.data?.status_id === AcceptanceStatus.ACTIVE) {
    return dateCellRenderer(params);
  }
  return null;
};

export const projectOverviewCellRenderer = ({ data }: any) => {
  const showNumber = (num: number = 0) => (
    <div className={num >= 1000 ? "text-xs" : "text-sm"}>{num}</div>
  );

  function CountDetail({ num, icon }: { num: number; icon: ReactElement }) {
    return (
      <div className="flex items-center space-x-1 w-12">
        {icon} {showNumber(num)}
      </div>
    );
  }

  const popOver = (
    <div className="flex-col w-56 text-xs space-y-1">
      <div className="flex justify-between w-full">
        <div className="flex items-center">
          <SubmittalCiqIcon />
          <div className="pl-1 opacity-60"> Submittals</div>
        </div>
        <div className="font-medium">{data.submittals_count}</div>
      </div>
      <div className="flex justify-between w-full">
        <div className="flex items-center">
          <MaterialCiqIcon />
          <div className="pl-1 opacity-60"> Materials</div>
        </div>
        <div className="font-medium">{data.materials_count}</div>
      </div>
      <div className="flex justify-between w-full">
        <div className="flex items-center">
          <ScheduleCiqIcon />
          <div className="pl-1 opacity-60"> Schedule Versions</div>
        </div>
        <div className="font-medium">{data.schedules_count}</div>
      </div>
    </div>
  );

  return (
    <div className="p-2">
      <Popover placement="right" content={popOver} trigger="hover">
        <div className="flex justify-evenly items-center pl-1 h-7 space-x-1 border border-solid border-ciqgray-100 rounded-sm opacity-80">
          <CountDetail
            num={data.submittals_count}
            icon={<SubmittalCiqIcon />}
          />
          <CountDetail num={data.materials_count} icon={<MaterialCiqIcon />} />
          <CountDetail num={data.schedules_count} icon={<ScheduleCiqIcon />} />
        </div>
      </Popover>
    </div>
  );
};

export function CellValueRendererWithEditIcon(
  params: any,
  disableTooltip: boolean
) {
  const { value, context, column, data, node } = params;
  return (
    <div className="w-full max-w-full overflow-hidden flex items-center">
      <div className="grow min-w-0">
        <TruncatedTextWithTooltip
          text={value}
          disableTooltip={disableTooltip}
        />
      </div>
      <div>
        <EditOutlined
          className="cell-edit-icon"
          onClick={() => {
            if (context.onEditCell)
              context.onEditCell({
                rowIndex: node.rowIndex!,
                colKey: column!.getId(),
                data
              });
          }}
        />
      </div>
    </div>
  );
}

export function CellCurrencyRendererWithEditIcon(params: any) {
  const { value, context, node, column, data } = params;
  return (
    <div className="w-full flex items-center">
      <div className="grow overflow-hidden truncate">$ {value}</div>
      <div>
        <EditOutlined
          className="cell-edit-icon"
          onClick={() => {
            if (context.onEditCell)
              context.onEditCell({
                rowIndex: node.rowIndex!,
                colKey: column!.getId(),
                data
              });
          }}
        />
      </div>
    </div>
  );
}

export function EditableCell({ children }: { children?: React.ReactNode }) {
  return (
    <div className="flex">
      <div className="grow overflow-hidden truncate">{children ?? ""}</div>
      <div className="w-[13px]">
        <EditOutlined className="cell-edit-icon" />
      </div>
    </div>
  );
}

export const editSubmittalFloatCellRenderer = (params: any) => {
  const { data, node, column, context } = params;
  if (data?.workflow_status === ESubmittalStatus.VOID) return "";
  return (
    <button
      type="button"
      className="border-0 bg-transparent w-full"
      onClick={() => {
        context.onEditCell({
          rowIndex: node.rowIndex!,
          colKey: column!.getId(),
          data
        });
      }}
    >
      <EditableCell>
        <TruncatedTextWithTooltip text={params.value} />
      </EditableCell>
    </button>
  );
};

export const userCellRenderer = (params: any) => {
  const { value, context, dataMapVar } = params;
  if (!value || !context[dataMapVar][value]) return null;
  try {
    return (
      <div className="flex w-full">
        <div className="grow">
          {value && (
            <div>
              <p className="colFirstValue">
                {`${context[dataMapVar][value].user.first_name} ${context[dataMapVar][value].user.last_name}`}
              </p>
              <p className="colSecondValue">
                {getUserCompany(context[dataMapVar][value])}
              </p>
            </div>
          )}
        </div>
        <div>
          <EditOutlined className="cell-edit-icon" />
        </div>
      </div>
    );
  } catch (ex) {
    return "";
  }
};

export function specSectionRenderer(params: any, disableTooltip?: boolean) {
  const { data, specNoField, specNameField, context } = params;
  const isTooltipDisabled =
    typeof disableTooltip === "boolean" && disableTooltip === true;
  try {
    let specSectionStr = data[specNoField] || "";
    if (data[specNoField] && data[specNameField]) {
      specSectionStr += " - ";
    }
    specSectionStr += data[specNameField] || "";
    return (
      <div className="w-full h-full flex items-center">
        <div className="grow truncate  items-center">
          <TruncatedTextWithTooltip
            text={specSectionStr}
            disableTooltip={isTooltipDisabled}
          />
        </div>
        <div>
          <EditOutlined
            className="cell-edit-icon"
            onClick={() => {
              context.onEditCell({
                rowIndex: params.node.rowIndex!,
                colKey: params.column!.getId(),
                data
              });
            }}
          />
        </div>
      </div>
    );
  } catch (ex) {
    return "";
  }
}

export function specSectionRendererWithFileViewer(props: any) {
  const { data, specNoField, specNameField, context } = props;

  try {
    let specSectionStr = data[specNoField] || "";
    if (data[specNoField] && data[specNameField]) {
      specSectionStr += " - ";
    }
    specSectionStr += data[specNameField] || "";

    return (
      <div className="w-full max-w-full h-full flex items-center overflow-hidden">
        <button
          type="button"
          className="w-full max-w-full grow truncate border-0 bg-transparent text-left flex items-center"
          onClick={() => {
            context.onEditCell({
              rowIndex: props.node.rowIndex!,
              colKey: props.column!.getId(),
              data
            });
          }}
        >
          <div className="w-full max-w-full">
            <TruncatedTextWithTooltip text={specSectionStr} />
          </div>
          <div className="-mt-1">
            <EditOutlined className="cell-edit-icon" />
          </div>
        </button>
        {context?.isDesignTabEnabled && data?.spec_section_file_key ? (
          <button
            type="button"
            className="spec-file-icon shrink-0 max-w-full -mt-0.5 ml-1 bg-white m-0 p-0 border-0 cursor-pointer leading-none"
            onClick={() => {
              context.onViewSpecSection(data);
            }}
          >
            <div>
              <SpecSectionIcon />
            </div>
          </button>
        ) : null}
      </div>
    );
  } catch (ex) {
    return "";
  }
}

export function userNameRenderer(params: any) {
  const { data } = params;
  try {
    let userNameStr = data?.user?.first_name || "";
    if (data?.user?.last_name) {
      userNameStr += `  ${data?.user?.last_name}`;
    }
    return (
      <div className="w-full flex">
        <div className="grow">{userNameStr}</div>
        <div>
          <EditOutlined className="cell-edit-icon" />
        </div>
      </div>
    );
  } catch (ex) {
    return "";
  }
}

export function userRoleRenderer(params: any) {
  const { data } = params;
  try {
    const role = data?.subscription_permission?.name || "";

    return (
      <div className="w-full flex">
        <div className="grow">{role}</div>
        <div>
          <EditOutlined className="cell-edit-icon" />
        </div>
      </div>
    );
  } catch (ex) {
    return "";
  }
}

export function projectUserRoleRenderer(params: any) {
  const { data } = params;
  try {
    const role = data.project_role?.name || "";

    return (
      <div className="w-full flex">
        <div className="grow">{role}</div>
        <div>
          <EditOutlined className="cell-edit-icon" />
        </div>
      </div>
    );
  } catch (ex) {
    return "";
  }
}

export function projectCompanyRoleRenderer(params: any) {
  const { data } = params;
  try {
    const role = data?.project_vendors_type?.name || "";

    return (
      <div className="w-full flex">
        <div className="grow">{role}</div>
        <div>
          <EditOutlined className="cell-edit-icon" />
        </div>
      </div>
    );
  } catch (ex) {
    return "";
  }
}

export const dropDownCellRenderer = (params: any, disableTooltip?: boolean) => {
  const { context, dataMapVar, labelVar } = params;

  // if (!params.value) return null;

  const getText = () => {
    if (params.value) {
      const mapObject = context[dataMapVar];
      const selectedValue = mapObject[params.value];
      if (!selectedValue) return null;
      return selectedValue[labelVar];
    }
    return "";
  };

  try {
    const text = getText();
    if (!text) return null;
    return (
      <div className="w-full">
        <TruncatedTextWithTooltip text={text} disableTooltip={disableTooltip} />
      </div>
    );
  } catch (ex) {
    return null;
  }
};

export const dropDownCellRendererWithCustomEdit = (props: any) => {
  const { context, dataMapVar, labelVar } = props;

  const getText = () => {
    if (props.value) {
      const mapObject = context[dataMapVar];
      const selectedValue =
        mapObject[props.value] ||
        mapObject.find((x: any) => x?.value === props.value);
      if (!selectedValue) return null;
      return selectedValue[labelVar];
    }
    return "";
  };

  try {
    const text = getText();
    return (
      <button
        type="button"
        className="bg-transparent border-0 w-full flex items-center"
        onClick={() => {
          context.onEditCell({
            rowIndex: props.node.rowIndex!,
            colKey: props.column!.getId()
          });
        }}
      >
        <div className="grow min-w-0">
          <TruncatedTextWithTooltip text={text} className="text-left" />
        </div>
        <div className="px-1">
          <EditOutlined className="cell-edit-icon" />
        </div>
      </button>
    );
  } catch (ex) {
    return null;
  }
};

function InviteStatusText(props: {
  statusId: number;
  userId: string;
  inviteType: string | null;
  isCIQUser: boolean;
  isInviteBtnVisible?: boolean;
  context?: any;
  userData?: any;
  roleId?: any;
}) {
  const {
    statusId,
    userId,
    inviteType,
    isCIQUser,
    isInviteBtnVisible,
    context,
    userData,
    roleId
  } = props;

  const { subscriptionRole } = useContext(SubscriptionContext);
  const { tokenContents } = useContext(ProjectContext);
  const { gqlClientForProject } = useContext(ProjectContext);
  const [cancelprogess, setCancelProgress] = useState(false);
  const [resendprogess, setResendProgress] = useState(false);

  const [resendAccProjectUserInviteMutation] = useMutation(
    MUTATION_SEND_ACC_PROJECT_LEVEL_EMAIL_INVITE,
    {
      variables: {
        user_id: userId,
        role_id: roleId,
        invited_user_company_id: userData?.company_id
      },
      client: gqlClientForProject
    }
  );

  const [resendAccSubscriptionUserInviteMutation] = useMutation(
    MUTATION_SEND_ACC_SUBSCRIPTION_LEVEL_EMAIL_INVITE,
    {
      variables: {
        user_id: userId,
        permission_id: roleId,
        invited_user_company_id: userData?.company_id
      }
    }
  );

  const [resendInviteMutation] = useMutation(MUTATION_RESEND_EMAIL_INVITE, {
    variables: {
      user_id: userId,
      invited_user_company_id: userData?.company_id
    },
    client: gqlClientForProject
  });

  const [resendSubscriptionInviteMutation] = useMutation(
    MUTATION_RESEND_EMAIL_INVITE,
    {
      variables: {
        user_id: userId,
        invited_user_company_id: userData?.company_id
      }
    }
  );
  const [cancelSubscriptionInviteMutattion] = useMutation<any>(
    MUTATION_CANCEL_SUBSCRIPTION_INVITATION,
    {
      variables: { user_id: userId }
    }
  );

  const resendInvite = () => {
    setResendProgress(true);

    if (inviteType === "Subscription") {
      (isCIQUser
        ? resendSubscriptionInviteMutation()
        : resendAccSubscriptionUserInviteMutation()
      )
        .then((res) => {
          if (res.data) {
            message.success(res.data.resend_email_invite.message);
          }
          if (res.errors) {
            message.error(res.errors[0].message);
          }
        })
        .finally(() => setResendProgress(false));
    } else {
      (isCIQUser
        ? resendInviteMutation()
        : resendAccProjectUserInviteMutation()
      )
        .then((res) => {
          if (res.data) {
            message.success(res.data.resend_email_invite.message);
          }
          if (res.errors) {
            message.error(res.errors[0].message);
          }
        })
        .finally(() => setResendProgress(false));
    }
  };

  const cancelSubscriptionInvite = () => {
    setCancelProgress(true);
    cancelSubscriptionInviteMutattion()
      .then((res) => {
        if (res.data) {
          message.success(res.data.cancel_subscription_invite.message);
        }
        if (res.errors) {
          message.error(res.errors[0].message);
        }
      })
      .finally(() => setCancelProgress(false));
  };

  switch (statusId) {
    case AcceptanceStatus.INVITED:
      return (
        <div className="flex space-x-2 items-baseline">
          <p className="margin-0 captialize-first text-red-600">Pending</p>
          {inviteType === "ProjectUser" &&
            tokenContents &&
            tokenContents.role ===
              EUserRoleName[EUserRoleName.gc_project_admin] && (
              <div className=" space-x-2">
                <Button
                  loading={resendprogess}
                  disabled={resendprogess || cancelprogess}
                  onClick={resendInvite}
                >
                  Resend
                </Button>
              </div>
            )}

          {inviteType === "Subscription" &&
            subscriptionRole >= RoleSubscriptionEnum.subscription_admin && (
              <div className=" space-x-2">
                <Button
                  loading={resendprogess}
                  disabled={resendprogess || cancelprogess}
                  onClick={resendInvite}
                >
                  Resend
                </Button>
                <Button
                  loading={cancelprogess}
                  disabled={resendprogess || cancelprogess}
                  onClick={cancelSubscriptionInvite}
                >
                  Cancel Invite
                </Button>
              </div>
            )}
        </div>
      );
    case AcceptanceStatus.ACTIVE:
      return <p className="margin-0 captialize-first text-green-600">Active</p>;
    case AcceptanceStatus.DEACTIVATED:
      return (
        <p className="margin-0 captialize-first text-[#3b3b3b]">Inactive</p>
      );
    case AcceptanceStatus.NOT_INVITED:
      return (
        <div className="flex space-x-2 items-baseline">
          <p className="margin-0 captialize-first text-[#3b3b3b]">
            Not Invited
          </p>
          {inviteType === "Subscription" &&
            isInviteBtnVisible &&
            subscriptionRole >= RoleSubscriptionEnum.subscription_admin && (
              <Button
                onClick={() => {
                  context?.setInviteAccUser({
                    showInviteModel: true,
                    inviteUserData: userData
                  });
                }}
              >
                Send Invite
              </Button>
            )}

          {inviteType === "ProjectUser" &&
            isInviteBtnVisible &&
            tokenContents &&
            tokenContents.role ===
              EUserRoleName[EUserRoleName.gc_project_admin] && (
              <div className=" space-x-2">
                <Button
                  onClick={() => {
                    context?.setInviteAccUser({
                      showInviteModel: true,
                      inviteUserData: userData
                    });
                  }}
                >
                  Send Invite
                </Button>
              </div>
            )}
        </div>
      );
    default:
      return null;
  }
}

export const projectUserActionRenderer = (params: any) => {
  const { data, context } = params;

  const infoMsg =
    data.status_id === AcceptanceStatus.ACTIVE
      ? "No permission to deactivate"
      : "No permission to activate";

  const confirmMsg =
    data.status_id === AcceptanceStatus.ACTIVE
      ? "Are you sure you want to deactivate this user from the project?"
      : "Are you sure you want to make this user active? ";

  const onToggleAction = (isChecked: boolean) => {
    modal.confirm({
      content: <div className="pl-10">{confirmMsg}</div>,
      onOk() {
        context?.userAction(data?.user?.id, isChecked);
      },
      okText: "Confirm"
    });
  };

  if (
    data.status_id === AcceptanceStatus.INVITED ||
    context?.currentUser?.email === data?.user?.email
  ) {
    return "";
  }

  try {
    return (
      <div className="w-full h-full flex items-center custom-switch">
        <Tooltip title={!context?.isGCADmin ? infoMsg : ""}>
          <Switch
            disabled={!context?.isGCADmin}
            checked={data.status_id === AcceptanceStatus.ACTIVE}
            checkedChildren="ON"
            unCheckedChildren="OFF"
            onChange={(isChecked) => {
              onToggleAction(isChecked);
            }}
          />
        </Tooltip>
      </div>
    );
  } catch (ex) {
    return "";
  }
};

export function InviteStatusCellRenderer(props: any) {
  const { data, context } = props;
  const { value, inviteType } = props;
  let dataSources = [];
  let isCIQUser = true;
  let nonCIQsrcPresent = true;

  let isInviteBtnVisible = false;
  let projectVendorType = null;
  let roleId = null;

  if (inviteType === "ProjectUser") {
    dataSources = getProjectUserSources(data);
    nonCIQsrcPresent =
      dataSources.filter((src) => src !== SourceNames.CONSTRUCTIVIQ).length > 0;

    projectVendorType =
      data?.subscription_vendor &&
      data?.subscription_vendor?.project_vendors?.length &&
      data?.subscription_vendor?.project_vendors[0].project_vendors_type?.id;

    if (data?.project_role) {
      roleId = data?.project_role?.id;
    }
  } else {
    dataSources = getSubscriptionUserSources(data);
    nonCIQsrcPresent =
      dataSources.filter((src) => src !== SourceNames.CONSTRUCTIVIQ).length > 0;
    roleId = data?.subscription_permission_id;
  }
  isCIQUser = dataSources.includes(SourceNames.CONSTRUCTIVIQ);
  isInviteBtnVisible =
    nonCIQsrcPresent && data.status_id === AcceptanceStatus.NOT_INVITED;
  const organizations = data.subscription?.organization_subscriptions;
  const org = organizations?.length ? organizations[0] : null;
  const company = data?.subscription_vendor ?? org?.organization;

  const userData = {
    email: data?.user?.email,
    phone: data?.user?.phone,
    id: data?.user?.id,
    first_name: data?.user?.first_name,
    last_name: data?.user?.last_name,
    company_id: company?.id,
    company_name: company?.name,
    type: projectVendorType
  };

  return (
    <div className="flex space-x-3">
      <div className="w-12">
        <InviteStatusText
          statusId={value}
          userId={data?.user_id}
          inviteType={inviteType}
          isCIQUser={isCIQUser}
          isInviteBtnVisible={isInviteBtnVisible}
          context={context}
          userData={userData}
          roleId={roleId}
        />
      </div>
      {inviteType === "ProjectUser" &&
        isCIQUser &&
        value !== AcceptanceStatus.NOT_INVITED && (
          <div className="-mt-3">{projectUserActionRenderer(props)}</div>
        )}
    </div>
  );
}

export function SubmittalStatusCellRenderer(
  params: any,
  disableTooltip?: boolean
) {
  const { value, data, context } = params;

  if (value === ESubmittalStatus.VOID) return <VoidSubmittalIcon />;

  return (
    <div className="w-full h-full max-h-full overflow-hidden flex flex-col justify-center space-y-1">
      <div className="leading-normal">
        {dropDownCellRenderer(params, disableTooltip)}
      </div>
      <div>
        {context.isIntegrationMode && (
          <ErrorBoundary>
            <SubmittalMicroVizDateBlock
              size="sm"
              submittalData={{
                ...data,
                date_block_submittals: [
                  {
                    actual_milestone_1: data.actual_assigned_date,
                    actual_milestone_2:
                      data.actual_trade_partner_submitted_date,
                    actual_milestone_3: data.actual_ext_review_submitted_date,
                    actual_milestone_4: data.actual_ext_review_completed_date,
                    actual_milestone_5: data.actual_submittal_distributed_date
                  }
                ]
              }}
              projectParticipants={context.projectParticipants}
              submittalRevisions={data.revision_date_blocks}
              selectedRevision={data.revision}
            />
          </ErrorBoundary>
        )}
        {!context.isIntegrationMode && (
          <div>
            <ErrorBoundary>
              <SubmittalMicroVisualiser
                design_reviewer_id={
                  data?.design_reviewer_user_id ||
                  data.design_reviewer_unregistered
                }
                gc_reviewer_id={data?.gc_reviewer_user_id}
                submittal_history={data?.history}
                size="sm"
                vendorInfo={{
                  designReviewerCompanyName:
                    data.design_reviewer_unregistered_org,
                  responsibleContractorCompanyName:
                    data.responsible_contractor_name
                }}
              />
            </ErrorBoundary>
          </div>
        )}
      </div>
    </div>
  );
}

export function userOrgCellRenderer(
  params: any,
  disableTooltip?: boolean
): any {
  const { data } = params;
  const userInput =
    params.userInput || params.colDef.cellRendererParams.userInput;

  if (!data[userInput.id] && !data[userInput.unregistered_user])
    return {
      cellMarkup: (
        <div className="flex justify-end">
          {agGridCheckFieldEditable(params) && <EditableCell />}
        </div>
      ),
      fullName: ""
    };

  let fullName = "";
  let userOrg = "";
  if (data[userInput.id]) {
    fullName = `${data[userInput.firstName]} ${data[userInput.lastName]}`;
    userOrg = data[userInput.org];
  }
  if (data[userInput.unregistered_user]) {
    fullName = data[userInput.unregistered_user];
    userOrg = data[userInput.unregistered_user_org];
  }

  const statusId = data[userInput.statusKey];

  let cellMarkup = <div />;
  try {
    cellMarkup = (
      <div className="flex w-full max-w-full h-full items-center">
        <div className="w-full max-w-full overflow-hidden space-y-1">
          <TruncatedTextWithTooltip
            className="w-full max-w-full leading-none colFirstValue"
            text={fullName}
            disableTooltip={disableTooltip}
            tooltipContent={
              <TitleDescriptionTooltip title={fullName} description={userOrg} />
            }
          />
          <TruncatedTextWithTooltip
            className="w-full max-w-full leading-none colSecondValue"
            text={userOrg}
            disableTooltip={disableTooltip}
            tooltipContent={
              <TitleDescriptionTooltip title={fullName} description={userOrg} />
            }
          />
        </div>
        {agGridCheckFieldEditable(params) && (
          <div className="flex items-center pl-1">
            <EditableCell />
          </div>
        )}
        {statusId && statusId === AcceptanceStatus.DEACTIVATED ? (
          <div className="absolute flex w-full items-start justify-end pr-3 -mt-[10px]">
            <UserTag label="Inactive" />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  } catch {
    //
  }

  return {
    cellMarkup,
    fullName,
    userOrg
  };
}

export function userOrgCellRendererWithCustomEdit(params: any): any {
  const { data, context } = params;

  const userInput =
    params.userInput || params.colDef.cellRendererParams.userInput;
  if (!data[userInput.id] && !data[userInput.unregistered_user])
    return {
      cellMarkup: (
        <button
          type="button"
          className="w-full flex justify-end border-0 bg-transparent text-left"
          onClick={() => {
            context.onEditCell({
              rowIndex: params.node.rowIndex!,
              colKey: params.column!.getId()
            });
          }}
        >
          {agGridCheckFieldEditable(params) && <EditableCell />}
        </button>
      ),
      fullName: ""
    };

  let fullName = "";
  let userOrg = "";
  if (data[userInput.id]) {
    fullName = `${data[userInput.firstName]} ${data[userInput.lastName]}`;
    userOrg = data[userInput.org];
  }
  if (data[userInput.unregistered_user]) {
    fullName = data[userInput.unregistered_user];
    userOrg = data[userInput.unregistered_user_org];
  }

  const statusId = data[userInput.statusKey];
  let cellMarkup = <div />;
  try {
    cellMarkup = (
      <button
        type="button"
        className="w-full flex border-0 bg-transparent text-left items-center"
        onClick={() => {
          context.onEditCell({
            rowIndex: params.node.rowIndex!,
            colKey: params.column!.getId()
          });
        }}
      >
        {statusId && statusId === AcceptanceStatus.DEACTIVATED ? (
          <div className="absolute flex w-full items-start justify-end pr-4 -top-2.5">
            <UserTag label="Inactive" />
          </div>
        ) : (
          ""
        )}
        <div className="grow w-full max-w-full overflow-hidden space-y-1">
          <p className="colFirstValue leading-none">
            <TruncatedTextWithTooltip
              text={fullName}
              tooltipContent={
                <TitleDescriptionTooltip
                  title={fullName}
                  description={userOrg}
                />
              }
            />
          </p>
          <p className="colSecondValue leading-none">
            <TruncatedTextWithTooltip
              text={userOrg}
              tooltipContent={
                <TitleDescriptionTooltip
                  title={fullName}
                  description={userOrg}
                />
              }
            />
          </p>
        </div>
        <div>{agGridCheckFieldEditable(params) && <EditableCell />}</div>
      </button>
    );
  } catch {
    //
  }

  return {
    cellMarkup,
    fullName,
    userOrg
  };
}

function LinkedActivitiesTooltip(props: {
  activity: {
    activity_id: string;
    task_name: string;
    reverse_dependency: boolean;
    critical?: boolean;
    start_date?: string;
    progress: number;
    total_slack: number;
    linked_to_end_date?: boolean;
    end_date?: string;
    actual_start_date?: string;
    actual_finish_date?: string;
  };
}) {
  const { activity } = props;
  const name = activity.activity_id
    ? `${activity.activity_id} - ${activity.task_name}`
    : activity.task_name;

  const getLabels = (label: string, value: string, valueClass: string = "") => {
    return (
      <div className="grid grid-cols-9 text-[10px] text-[#8E8E8E]">
        <span className="col-span-4 font-medium">{label}</span>{" "}
        <span className={`col-span-5 text-[#000000CC] ${valueClass}`}>
          {value}
        </span>
      </div>
    );
  };

  const floatVal = Math.floor((activity.total_slack || 0) / 8);

  const popupContent = (
    <div className="w-[228px]">
      {activity.critical && <CriticalActivityTag />}
      {activity.reverse_dependency && (
        <div className="flex items-center justify-center bg-[#EFEFEF] rounded h-6 space-x-1 px-2">
          <span className="flex items-center">
            <SwapOutlined className="text-[#8C8C8C]" />
          </span>
          <span className="flex items-center font-normal text-xs">
            Activity is the Predecessor
          </span>
        </div>
      )}
      <div className="py-2 text-xs font-medium ">{name}</div>
      {activity.linked_to_end_date
        ? getLabels(
            "Planned End date:",
            DateUtils.format(activity?.end_date, "MM-DD-YYYY")
          )
        : getLabels(
            "Planned Start date:",
            DateUtils.format(activity?.start_date, "MM-DD-YYYY")
          )}
      {getLabels(
        "Float:",
        `${floatVal}`,
        activity?.critical ? "font-bold text-[#000000CC]" : "font-medium"
      )}
      {activity?.actual_start_date &&
        getLabels(
          "Actual Start date:",
          DateUtils.format(activity?.actual_start_date, "MM-DD-YYYY")
        )}
      {activity?.actual_start_date &&
        activity?.progress !== 100 &&
        !activity?.actual_finish_date &&
        getLabels("Progress:", `${activity?.progress}%`)}
      {activity?.actual_finish_date &&
        getLabels(
          "Actual End date:",
          DateUtils.format(activity?.actual_finish_date, "MM-DD-YYYY")
        )}
    </div>
  );

  const activityIcon = () => {
    if (activity.critical && activity.reverse_dependency) {
      return (
        <span className="flex items-center">
          <span className="flex items-center bg-[#ADC6FF] p-1">
            <CriticalActivityIcon />
          </span>
          <span className="flex items-center p-1">
            <SwapOutlined className="text-[#8C8C8C]" />
          </span>
        </span>
      );
    }
    if (activity.critical) {
      return (
        <span className="flex items-center bg-[#ADC6FF] p-[3px] mr-1">
          <CriticalActivityIcon />
        </span>
      );
    }

    if (activity.reverse_dependency) {
      return (
        <span className="flex items-center p-1">
          <SwapOutlined className="text-[#8C8C8C]" />
        </span>
      );
    }
    return (
      <span className="flex items-center p-1">
        <LinkOutlined />
      </span>
    );
  };

  return (
    <Popover trigger="hover" content={popupContent}>
      <Tag
        color="default"
        className={`flex w-[120px] space-x-0.5 items-center p-0 ${
          activity.critical ? "border-[#ADC6FF]" : "border-gray-300"
        }`}
      >
        <span className="flex items-center">{activityIcon()}</span>
        <span className="truncate">{name}</span>
      </Tag>
    </Popover>
  );
}

export function TaskLinksCellRenderer(params: ICellRendererParams) {
  const { data } = params;
  const tasks = sortAlphanumeric(data?.tasks || [], "activity_id");

  const toShow = tasks.slice(0, 2);
  const remaining = tasks.slice(2, tasks.length);

  const popupContent = (
    <div className="max-w-[260px] m-0 text-xs flex flex-wrap gap-y-1 gap-x-0">
      {remaining.map((task: any) => {
        return <LinkedActivitiesTooltip activity={task} key={task?.id} />;
      })}
    </div>
  );

  return (
    <div className="py-1 flex flex-wrap items-center gap-y-1 gap-x-0">
      {toShow.map((task: any, index: number) => {
        return (
          <div className="flex items-center">
            <LinkedActivitiesTooltip activity={task} key={task?.id} />

            {index === 1 && tasks.length > 2 ? (
              <div className="flex-none flex-grow-0 leading-none">
                <Popover
                  content={popupContent}
                  title={<div className="py-1">Linked Task(s)</div>}
                >
                  <div>+{remaining.length}</div>
                </Popover>
              </div>
            ) : (
              ""
            )}
          </div>
        );
      })}
    </div>
  );
}

function LinkedMaterialTooltip(props: {
  material: {
    id: string;
    material_sequence_id: string;
    name: string;
    implicit: boolean;
    milestone_state: string;
    risk_level: string;
    spec_section_no?: string;
    spec_section_name?: string;
  };
}) {
  const { material } = props;

  const materialName = material.implicit
    ? material.name
    : `${material?.material_sequence_id} - ${material?.name}`;

  const row = (title: string, value: any, valueClassname = "") => (
    <div className="flex">
      <div className="w-12 text-[#8E8E8E]">{title}</div>
      <div className={valueClassname}>{value}</div>
    </div>
  );

  const popupContent = (
    <div className="min-w-[129px] max-w-[239px] text-[#000000CC]">
      <div className="text-xs">{materialName}</div>
      <div className="text-[10px] pt-3 flex flex-col">
        <div className="flex-col">
          <div className="text-[#8E8E8E]">Spec Section:</div>
          <div>
            {material?.spec_section_no
              ? `${material?.spec_section_no || ""} - ${
                  material?.spec_section_name || ""
                }`
              : ""}
          </div>
        </div>
      </div>
      <div className="pt-3 flex flex-col text-[10px]">
        {row("Status:", material?.milestone_state)}
        {row(
          "Risk:",
          material?.risk_level,
          material?.risk_level === RiskLevelType.High
            ? "date-block-risk-high font-semibold"
            : ""
        )}
      </div>
    </div>
  );
  return (
    <Popover trigger="hover" content={popupContent}>
      <Tag
        color="default"
        icon={<LinkOutlined />}
        className={
          material?.risk_level === RiskLevelType.High
            ? "w-[120px] truncate risk-high-box"
            : "bg-gray-100 w-[120px] truncate"
        }
      >
        {materialName}
      </Tag>
    </Popover>
  );
}

export function MaterialLinksCellRenderer(params: ICellRendererParams) {
  const { data } = params;
  const value = data?.materials?.filter((m: any) => !m.implicit);

  const materials = sortLinkedItems(value || [], "material_sequence_id");
  const actualMaterials = materials.filter(
    (material: any) => !material.implicit
  );
  const toShow = actualMaterials.slice(0, 2);
  const remaining = actualMaterials.slice(2, actualMaterials.length);
  const isHighEntityExist = remaining.some(
    (entity: any) => entity?.risk_level === RiskLevelType.High
  );

  const popupContent = (
    <div className="max-w-[260px] m-0 text-xs flex flex-wrap gap-y-1 gap-x-0">
      {remaining.map((item: any) => {
        return (
          <Link to={`materials/${item.id}`} target="_blank">
            <LinkedMaterialTooltip material={item} />
          </Link>
        );
      })}
    </div>
  );

  return (
    <div className="py-1 flex flex-wrap items-center gap-y-1 gap-x-0">
      {toShow.slice(0, 2).map((material: any, index: number) => {
        return (
          <div className="flex items-center">
            <Link
              to={`materials/${material.id}`}
              target="_blank"
              className="leading-normal"
            >
              <LinkedMaterialTooltip material={material} />
            </Link>
            {index === 1 && actualMaterials.length > 2 ? (
              <div className="flex-none flex-grow-0 leading-none">
                <Popover
                  content={popupContent}
                  title={<div className="py-1">Linked Material(s)</div>}
                >
                  <div
                    className={
                      isHighEntityExist
                        ? "date-block-risk-high font-semibold"
                        : ""
                    }
                  >
                    +{remaining.length}
                  </div>
                </Popover>
              </div>
            ) : (
              ""
            )}
          </div>
        );
      })}
    </div>
  );
}

function LinkedSubmittalTooltip(props: {
  showSpecSectionNo: boolean;
  submittal: {
    id: string;
    submittal_sequence_id: string;
    submittal_id?: string;
    name: string;
    implicit: boolean;
    workflow_status: number;
    risk_level: string;
    type: number;
    revision: number;
    spec_no: string;
    spec_section_no?: string;
    spec_name: string;
  };
}) {
  const { submittal, showSpecSectionNo } = props;
  const sequenceId =
    submittal?.submittal_sequence_id || submittal?.submittal_id || "";
  const specNo = submittal?.spec_no || submittal?.spec_section_no;

  const submittalRefrenceId: string =
    showSpecSectionNo && specNo ? `${specNo} - ${sequenceId}` : sequenceId;
  const name = `${submittalRefrenceId} - ${submittal?.name}`;
  const status = SubmittalStatusToStrMap[submittal?.workflow_status];
  const row = (title: string, value: any, valueClassname = "") => (
    <div className="flex">
      <div className="w-12 text-[#8E8E8E]">{title}</div>
      <div className={valueClassname}>{value}</div>
    </div>
  );

  const popupContent = (
    <div className="min-w-[129px] max-w-[239px] text-[#000000CC]">
      {submittal.workflow_status === ESubmittalStatus.VOID && (
        <div className="pb-3">
          <div className="w-full p-1 bg-void-100 text-black text-opacity-50 flex items-center justify-center font-bold text-xs rounded">
            Void
          </div>
        </div>
      )}
      <div className="text-xs">
        {submittal?.submittal_sequence_id} - {submittal?.name}
      </div>
      <div className="text-[10px] pt-3 flex flex-col">
        <div className="flex-col">
          <div className="text-[#8E8E8E]">Spec Section:</div>
          <div>{specNo ? `${specNo} - ${submittal?.spec_name}` : ""}</div>
        </div>
      </div>

      <div className="pt-3 flex flex-col text-[10px]">
        {row("Type:", submittal?.type)} {row("Revision:", submittal?.revision)}
      </div>
      <div className="pt-3 flex flex-col text-[10px]">
        {row("Status:", status)}{" "}
        {row(
          "Risk:",
          submittal?.risk_level,
          submittal?.risk_level === RiskLevelType.High
            ? "date-block-risk-high font-semibold"
            : ""
        )}
      </div>
    </div>
  );
  const getClass = () => {
    switch (true) {
      case submittal.workflow_status === ESubmittalStatus.VOID:
        return "w-[120px] truncate bg-void-100 border-void-200";
        break;
      case submittal?.risk_level === RiskLevelType.High:
        return "w-[120px] truncate risk-high-box";
      default:
        return "bg-gray-100 w-[120px] truncate";
        break;
    }
  };
  return (
    <Popover trigger="hover" content={popupContent}>
      <Tag
        color="default"
        icon={
          submittal.workflow_status === ESubmittalStatus.VOID ? (
            <VoidSubmittalIcon type="text" />
          ) : (
            <LinkOutlined />
          )
        }
        className={getClass()}
      >
        {name}
      </Tag>
    </Popover>
  );
}

function LinkedSubmittalWithPopover(props: {
  showSpecSectionNo: boolean;
  submittal: {
    id: string;
    submittal_sequence_id: string;
    submittal_id?: string;
    name: string;
    implicit: boolean;
    workflow_status: number;
    risk_level: string;
    type: number;
    revision: number;
    spec_no: string;
    spec_section_no?: string;
    spec_name: string;
    actual_milestone_2: string;
    actual_milestone_4: string;
    projected_milestone_2: string;
    projected_milestone_4: string;
    name_milestone_2: string;
    name_milestone_4: string;
    planned_milestone_2: string;
    planned_milestone_4: string;
  };
}) {
  const { submittal, showSpecSectionNo } = props;

  const sequenceId =
    submittal?.submittal_sequence_id || submittal?.submittal_id || "";
  const specNo = submittal?.spec_no || submittal?.spec_section_no;

  const submittalRefrenceId: string =
    showSpecSectionNo && specNo ? `${specNo} - ${sequenceId}` : sequenceId;
  const name = `${submittalRefrenceId} - ${submittal?.name}`;
  const status = SubmittalStatusToStrMap[submittal?.workflow_status];

  const submittalWFStarted = useMemo(() => {
    return (
      submittal?.workflow_status !== ESubmittalStatus.CREATE &&
      submittal?.workflow_status !== ESubmittalStatus.VOID
    );
  }, [submittal]);

  const submittalWFStatusUsingDateBlock = useMemo(() => {
    const submitDate =
      submittal.actual_milestone_2 ||
      (submittalWFStarted
        ? submittal.projected_milestone_2
        : submittal.planned_milestone_2);

    const designReviewDate =
      submittal.actual_milestone_4 ||
      (submittalWFStarted
        ? submittal.projected_milestone_4
        : submittal.planned_milestone_4);

    return {
      submit_by_subcontractor: {
        label: submittal.name_milestone_2,
        date: submitDate ? DateUtils.format(submitDate, "MM-DD-YY") : "",
        milestoneCompleted: !!submittal.actual_milestone_2
      },
      design_review_complete: {
        label: submittal.name_milestone_4,
        date: designReviewDate
          ? DateUtils.format(designReviewDate, "MM-DD-YY")
          : "",
        milestoneCompleted: !!submittal.actual_milestone_4
      }
    };
  }, [submittal, submittalWFStarted]);

  const row = useCallback(
    (title: string, value: any, valueClassname = "") => (
      <div className="flex">
        <div className="w-12 text-[#8E8E8E]">{title}</div>
        <div className={valueClassname}>{value}</div>
      </div>
    ),
    []
  );

  const getStatusIcon = useCallback(
    (dateStr: string, milestoneCompleted: boolean) => {
      if (dateStr) {
        return milestoneCompleted ? <TickIcon /> : <ClockIcon />;
      }
      return "";
    },
    []
  );

  const wfStatusRow = useCallback(
    (
      title: string = "",
      value: any = "",
      milestoneCompleted: boolean = false
    ) => (
      <>
        <div className="text-[#8E8E8E]">{title}:</div>
        <div className={value ? "text-right" : "text-left"}>{value || "-"}</div>
        <div className="w-[10px] flex justify-center items-center">
          {getStatusIcon(value, milestoneCompleted)}
        </div>
      </>
    ),
    [getStatusIcon]
  );

  const isVoidSubmittal = useMemo(
    () => submittal.workflow_status === ESubmittalStatus.VOID,
    [submittal]
  );

  const popupContent = (
    <div className="text-[#000000CC] max-w-[239px]">
      {isVoidSubmittal && (
        <div className="pb-3">
          <div className="w-full p-1 bg-void-100 text-black text-opacity-50 flex items-center justify-center font-bold text-xs rounded">
            Void
          </div>
        </div>
      )}
      <div className="text-xs mb-3">
        {submittal?.submittal_sequence_id} - {submittal?.name}
      </div>
      <div className="text-[10px] space-y-3">
        <div>
          <div className="text-[#8E8E8E]">Spec Section:</div>
          <div>{specNo ? `${specNo} - ${submittal?.spec_name}` : ""}</div>
        </div>
        <div>
          {row("Type:", submittal?.type)}{" "}
          {row("Revision:", submittal?.revision)}
        </div>
        {!isVoidSubmittal && (
          <>
            <div>
              {row("Status:", status)}{" "}
              {row(
                "Risk:",
                submittal?.risk_level,
                submittal?.risk_level === RiskLevelType.High
                  ? "date-block-risk-high font-semibold"
                  : ""
              )}
            </div>
            <div>
              <div className="grid grid-cols-[max-content_auto_12px] gap-x-1">
                {wfStatusRow(
                  submittalWFStatusUsingDateBlock?.submit_by_subcontractor
                    ?.label,
                  submittalWFStatusUsingDateBlock?.submit_by_subcontractor
                    ?.date,
                  submittalWFStatusUsingDateBlock?.submit_by_subcontractor
                    ?.milestoneCompleted
                )}

                {wfStatusRow(
                  submittalWFStatusUsingDateBlock?.design_review_complete
                    ?.label,
                  submittalWFStatusUsingDateBlock?.design_review_complete?.date,
                  submittalWFStatusUsingDateBlock?.design_review_complete
                    ?.milestoneCompleted
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );

  const conditionalClasses = useMemo(() => {
    switch (true) {
      case isVoidSubmittal:
        return "bg-[##F0F3FB] border-[#CFCFDF]";
        break;
      case submittal?.risk_level === RiskLevelType.High:
        return "risk-high-box";
      default:
        return "bg-[#F2F2F2] border-[#0000001A]";
        break;
    }
  }, [submittal, isVoidSubmittal]);

  const linkIcon = useMemo(() => {
    return isVoidSubmittal ? (
      <VoidSubmittalIcon type="text" />
    ) : (
      <LinkOutlined />
    );
  }, [isVoidSubmittal]);

  return (
    <Popover trigger="hover" content={popupContent}>
      <Tag
        color="default"
        className={`w-full max-w-full m-0 py-[1.5px] ${conditionalClasses}`}
      >
        {!isVoidSubmittal ? (
          <>
            <div className="w-full max-w-full overflow-hidden truncate leading-none py-0.5 mb-0.5">
              <span className="mr-1 align-middle">{linkIcon}</span>
              <span className="text-xs text-wrap font-semibold align-middle">
                {name}
              </span>
            </div>
            <div className="space-y-0.5">
              <div className="text-xs align-middle leading-none flex">
                <div className="w-[60px]">Submitted:</div>
                <div
                  className={`grow ${
                    submittalWFStatusUsingDateBlock?.submit_by_subcontractor
                      ?.date
                      ? "text-right"
                      : "pl-1.5"
                  }`}
                >
                  {submittalWFStatusUsingDateBlock?.submit_by_subcontractor
                    ?.date || "-"}
                </div>
                <div className="pl-1 min-w-[10px]">
                  {getStatusIcon(
                    submittalWFStatusUsingDateBlock?.submit_by_subcontractor
                      ?.date,
                    submittalWFStatusUsingDateBlock?.submit_by_subcontractor
                      ?.milestoneCompleted
                  )}
                </div>
              </div>

              <div className="text-xs align-middle leading-none flex">
                <div className="w-[60px]">Approved:</div>
                <div
                  className={`grow ${
                    submittalWFStatusUsingDateBlock?.design_review_complete
                      ?.date
                      ? "text-right"
                      : "pl-1.5"
                  }`}
                >
                  {submittalWFStatusUsingDateBlock?.design_review_complete
                    ?.date || "-"}
                </div>
                <div className="pl-1 min-w-[10px]">
                  {getStatusIcon(
                    submittalWFStatusUsingDateBlock?.design_review_complete
                      ?.date,
                    submittalWFStatusUsingDateBlock?.design_review_complete
                      ?.milestoneCompleted
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="w-full max-w-full py-0.5 mb-0.5 overflow-hidden leading-normal text-wrap whitespace-normal break-words">
            <span className="mr-1 align-middle">
              <VoidSubmittalIcon type="text" />
            </span>

            <span className="text-xs text-wrap font-semibold">{name}</span>
          </div>
        )}
      </Tag>
    </Popover>
  );
}

export function SubmittalLinksCellRenderer(params: ICellRendererParams) {
  const { data, context } = params;
  const showSpecSectionNo = context.projectDetails?.spec_section;

  const submittals = sortLinkedItems(
    data?.submittals || [],
    "submittal_sequence_id"
  );
  const toShow = submittals.slice(0, 2);
  const remaining = submittals.slice(2, submittals.length);

  const isHighEntityExist = remaining.some(
    (entity: any) => entity?.risk_level === RiskLevelType.High
  );

  const popupContent = (
    <div className="m-0 text-xs grid grid-cols-2 gap-y-2 gap-x-2">
      {remaining.map((item: any) => {
        return (
          <div key={item.id}>
            <Link
              target="_blank"
              to={`submittals/${item.id}`}
              className="w-[160px] h-[56px] flex items-stretch"
            >
              <LinkedSubmittalWithPopover
                submittal={item}
                showSpecSectionNo={showSpecSectionNo}
              />
            </Link>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="h-full py-0.5 flex items-stretch gap-x-2">
      {toShow.map((submittal: any) => {
        return (
          <div className="flex items-stretch space-x-2" key={submittal.id}>
            <Link
              target="_blank"
              to={`submittals/${submittal.id}`}
              className="w-[160px] leading-normal flex items-stretch"
            >
              <LinkedSubmittalWithPopover
                submittal={submittal}
                showSpecSectionNo={showSpecSectionNo}
              />
            </Link>
          </div>
        );
      })}
      {submittals.length > 2 ? (
        <div className="flex-none flex-grow-0 leading-none flex items-center">
          <Popover
            content={popupContent}
            title={<div className="py-1">Linked Submittal(s)</div>}
          >
            <div
              className={
                isHighEntityExist ? "date-block-risk-high font-semibold" : ""
              }
            >
              +{remaining.length}
            </div>
          </Popover>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export const responsibleContractorCellRenderer = (params: any): any => {
  const { data } = params;
  let cellMarkup = <div />;

  try {
    cellMarkup = (
      <div className="w-full flex items-center">
        <div className="grow truncate">
          {data.responsible_contractor_name || data.trade_partner_name}
        </div>
        <div className="px-1">
          <EditOutlined className="cell-edit-icon" />
        </div>
      </div>
    );
  } catch (ex) {
    return "";
  }

  return {
    cellMarkup,
    orgName: data?.responsible_contractor_name || data.trade_partner_name || ""
  };
};

export const responsibleContractorCellRendererWithCustomEdit = (
  params: any
): any => {
  const { data, context } = params;
  let cellMarkup = <div />;

  try {
    cellMarkup = (
      <button
        type="button"
        className="w-full flex border-0 bg-transparent text-left"
        onClick={() => {
          context.onEditCell({
            rowIndex: params.node.rowIndex!,
            colKey: params.column!.getId(),
            data
          });
        }}
      >
        <div className="grow overflow-hidden">
          <TruncatedTextWithTooltip
            text={data.responsible_contractor_name || data.trade_partner_name}
          />
        </div>
        <div className="px-1">
          <EditOutlined className="cell-edit-icon" />
        </div>
      </button>
    );
  } catch (ex) {
    return "";
  }

  return {
    cellMarkup,
    orgName: data?.responsible_contractor_name || data.trade_partner_name || ""
  };
};

export function gcRepresentativeCellRendererWithCustomEdit(params: any): any {
  const { data, context } = params;
  const onClickEdit = () => {
    context.onEditCell({
      rowIndex: params.node.rowIndex!,
      colKey: params.column!.getId(),
      data
    });
  };
  const userInput =
    params.userInput || params.colDef.cellRendererParams.userInput;
  if (!data[userInput.id])
    return {
      cellMarkup: (
        <button
          type="button"
          className="flex justify-end w-full border-0 bg-transparent"
          onClick={onClickEdit}
        >
          {agGridCheckFieldEditable(params) && <EditableCell />}
        </button>
      ),
      fullName: ""
    };

  let fullName = "";
  if (data[userInput.id]) {
    fullName = `${data[userInput.firstName]} ${data[userInput.lastName]}`;
  }

  const statusId = data[userInput.statusKey];

  let cellMarkup = <div />;
  try {
    cellMarkup = (
      <button
        type="button"
        className="w-full flex border-0 bg-transparent text-left items-center"
        onClick={onClickEdit}
      >
        {statusId && statusId === AcceptanceStatus.DEACTIVATED ? (
          <div className="absolute flex w-full items-start justify-end pr-4 -top-2.5">
            <UserTag label="Inactive" />
          </div>
        ) : (
          ""
        )}
        <div className="grow overflow-hidden">
          <TruncatedTextWithTooltip text={fullName} />
        </div>
        <div>{agGridCheckFieldEditable(params) && <EditableCell />}</div>
      </button>
    );
  } catch {
    //
  }

  return {
    cellMarkup,
    fullName
  };
}

export function gcRepresentativeCellRenderer(params: any): any {
  const { data } = params;
  const userInput =
    params.userInput || params.colDef.cellRendererParams.userInput;
  if (!data[userInput.id])
    return {
      cellMarkup: (
        <div className="flex justify-end">
          {agGridCheckFieldEditable(params) && <EditableCell />}
        </div>
      ),
      fullName: ""
    };

  let fullName = "";
  if (data[userInput.id]) {
    fullName = `${data[userInput.firstName]} ${data[userInput.lastName]}`;
  }

  let cellMarkup = <div />;
  try {
    cellMarkup = (
      <div className="flex items-start">
        <div>
          <p className="colFirstValue">{fullName}</p>
        </div>
        {agGridCheckFieldEditable(params) && <EditableCell />}
      </div>
    );
  } catch {
    //
  }

  return {
    cellMarkup,
    fullName
  };
}

export function SubmittalReportDetailsCellRenderer(params: any) {
  const { data } = params;
  const submittalData = { ...data };

  submittalData.submittal_schedule_links =
    submittalData.submittal_schedule_links || [];
  submittalData.submittal_material_links =
    submittalData.submittal_material_links?.map((m: any) => ({
      assignee: m.assignee,
      driving_material: m.driving_material,
      material_id: m.id,
      material_sequence_id: m.material_id,
      material_name: m.name,
      status: m.status,
      implicit: m.implicit,
      material: {
        date_block_materials: [
          {
            planned_milestone_1: m.ROJ_date,
            name_milestone_1: m.last_milestone_name
          }
        ]
      }
    })) || [];

  const governingActivity = useMemo(() => {
    const linkedTask = submittalData?.submittal_schedule_links?.find(
      (task: any) => task.driving_task
    );
    if (linkedTask)
      return {
        ...linkedTask.gantt_task,
        text: linkedTask.task_name,
        isLinkedEndDate: linkedTask.linked_to_end_date
      };

    return {} as any;
  }, [submittalData?.submittal_schedule_links]);

  return (
    <div>
      <DateBlockSubmittalImpactVisualisation
        submittal={submittalData}
        governingTask={governingActivity}
      />
    </div>
  );
}

export function UpcomingSubmittalReportDetailsCellRenderer(params: any) {
  const { data } = params;
  const submittalData = { ...data };
  const { projectId } = useParams() as any;

  submittalData.submittal_schedule_links =
    submittalData.submittal_schedule_links || [];
  submittalData.submittal_material_links =
    submittalData.submittal_material_links?.map((m: any) => ({
      assignee: m.assignee,
      driving_material: m.driving_material,
      material_id: m.id,
      material_sequence_id: m.material_id,
      material_name: m.name,
      status: m.status,
      implicit: m.implicit,
      material: {
        date_block_materials: [
          {
            planned_milestone_1: m.ROJ_date,
            name_milestone_1: m.last_milestone_name
          }
        ]
      },

      risk_level: m.risk_level,
      milestone_state: m.milestone_state,
      spec_section_name: m.spec_section_name,
      spec_section_no: m.spec_section_no
    })) || [];

  const governingActivity = useMemo(() => {
    const linkedTask = submittalData?.submittal_schedule_links?.find(
      (task: any) => task.driving_task
    );
    if (linkedTask)
      return {
        ...linkedTask.gantt_task,
        text: linkedTask.task_name,
        isLinkedEndDate: linkedTask.linked_to_end_date
      };

    return {} as any;
  }, [submittalData?.submittal_schedule_links]);

  return (
    <div>
      <div className="flex font-medium space-x-2 pt-3">
        <div className="px-2 uppercase">IMPACTED MATERIALS:</div>
        {submittalData?.submittal_material_links?.length ? "" : "None"}
        {submittalData?.submittal_material_links?.map((item: any) => {
          let materialName = "";
          if (item.implicit) {
            materialName = `Material for Submittal ${submittalData.submittal_id}`;
          } else {
            materialName = item.material_name;
          }
          const tag = (
            <LinkedMaterialTooltip material={{ ...item, name: materialName }} />
          );
          return item.implicit ? (
            tag
          ) : (
            <Link
              to={`/project/${projectId}/materials/${item.material_id}`}
              target="_blank"
              key={materialName}
            >
              {tag}
            </Link>
          );
        })}
      </div>

      <div className="flex font-medium space-x-2 pt-4">
        <div className="px-2 uppercase">IMPACTED ACTIVITIES:</div>
        {submittalData?.submittal_schedule_links?.length ? "" : "None"}
        {submittalData?.submittal_schedule_links?.map((item: any) => {
          return <LinkedActivitiesTooltip activity={item} key={item.id} />;
        })}
      </div>
      <DateBlockSubmittalImpactVisualisation
        submittal={submittalData}
        governingTask={governingActivity}
      />
    </div>
  );
}

export function UpcomingMaterialReportDetailsCellRenderer(params: any) {
  const { data, context } = params;
  const { projectId } = useParams() as any;
  const materialData = { ...data };
  const showSpecSectionNo = context.projectDetails?.spec_section;

  return (
    <div className="pb-3">
      <div className="flex font-medium space-x-2 pt-3">
        <div className="px-2 uppercase">LINKED SUBMITTALS:</div>
        {materialData?.material_submitttal_links?.length ? "" : "None"}
        {materialData?.material_submitttal_links?.map((item: any) => {
          return (
            <Link
              to={`/project/${projectId}/submittals/${item.id}`}
              target="_blank"
              className="leading-normal"
            >
              <LinkedSubmittalTooltip
                submittal={item}
                showSpecSectionNo={showSpecSectionNo}
              />
            </Link>
          );
        })}
      </div>

      <div className="flex font-medium space-x-2 pt-4">
        <div className="px-2 uppercase">IMPACTED ACTIVITIES:</div>
        {materialData?.material_schedule_links?.length ? "" : "None"}
        {materialData?.material_schedule_links?.map((item: any) => {
          return <LinkedActivitiesTooltip activity={item} key={item.id} />;
        })}
      </div>
    </div>
  );
}

export const nextDeadlineCellRenderer = (
  params: any,
  disableTooltip: boolean
) => {
  const { data, context } = params;
  if (!params.value) return null;
  const todayStartDateObject = DateUtils.dateTimeObj().startOf("day");
  const nextDeadlineDateObject = DateUtils.dateTimeObj(params.value);
  const isPast = nextDeadlineDateObject.isBefore(todayStartDateObject);
  const cellValueClassnames = isPast ? "date-block-date-risk" : "";
  if (!data.next_action) {
    return (
      <div className="flex items-center">
        <TruncatedTextWithTooltip
          text={params.value}
          className={cellValueClassnames}
          disableTooltip={disableTooltip}
        />
      </div>
    );
  }

  const popupContent = <div className="p-1">{data.next_action}</div>;
  return (
    <div className="flex items-center">
      <div className="min-w-0 px-0.5">
        <TruncatedTextWithTooltip
          text={params.value}
          className={cellValueClassnames}
        />
      </div>
      {context.isCurrentUserGC && (
        <Tooltip title={popupContent} placement="right">
          <InfoCircleOutlined className="pl-2 text-xs h-[13px] mt-[2px]" />
        </Tooltip>
      )}
    </div>
  );
};

export const materialTemplateListNameCellRenderer = (params: any) => {
  const { data, context } = params;

  return (
    <div className="w-full">
      <div className="flex space-x-3 min-w-0">
        <div className="shrink overflow-hidden text-ellipsis">
          <button
            type="button"
            className="bg-transparent border-0 underlined-text cursor-pointer"
            onClick={() => {
              context.onTemplateNameClick(data);
            }}
          >
            {params.value}
          </button>
        </div>
        {data.default && (
          <div>
            <DefaultTag />
          </div>
        )}
      </div>
    </div>
  );
};

export function MaterialTemplateListActionsCellRenderer(params: any) {
  const {
    data,
    context,
    savingDefaultTemplate,
    canEditMaterialDBTemplate,
    materialTemplates
  } = params;
  const { onWorkflowCopy } = context;
  const [copyPopupVisible, setCopyPopupVisible] = useState(false);

  const isTemplateDisabled = data.disabled;
  const isTemplateUsed = !!data.date_blocks_aggregate?.aggregate?.count;

  const availableMaterialTemplateNames = useMemo(() => {
    return materialTemplates.map((template: any) => {
      return template.name.toLowerCase();
    });
  }, [materialTemplates]);

  const editDisableTooltip = useMemo(() => {
    if (!canEditMaterialDBTemplate) {
      return ErrorMessages.PermissionNotGranted;
    }
    if (isTemplateUsed) {
      return ErrorMessages.templateAlreadyUsed;
    }
    return "";
  }, [canEditMaterialDBTemplate, isTemplateUsed]);

  const isCopyDisabled =
    savingDefaultTemplate || !canEditMaterialDBTemplate || isTemplateDisabled;

  return (
    <div className="flex items-center">
      <Button
        disabled={
          savingDefaultTemplate ||
          !canEditMaterialDBTemplate ||
          isTemplateUsed ||
          isTemplateDisabled
        }
        title={editDisableTooltip}
        type="text"
        size="small"
        className="-mt-0.5 border-0"
        onClick={() => {
          context.onTemplateEditClick(data);
        }}
      >
        <EditOutlined />
      </Button>
      {!isCopyDisabled ? (
        <Popover
          open={copyPopupVisible}
          placement="bottom"
          content={
            <CopyDBTemplate
              setCopyPopupVisible={setCopyPopupVisible}
              availableTemplateNames={availableMaterialTemplateNames}
              template={data}
              onCopy={onWorkflowCopy}
            />
          }
          trigger="click"
          destroyTooltipOnHide
          onOpenChange={() => {
            setCopyPopupVisible(!copyPopupVisible);
          }}
        >
          <Button
            type="text"
            size="small"
            className="pt-0.5 border-0"
            onClick={() => {
              setCopyPopupVisible(true);
            }}
          >
            <CopyIcon />
          </Button>
        </Popover>
      ) : (
        <Button
          disabled
          type="text"
          size="small"
          className="pt-0.5 border-0"
          title={
            !canEditMaterialDBTemplate ? ErrorMessages.PermissionNotGranted : ""
          }
        >
          <CopyIcon />
        </Button>
      )}

      <Switch
        className="-mt-0.5 ml-1.5"
        checked={!isTemplateDisabled}
        onChange={(value: any) => {
          context.onToggleTemplateDisabledConfirm(data, value);
        }}
        size="small"
        disabled={!canEditMaterialDBTemplate || savingDefaultTemplate}
        title={
          !canEditMaterialDBTemplate ? ErrorMessages.PermissionNotGranted : ""
        }
      />
    </div>
  );
}

export const materialTemplateListDefaultCellRenderer = (params: any) => {
  const { data, context, savingDefaultTemplate, canEditMaterialDBTemplate } =
    params;

  const isTemplateDisabled = data.disabled;

  return (
    <div className="flex items-center">
      <Radio.Group
        disabled={
          savingDefaultTemplate ||
          !canEditMaterialDBTemplate ||
          isTemplateDisabled
        }
        onChange={() => {
          context.onTemplateDefaultChange(data);
        }}
        value={data.default ? 1 : 0}
      >
        <Radio className="ml-5" value={1} />
      </Radio.Group>
    </div>
  );
};

export const featureTemplateListNameCellRenderer = (params: any) => {
  const { data, context } = params;

  return (
    <div className="w-full">
      <div className="flex space-x-3 min-w-0">
        <div className="shrink overflow-hidden text-ellipsis">
          <button
            type="button"
            className="bg-transparent border-0 underlined-text cursor-pointer whitespace-pre"
            onClick={() => {
              context.onTemplateNameClick(data);
            }}
          >
            {params.value}
          </button>
        </div>
        {data.default && (
          <div>
            <DefaultTag />
          </div>
        )}
      </div>
    </div>
  );
};

export function FeatureTemplateListActionsCellRenderer(params: any) {
  const {
    data,
    context,
    savingDefaultTemplate,
    canEditFeatureDBTemplate,
    featureTemplates
  } = params;
  const { onWorkflowCopy } = context;
  const [copyPopupVisible, setCopyPopupVisible] = useState(false);

  const isTemplateDisabled = data.disabled;
  const isTemplateUsed = !!data.date_blocks_aggregate?.aggregate?.count;

  const availableTemplateNames = useMemo(() => {
    return featureTemplates.map((template: any) => {
      return template.name.toLowerCase();
    });
  }, [featureTemplates]);

  const editDisableTooltip = useMemo(() => {
    if (!canEditFeatureDBTemplate) {
      return ErrorMessages.PermissionNotGranted;
    }

    return "";
  }, [canEditFeatureDBTemplate]);

  const isCopyDisabled =
    savingDefaultTemplate || !canEditFeatureDBTemplate || isTemplateDisabled;

  return (
    <div className="flex items-center">
      <Button
        disabled={
          savingDefaultTemplate ||
          !canEditFeatureDBTemplate ||
          isTemplateDisabled
        }
        title={editDisableTooltip}
        type="text"
        size="small"
        className="-mt-0.5 border-0"
        onClick={() => {
          context.onTemplateEditClick(data);
        }}
      >
        <EditOutlined />
      </Button>
      {!isCopyDisabled ? (
        <Popover
          open={copyPopupVisible}
          placement="bottom"
          content={
            <CopyDBTemplate
              setCopyPopupVisible={setCopyPopupVisible}
              availableTemplateNames={availableTemplateNames}
              template={data}
              onCopy={onWorkflowCopy}
            />
          }
          trigger="click"
          destroyTooltipOnHide
          onOpenChange={() => {
            setCopyPopupVisible(!copyPopupVisible);
          }}
        >
          <Button
            type="text"
            size="small"
            className="pt-0.5 border-0"
            onClick={() => {
              setCopyPopupVisible(true);
            }}
          >
            <CopyIcon />
          </Button>
        </Popover>
      ) : (
        <Button
          disabled
          type="text"
          size="small"
          className="pt-0.5 border-0"
          title={
            !canEditFeatureDBTemplate ? ErrorMessages.PermissionNotGranted : ""
          }
        >
          <CopyIcon />
        </Button>
      )}

      <Switch
        className="-mt-0.5 ml-1.5"
        checked={!isTemplateDisabled}
        onChange={(value: any) => {
          context.onToggleTemplateDisabledConfirm(data, value);
        }}
        size="small"
        disabled={!canEditFeatureDBTemplate || savingDefaultTemplate}
        title={
          !canEditFeatureDBTemplate ? ErrorMessages.PermissionNotGranted : ""
        }
      />
      {isTemplateUsed && (
        <div className="ml-2">
          <Tag>Template in use. Actions limited.</Tag>
        </div>
      )}
    </div>
  );
}

export const featureTemplateListDefaultCellRenderer = (params: any) => {
  const { data, context, savingDefaultTemplate, canEditFeatureDBTemplate } =
    params;

  const isTemplateDisabled = data.disabled;

  return (
    <div className="flex items-center">
      <Radio.Group
        disabled={
          savingDefaultTemplate ||
          !canEditFeatureDBTemplate ||
          isTemplateDisabled
        }
        onChange={() => {
          context.onTemplateDefaultChange(data);
        }}
        value={data.default ? 1 : 0}
      >
        <Radio className="ml-5" value={1} />
      </Radio.Group>
    </div>
  );
};

export const SelectCellEditor = forwardRef((props: any, ref) => {
  const {
    context,
    dropDownOptionsProperty,
    showSearch,
    stopEditing,
    notFoundContent,
    isSorting = true
  } = props;

  const [value, setValue] = useState(props.value);

  const dropDownOptions = useMemo(() => {
    if (isSorting)
      return context[dropDownOptionsProperty].sort((a: any, b: any) =>
        a.label.localeCompare(b.label)
      );
    return context[dropDownOptionsProperty];
  }, [context, dropDownOptionsProperty, isSorting]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (!value) return null;
        return value;
      }
    };
  });

  return (
    <Select
      autoFocus
      defaultOpen
      placeholder={showSearch ? "Select / Search" : "Select"}
      allowClear
      showSearch={showSearch}
      optionFilterProp="label"
      options={dropDownOptions}
      virtual={false}
      notFoundContent={notFoundContent}
      style={{ width: "100%" }}
      filterOption={(input, option: any) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      onChange={(event: any) => {
        setValue(event);
        setTimeout(() => {
          stopEditing();
        }, 200);
      }}
    />
  );
});

export const integrationTypeCellRenderer = (params: any) => {
  const { value } = params;
  const integrationSystemName = IntegrationSystemNameMap[value];

  const sourceLogo = () => {
    switch (value) {
      case IntegrationType[IntegrationType.PROCORE]:
        return <ProcoreLogoIcon />;
      case IntegrationType[IntegrationType.AUTODESK]:
        return <AutodeskLogoIcon />;
      case IntegrationType[IntegrationType.BIM360]:
        return (
          <img src={BIM360logo} alt="" style={{ width: 18, height: 19 }} />
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex items-center space-x-2" title={integrationSystemName}>
      <div className="flex items-center">{sourceLogo()}</div>
      <div>{integrationSystemName}</div>
    </div>
  );
};

export function IntegrationNameCellRenderer(params: any) {
  const { context, data } = params;

  const isFileBasedIntegration =
    data?.systemIntgConfigProperties?.INTEGRATION_METHOD === "file";

  const subReauthRequired =
    data.properties.SUBSCRIPTION_CONFIG_AUTH_STATE &&
    data.properties.SUBSCRIPTION_CONFIG_AUTH_STATE ===
      IntegrationAuthStates.REAUTHORIZATION_REQUIRED;

  const projReauthRequired =
    data.properties.PROJECT_CONFIG_AUTH_STATE &&
    data.properties.PROJECT_CONFIG_AUTH_STATE ===
      IntegrationAuthStates.REAUTHORIZATION_REQUIRED;

  const reauthRequired = subReauthRequired || projReauthRequired;

  return (
    <div className="max-w-full flex items-center">
      <div className="grow truncate flex items-center">
        {!isFileBasedIntegration ? (
          <button
            type="button"
            className="gridButtonLink "
            onClick={async () => {
              context.onManageIntegrationClick(data);
            }}
          >
            <div className="space-x-1">
              <span>{data.properties.INTEGRATION_NAME}</span>
            </div>
          </button>
        ) : (
          <div className="space-x-1">
            <span>{data.properties.INTEGRATION_NAME}</span>
          </div>
        )}
      </div>
      <div className="shrink-0 flex items-end">
        {reauthRequired && (
          <div className=" mt-2">
            <Popover
              placement="left"
              content={
                <div className="w-[300px]">
                  Authorization has expired and integration with{" "}
                  {`${IntegrationSystemNameMap[data.integration.system]}`} is no
                  longer valid. View configuration details to renew the
                  connection.
                </div>
              }
            >
              <div className="inline">
                <AuthenticationExpiredIcon />
              </div>
            </Popover>
          </div>
        )}
      </div>
    </div>
  );
}

export const dlGrpUserNameCellRenderer = (params: any): any => {
  const { data } = params;

  let cellDiv = <div />;
  try {
    cellDiv = (
      <div className="flex">
        <div>
          <p className="colFirstValue">{data.name}</p>
        </div>
        {data.status_id && data.status_id === AcceptanceStatus.DEACTIVATED ? (
          <div className="absolute flex w-full items-start justify-end pr-3 -top-2">
            <UserTag label="Inactive" />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  } catch {
    //
  }

  return cellDiv;
};

export const userNameCellRenderer = (params: any) => {
  const { data } = params;
  const userInfo = data?.created_by_user ?? data?.user_created;

  const fullName = `${userInfo?.first_name ?? ""} ${userInfo?.last_name ?? ""}`;
  const email = userInfo?.email ?? "";

  const userDetails = (
    <div className="flex flex-col ">
      <div className="flex items-center h-5">{fullName}</div>
      <div className="flex items-center h-5">{email}</div>
    </div>
  );

  return userDetails;
};

export const riskCellRenderer = (
  { value, data }: any,
  disableTooltip: boolean
) => {
  if (!value) return "";
  if (data?.workflow_status === ESubmittalStatus.VOID) return "";
  return (
    <div
      className={
        value === RiskLevelType.High
          ? "date-block-risk-high font-semibold cursor-default"
          : "cursor-default"
      }
    >
      <TruncatedTextWithTooltip text={value} disableTooltip={disableTooltip} />
    </div>
  );
};

export const logStatusCellRenderer = (params: any) => {
  const { data, context, node } = params;
  const { cannotChangeActualDate } = context;
  const status = data?.status;
  const headerTitle = data?.material_id
    ? `${data?.material_id ?? ""}  - ${data?.name ?? ""}`
    : data?.name ?? "";

  const userDetails = (
    <div className="flex space-x-2 items-center justify-between">
      {status}
      <FeatureSetStatus
        headerTitle={headerTitle}
        data={data}
        node={node}
        gqlClientForProject={context?.gqlClientForProject}
        updatePermitted={!cannotChangeActualDate}
      />
    </div>
  );

  return userDetails;
};

export const materialActionCellRenderer = (params: any) => {
  const { context, data } = params;
  const {
    onLinkClick,
    onRequestDurationClick,
    canRequestLeadTime,
    isRequestLeadTimeFeatureEnabled
  } = context;

  const workflowStatus = data?.date_block_data?.workflow_status;
  const isAssigneePresent = data?.assignee || data?.assignee_unregistered;
  const isAssigneeUnregistered = !!data?.assignee_unregistered;
  const isWorkflowNotStarted =
    workflowStatus === EWorkflowStatusDataBlock.WF_NOT_STARTED;

  const requestAlreadySent =
    data?.invite_status &&
    data?.invite_status === EmailInviteStatus.REQUEST_SENT;

  const assigneeIsInactive =
    data?.assignee_status === AcceptanceStatus.DEACTIVATED;

  const requestBtnDisabled =
    assigneeIsInactive ||
    !isAssigneePresent ||
    isAssigneeUnregistered ||
    !isWorkflowNotStarted ||
    requestAlreadySent;

  const linkButton = (
    <button
      type="button"
      className="ciq-icon-button !p-1.5"
      onClick={() => {
        onLinkClick(params.data);
      }}
    >
      <LinkOutlined />
    </button>
  );

  const requestBtnDisabledHint = () => {
    const strCannotRequestDurations = "Cannot request Lead Times:";

    if (!isWorkflowNotStarted)
      return (
        <div className="w-[200px] text-sm">
          <div>{strCannotRequestDurations}</div>
          <br />
          <div>Workflow Started.</div>
        </div>
      );
    if (!isAssigneePresent)
      return (
        <div className="w-[200px] text-sm">
          <div>{strCannotRequestDurations}</div>
          <br />
          <div>No subcontractor assigned.</div>
        </div>
      );
    if (isAssigneeUnregistered)
      return (
        <div className="w-[200px] text-sm">
          <div>{strCannotRequestDurations}</div>
          <br />
          <div>Unregistered subcontractor assigned.</div>
        </div>
      );

    if (assigneeIsInactive)
      return (
        <div className="w-[200px] text-sm">
          <div>{strCannotRequestDurations}</div>
          <br />
          <div>Assigned subcontractor is inactive.</div>
        </div>
      );

    if (requestAlreadySent) {
      return (
        <div className="w-[200px] text-sm">
          <div>Request Lead Times</div>
          <br />
          <div>Request sent.</div>
        </div>
      );
    }
    return <div className="w-[200px] text-sm">Request Lead Times</div>;
  };

  const requestDurationButton = () => {
    if (!isRequestLeadTimeFeatureEnabled) return null;
    if (!canRequestLeadTime) return null;

    if (
      isAssigneePresent &&
      !assigneeIsInactive &&
      !isAssigneeUnregistered &&
      requestAlreadySent
    ) {
      return (
        <Popover
          placement="left"
          content={<div>{requestBtnDisabledHint()}</div>}
        >
          <div>
            <button
              type="button"
              className="ciq-icon-button !p-1.5 text-[#5f6368] hover:text-[#333333]"
              onClick={() => {
                onRequestDurationClick(params.data);
              }}
            >
              <PersonCheckIcon />
            </button>
          </div>
        </Popover>
      );
    }

    return (
      <Popover placement="left" content={<div>{requestBtnDisabledHint()}</div>}>
        <div>
          <button
            type="button"
            className={`ciq-icon-button !p-1.5 ${
              requestBtnDisabled
                ? "text-[#D0D0D0] hover:text-[#D0D0D0]"
                : "text-[#5f6368] hover:text-[#333333]"
            }`}
            onClick={() => {
              onRequestDurationClick(params.data);
            }}
            disabled={requestBtnDisabled}
          >
            <PersonEditIcon />
          </button>
        </div>
      </Popover>
    );
  };

  return (
    <div className="flex items-center justify-center">
      {linkButton}
      {requestDurationButton()}
    </div>
  );
};

export function ActionsCellRenderer(params: any) {
  const { data, context } = params;
  const { projectId, toolTipMsg, history } = context;

  return (
    <div>
      <Button
        className="icon-link-arrow"
        icon={<LinkIconLogPage />}
        color="default"
        type="link"
        onClick={() => {
          history.push(
            `/project/${projectId}/schedule/submittal-linking?&SubmittalId=${data.id}`
          );
        }}
      />
      {data?.redirect_url && (
        <Tooltip placement="right" title={toolTipMsg}>
          <Button
            className="icon-link-arrow button-spacing"
            icon={<ArrowUpBox />}
            color="default"
            type="link"
            onClick={() => {
              window.open(data?.redirect_url, "_blank");
            }}
          />
        </Tooltip>
      )}
    </div>
  );
}

export const submittalTypeCellRenderer = (params: any) => {
  const { context } = params;
  const { isIntegrationMode } = context;
  if (isIntegrationMode) {
    return <TruncatedTextWithTooltip text={params?.value} />;
  }

  return dropDownCellRendererWithCustomEdit(params);
};

export const logPageIDLinkCellRenderer = (id: string, url: string) => {
  return (
    <Link className="gridIdLink" to={url}>
      <TruncatedTextWithTooltip text={id} />
    </Link>
  );
};

export const criticalActivityIndicatorCellRenderer = (params: any) => {
  const { data } = params;
  if (data?.critical && data?.type !== GanttActivityTypes.Wps) {
    return (
      <div className="flex w-full h-full items-center justify-start ml-1.5">
        <Tooltip title="Critical Activity">
          <CriticalActivityIndicator />
        </Tooltip>
      </div>
    );
  }
  return "";
};
