import {
  ExclamationCircleOutlined,
  LinkOutlined,
  SwapOutlined
} from "@ant-design/icons";
import { Button, Space, Tooltip, message } from "antd";
import { ProjectContext } from "context/ProjectProvider";
import { useState, useRef, useContext, useEffect, useMemo } from "react";
import { useMutation } from "@apollo/client";
import modal from "antd/lib/modal";
import {
  MUTATION_INSERT_LINKING_MATERIAL_SCHEDULE_BULK,
  MUTATION_DELETE_LINKING_MATERIAL_SCHEDULE
} from "services/graphQL/mutations";
import ErrorBoundary from "components/error-boundary";
import ActivityIcon from "components/svg-icons/activity-icon";
import { DateUtils } from "utils/dateutils";
import { ErrorMessages } from "constants/index";
import CriticalActivityIndicator from "components/links/critical-activity-indicator";
import { MaterialType, ScheduleLinkingType, ScheduleTaskType } from "./models";
import ActivityGrid from "./activity-grid";
import ListingWindow from "./listing-window";
import ReverseLinkingSettings from "./reverse-linking-setting";

interface IMaterialActivityLinkingProps {
  activityList: Array<ScheduleTaskType>;
  material: MaterialType;
  reFetchActivity?: () => void;
  canEdit: boolean;
}
function MaterialActivityLinking({
  activityList,
  material,
  reFetchActivity,
  canEdit
}: IMaterialActivityLinkingProps) {
  const activityLinking = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLElement>(null);
  const [openActivityList, setOpenActivityList] = useState(false);
  const { gqlClientForProject } = useContext(ProjectContext);
  const [associatedActivitiesCount, setAssociatedActivitiesCount] =
    useState("");
  const [apiCallBack, setApiCallBack] = useState<any>();
  useEffect(() => {
    const element = activityLinking.current;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    element && element?.scrollTo(0, element.scrollHeight);
  }, [material?.material_schedule_links]);
  const [linkingMaterialScheduleMutation] = useMutation(
    MUTATION_INSERT_LINKING_MATERIAL_SCHEDULE_BULK,
    {
      client: gqlClientForProject
    }
  );
  const [deleteLinkingMaterialScheduleMutation] = useMutation(
    MUTATION_DELETE_LINKING_MATERIAL_SCHEDULE,
    {
      client: gqlClientForProject
    }
  );

  const onLinkingMaterialSchedule = (
    selectedScheduleId: String,
    linked_to_end_date: boolean
  ) => {
    const objects = material.ids.map((id) => ({
      task_id: selectedScheduleId,
      material_id: id,
      linked_to_end_date
    }));
    linkingMaterialScheduleMutation({
      variables: {
        objects
      }
    }).then((res) => {
      if (res.data) {
        console.log(res.data);
      }
      if (res.errors) {
        if (res.errors[0]?.message.includes(ErrorMessages.uniquessViolation)) {
          message.error(ErrorMessages.linkingErrorMsg);
          return;
        }
        message.error(res.errors[0].message);
      }
    });
  };

  const onRemoveLinkingMaterialSchedule = (scheduleId: string) => {
    const materialData = material;
    modal.confirm({
      title: "Do you want to unlink?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      className: "skip-listing-window",
      onOk() {
        const materialIds = materialData.ids || [materialData.id];
        deleteLinkingMaterialScheduleMutation({
          variables: {
            where: {
              material_id: { _in: materialIds },
              task_id: { _eq: scheduleId }
            }
          }
        }).then((res) => {
          const activity = {
            id: scheduleId,
            action: "deleted"
          };
          setApiCallBack(activity);
          if (res.errors) {
            if (
              res.errors[0]?.message.includes(ErrorMessages.uniquessViolation)
            ) {
              message.error(ErrorMessages.unLinkingErrorMsg);
              return;
            }
            message.error(res.errors[0].message);
          }
        });
      },
      onCancel() {
        const activity = {
          id: scheduleId,
          action: "cancelled"
        };
        setApiCallBack(activity);
      },
      okText: "Yes",
      cancelText: "No"
    });
  };
  const onChange = (id: string, params?: any) => {
    const selectedActivity = Object.keys(params?.selectedList).find(
      (scheduleId: string) => scheduleId === id
    );
    if (selectedActivity) {
      if (!params || (params && params.linked_to_end_date === null)) {
        onRemoveLinkingMaterialSchedule(id);
      } else {
        const materialData = material;
        const materialIds = materialData.ids || [materialData.id];
        deleteLinkingMaterialScheduleMutation({
          variables: {
            where: {
              material_id: { _in: materialIds },
              task_id: { _eq: id }
            }
          }
        }).then((res) => {
          if (res.errors) {
            if (
              res.errors[0]?.message.includes(ErrorMessages.uniquessViolation)
            ) {
              message.error(ErrorMessages.unLinkingErrorMsg);
              return;
            }
            message.error(res.errors[0].message);
          }
          if (!res.errors) {
            onLinkingMaterialSchedule(id, params.linked_to_end_date);
          }
        });
      }
    } else {
      onLinkingMaterialSchedule(id, params.linked_to_end_date);
    }
  };

  const onChangeReverseLinking = async (prop: {
    selectedScheduleId: String;
    reverse_dependency: boolean;
  }) => {
    const objects = material.ids.map((id) => ({
      task_id: prop.selectedScheduleId,
      material_id: id,
      reverse_dependency: prop.reverse_dependency
    }));
    const res = await linkingMaterialScheduleMutation({
      variables: {
        objects
      }
    });
    if (res.data) {
      message.success("Updated Successfully");
    }
    if (res.errors) {
      if (res.errors[0]?.message.includes(ErrorMessages.uniquessViolation)) {
        message.error(ErrorMessages.linkingErrorMsg);
        return;
      }
      message.error(res.errors[0].message);
    }
  };

  useEffect(() => {
    if (material?.material_schedule_links) {
      const associatedActArr = material?.material_schedule_links.map(
        (item: ScheduleLinkingType) => {
          const taskData = activityList.find(
            (task: ScheduleTaskType) => task.id === item.gantt_task.id
          );
          if (taskData) {
            return taskData;
          }
          return "";
        }
      );

      if (associatedActArr?.length > 0) {
        setAssociatedActivitiesCount(`${associatedActArr.length}`);
      }
    }
  }, [activityList, material?.material_schedule_links]);

  const selectedList = useMemo(() => {
    const tmpSelectedListObj: any = {};
    material?.material_schedule_links.forEach((item: ScheduleLinkingType) => {
      tmpSelectedListObj[item.gantt_task.id] = {
        linked_to_end_date: item.linked_to_end_date
      };
    });
    return tmpSelectedListObj || {};
  }, [material?.material_schedule_links]);

  return (
    <ErrorBoundary>
      <div className="activity-linking" ref={activityLinking}>
        <div className="!border-t-0 activity-linking--nav ">
          <h4>
            Associated Activities{" "}
            <span className="">
              {associatedActivitiesCount !== ""
                ? ` (${associatedActivitiesCount})`
                : ""}
            </span>
          </h4>
          <Button
            disabled={!canEdit}
            ref={buttonRef}
            onClick={() => setOpenActivityList(true)}
          >
            + Associate Activity
          </Button>
        </div>
        <div className="h-full pb-10">
          <div className="h-full overflow-auto overflow-x-hidden">
            {material?.material_schedule_links.map(
              (task: ScheduleLinkingType) => {
                const taskData = activityList.find(
                  (item: ScheduleTaskType) => item.id === task.gantt_task.id
                );
                if (taskData) {
                  return (
                    <div
                      key={task.gantt_task.id}
                      className="material-card--item flex-col"
                    >
                      <div className="flex justify-between items-center">
                        {taskData?.critical ? (
                          <div className="ml-[2px]">
                            <CriticalActivityIndicator />
                          </div>
                        ) : (
                          <ActivityIcon
                            size={20}
                            fill="#a8a8a8"
                            className="grow-none"
                          />
                        )}

                        <i className="mr-2 grow-none entity-id-with-spec">
                          {taskData.source_task_id}
                        </i>
                        <Tooltip
                          title={taskData.text}
                          className="flex align-center grow"
                          placement="topLeft"
                        >
                          {task.driving_task ? (
                            <span className="text-base mr-1 pt-[10px] leading-[0]">
                              *
                            </span>
                          ) : (
                            ""
                          )}
                          {taskData.text}{" "}
                          {taskData?.start_date &&
                            task.linked_to_end_date === false && (
                              <i className="entity-id-with-spec">
                                Start Date :{" "}
                                {taskData?.start_date
                                  ? DateUtils.format(taskData?.start_date)
                                  : ""}
                              </i>
                            )}
                          {taskData?.end_date &&
                            task.linked_to_end_date === true && (
                              <i className="entity-id-with-spec">
                                End Date :{" "}
                                {taskData?.end_date
                                  ? DateUtils.format(taskData?.end_date)
                                  : ""}
                              </i>
                            )}
                        </Tooltip>
                        <Button
                          size="small"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            onChange(taskData.id, {
                              linked_to_end_date: null,
                              selectedList
                            });
                          }}
                          type="link"
                          className="linking--unlink-button grow-none skip-listing-window"
                          disabled={!canEdit}
                        >
                          Unlink <LinkOutlined />
                        </Button>

                        <ReverseLinkingSettings
                          selectedScheduleId={taskData.id}
                          reverseDependency={task.reverse_dependency || false}
                          onChangeReverseLinking={onChangeReverseLinking}
                        />
                      </div>
                      {task.reverse_dependency && (
                        <div className="flex pb-1 pt-2">
                          <Space className="px-4 py-1 flex bg-[#EFEFEF] rounded-sm">
                            <SwapOutlined className="text-[#8C8C8C]" /> Activity
                            is the Predecessor
                          </Space>
                        </div>
                      )}
                    </div>
                  );
                }
                return "";
              }
            )}
            <div
              className="flex justify-end mt-2 mb-4 mr-2"
              style={{ marginRight: "6px" }}
            >
              {openActivityList ? (
                <ListingWindow
                  onClose={() => setOpenActivityList(false)}
                  title="List of Activities"
                  isFromSubmittals={undefined}
                >
                  <div className="material-card--options">
                    <ActivityGrid
                      activityList={activityList}
                      selectedList={selectedList}
                      onToggleSelect={onChange}
                      reFetchActivity={reFetchActivity}
                      apiCallBack={apiCallBack}
                    />
                  </div>
                </ListingWindow>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default MaterialActivityLinking;
