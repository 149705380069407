import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Form, Input, message } from "antd";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { useContext } from "react";
import { MUTATION_INSERT_SPEC_SECTION } from "services/graphQL/mutations";
import { QUERY_GET_SPEC_SECTION_BY_SECTION_NUMBER } from "services/graphQL/queries";
import "./submittal-details.css";
import { CloseOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";

type TCreateSpecSectionModalProps = {
  isModalOpen: boolean;
  onDoneCb: Function;
};

export default function CreateSpecSectionModal(
  props: TCreateSpecSectionModalProps
) {
  const { isModalOpen, onDoneCb } = props;

  const { gqlClientForProject }: TProjectContext = useContext(ProjectContext);

  const [insertSpecSection, { loading: insertingSpecSection }] =
    useMutation<any>(MUTATION_INSERT_SPEC_SECTION, {
      client: gqlClientForProject
    });

  const [form] = useForm();

  const [checkForExistingSpecSection, { loading: loadingExistingSpec }] =
    useLazyQuery(QUERY_GET_SPEC_SECTION_BY_SECTION_NUMBER, {
      client: gqlClientForProject
    });

  const createSpecSection = async () => {
    if (loadingExistingSpec) return;
    try {
      const object = {
        title: form.getFieldValue("title"),
        section_number: form.getFieldValue("section_number")
      };
      const insertSpecResponse = await insertSpecSection({
        variables: {
          object: {
            title: form.getFieldValue("title"),
            section_number: form.getFieldValue("section_number")
          }
        },
        client: gqlClientForProject
      });

      if (insertSpecResponse.data.insert_spec_sections_one.id) {
        message.success("Spec section created successfully");
        onDoneCb(
          insertSpecResponse.data.insert_spec_sections_one.id,
          object.title,
          object.section_number
        );
      } else {
        message.error("Error occured while creating spec section");
      }
    } catch (ex) {
      message.error("Error occured while creating spec section");
    }
  };

  return (
    <div>
      {isModalOpen && (
        <div>
          <div className="createSpecSectionHeader px-3 mb-4 flex justify-between items-center">
            <span className="createSpecSectionLbl flex h-11 items-center ">
              New Spec Section
            </span>
            <CloseOutlined
              onClick={() => {
                onDoneCb(null);
              }}
              style={{ color: "gray", fontSize: "16px" }}
            />
          </div>
          <Form
            form={form}
            name="createSpecSection"
            layout="vertical"
            className="space-y-3 px-3"
          >
            <Form.Item
              label="SPEC SECTION NUMBER"
              name="section_number"
              rules={[
                {
                  required: true,
                  pattern: /^[0-9.]+$/,
                  message: "Please input a number"
                },
                () => ({
                  async validator(_, value) {
                    if (value === undefined) return Promise.resolve();
                    const existingSpecResp = await checkForExistingSpecSection({
                      variables: {
                        sectionNumber: value
                      }
                    });

                    if (existingSpecResp?.data?.spec_sections.length) {
                      return Promise.reject(
                        new Error(
                          "A spec section with this number already exists"
                        )
                      );
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="SPEC SECTION TITLE"
              name="title"
              required
              rules={[
                {
                  required: true,
                  message: "Please enter spec section Title"
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <div className="flex justify-end mt-3">
                <Button
                  type="primary"
                  disabled={insertingSpecSection}
                  loading={insertingSpecSection}
                  onClick={() => {
                    form.validateFields().then(() => {
                      createSpecSection();
                    });
                  }}
                >
                  Add
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
}
