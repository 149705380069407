import { Select } from "antd";
import UserTag from "components/user-tag/user-tag";
import { AcceptanceStatus } from "constants/index";
import { useProjectParticipants } from "hooks/project-participants";
import { useMemo } from "react";

const { Option } = Select;

function ResponsibleDesignerComponent(props: any) {
  const { value, notFoundContent, onChange, disabled, selectedDesignFirmId } =
    props;
  const { projectParticipants } = useProjectParticipants();

  const responsibleDesigners = useMemo(() => {
    const arr = projectParticipants?.designReviewers?.filter(
      (user: any) =>
        selectedDesignFirmId &&
        user &&
        user?.company?.vendor_id === selectedDesignFirmId
    );
    return arr;
  }, [projectParticipants?.designReviewers, selectedDesignFirmId]);

  return (
    <Select
      className="w-full"
      loading={!projectParticipants?.dataLoaded}
      value={value}
      onChange={onChange}
      showSearch
      filterOption={(inputValue, option: any) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      }
      optionFilterProp="label"
      notFoundContent={notFoundContent}
      disabled={disabled}
    >
      {responsibleDesigners.map((user: any) => {
        if (
          (user.status_id === AcceptanceStatus.DEACTIVATED &&
            user.id !== value?.toString()) ??
          ""
        )
          return "";

        const selectedAndInactive =
          (user.status_id === AcceptanceStatus.DEACTIVATED &&
            user.id === value?.toString()) ??
          "";

        return (
          <Option
            key={user.id}
            value={user.id}
            label={`${user.first_name} ${user.last_name} ${user?.company?.name}`}
            firstName={user.first_name}
            lastName={user.last_name}
            className={selectedAndInactive ? "hidden" : ""}
          >
            {user.status_id === AcceptanceStatus.DEACTIVATED && (
              <div className="absolute flex w-full items-center justify-end pr-3 -mt-[1px]">
                <UserTag label="Inactive" />
              </div>
            )}
            <div>
              {user.first_name} {user.last_name}
            </div>
            <div className="text-sm text-gray-500">{user?.company?.name}</div>
          </Option>
        );
      })}
    </Select>
  );
}

export default ResponsibleDesignerComponent;
