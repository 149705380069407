import {
  TEntityMilestone,
  TFeatureWorkflowInstance,
  TFetureDetailUpdateAction
} from "entity-app/models";
import { useRef } from "react";
import { Button, Tooltip } from "antd";
import DatePickerWithIcon from "components/date-picker-with-icon/date-picker-with-icon";
import { DateUtils } from "utils/dateutils";
import ProgressIndicatorCircle from "components/date-block/progress-indicator-circle";
import { getDaysText } from "utils/utils";
import {
  CalendarOutlined,
  CarryOutOutlined,
  EditOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import { EWorkflowStatusDataBlock } from "constants/index";
import { InputEditDataType } from "popups/rename-model/rename-popup";
import { RiskLevelType } from "components/date-block/models";
import { TWidthCalculation } from "../../models";

export default function TimelineIconsOnly(props: {
  milestones: Array<TEntityMilestone>;
  widthCal: TWidthCalculation;
  featureWorkflowInstance: TFeatureWorkflowInstance;
  actions: TFetureDetailUpdateAction;
  showFloat: boolean;
  sourceLink: Array<any>;
  setIsRenameModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRenameDataInput: React.Dispatch<React.SetStateAction<InputEditDataType>>;
  disabled: boolean;
}) {
  const {
    milestones,
    widthCal,
    featureWorkflowInstance,
    actions,
    showFloat,
    sourceLink,
    setIsRenameModalOpen,
    setRenameDataInput,
    disabled
  } = props;
  const { length } = milestones;

  const rectWidth = 48;
  const circleWidth = 24;
  const lineWidth = (widthCal.timelineWidth - circleWidth - rectWidth) / 2;
  const leftMargin = (widthCal.milestonesWidth - circleWidth) / 2;

  const childRefPlannedDate = useRef<any>();
  const renderExtraFooterDiv = (
    <div className="flex justify-center py-2">
      <Button
        // disabled={cannotChangePlannedDate}
        onClick={() => {
          childRefPlannedDate.current?.close();
          actions.updateFeatureWorkflowInstance({
            id: featureWorkflowInstance.id,
            set: { manual_entry_date: null }
          });
        }}
      >
        Clear Date
      </Button>
    </div>
  );

  const allActualSubmitted = milestones.every((m) => m.actual_date);

  const isWorkflowStarted =
    featureWorkflowInstance.workflow_status !==
    EWorkflowStatusDataBlock.WF_NOT_STARTED;

  const plannedFloat = featureWorkflowInstance.pre_WF_start_float;
  const actualFloat = featureWorkflowInstance.effective_float;
  return (
    <div className="flex h-10">
      <div className="shrink-0" style={{ width: leftMargin }} />
      {milestones.map((m, index) => {
        const isNextMileStone = length !== index + 1;
        const isOffest = m.feature_workflow_instance_offsets_as_src?.length > 0;

        return (
          <div
            key={`TimelineIconsOnly_${m.id}`}
            className="flex relative items-center"
          >
            <ProgressIndicatorCircle
              actualDate={m.actual_date}
              projetedDate={m.projected_date}
              plannedDate={m.planned_date}
            />

            {isOffest && (
              <div className="flex items-center">
                <div className="bl-line" style={{ width: lineWidth }} />
                <div className="bl-rect" />
              </div>
            )}
            {isNextMileStone && (
              <div className="bl-line" style={{ width: lineWidth }} />
            )}
          </div>
        );
      })}

      <div className="flex items-center">
        <div className="bl-line" style={{ width: widthCal.lastLineWidth }} />
        <div className="bl-rect bg-[#EBEBEB]">
          <div className="absolute w-52 h-52 -left-20 top-10 flex-col space-y-3 text-xs text-[#3B3B3B]">
            {showFloat && (
              <div className="w-full bg-[#F7F6F4] p-1">
                <div className="flex justify-between p-1 items-center">
                  <div>Planned Float</div>
                  <div className="font-semibold space-x-1 flex items-center">
                    <div>
                      {plannedFloat} {getDaysText(plannedFloat)}
                    </div>
                    {!disabled && !isWorkflowStarted && (
                      <EditOutlined
                        className="pl-1"
                        onClick={() => {
                          setRenameDataInput({
                            value: plannedFloat,
                            id: featureWorkflowInstance.id,
                            lable: "Float",
                            type: "text",
                            identityName: "WorkflowInstanceFloat"
                          });
                          setIsRenameModalOpen(true);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="flex justify-between p-1 items-center">
                  <div>Actual Float</div>
                  <div
                    className={
                      featureWorkflowInstance.risk_level === RiskLevelType.High
                        ? "date-block-risk-high font-semibold"
                        : "font-semibold"
                    }
                  >
                    {actualFloat
                      ? `${actualFloat} ${getDaysText(actualFloat)}`
                      : " - "}
                  </div>
                </div>
              </div>
            )}
            <div className="w-full p-1 bg-[#F7F6F4]">
              <div className="flex justify-between p-1 items-baseline">
                <div>Deadline</div>
                <div className="font-semibold flex space-x-1 items-center">
                  {featureWorkflowInstance.final_deadline_date ? (
                    <div>
                      {DateUtils.format(
                        featureWorkflowInstance.final_deadline_date
                      )}
                    </div>
                  ) : (
                    <div className="px-1">-</div>
                  )}
                  {featureWorkflowInstance.final_deadline_date && (
                    <Tooltip
                      title={
                        sourceLink?.length ? (
                          <div className="p-2">
                            <div className="text-[#8E8E8E]">Source:</div>
                            <div>
                              ({sourceLink[0].target_feature_instance?.title})
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      }
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  )}

                  <div className="mb-1 flex">
                    <DatePickerWithIcon
                      disabledDate={() => {
                        return disabled || allActualSubmitted;
                      }}
                      value={
                        featureWorkflowInstance.manual_entry_date
                          ? DateUtils.dateTimeObj(
                              featureWorkflowInstance.manual_entry_date
                            )
                          : undefined
                      }
                      icon={
                        featureWorkflowInstance.manual_entry_date ? (
                          <CarryOutOutlined />
                        ) : (
                          <CalendarOutlined />
                        )
                      }
                      onChange={(date) => {
                        if (date) {
                          const saveDate =
                            DateUtils.formatDateWithLunchTime(date);
                          const set = { manual_entry_date: saveDate };
                          actions.updateFeatureWorkflowInstance({
                            id: featureWorkflowInstance.id,
                            set
                          });
                        }
                      }}
                      ref={childRefPlannedDate}
                      renderExtraFooter={() => {
                        return featureWorkflowInstance.manual_entry_date
                          ? renderExtraFooterDiv
                          : undefined;
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
