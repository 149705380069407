import { Button, Form, FormRule, Input, message, Card, Col, Row } from "antd";
import {
  MUTATION_INSERT_COMPANY,
  MUTATION_INSERT_POC_TO_COMPANY,
  MUTATION_UPDATE_COMPANY,
  MUTATION_UPDATE_COMPANY_POC
} from "services/graphQL/mutations";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";

import { SUBSCRIPTION_LEVEL_COMPANY_LIST } from "services/graphQL/subscriptions";
import FormItem from "antd/es/form/FormItem";

import { DeleteOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { useCIQMutation, useCIQSubscription } from "hooks/ciq-gql-hooks";
import { ErrorMessages, Regex } from "../../constants";

export interface NewCompanyFormRef {
  focus: () => void;
  scrollIntoView: () => void;
}

interface NewCompanyFormProps {
  editCompany?: any;
  isFormEditOnly?: boolean;
  onNewCompany?: Function;
  onUpdateCompany?: Function;
}

const NewCompanyForm = forwardRef<
  NewCompanyFormRef | undefined,
  NewCompanyFormProps
>((props, ref) => {
  const {
    editCompany,
    isFormEditOnly = true,
    onNewCompany,
    onUpdateCompany
  } = props;

  const [form] = Form.useForm();

  const formContainerRef = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      form.getFieldInstance("name")?.focus();
    },
    scrollIntoView: () => {
      formContainerRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
  }));

  const { data: companies } = useCIQSubscription(
    SUBSCRIPTION_LEVEL_COMPANY_LIST
  );
  const [addCompany, { error: addCompanyError }] = useCIQMutation(
    MUTATION_INSERT_COMPANY
  );
  const [insertPoc] = useCIQMutation(MUTATION_INSERT_POC_TO_COMPANY);
  const [updatePoc] = useCIQMutation(MUTATION_UPDATE_COMPANY_POC);
  const [updateCompanyData] = useCIQMutation(MUTATION_UPDATE_COMPANY);

  const [isCompanyNameExist, setCompanyNameExist] = useState(false);
  const [responseLoading, setResponseLoading] = useState(false);
  const [isFormEditable, setFormEditable] = useState(isFormEditOnly);

  const [existingPocArr, setExistingPocArr] = useState<any[]>([]);
  const [deletedPocArr, setDeletedPocArr] = useState<any[]>([]);

  useEffect(() => {
    setFormEditable(isFormEditOnly);

    const fillExistingPocArr = editCompany?.trade_partner_pocs.map(
      (poc: any) => {
        const pocmap = poc;
        pocmap.isOldPoc = true;
        return pocmap;
      }
    );
    setExistingPocArr(fillExistingPocArr);
  }, [editCompany?.trade_partner_pocs, isFormEditOnly]);

  async function deletePOCList() {
    if (deletedPocArr.length > 0) {
      const deletedItems: any = [];

      deletedPocArr.forEach((poc) => {
        const item = existingPocArr.find(
          (existingItem) => existingItem.id === poc.id
        );
        if (item) {
          deletedItems.push(item);
        }
      });

      if (deletedItems.length > 0) {
        const deletePromises = deletedItems.map((poc: any) => {
          const variables = {
            pk_columns: { id: poc.id },
            _set: { deleted: true }
          };
          return updatePoc({ variables });
        });

        const results = await Promise.all(deletePromises);
        if (
          results.some((result) => !result.data?.update_trade_partner_poc_by_pk)
        ) {
          setResponseLoading(false);
          message.error(ErrorMessages.CompanyUpdateFailedMsg);
        }
      }
    }
  }

  async function updatePOCList(values: any) {
    const pocs: [] = values.trade_partner_pocs;
    const newPOCArr: any = [];
    const updatePromises: Promise<any>[] = [];

    for (let i = 0; i < pocs.length; i += 1) {
      const poc: any = pocs[i];
      const pocMap: any = {};
      const existingPOC = existingPocArr.filter((c) => c.id === poc.id);
      if (existingPOC.length > 0) {
        // Updating existing POC
        Object.keys(poc).forEach((key: string) => {
          if (existingPOC[0][key] !== poc[key]) {
            pocMap[key] = poc[key];
          }
        });
        if (Object.entries(pocMap).length > 0) {
          setResponseLoading(true);
          const variables = {
            pk_columns: { id: poc.id },
            _set: pocMap
          };
          updatePromises.push(updatePoc({ variables }));
        }
      } else {
        // Adding New Added POC to existing Company
        Object.keys(poc).forEach((key: string) => {
          if (poc[key] && poc[key] !== undefined) {
            pocMap[key] = poc[key];
          }
        });
        if (Object.keys(pocMap).length > 0) {
          pocMap.vendor_id = editCompany.id;
          newPOCArr.push(pocMap);
        }
      }
    }

    // Execute all update promises
    if (updatePromises.length > 0) {
      const results = await Promise.all(updatePromises);
      if (
        results.some((result) => !result.data?.update_trade_partner_poc_by_pk)
      ) {
        setResponseLoading(false);
        message.error(ErrorMessages.CompanyUpdateFailedMsg);
        return;
      }
    }

    // Adding New Added POC to existing Company
    if (newPOCArr.length > 0) {
      const addPOCResponse = await insertPoc({
        variables: { objects: newPOCArr }
      });
      if (!addPOCResponse.data) {
        setResponseLoading(false);
        message.error(ErrorMessages.CompanyUpdateFailedMsg);
      }
    }
  }

  const onFinish = async (values: any) => {
    if (isCompanyNameExist) return;
    const company: any = {};
    const pocs: any = [];
    if (editCompany) {
      setResponseLoading(true);
      await updatePOCList(values);
      await deletePOCList();

      Object.keys(values).forEach((key: string) => {
        if (key !== "trade_partner_pocs") {
          company[key] = values[key];
        }
      });

      if (
        company.name === editCompany.name &&
        company.address === editCompany.address
      ) {
        setResponseLoading(false);
        message.success(ErrorMessages.CompanyUpdatedMsg);
        onUpdateCompany?.(editCompany);
        return;
      }

      if (
        Object.entries(company).length > 0 &&
        (company.name !== editCompany.name ||
          company.address !== editCompany.address)
      ) {
        const variables = {
          pk_columns: { id: editCompany.id },
          _set: company
        };
        const updateCompanyDataResponse = await updateCompanyData({
          variables
        });

        setResponseLoading(false);
        if (
          updateCompanyDataResponse.data?.update_subscription_vendors_by_pk?.id
        ) {
          setResponseLoading(false);
          message.success(ErrorMessages.CompanyUpdatedMsg);
          onUpdateCompany?.(updateCompanyDataResponse.data);
        } else {
          message.error(ErrorMessages.CompanyUpdateFailedMsg);
        }
      }
    } else {
      Object.keys(values).forEach((key: string) => {
        if (values[key]) {
          if (key === "trade_partner_pocs") {
            const users: [] = values[key];
            users.forEach((user: any) => {
              const userdict: any = {};
              Object.keys(user).forEach((pockey: string) => {
                if (user[pockey] || user[key] !== undefined) {
                  userdict[pockey] = user[pockey];
                }
              });
              if (Object.keys(userdict).length > 0) {
                pocs.push(userdict);
              }
            });
            if (pocs.length > 0) {
              company[key] = { data: pocs };
            }
          } else {
            company[key] = values[key];
          }
        }
      });
      setResponseLoading(true);
      const addCompanyResponse = await addCompany({
        variables: { object: company }
      });

      setResponseLoading(false);
      if (addCompanyResponse.data?.insert_subscription_vendors_one?.id) {
        form.resetFields();
        message.success("Company has been added succssfully.");
        onNewCompany?.(addCompanyResponse.data);
      } else if (addCompanyError) {
        message.error(addCompanyError.message);
      }
    }
  };

  const onCompanyNameChange = (event: any) => {
    if (companies?.subscription_vendors) {
      const companylist = companies.subscription_vendors;
      for (let index = 0; index < companylist.length; index += 1) {
        const element: any = companylist[index];
        if (
          (element.name as string).toLowerCase() ===
          (event.target.value as string).toLowerCase().trim()
        ) {
          setCompanyNameExist(true);
          break;
        } else {
          setCompanyNameExist(false);
        }
      }
    }
  };

  const isEmailFieldReadOnly = (poc: any) => {
    if (editCompany) {
      if (poc) {
        const arr = existingPocArr.filter(
          (item: any) => item.email !== poc.email
        );
        if (arr.length > 0) {
          return false;
        }
        return true;
      }
    }
    return false;
  };

  const companyFormRules: { [key: string]: FormRule[] } = {
    name: [
      {
        required: true,
        validateTrigger: "onSubmit",
        message: ErrorMessages.CompanyNameRequired
      }
    ],
    contactFirstName: [
      {
        required: true,
        validateTrigger: "onSubmit",
        message: ErrorMessages.FirstName
      }
    ],
    contactLastName: [
      {
        required: true,
        validateTrigger: "onSubmit",
        message: ErrorMessages.LastName
      }
    ],
    contactPhone: [
      {
        message: ErrorMessages.PhoneNumber,
        validateTrigger: "onSubmit",
        pattern: Regex.phoneNumber
      }
    ]
  };

  return (
    <div
      className="company-form h-full overflow-x-hidden px-3 py-1"
      ref={formContainerRef}
      data-testid="new-company-form-container"
    >
      <Form
        preserve={false}
        onFinish={onFinish}
        form={form}
        layout="vertical"
        initialValues={{
          name: editCompany?.name,
          address: editCompany?.address,
          trade_partner_pocs: editCompany?.trade_partner_pocs || [{}]
        }}
        data-testid="new-company-form"
      >
        <FormItem
          name="name"
          label="Company name"
          rules={companyFormRules.name}
          data-testid="company-name-form-item"
        >
          <Input
            type="text"
            disabled={!isFormEditable}
            onChange={onCompanyNameChange}
            autoFocus
            data-testid="company-name-input"
          />
        </FormItem>
        {isCompanyNameExist && (
          <div
            className="ant-form-item-explain-error mt-[-12px]"
            data-testid="company-name-exist-error"
          >
            {ErrorMessages.CompanyNameExist}
          </div>
        )}
        <FormItem
          name="address"
          label="Address"
          data-testid="company-address-form-item"
        >
          <TextArea
            autoSize={{ maxRows: 3, minRows: 3 }}
            disabled={!isFormEditable}
            data-testid="company-address-input"
          />
        </FormItem>
        <Form.List
          name="trade_partner_pocs"
          data-testid="company-poc-form-list"
        >
          {(fields, { add, remove }) => (
            <>
              <div className="form-item-label">Point of contact</div>
              {fields.map((field, index) => (
                <Card
                  className="mb-2 relative"
                  key={field.key}
                  size="small"
                  title={`Contact ${index + 1}`}
                  extra={
                    isFormEditable && (
                      <DeleteOutlined
                        onClick={() => {
                          if (
                            form.getFieldValue("trade_partner_pocs")[field.key]
                          ) {
                            setDeletedPocArr([
                              ...deletedPocArr,
                              form.getFieldValue("trade_partner_pocs")[
                                field.key
                              ]
                            ]);
                          }
                          remove(field.name);
                        }}
                      />
                    )
                  }
                  data-testid="company-poc-form-card"
                >
                  <Row gutter={12}>
                    <Col span={12}>
                      <FormItem
                        {...field}
                        key="first_name"
                        label="First name"
                        name={[field.name, "first_name"]}
                        rules={companyFormRules.contactFirstName}
                        data-testid="company-poc-first-name-form-item"
                      >
                        <Input
                          type="text"
                          disabled={!isFormEditable}
                          data-testid="company-poc-first-name-input"
                        />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem
                        {...field}
                        key="last_name"
                        name={[field.name, "last_name"]}
                        label="Last name"
                        rules={companyFormRules.contactLastName}
                        data-testid="company-poc-last-name-form-item"
                      >
                        <Input
                          disabled={!isFormEditable}
                          data-testid="company-poc-last-name-input"
                        />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem
                        {...field}
                        key="email"
                        className="mb-0"
                        name={[field.name, "email"]}
                        label="Email"
                        rules={[
                          {
                            type: "email",
                            validateTrigger: "onSubmit",
                            message: ErrorMessages.EmailRequired
                          }
                        ]}
                        data-testid="company-poc-email-form-item"
                      >
                        <Input
                          disabled={isEmailFieldReadOnly(
                            form.getFieldValue("trade_partner_pocs")[field.key]
                          )}
                          data-testid="company-poc-email-input"
                        />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem
                        {...field}
                        key="phone"
                        className="mb-0"
                        name={[field.name, "phone"]}
                        label="Phone number"
                        rules={companyFormRules.contactPhone}
                        data-testid="company-poc-phone-form-item"
                      >
                        <Input
                          disabled={!isFormEditable}
                          data-testid="company-poc-phone-input"
                        />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem
                        {...field}
                        key="id"
                        name={[field.name, "id"]}
                        hidden
                      >
                        <Input disabled />
                      </FormItem>
                    </Col>
                  </Row>
                </Card>
              ))}
              {isFormEditable && (
                <div className="text-right">
                  <Button
                    onClick={() => add()}
                    size="small"
                    data-testid="add-another-contact-button"
                  >
                    Add another contact
                  </Button>
                </div>
              )}
            </>
          )}
        </Form.List>
        {isFormEditable && (
          <div className="mt-4 flex justify-end">
            <Button
              htmlType="submit"
              type="primary"
              disabled={responseLoading || isCompanyNameExist}
              loading={responseLoading}
              data-testid={
                editCompany ? "update-company-button" : "add-company-button"
              }
            >
              {editCompany ? "Update Company" : "Add Company"}
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
});

export default NewCompanyForm;
