import { Form, InputNumber, Select } from "antd";
import { CompanyParticipationType } from "constants/index";
import { ProjectContext } from "context/ProjectProvider";
import { useProjectParticipants } from "hooks/project-participants";
import { useContext, useEffect } from "react";
import { restrictInputToNumbers } from "utils/inpututils";

function Participants(props: {
  actions?: any;
  featureInstanceData?: any;
  disabled: boolean;
}) {
  const { actions, featureInstanceData, disabled } = props;
  const [form] = Form.useForm();
  const { projectParticipants } = useProjectParticipants();
  const subContractors = projectParticipants?.materialTradePartners;
  const {
    columnHeaders: { bidPackageHeaderMap }
  } = useContext(ProjectContext);

  useEffect(() => {
    if (!featureInstanceData) return;
    const awardedSubContractor =
      featureInstanceData?.feature_company_participants?.find(
        (company: any) =>
          company?.company_participation_type_id ===
          CompanyParticipationType.AWARDED_SUB_CONTRACTOR
      );

    const biddingSubContractor =
      featureInstanceData?.feature_company_participants?.filter(
        (company: any) =>
          company?.company_participation_type_id ===
          CompanyParticipationType.BIDDING_SUB_CONTRACTOR
      );

    form.setFieldsValue({
      no_of_bidders: featureInstanceData?.feature_bid_package?.no_of_bidders,
      estimated_value:
        featureInstanceData?.feature_bid_package?.estimated_value,
      awarded_value: featureInstanceData?.feature_bid_package?.awarded_value,
      bidding_subcontractors: biddingSubContractor?.map(
        (c: any) => c?.vendor_id
      ),
      awarded_subcontractor: awardedSubContractor?.vendor_id
    });
  }, [featureInstanceData, form]);

  const updateParticipantInputFields = async (key: string, value: any) => {
    const param: any = {};
    param[key] = value;
    const response = await actions.updateBidPackageFields(param);
    if (!response.success) {
      form.setFieldsValue({ key: value });
    }
  };

  return (
    <Form layout="vertical" form={form}>
      <Form.Item
        label={bidPackageHeaderMap?.no_of_bidders}
        name="no_of_bidders"
      >
        <InputNumber
          disabled={disabled}
          className="w-full"
          type="number"
          min={0}
          onKeyDown={restrictInputToNumbers}
          onBlur={(event: any) =>
            updateParticipantInputFields("no_of_bidders", event?.target?.value)
          }
        />
      </Form.Item>
      <Form.Item
        label={bidPackageHeaderMap?.bidding_subcontractors}
        name={["bidding_subcontractors"]}
      >
        <Select
          disabled={disabled}
          mode="multiple"
          options={
            subContractors
              ? subContractors.map((company: any) => ({
                  label: company.subscription_vendor.name,
                  value: company.vendor_id
                }))
              : []
          }
          onBlur={() => {
            const ids = form.getFieldValue(["bidding_subcontractors"]);
            actions.updateFeatureCompanyParticipant({
              vendorIds: ids,
              companyParticipantTypeId:
                CompanyParticipationType.BIDDING_SUB_CONTRACTOR
            });
          }}
          removeIcon={null}
          showArrow
        />
      </Form.Item>
      <div className="flex space-x-2 w-full">
        <Form.Item
          className="grow"
          label={bidPackageHeaderMap?.estimated_value}
          name="estimated_value"
        >
          <InputNumber
            disabled={disabled}
            className="w-full"
            addonBefore="$"
            min={0}
            onKeyDown={restrictInputToNumbers}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            onBlur={(event: any) =>
              updateParticipantInputFields(
                "estimated_value",
                event?.target?.value?.replaceAll(",", "")
              )
            }
          />
        </Form.Item>
        <Form.Item
          className="grow"
          label={bidPackageHeaderMap?.awarded_value}
          name="awarded_value"
        >
          <InputNumber
            disabled={disabled}
            className="w-full"
            addonBefore="$"
            min={0}
            onKeyDown={restrictInputToNumbers}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            onBlur={(event: any) => {
              updateParticipantInputFields(
                "awarded_value",
                event?.target?.value?.replaceAll(",", "")
              );
            }}
          />
        </Form.Item>
      </div>
      <Form.Item
        label={bidPackageHeaderMap?.awarded_subcontractor}
        name="awarded_subcontractor"
      >
        <Select
          disabled={disabled}
          options={
            subContractors
              ? subContractors.map((company: any) => ({
                  label: company.subscription_vendor.name,
                  value: company.vendor_id
                }))
              : []
          }
          onChange={(vendorId) => {
            actions.updateFeatureCompanyParticipant({
              vendorIds: [vendorId],
              companyParticipantTypeId:
                CompanyParticipationType.AWARDED_SUB_CONTRACTOR
            });
          }}
        />
      </Form.Item>
    </Form>
  );
}

export default Participants;
