export default function RiskReportCardIcon({ size = 80 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 89 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.43956 0C1.98768 0 0 1.98987 0 4.44444V75.5556C0 78.0102 1.98768 80 4.43956 80H84.3516C86.8036 80 88.7912 78.0102 88.7912 75.5556V4.44444C88.7912 1.98987 86.8036 0 84.3516 0H4.43956ZM8.87912 71.1111V8.88889H79.9121V71.1111H8.87912ZM53.2747 17.7778H17.7582V26.6667H53.2747V17.7778ZM71.033 53.3333V62.2222H35.5165V53.3333H71.033ZM62.1538 35.5556H26.6374V44.4444H62.1538V35.5556Z"
        fill="#3B3B3B"
        fillOpacity="0.3"
      />
    </svg>
  );
}
