import { Button, Divider, Form, message, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useMemo } from "react";
import { IMaterial } from "./type-definitions";
import { useRequestDurations } from "./hooks/request-durations";

function RequestDurationsSingle({
  onCancel,
  onComplete,
  selectedItem
}: {
  onCancel: () => void;
  onComplete: () => void;
  selectedItem: {
    id: string;
  };
}) {
  const {
    data: queryResponse,
    loading,
    sendInvite,
    sendingInvite
  } = useRequestDurations([selectedItem.id]);

  const materialData: any = useMemo(() => {
    if (!queryResponse) return null;
    const material: IMaterial = queryResponse.material[0];
    return {
      id: material.id,
      name: material.name,
      materialAssignee: material.material_assignee,
      assigneeName: material.material_assignee
        ? `${material.material_assignee?.first_name} ${material.material_assignee?.last_name}`
        : "",
      assigneeEmail: material.material_assignee?.email,
      tradePartner: material.materials_trade_partner?.name
    };
  }, [queryResponse]);

  const [form] = Form.useForm();

  const onSubmit = async (values: any) => {
    const variables = {
      sendEstimateInviteData: [
        {
          assignee: materialData.materialAssignee.id,
          material_ids: [materialData.id],
          notes: values?.notes?.trim()
        }
      ]
    };
    const response = await sendInvite(variables);

    if (response.success) {
      message.success("Invite sent successfully");
      onComplete();
    }
  };

  if (loading && !queryResponse)
    return (
      <div className="min-h-[260px] flex justify-center items-center">
        <Spin size="small" data-testid="loading-spinner" />
      </div>
    );

  return (
    <div>
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <div className="px-4 space-y-6">
          <div className="mt-6">
            <div className="form-field-heading-2">recipient</div>
            <div>
              {materialData?.materialAssignee && (
                <div className="text-sm">
                  <div>{materialData?.tradePartner}</div>
                  <div>
                    {materialData?.assigneeName} &lt;
                    {materialData?.assigneeEmail}&gt;
                  </div>
                </div>
              )}
            </div>
          </div>

          <div>
            <Form.Item label="Note" name="notes">
              <TextArea
                rows={2}
                placeholder="Add a Note to your Request"
                autoSize={{ minRows: 2, maxRows: 2 }}
                autoFocus
              />
            </Form.Item>
          </div>
        </div>
        <Divider className="m-0 p-0" />
        <div className="flex justify-end gap-2 px-4 py-3">
          <Button
            type="default"
            onClick={onCancel}
            disabled={sendingInvite}
            data-testid="cancel-button"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={sendingInvite}
            data-testid="send-request-button"
          >
            Send Request
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default RequestDurationsSingle;
