export default function CriticalActivityBorderedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="22"
      viewBox="0 0 30 22"
      fill="none"
    >
      <path
        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H26C27.933 0.5 29.5 2.067 29.5 4V18C29.5 19.933 27.933 21.5 26 21.5H4C2.067 21.5 0.5 19.933 0.5 18V4Z"
        fill="#F0F5FF"
      />
      <path
        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H26C27.933 0.5 29.5 2.067 29.5 4V18C29.5 19.933 27.933 21.5 26 21.5H4C2.067 21.5 0.5 19.933 0.5 18V4Z"
        stroke="#ADC6FF"
      />
      <g clipPath="url(#clip0_1505_5075)">
        <path
          d="M16.75 15.0835L12.9583 15.0835C11.5086 15.0835 10.3333 13.9082 10.3333 12.4585C10.3333 11.0087 11.5086 9.8335 12.9583 9.8335L17.0417 9.8335C17.8471 9.8335 18.5 9.18057 18.5 8.37516C18.5 7.56976 17.8471 6.91683 17.0417 6.91683L13.1504 6.91683C12.9102 7.59653 12.262 8.0835 11.5 8.0835C10.5335 8.0835 9.75 7.30002 9.75 6.3335C9.75 5.36697 10.5335 4.5835 11.5 4.5835C12.262 4.5835 12.9102 5.07046 13.1504 5.75016L17.0417 5.75016C18.4914 5.75016 19.6667 6.92541 19.6667 8.37516C19.6667 9.82492 18.4914 11.0002 17.0417 11.0002L12.9583 11.0002C12.1529 11.0002 11.5 11.6531 11.5 12.4585C11.5 13.2639 12.1529 13.9168 12.9583 13.9168L16.75 13.9168L16.75 12.1668L19.6667 14.5002L16.75 16.8335L16.75 15.0835Z"
          fill="#2F54EB"
        />
      </g>
      <defs>
        <clipPath id="clip0_1505_5075">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="matrix(-4.37114e-08 1 1 4.37114e-08 8 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
