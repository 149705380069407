import SuccessCheckIcon from "components/svg-icons/success-icon";
import { Button, Drawer, Result } from "antd";
import {
  CloseOutlined,
  InfoCircleOutlined,
  RightOutlined
} from "@ant-design/icons";
import { useState } from "react";
import Paragraph from "antd/lib/typography/Paragraph";
import { EstimateStatus, IMaterial } from "./type-definitions";

const LEAD_TIME_DATA_FETCH_ERROR_MSGS: { [key: string]: string } = {
  INVALID_TOKEN: "This link is invalid.",
  FAILED_TO_FETCH_DATA:
    "Failed to fetch Lead Times request details. Please try again."
};

export enum LeadTimeErrorCodes {
  FAILED_TO_FETCH_DATA = "FAILED_TO_FETCH_DATA",
  INVALID_TOKEN = "INVALID_TOKEN",
  TOKEN_EXPIRED = "TOKEN_EXPIRED",
  REQUEST_ALREADY_SENT = "REQUEST_ALREADY_SENT"
}

export function VerticalNavList({
  items,
  onSelect,
  activeKey
}: {
  items: IMaterial[];
  onSelect: (materialIndex: number) => void;
  activeKey: string | null;
}) {
  const initialCssClassNames =
    "text-left bg-white cursor-pointer w-full py-2.5 pl-6 pr-5 space-x-2 border-0 border-r-2 border-transparent border-solid flex items-center overflow-hidden";

  return (
    <div>
      {items.map((item, index) => (
        <button
          role="tab"
          type="button"
          tabIndex={0}
          className={`${initialCssClassNames} ${
            activeKey === item.id
              ? "bg-[#F0F0F0] !border-black font-semibold"
              : ""
          }`}
          onClick={() => {
            onSelect(index);
          }}
          key={item.id}
          data-testid={`vertical-list-item-${item.id}`}
        >
          <div className="grow text-color-5">{item.name}</div>
          {item.estimate_status === EstimateStatus.SUBMITTED && (
            <div className="flex items-center justify-center">
              <SuccessCheckIcon />
            </div>
          )}
        </button>
      ))}
    </div>
  );
}

export function FailureSection({
  errorCode,
  onRetry
}: {
  errorCode: LeadTimeErrorCodes;
  onRetry: () => void;
}) {
  if (errorCode === LeadTimeErrorCodes.INVALID_TOKEN) {
    return (
      <Result
        status="error"
        title={LEAD_TIME_DATA_FETCH_ERROR_MSGS[errorCode]}
      />
    );
  }

  if (errorCode === LeadTimeErrorCodes.FAILED_TO_FETCH_DATA) {
    return (
      <Result
        status="error"
        title={LEAD_TIME_DATA_FETCH_ERROR_MSGS[errorCode]}
        subTitle="Please try again."
        extra={[
          <Button key="retry" onClick={onRetry}>
            Retry
          </Button>
        ]}
      />
    );
  }

  return null;
}

export function mergeSpecSectionNoandName(
  specSectionNo: string | null,
  specSectionName: string | null
) {
  if (!specSectionNo || !specSectionName) return null;
  if (specSectionNo === null || specSectionNo === "") return specSectionName;
  if (specSectionName === null || specSectionName === "") return specSectionNo;
  return `${specSectionNo} - ${specSectionName}`;
}

export function MaterialDetailsSectionHeader({
  material,
  className
}: {
  material: IMaterial;
  className?: string;
}) {
  const [open, setOpen] = useState(false);

  const specSectionString = mergeSpecSectionNoandName(
    material.spec_section_no,
    material.spec_section_name
  );

  const noMaterialDetailsPresent =
    !specSectionString && !material.material_tag && !material.description;

  return (
    <div className={`${className}`}>
      <div className="text-sm font-semibold text-color-1 hidden md:block">
        Material Details
      </div>
      <div>
        <div className="flex items-start justify-start space-x-2 md:space-x-4 mt-3">
          <div className="text-base text-color-4 font-semibold">
            {material.name}
          </div>
          <div className="flex items-center justify-start pt-0.5 grow">
            {material.estimate_status === EstimateStatus.SUBMITTED && (
              <span className="inline-block" title="Lead Time Submitted">
                <SuccessCheckIcon />
              </span>
            )}
          </div>
          <div className="md:hidden flex items-center justify-center">
            <Button
              size="small"
              icon={<InfoCircleOutlined className="text-color-9" />}
              className="text-color-9"
              onClick={() => {
                setOpen(true);
              }}
            />
          </div>
        </div>
      </div>

      <Drawer
        placement="bottom"
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        closable={false}
        title={
          <div className="material-details-drawer w-full max-w-full flex justify-between items-center space-x-2 overflow-hidden">
            <div className="grow overflow-hidden">
              <div className="font-semibold truncate text-sm text-color-1">
                Material Details
              </div>
            </div>
            <button
              type="button"
              className="ciq-icon-button"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseOutlined />
            </button>
          </div>
        }
      >
        <div className="material-details-drawer h-full space-y-4">
          {noMaterialDetailsPresent && (
            <div className="flex items-center justify-center h-full">
              <div className="text-color-1 italic">No details available.</div>
            </div>
          )}
          {specSectionString && (
            <div>
              <div className="text-color-1">Spec Section</div>
              <div className="text-color-3">{specSectionString}</div>
            </div>
          )}
          {material.material_tag && (
            <div>
              <div className="text-color-1">Tag</div>
              <div className="text-color-3">{material.material_tag}</div>
            </div>
          )}
          {material.description && (
            <div>
              <div className="text-color-1">Description</div>
              <div className="text-color-3">
                <Paragraph>{material.description}</Paragraph>
              </div>
            </div>
          )}
        </div>
      </Drawer>
    </div>
  );
}

export function LandingPageListItem({
  material,
  onClick
}: {
  material: IMaterial;
  onClick: () => void;
}) {
  return (
    <button
      type="button"
      className="bg-white text-left w-full flex items-center justify-between gap-x-2 border-solid border border-color-5 p-3.5 rounded-md"
      onClick={onClick}
    >
      <div className="grow font-semibold">{material.name}</div>
      <div>
        {material.estimate_status === EstimateStatus.SUBMITTED && (
          <div className="flex items-center justify-center">
            <SuccessCheckIcon />
          </div>
        )}
      </div>
      <div className="flex items-center justify-center">
        <RightOutlined className="text-color-6" />
      </div>
    </button>
  );
}
