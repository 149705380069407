import CIQClient from "services/ciq-client";
import { BASE_URL } from "services/endpoints";
import { UpdateLeadTimeRequestData } from "./type-definitions";

// feature/estimate/materials
export const getLeadTimeRequestDetails = async (
  leadTimeRequestToken: string
) => {
  const config = {
    headers: { Authorization: `Bearer ${leadTimeRequestToken}` }
  };
  return CIQClient.post({
    url: `${BASE_URL}/feature/estimate/materials`,
    config,
    showError: false
  });
};

export const updateLeadTimeRequestDetails = async (
  updateLeadTimeRequestToken: string,
  data: UpdateLeadTimeRequestData
) => {
  const config = {
    headers: { Authorization: `Bearer ${updateLeadTimeRequestToken}` }
  };
  return CIQClient.put({
    url: `${BASE_URL}/feature/estimate/materials`,
    config,
    data
  });
};

export const resendInvite = async (resendInviteToken: string) => {
  const config = { headers: { Authorization: `Bearer ${resendInviteToken}` } };
  return CIQClient.put({
    url: `${BASE_URL}/feature/estimate/resendInvite`,
    config,
    showError: false
  });
};
