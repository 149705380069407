import { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Button, Drawer, Spin } from "antd";
import moment from "moment";
import ErrorBoundary from "components/error-boundary";
import MaterialPlanningNavbar from "./navbar";
import {
  FailureSection,
  VerticalNavList,
  LeadTimeErrorCodes,
  MaterialDetailsSectionHeader
} from "./widgets";
import "./material-planning.scss";
import { EstimateStatus } from "./type-definitions";
import MaterialDetailsSection from "./material-details-section";
import MaterialDurationsSection from "./material-durations-section";
import { getLeadTimeRequestDetails } from "./services";
import { ResponsiveFooterV2 } from "./responsive-footer";
import GenerateNewLink from "./generate-new-link";
import RequestDetailsSection from "./request-details-section";
import LandingSection from "./landing-section";

function MaterialPlanning() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const leadTimeRequestToken: string | null = params.get("token");

  const [leadTimeRequestData, setLeadTimeRequestData] = useState<any>(null);
  const [leadTimeRequestErrorData, setLeadTimeRequestErrorData] =
    useState<any>(null);
  const [loadingLeadTimeRequestData, setLoadingLeadTimeRequestData] =
    useState(true);

  const [leadTimeDataFetchErrorCode, setLeadTimeDataFetchErrorCode] =
    useState<LeadTimeErrorCodes | null>(null);

  // Note: kept at a global level so that it can be used at other places
  const [submittingLeadTime, setSubmittingLeadTime] = useState(false);

  const [activeMaterialIndex, setActiveMaterialIndex] = useState(0);

  const [isMaterialListDrawerOpen, setIsMaterialListDrawerOpen] =
    useState(false);

  const [showMobileLandingSection, setShowMobileLandingSection] =
    useState(true);

  const [initialSortIds, setInitialSortIds] = useState<string[]>([]);

  const materialDetailsSectionRef = useRef<HTMLDivElement>(null);

  const getLeadTimeData = useCallback(
    async (cbOnSuccess?: (data: any) => void) => {
      if (!leadTimeRequestToken) {
        setLeadTimeDataFetchErrorCode(LeadTimeErrorCodes.INVALID_TOKEN);
        return;
      }

      setLoadingLeadTimeRequestData(true);
      const response = await getLeadTimeRequestDetails(leadTimeRequestToken!);

      // console.log("response", response);

      if (response.success) {
        const errorType: any = response.data.error;

        if (
          errorType &&
          LeadTimeErrorCodes[errorType as keyof typeof LeadTimeErrorCodes]
        ) {
          setLeadTimeDataFetchErrorCode(errorType);
          setLeadTimeRequestErrorData(response.data);
          return;
        }

        if (response.data.timezone_id) {
          moment.tz.setDefault(response.data.timezone_id);
        }

        setTimeout(() => {
          // inside setTimeout so that moment timezone is properly set before rendering

          if (!initialSortIds.length) {
            const sortedMaterials = [...response.data.materials].sort(
              (a: any, b: any) => {
                // First sort alphabetically by name
                const nameCompare = a.name.localeCompare(b.name);

                // If both items have the same estimate status, use the name comparison
                if (a.estimate_status === b.estimate_status) {
                  return nameCompare;
                }

                // Otherwise, PENDING items should come first
                if (a.estimate_status === EstimateStatus.PENDING) {
                  return -1;
                }
                if (b.estimate_status === EstimateStatus.PENDING) {
                  return 1;
                }
                return nameCompare;
              }
            );

            setInitialSortIds(
              sortedMaterials.map((material: any) => material.id)
            );
            response.data.materials = sortedMaterials;
          } else {
            // sort response.data.materials based on initialSortIds
            response.data.materials = [...response.data.materials].sort(
              (a: any, b: any) => {
                return (
                  initialSortIds.indexOf(a.id) - initialSortIds.indexOf(b.id)
                );
              }
            );
          }

          setLeadTimeRequestData(response.data);
          setLoadingLeadTimeRequestData(false);
          cbOnSuccess?.(response.data);
        });
      }

      if (!response?.success) {
        const responseErrorCode = response.errorMsg as LeadTimeErrorCodes;
        let finalErrorCode = LeadTimeErrorCodes.FAILED_TO_FETCH_DATA;
        if (responseErrorCode && LeadTimeErrorCodes[responseErrorCode]) {
          finalErrorCode = responseErrorCode;
        }
        setLeadTimeDataFetchErrorCode(finalErrorCode);
      }
    },
    [leadTimeRequestToken, initialSortIds]
  );

  useEffect(() => {
    getLeadTimeData();
  }, [getLeadTimeData]);

  const selectedMaterial = useMemo(() => {
    if (!leadTimeRequestData?.materials?.length || activeMaterialIndex === null)
      return null;
    return activeMaterialIndex < leadTimeRequestData.materials.length
      ? leadTimeRequestData.materials[activeMaterialIndex]
      : null;
  }, [leadTimeRequestData, activeMaterialIndex]);

  const isSingleMaterial = useMemo(() => {
    return leadTimeRequestData?.materials?.length === 1;
  }, [leadTimeRequestData]);

  // console.log("leadTimeRequestData", leadTimeRequestData);

  // close the material list drawer on window resize
  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMaterialListDrawerOpen(false);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setIsMaterialListDrawerOpen(false);
      });
    };
  }, []);

  const navigateToMaterial = useCallback(
    (materialIndex: number) => {
      if (!leadTimeRequestData?.materials?.length) return;

      const safeIndex = Math.max(
        0,
        Math.min(materialIndex, leadTimeRequestData.materials.length - 1)
      );

      setSubmittingLeadTime(false);
      setActiveMaterialIndex(safeIndex);

      const selectedMaterialDOMElement = materialDetailsSectionRef.current;
      selectedMaterialDOMElement?.scrollTo({ top: 0, behavior: "smooth" });
    },
    [leadTimeRequestData?.materials, materialDetailsSectionRef]
  );

  const autoNavigateToNextMaterial = useCallback(() => {
    if (!leadTimeRequestData?.materials?.length) return;
    if (activeMaterialIndex === null) return;
    if (activeMaterialIndex < leadTimeRequestData.materials.length - 1) {
      navigateToMaterial(activeMaterialIndex + 1);
    }
  }, [activeMaterialIndex, leadTimeRequestData, navigateToMaterial]);

  const onSubmitSuccess = useCallback(() => {
    getLeadTimeData((data: any) => {
      const allMaterialsSubmitted = data.materials.every(
        (material: any) => material.estimate_status === EstimateStatus.SUBMITTED
      );

      if (allMaterialsSubmitted) {
        setShowMobileLandingSection(true);
      }

      autoNavigateToNextMaterial();
    });
  }, [autoNavigateToNextMaterial, getLeadTimeData]);

  const getConditionalClasses = useMemo(() => {
    const materialDetailsContainerClasses = `grow w-full flex bg-white overflow-hidden md:mb-0 md:flex ${
      !isSingleMaterial && showMobileLandingSection ? "hidden" : ""
    } ${!isSingleMaterial ? "mb-12" : ""}`;

    const landingSectionClasses = `grow min-h-0 w-full flex bg-white overflow-y-auto md:hidden my-0.5 ${
      isSingleMaterial || !showMobileLandingSection ? "hidden" : ""
    }`;

    return { materialDetailsContainerClasses, landingSectionClasses };
  }, [isSingleMaterial, showMobileLandingSection]);

  if (
    leadTimeDataFetchErrorCode === LeadTimeErrorCodes.INVALID_TOKEN ||
    leadTimeDataFetchErrorCode === LeadTimeErrorCodes.FAILED_TO_FETCH_DATA
  ) {
    return (
      <FailureSection
        errorCode={leadTimeDataFetchErrorCode}
        onRetry={() => {
          setLeadTimeDataFetchErrorCode(null);
          getLeadTimeData();
        }}
      />
    );
  }

  if (leadTimeDataFetchErrorCode === LeadTimeErrorCodes.TOKEN_EXPIRED) {
    return (
      <GenerateNewLink
        error={leadTimeRequestErrorData}
        onEmailNewLinkSuccess={() => {
          getLeadTimeData();
        }}
        leadTimeRequestToken={leadTimeRequestToken!}
      />
    );
  }

  if (!leadTimeRequestData && loadingLeadTimeRequestData) {
    return (
      <div
        className="h-screen flex justify-center items-center"
        data-testid="loading-spinner"
      >
        <Spin size="large" />
      </div>
    );
  }

  // console.log("leadTimeRequestData", leadTimeRequestData);

  return (
    <div className="material-planning-page h-[100dvh] flex flex-col overflow-y-hidden">
      <div className="shrink-0 overflow-x-auto">
        <ErrorBoundary>
          <MaterialPlanningNavbar
            userName={`${leadTimeRequestData.assignee_first_name} ${leadTimeRequestData.assignee_last_name}`}
            companyName={
              leadTimeRequestData.assignee_vendor_name ||
              leadTimeRequestData.assignee_organization_name
            }
            onMenuClick={() => {
              setIsMaterialListDrawerOpen(true);
            }}
            showHamburgerMenu={!showMobileLandingSection && !isSingleMaterial}
          />
        </ErrorBoundary>
      </div>

      <div className="grow flex flex-col overflow-y-hidden">
        <div className="md:px-10 w-full border-solid border-0 border-b border-color-1 pb-0.5">
          <div>
            <ErrorBoundary>
              <RequestDetailsSection requestDetails={leadTimeRequestData} />
            </ErrorBoundary>
          </div>
        </div>

        <div className={getConditionalClasses.landingSectionClasses}>
          <LandingSection
            materials={leadTimeRequestData?.materials}
            onMaterialClick={(index) => {
              navigateToMaterial(index);
              setShowMobileLandingSection(false);
            }}
          />
        </div>
        <div className={getConditionalClasses.materialDetailsContainerClasses}>
          <div className="w-[250px] lg:w-[350px] overflow-y-auto shrink-0 border-0 border-r border-solid border-color-2 hidden md:block">
            <div>
              <div className="p-5 mt-0.5 text-sm font-semibold text-color-1">
                Requested Materials
              </div>
              <ErrorBoundary>
                <VerticalNavList
                  items={leadTimeRequestData?.materials}
                  onSelect={navigateToMaterial}
                  activeKey={selectedMaterial?.id ?? null}
                />
              </ErrorBoundary>
            </div>
          </div>
          {selectedMaterial && (
            <div className="grow overflow-hidden material-details-section py-0.5">
              <div
                className="h-full max-h-full overflow-y-auto relative material-content"
                ref={materialDetailsSectionRef}
                key={selectedMaterial?.id}
              >
                <ErrorBoundary>
                  <MaterialDetailsSectionHeader
                    material={selectedMaterial}
                    className="pt-1 md:pt-5 sticky -top-[1px] bg-white pb-2.5 z-10 px-4 lg:hidden"
                  />
                </ErrorBoundary>
                <div className="flex items-start justify-between flex-wrap">
                  <div className="w-full lg:sticky lg:top-0 lg:w-1/2">
                    <div className="px-4 lg:px-7 lg:pb-20">
                      <ErrorBoundary>
                        <MaterialDetailsSectionHeader
                          material={selectedMaterial}
                          className="pt-5 sticky top-0 bg-white pb-2.5 hidden lg:block"
                        />
                      </ErrorBoundary>
                      <ErrorBoundary>
                        <MaterialDetailsSection
                          material={selectedMaterial}
                          key={selectedMaterial.id}
                        />
                      </ErrorBoundary>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2">
                    <ErrorBoundary>
                      <MaterialDurationsSection
                        material={selectedMaterial}
                        key={selectedMaterial.id}
                        token={leadTimeRequestData.token}
                        submittingState={[
                          submittingLeadTime,
                          setSubmittingLeadTime
                        ]}
                        onSubmitSuccess={onSubmitSuccess}
                      />
                    </ErrorBoundary>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {!showMobileLandingSection &&
          leadTimeRequestData?.materials?.length && (
            <ResponsiveFooterV2
              leadTimeRequestData={leadTimeRequestData}
              activeMaterialIndex={activeMaterialIndex}
              onMaterialNavigation={navigateToMaterial}
            />
          )}
        <Drawer
          placement="left"
          closable={false}
          onClose={() => {
            setIsMaterialListDrawerOpen(false);
          }}
          open={isMaterialListDrawerOpen}
          key="left"
          className="material-planning-page material-list-drawer"
          maskClosable
        >
          <div className="h-full max-h-full flex flex-col">
            <div className="p-5 text-sm font-semibold text-color-1 shrink-0">
              Requested Materials ({leadTimeRequestData?.materials?.length})
            </div>
            <div className="grow overflow-y-auto">
              <ErrorBoundary>
                <VerticalNavList
                  items={leadTimeRequestData?.materials}
                  onSelect={(material) => {
                    navigateToMaterial(material);
                    setIsMaterialListDrawerOpen(false);
                  }}
                  activeKey={selectedMaterial?.id ?? null}
                />
              </ErrorBoundary>
            </div>
            <div className="shrink-0 px-5 py-3">
              <Button
                htmlType="button"
                type="primary"
                onClick={() => {
                  setIsMaterialListDrawerOpen(false);
                }}
                className="w-full"
              >
                Close
              </Button>
            </div>
          </div>
        </Drawer>
      </div>
    </div>
  );
}

export default MaterialPlanning;
