/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
import { useState, useMemo, useEffect, useRef, useContext } from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import { Button, message } from "antd";
import { EditOutlined } from "@ant-design/icons";

import { ColDef } from "ag-grid-community";
import AddCompany from "components/add-company";
import { useSubscription } from "@apollo/client";
import { SUBSCRIPTION_LEVEL_COMPANY_LIST } from "services/graphQL/subscriptions";
import "./companies.scss";
import {
  RoleSubscriptionEnum,
  SubscriptionContext
} from "context/SubscriptionProvider";
import { GridLoadingIndicator } from "components/widgets";
import { QUERY_GET_PROJECT_LIST } from "services/graphQL/queries";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import {
  FilterChipComponent,
  FilterChipComponentRef
} from "entity-app/shared-components/log-render-components/filter-chip-component";
import CiqLogPageHeader, {
  calculateLogPageStats
} from "components/ciq-log-page-header";
import CiqAgSearch from "components/ciq-ag-search";
import { getSubscriptionVendorSources } from "utils/utils";
import {
  AcceptanceStatus,
  ErrorMessages,
  ESubmittalMgmtModes
} from "../../constants";

export enum DrawerTitle {
  ADD_COMPANY = "Add Company",
  COMPANY_DETAILS = "View Company Details",
  UPDATE_COMPANY = "Update Company Details"
}

function CompanyList() {
  const gridRef = useRef<AgGridReact>(null);
  const [isGridReady, setGridReady] = useState(false);
  const [drawer, setDrawerVisibility] = useState<boolean>(false);
  const [editCompanyData, setEditCompanyData] = useState<any>();
  const [isFormEditable, setisFormEditable] = useState(true);
  const [drawerTitle, setDrawerTitle] = useState(DrawerTitle.ADD_COMPANY);
  const { subscriptionRole } = useContext(SubscriptionContext);

  const [statsData, setStatsData] = useState<{
    displayCount: number;
    filterName: undefined | string;
  }>();
  const filterChipRef = useRef<FilterChipComponentRef>(null);

  const {
    data: companyData,
    error: companyDataError,
    loading
  } = useSubscription(SUBSCRIPTION_LEVEL_COMPANY_LIST, {
    shouldResubscribe: true
  });

  const [allCompanies, setCompanyList] = useState([]);

  const { data: projectListData } = useCIQQuery(QUERY_GET_PROJECT_LIST, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        status_id: { _neq: AcceptanceStatus.DEACTIVATED }
      }
    }
  });

  const isIntegratedProjectExist = useMemo(() => {
    if (projectListData && projectListData?.project_list_func.length > 0) {
      const isIntegratedProjExist = projectListData?.project_list_func.some(
        (proj: any) => proj?.mode === ESubmittalMgmtModes.INTEGRATION_MODE
      );
      return isIntegratedProjExist;
    }
    return false;
  }, [projectListData]);

  function CompanyNameComparator(
    currentStringValue: string,
    nextStringValue: string
  ) {
    return currentStringValue
      .toLowerCase()
      .localeCompare(nextStringValue.toLowerCase());
  }

  useEffect(() => {
    if (isGridReady && gridRef && gridRef.current) {
      if (loading) {
        gridRef.current?.api.showLoadingOverlay();
      } else {
        gridRef.current?.api.hideOverlay();
      }
    }
    if (companyDataError) {
      gridRef.current?.api.hideOverlay();
      message.error(companyDataError.message);
      return;
    }
    if (!companyData) return;
    setCompanyList(companyData.subscription_vendors);
  }, [companyDataError, isGridReady, loading, companyData]);

  function companyValueSetter(params: any) {
    // eslint-disable-next-line no-param-reassign
    params.data.make = params.newValue;
    return true;
  }

  function pointOfContactsSetter(params: any) {
    const pocs: any = params?.data?.trade_partner_pocs;
    if (pocs?.length > 0) {
      const pocstr = `${pocs[0].first_name} ${pocs[0].last_name}`;

      if (pocs?.length === 1) {
        return pocstr;
      }
      return `${pocstr} +${pocs.length - 1}`;
    }

    return "";
  }

  function pointOfContactsSetter1(params: any) {
    const pocs: any = params?.data?.trade_partner_pocs;
    if (pocs?.length > 0) {
      const pocstr = `${pocs[0].first_name} ${pocs[0].last_name}`;

      if (pocs?.length === 1) {
        return pocstr;
      }
      return (
        <div>
          {pocstr}
          <a href={undefined}> +{pocs.length - 1}</a>
        </div>
      );
    }

    return "";
  }

  function editComponent() {
    return (
      <div className="pencil-icon">
        <EditOutlined />
      </div>
    );
  }

  const columnDefs: ColDef[] = useMemo<ColDef[]>(() => {
    const arr: ColDef[] = [
      {
        headerName: "Serial No.",
        valueGetter: "node.rowIndex + 1",
        headerTooltip: "SERIAL NO.",
        tooltipField: "node.rowIndex + 1",
        menuTabs: [],
        width: 100,
        sortable: false,
        editable: false
      },
      {
        colId: "name",
        field: "name",
        headerName: "Company Name",
        headerTooltip: "COMPANY NAME",
        tooltipField: "name",
        menuTabs: [],
        filter: false,
        valueSetter: companyValueSetter,
        comparator: CompanyNameComparator,
        sort: "asc"
      },
      {
        colId: "address",
        field: "address",
        headerName: "Address",
        headerTooltip: "ADDRESS",
        tooltipField: "address",
        menuTabs: []
      },
      {
        colId: "point_of_contact",
        headerName: "Points of Contact",
        headerTooltip: "POINT OF CONTACT",
        menuTabs: [],
        resizable: true,
        sortable: true,
        field: "point_of_contact",
        cellRenderer: pointOfContactsSetter1,
        comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any) => {
          const pocA = pointOfContactsSetter(nodeA);
          const pocB = pointOfContactsSetter(nodeB);
          return pocA.toLowerCase().localeCompare(pocB.toLowerCase());
        },
        tooltipValueGetter: (params) => {
          const pocs: any = params?.data?.trade_partner_pocs;
          if (pocs.length === 1) {
            return `${pocs[0].first_name} ${pocs[0].last_name}`;
          }
          let nameStr = "";
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < pocs.length; i++) {
            const user = pocs[i];
            nameStr = `${user.first_name} ${user.last_name}${
              nameStr !== "" ? `, ${nameStr}` : ""
            }`;
          }
          return nameStr;
        }
      },
      {
        colId: "external_source_id",
        headerName: "Source",
        menuTabs: ["filterMenuTab"],
        hide: !isIntegratedProjectExist,
        valueGetter: ({ data }) => {
          const allSources = getSubscriptionVendorSources(data);
          return allSources.join(",");
        }
      },
      {
        colId: "edit_pencil",
        headerName: "",
        menuTabs: [],
        resizable: true,
        width: 20,
        cellRenderer: editComponent,
        hide: subscriptionRole <= RoleSubscriptionEnum.subscription_viewer
      }
    ];

    return arr;
  }, [subscriptionRole, isIntegratedProjectExist]);

  const defaultColDef: {} = useMemo<ColDef>(() => {
    return {
      sortable: true,
      autoSizeAllColumns: true,
      autoHeight: true,
      resizable: true
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      if (isGridReady && gridRef && gridRef.current) {
        gridRef?.current?.api?.sizeColumnsToFit();
      }
    }
    window.addEventListener("resize", handleResize);
  });

  const onEditPencilClick = () => {
    setisFormEditable(true);
    setDrawerTitle(DrawerTitle.UPDATE_COMPANY);
  };

  const setDrawerAction = (drawerAction: boolean) => {
    setEditCompanyData(null);
    setDrawerVisibility(drawerAction);
  };

  const onFiltersApplied = () => {
    filterChipRef.current?.onFilterApplied();
    setStatsData(
      calculateLogPageStats({
        gridRef
      })
    );
  };

  const headerItems = [
    <CiqAgSearch
      key="SubscriptionCompany_search"
      gridRef={gridRef}
      placeholder="Search Companies"
    />,
    <FilterChipComponent
      key="SubscriptionCompany_FilterChip"
      columnDefs={columnDefs}
      gridRef={gridRef}
      ref={filterChipRef}
    />,
    <Button
      key="SubscriptionCompany_AddCompany"
      onClick={() => {
        setDrawerVisibility(true);
        setDrawerTitle(DrawerTitle.ADD_COMPANY);
        setisFormEditable(true);
      }}
      title={
        subscriptionRole <= RoleSubscriptionEnum.subscription_viewer
          ? ErrorMessages.PermissionNotGranted
          : DrawerTitle.ADD_COMPANY
      }
      disabled={subscriptionRole <= RoleSubscriptionEnum.subscription_viewer}
    >
      {DrawerTitle.ADD_COMPANY}
    </Button>
  ];

  return (
    <div className="flex flex-col h-screen">
      <CiqLogPageHeader
        {...{
          entityName: "Companies",
          titleParam: {
            title: "Companies",
            totalCount: allCompanies?.length,
            filterStats: statsData
          },
          gridRef,
          items: headerItems
        }}
      />
      <div className="grow ag-theme-alpine px-2 pb-2">
        <AgGridReact<any>
          ref={gridRef}
          onGridReady={() => {
            setGridReady(true);
            gridRef?.current?.api?.sizeColumnsToFit();
          }}
          rowSelection="single"
          rowData={allCompanies}
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows // Optional - set to 'true' to have rows animate when sorted
          loadingOverlayComponent={GridLoadingIndicator}
          suppressDragLeaveHidesColumns
          onSortChanged={(e) => {
            e.api.refreshCells();
          }}
          onFilterChanged={(e) => {
            e.api.refreshCells();
            onFiltersApplied();
          }}
          onCellClicked={(e) => {
            if (e.column.getColId() === "edit_pencil") {
              setisFormEditable(true);
              setDrawerTitle(DrawerTitle.UPDATE_COMPANY);
            } else {
              setisFormEditable(false);
              setDrawerTitle(DrawerTitle.COMPANY_DETAILS);
            }

            setEditCompanyData(e.data);
            setDrawerVisibility(true);
          }}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
        />
      </div>
      {drawer && (
        <AddCompany
          editCompany={editCompanyData}
          isFormEditOnly={isFormEditable}
          setDrawerOpen={setDrawerAction}
          showDrawerOpen={drawer}
          modelHeader={
            <div className="flex justify-between items-center mr-8 h-5">
              <div className="">{drawerTitle}</div>
              <Button
                className="border-none focus:border-none hover:border-none hover:bg-transparent"
                type="text"
                icon={<EditOutlined className="company-edit-icon" />}
                title={
                  subscriptionRole <= RoleSubscriptionEnum.subscription_viewer
                    ? ErrorMessages.PermissionNotGranted
                    : ""
                }
                hidden={
                  drawerTitle === DrawerTitle.ADD_COMPANY || isFormEditable
                }
                disabled={
                  subscriptionRole <= RoleSubscriptionEnum.subscription_viewer
                }
                onClick={onEditPencilClick}
              />
            </div>
          }
        />
      )}
    </div>
  );
}

export default CompanyList;
