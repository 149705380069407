import { ProjectContext, TProjectContext } from "context/ProjectProvider";

import { useCIQQuery } from "hooks/ciq-gql-hooks";
import {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle
} from "react";
import { useParams } from "react-router";
import { getSubscriptionId } from "services/auth";
import {
  QUERY_ACTIVE_PROJECT_USERS,
  QUERY_DISTRIBUTION_GROUP_LIST
} from "services/graphQL/queries";
import { Select, Tag } from "antd";
import { UserTagV2 } from "components/user-tag/user-tag";
import { AcceptanceStatus } from "../../constants";
import { IAvailableDistributionLists, IAvailableUsers } from "./types";

function distributionListTagRenderer(
  params: any,
  availableUsers: IAvailableUsers | undefined
) {
  const { value, closable, onClose, label } = params;

  const selectedUser = availableUsers?.project_users.find(
    (usr: any) => usr.user.id === value
  );

  return (
    <Tag
      closable={closable}
      onClose={onClose}
      key={value}
      className="flex items-center my-[1px] mx-0.5"
    >
      <span className="mr-2">{label}</span>
      {selectedUser?.status_id &&
        selectedUser?.status_id === AcceptanceStatus.DEACTIVATED && (
          <UserTagV2 label="Inactive" />
        )}
    </Tag>
  );
}

export function groupIDsByType(
  ids: string[],
  availableDistributionLists: IAvailableDistributionLists | undefined
): { distributionGroupIds: string[]; userIds: string[] } {
  return ids.reduce(
    (acc, id) => {
      const isDistributionGroup =
        availableDistributionLists?.distribution_group.some(
          (group) => group.id === id
        );

      if (isDistributionGroup) {
        acc.distributionGroupIds.push(id);
      } else {
        acc.userIds.push(id);
      }

      return acc;
    },
    { distributionGroupIds: [], userIds: [] } as {
      distributionGroupIds: string[];
      userIds: string[];
    }
  );
}

interface DistributionListFieldProps {
  onChange: (value: string[]) => void;
  disabled?: boolean;
  dataTestId?: string;
}

export interface DistributionListFieldRef {
  getAvailableOptions: () => {
    availableDistributionLists: IAvailableDistributionLists | undefined;
    availableUsers: IAvailableUsers | undefined;
  };
  setRecipientIds: (ids: string[]) => void;
}

const DistributionListField = forwardRef<
  DistributionListFieldRef,
  DistributionListFieldProps
>((props, ref) => {
  const {
    onChange,
    disabled = false,
    dataTestId = "distribution-list-field"
  } = props;
  const { projectId } = useParams() as {
    projectId: string;
  };
  const subscriptionId = getSubscriptionId();

  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const [recipientIds, setRecipientIds] = useState<string[]>([]);

  const { gqlClientForProject, eventLogs }: TProjectContext =
    useContext(ProjectContext);

  const {
    data: availableDistributionLists,
    refetch: refetchAvailableDistributionLists,
    loading: loadingDistributionLists
  } = useCIQQuery<IAvailableDistributionLists>(QUERY_DISTRIBUTION_GROUP_LIST, {
    client: gqlClientForProject,
    variables: {
      where: {
        subscription: { project_users: { project_id: { _eq: projectId } } }
      }
    }
  });

  const {
    data: availableUsers,
    refetch: refetchAvailableUsers,
    loading: loadingAvailableUsers
  } = useCIQQuery<IAvailableUsers>(QUERY_ACTIVE_PROJECT_USERS, {
    client: gqlClientForProject,
    variables: {
      where: {
        project_id: { _eq: projectId },
        status_id: { _neq: 1 },
        subscription_id: { _eq: subscriptionId }
      }
    },
    skip: !subscriptionId || !projectId
  });

  const loading = loadingDistributionLists || loadingAvailableUsers;

  const selectInputOptions = useMemo(() => {
    if (!availableDistributionLists || !availableUsers) return [];

    let distributionGroupOptions = null;
    let userOptions = null;
    distributionGroupOptions =
      availableDistributionLists?.distribution_group.map((list) => ({
        label: list.name,
        value: list.id
      })) ?? [];

    userOptions =
      availableUsers?.project_users
        .filter((user) => user.status_id !== AcceptanceStatus.DEACTIVATED)
        .map((user) => ({
          label: `${user.user.first_name} ${user.user.last_name}`,
          value: user.user.id
        })) ?? [];

    return [
      { label: "Groups", options: distributionGroupOptions },
      { label: "Users", options: userOptions }
    ];
  }, [availableDistributionLists, availableUsers]);

  const appliedValues = useMemo(() => {
    if (!recipientIds || !availableDistributionLists || !availableUsers)
      return [];

    return recipientIds;
  }, [recipientIds, availableDistributionLists, availableUsers]);

  useEffect(() => {
    setSelectedValues(appliedValues);
  }, [appliedValues]);

  const previousEventLogs = useRef(eventLogs);
  useEffect(() => {
    if (eventLogs.length && previousEventLogs.current !== eventLogs) {
      if (eventLogs.some((log) => log.data_source === "distribution_group")) {
        refetchAvailableDistributionLists();
      }

      if (eventLogs.some((log) => log.data_source === "project_users")) {
        refetchAvailableUsers();
      }
    }
    previousEventLogs.current = eventLogs;
  }, [eventLogs, refetchAvailableDistributionLists, refetchAvailableUsers]);

  const handleChange = (values: string[]) => {
    setSelectedValues(values);
    onChange(values);
  };

  useImperativeHandle(ref, () => ({
    getAvailableOptions: () => ({
      availableDistributionLists,
      availableUsers
    }),
    setRecipientIds
  }));

  return (
    <Select
      className="w-full"
      mode="multiple"
      options={selectInputOptions}
      placeholder="Select distribution groups"
      optionFilterProp="label"
      filterOption
      value={selectedValues}
      onChange={handleChange}
      disabled={disabled}
      tagRender={(prop) => {
        return distributionListTagRenderer(prop, availableUsers);
      }}
      showArrow
      data-test-id={dataTestId}
      loading={loading}
    />
  );
});

export default DistributionListField;
