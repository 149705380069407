import { useContext, useMemo, useRef, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Tooltip,
  message
} from "antd";
import { useMutation } from "@apollo/client";
import { MUTATION_INSERT_MATERIAL } from "services/graphQL/mutations";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { QUERY_GET_MATERIAL } from "services/graphQL/queries";
import SpecNumberNameDropDown from "components/spec-number-name";
import { useForm } from "antd/lib/form/Form";
import CreateSpecSectionModal from "components/submittal-details/create-spec-section";
import TextArea from "antd/lib/input/TextArea";
import SelectSearchNotFoundContent from "components/widgets/select-search-notfound-content";
import { useProjectParticipants } from "hooks/project-participants";
import { useParams } from "react-router";
import { useCIQLazyQuery } from "hooks/ciq-gql-hooks";
import { AcceptanceStatus, ErrorMessages } from "../../constants";

type TCreateMaterial = {
  name: string | undefined;
  spec_section_id: any;
  spec_section_name: any;
  spec_section_no: any;
  description: string | undefined;
  tag_name: string | undefined;
  quantity: number | undefined;
  quantity_unit_id: number | undefined;
  manufacturer: string | undefined;
  trade_partner: string | undefined;
  assignee: string | undefined;
  assignee_unregistered: string | undefined;
  gc_representative: string | undefined;
  workflow_template_id: string | undefined;
};

function MaterialCreateComponent({
  setDrawerOpen,
  showCreateLogDrawer,
  onCreateClick,
  MDBTemplates
}: {
  setDrawerOpen: Function;
  showCreateLogDrawer: boolean;
  onCreateClick: any;
  MDBTemplates: null | Array<any>;
}) {
  const [form] = useForm();
  const createSpecRef = useRef<any>(null);
  const { projectId } = useParams() as any;
  const [materialData, setMaterialData] = useState<TCreateMaterial>({
    name: undefined,
    spec_section_id: undefined,
    spec_section_name: undefined,
    spec_section_no: undefined,
    description: undefined,
    tag_name: undefined,
    quantity: undefined,
    quantity_unit_id: undefined,
    manufacturer: undefined,
    trade_partner: undefined,
    assignee: undefined,
    assignee_unregistered: undefined,
    gc_representative: undefined,
    workflow_template_id: undefined
  });
  const {
    gqlClientForProject,
    columnHeaders: { materialHeaderMap }
  }: TProjectContext = useContext(ProjectContext);

  const [showCreateSpecSectionModal, setShowCreateSpecSectionModal] =
    useState(false);

  const [addMaterial, { loading: addMaterialLoading }] = useMutation<any>(
    MUTATION_INSERT_MATERIAL,
    {
      client: gqlClientForProject
    }
  );

  const [checkForExistingMaterial] = useCIQLazyQuery(QUERY_GET_MATERIAL);

  const { projectParticipants } = useProjectParticipants(false);

  const tradePartnerOptions = useMemo(() => {
    return projectParticipants.materialTradePartners.map((company: any) => {
      return {
        id: company.vendor_id,
        name: company.subscription_vendor.name,
        companyInviteStatus: company?.project_vendors_company_status?.status,
        ...company
      };
    });
  }, [projectParticipants.materialTradePartners]);

  const getAssigneeOptions = () => {
    const asigneeUsers = projectParticipants.submitterUsers.filter(
      (user: any) => {
        return user.company.vendor_id === materialData?.trade_partner;
      }
    );
    return asigneeUsers;
  };

  const MDBTemplatesDropdownOptions = useMemo(() => {
    if (!MDBTemplates) return [];

    const defaultTemplateId = MDBTemplates.find(
      (x: { default: boolean; id: string }) => x.default
    )?.id as string;
    setMaterialData((pre: any) => ({
      ...pre,
      workflow_template_id: defaultTemplateId
    }));
    return MDBTemplates.filter((template: any) => !template.disabled).map(
      (template: any) => ({
        label: template.name,
        value: template.id
      })
    );
  }, [MDBTemplates]);

  const onFinish = async () => {
    try {
      const addMaterialResponse = await addMaterial({
        variables: { newMaterial: materialData }
      });

      if (addMaterialResponse?.errors) {
        message.error(addMaterialResponse?.errors[0].message);
        return;
      }
      if (addMaterialResponse?.data) {
        onCreateClick(
          addMaterialResponse.data?.insert_material_one?.id,
          materialData.name
        );
      }
      setDrawerOpen(false);
    } catch (ex) {
      console.log("ex ", ex);
      message.error("An error occured");
    }
  };

  return (
    <Modal
      className="custom-drawer"
      title="Create Material"
      width={420}
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ minHeight: "calc(100vh - 92px)" }}
      footer={null}
      open={showCreateLogDrawer}
      onCancel={() => {
        setDrawerOpen(false);
      }}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        className="space-y-3 px-3"
        disabled={showCreateSpecSectionModal}
      >
        <Form.Item
          label={materialHeaderMap?.name?.toUpperCase()}
          name="name"
          rules={[
            { required: true, message: "Please enter material name" },
            () => ({
              async validator(_, value) {
                if (value === undefined) return Promise.resolve();
                const existingMaterialResp = await checkForExistingMaterial({
                  where: { name: { _ilike: value?.trim() } }
                });

                if (existingMaterialResp?.data?.data?.material?.length) {
                  return Promise.reject(
                    new Error(ErrorMessages.MaterialExists)
                  );
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          <Input
            maxLength={1000}
            value={materialData.name}
            onChange={(e) =>
              setMaterialData((pre) => ({ ...pre, name: e.target.value }))
            }
          />
        </Form.Item>
        <Form.Item label={materialHeaderMap?.spec_section_no?.toUpperCase()}>
          <SpecNumberNameDropDown
            allowClear
            allowCreate
            currentSpecSection={{
              specSectionId: materialData.spec_section_id,
              enable: false
            }}
            onChange={(
              id: string | null,
              number: string | null,
              name: string | null
            ) => {
              setMaterialData((pre) => ({
                ...pre,
                spec_section_id: id,
                spec_section_no: number,
                spec_section_name: name
              }));
            }}
            onCreateSpecSectionClick={(showSpecSectionView) => {
              setShowCreateSpecSectionModal(showSpecSectionView);
              setTimeout(() => {
                if (createSpecRef.current)
                  createSpecRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "end"
                  });
              }, 100);
            }}
          />
        </Form.Item>
        <Form.Item label={materialHeaderMap?.tag_name?.toUpperCase()}>
          <Input
            placeholder="Enter Material Tag"
            value={materialData.tag_name}
            onChange={(event: any) => {
              setMaterialData((prev: any) => ({
                ...prev,
                tag_name: event.target.value
              }));
            }}
          />
        </Form.Item>
        <Form.Item label={materialHeaderMap?.description?.toUpperCase()}>
          <TextArea
            rows={3}
            disabled={showCreateSpecSectionModal}
            placeholder="Enter material description"
            value={materialData.description}
            onChange={(event: any) => {
              setMaterialData((prev: any) => ({
                ...prev,
                description: event.target.value
              }));
            }}
          />
        </Form.Item>
        <Form.Item
          label={materialHeaderMap?.date_block_template_name?.toUpperCase()}
        >
          <Select
            optionFilterProp="label"
            className="constructionSelect"
            value={materialData.workflow_template_id}
            onChange={(value: string) => {
              setMaterialData((pre: any) => ({
                ...pre,
                workflow_template_id: value
              }));
            }}
            options={MDBTemplatesDropdownOptions}
          />
        </Form.Item>
        <Form.Item label={materialHeaderMap?.manufacturer?.toUpperCase()}>
          <Input
            placeholder="Enter Manufacturer"
            value={materialData.manufacturer}
            onChange={(event: any) => {
              setMaterialData((prev: any) => ({
                ...prev,
                manufacturer: event.target.value
              }));
            }}
          />
        </Form.Item>
        <Form.Item label={materialHeaderMap?.trade_partner?.toUpperCase()}>
          <Select
            value={materialData.trade_partner}
            showSearch
            filterOption
            optionFilterProp="label"
            onChange={async (value: string) => {
              setMaterialData((pre: any) => ({
                ...pre,
                trade_partner: value,
                assignee_unregistered: ""
              }));
            }}
            notFoundContent={
              <SelectSearchNotFoundContent
                notFoundMsg={
                  tradePartnerOptions?.length > 0
                    ? "Company not found. To add, go to "
                    : "No companies added to this project. To add, go to "
                }
                linkTitle="Project Companies"
                linkPath={`/project/${projectId}/settings/general/project-companies`}
              />
            }
            options={tradePartnerOptions?.map((company: any) => ({
              value: company.id,
              label: company.name
            }))}
          />
        </Form.Item>

        <Tooltip
          title={
            !materialData?.trade_partner
              ? "First select a Responsible Contractor"
              : ""
          }
          placement="rightBottom"
        >
          <Form.Item label={materialHeaderMap?.assignee?.toUpperCase()}>
            <Select
              disabled={!materialData?.trade_partner}
              value={
                materialData.assignee || materialData.assignee_unregistered
              }
              showSearch
              filterOption
              optionFilterProp="filterProp"
              onChange={async (value: string) => {
                const selectedUser = getAssigneeOptions().find((user: any) => {
                  return user.id === value;
                });

                if (selectedUser.type === "actual") {
                  setMaterialData((pre: any) => ({
                    ...pre,
                    assignee: value,
                    assignee_unregistered: ""
                  }));
                } else {
                  setMaterialData((pre: any) => ({
                    ...pre,
                    assignee: null,
                    assignee_unregistered: value
                  }));
                }
              }}
              notFoundContent="User not found. Go to Project Settings to add users"
              options={getAssigneeOptions()
                .filter(
                  (usr: any) => usr.status_id !== AcceptanceStatus.DEACTIVATED
                )
                .map((user: any) => ({
                  value: user.id,
                  filterProp: `${user.first_name} ${user.last_name} ${user.company.name}`,
                  label: (
                    <div>
                      <div>
                        {user.first_name} {user.last_name}
                      </div>
                      <div className="text-sm text-gray-500">
                        {user.company.name}
                      </div>
                    </div>
                  )
                }))}
            />
          </Form.Item>
        </Tooltip>
        <Form.Item label={materialHeaderMap?.gc_representative?.toUpperCase()}>
          <Select
            value={materialData.gc_representative}
            showSearch
            filterOption
            optionFilterProp="label"
            onChange={(value: string) => {
              setMaterialData((pre: any) => ({
                ...pre,
                gc_representative: value
              }));
            }}
            notFoundContent="User not found. Go to Project Settings to add users"
            options={projectParticipants?.gcReviewers
              .filter(
                (usr: any) => usr.status_id !== AcceptanceStatus.DEACTIVATED
              )
              .map((user: any) => ({
                value: user.id,
                label: `${user.first_name} ${user.last_name}`
              }))}
          />
        </Form.Item>

        <section className="flex items-center justify-end space-x-2">
          <Button
            onClick={() => {
              setDrawerOpen(false);
            }}
            disabled={addMaterialLoading}
          >
            Cancel
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            loading={addMaterialLoading}
            disabled={addMaterialLoading}
          >
            Create Material
          </Button>
        </section>
      </Form>
      {showCreateSpecSectionModal && (
        <div ref={createSpecRef} className="pb-3">
          <Divider style={{ margin: "15px 0" }} />
          <div>
            <CreateSpecSectionModal
              isModalOpen={showCreateSpecSectionModal}
              onDoneCb={(newSpecSectionId: string, name: any, number: any) => {
                if (newSpecSectionId) {
                  setMaterialData((pre) => ({
                    ...pre,
                    spec_section_id: newSpecSectionId,
                    spec_section_no: number,
                    spec_section_name: name
                  }));

                  setShowCreateSpecSectionModal(false);
                } else {
                  setShowCreateSpecSectionModal(false);
                }
              }}
            />
          </div>
        </div>
      )}
    </Modal>
  );
}

export default MaterialCreateComponent;
