export default function UpcommingSubmittalsCardIcon({
  size = 80
}: {
  size?: number;
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0476 7.61905V0H26.6667V7.61905H49.5238V0H57.1429V7.61905H72.381C74.485 7.61905 76.1905 9.32465 76.1905 11.4286V30.4762H68.5714V15.2381H57.1429V22.8571H49.5238V15.2381H26.6667V22.8571H19.0476V15.2381H7.61905V68.5714H30.4762V76.1905H3.80952C1.7056 76.1905 0 74.485 0 72.381V11.4286C0 9.32465 1.7056 7.61905 3.80952 7.61905H19.0476ZM57.1429 41.9048C48.7272 41.9048 41.9048 48.7272 41.9048 57.1429C41.9048 65.5585 48.7272 72.381 57.1429 72.381C65.5585 72.381 72.381 65.5585 72.381 57.1429C72.381 48.7272 65.5585 41.9048 57.1429 41.9048ZM34.2857 57.1429C34.2857 44.5192 44.5192 34.2857 57.1429 34.2857C69.7665 34.2857 80 44.5192 80 57.1429C80 69.7665 69.7665 80 57.1429 80C44.5192 80 34.2857 69.7665 34.2857 57.1429ZM53.3333 45.7143V58.7208L62.0682 67.4556L67.4556 62.0682L60.9524 55.565V45.7143H53.3333Z"
        fill="#3B3B3B"
        fillOpacity="0.3"
      />
    </svg>
  );
}
