function ScrollIcon({ size = 48 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 49 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.2001 0.875C43.9605 0.875 45.6487 1.57431 46.8935 2.81908C48.1383 4.06385 48.8376 5.75213 48.8376 7.5125V11.9375H44.4126V38.4875C44.4126 40.2479 43.7133 41.9361 42.4685 43.1809C41.2237 44.4257 39.5355 45.125 37.7751 45.125H6.8001C5.03972 45.125 3.35145 44.4257 2.10668 43.1809C0.861904 41.9361 0.162598 40.2479 0.162598 38.4875V34.0625H35.5626V38.4875C35.5627 39.0294 35.7616 39.5525 36.1217 39.9574C36.4818 40.3624 36.978 40.6211 37.5162 40.6845L37.7751 40.7C38.317 40.6999 38.8401 40.501 39.245 40.1409C39.65 39.7808 39.9087 39.2846 39.9721 38.7464L39.9876 38.4875V5.3H11.2251C10.6832 5.30007 10.1601 5.49903 9.75517 5.85913C9.35021 6.21924 9.09149 6.71544 9.02808 7.25364L9.0126 7.5125V29.6375H4.5876V7.5125C4.5876 5.75213 5.2869 4.06385 6.53168 2.81908C7.77645 1.57431 9.46472 0.875 11.2251 0.875H42.2001Z"
        fill="#C4C4C4"
      />
    </svg>
  );
}

export default ScrollIcon;
