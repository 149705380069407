import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Progress } from "antd";
import BackArrowIcon from "components/svg-icons/back-arrow-icon";
import ForwardArrowIcon from "components/svg-icons/forward-arrow-icon";
import { Dispatch, SetStateAction, useMemo } from "react";
import { EstimateStatus } from "./type-definitions";

function ResponsiveFooter({
  leadTimeRequestData,
  activeMaterialIndex,
  onMaterialNavigation,
  setIsMaterialListDrawerOpen
}: {
  leadTimeRequestData: any;
  activeMaterialIndex: number;
  onMaterialNavigation: (materialIndex: number) => void;
  setIsMaterialListDrawerOpen: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <div className="md:hidden w-full flex items-center shrink-0 px-5 py-3 space-x-2  bg-white">
      <div className="flex items-center justify-start grow shrink-0">
        <Button
          htmlType="button"
          type="primary"
          onClick={() => {
            setIsMaterialListDrawerOpen(true);
          }}
        >
          All Materials
        </Button>
      </div>
      <div className="flex items-center justify-end gap-2">
        <Button
          htmlType="button"
          className="flex items-center gap-2"
          onClick={() => {
            onMaterialNavigation(activeMaterialIndex - 1);
          }}
          disabled={activeMaterialIndex === 0}
        >
          <BackArrowIcon
            width={16}
            height={16}
            disabled={activeMaterialIndex === 0}
          />{" "}
          Previous
        </Button>
        <Button
          htmlType="button"
          className="flex items-center gap-2"
          onClick={() => {
            onMaterialNavigation(activeMaterialIndex + 1);
          }}
          disabled={
            activeMaterialIndex === leadTimeRequestData.materials.length - 1
          }
        >
          Next{" "}
          <ForwardArrowIcon
            width={16}
            height={16}
            disabled={
              activeMaterialIndex === leadTimeRequestData.materials.length - 1
            }
          />
        </Button>
      </div>
    </div>
  );
}

export function ResponsiveFooterV2({
  leadTimeRequestData,
  activeMaterialIndex,
  onMaterialNavigation
}: {
  leadTimeRequestData: any;
  activeMaterialIndex: number;
  onMaterialNavigation: (materialIndex: number) => void;
}) {
  const materialSubmissionStats = useMemo(() => {
    const stats = {
      notSubmittedCount: 0,
      submittedCount: 0,
      totalCount: leadTimeRequestData.materials.length,
      percentSubmitted: 0
    };
    leadTimeRequestData.materials.forEach((material: any) => {
      stats.notSubmittedCount +=
        material.estimate_status === EstimateStatus.PENDING ? 1 : 0;
      stats.submittedCount +=
        material.estimate_status === EstimateStatus.SUBMITTED ? 1 : 0;
    });
    stats.percentSubmitted = (stats.submittedCount / stats.totalCount) * 100;
    return stats;
  }, [leadTimeRequestData]);

  return (
    <div className="md:hidden flex items-center w-full p-4 bg-white fixed bottom-0 left-0 right-0 border-0 border-t border-solid border-color-4">
      <div>
        <Button
          icon={<LeftOutlined />}
          onClick={() => {
            onMaterialNavigation(activeMaterialIndex - 1);
          }}
          disabled={activeMaterialIndex === 0}
          className="text-color-6"
        />
      </div>
      <div className="flex flex-col items-center justify-center grow shrink-0">
        <div className="w-2/3">
          <div className="text-xs font-semibold flex items-center justify-between -mb-1.5">
            <div>{materialSubmissionStats.submittedCount} Done</div>
            <div className="text-color-10">
              Page {activeMaterialIndex + 1} of{" "}
              {materialSubmissionStats.totalCount}
            </div>
          </div>
          <Progress
            percent={materialSubmissionStats.percentSubmitted}
            showInfo={false}
            strokeColor="#333333"
          />
        </div>
      </div>
      <div>
        <Button
          icon={<RightOutlined />}
          onClick={() => {
            onMaterialNavigation(activeMaterialIndex + 1);
          }}
          disabled={
            activeMaterialIndex === leadTimeRequestData.materials.length - 1
          }
          className="text-color-6"
        />
      </div>
    </div>
  );
}

export default ResponsiveFooter;
