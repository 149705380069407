import { Button } from "antd";

export const headerView = ({
  title,
  disabled,
  cancelAction,
  isFieldsEditable,
  setFieldsEditable,
  submitInProgress,
  showWarningMsg
}: {
  title: string;
  disabled: boolean;
  cancelAction: () => void;
  isFieldsEditable: boolean;
  setFieldsEditable: React.Dispatch<React.SetStateAction<boolean>>;
  submitInProgress: boolean;
  showWarningMsg: boolean;
}) => {
  return (
    <div className="flex w-full items-center justify-between h-[40px] py-[2px] px-4">
      <div className=" text-[#3B3B3B] font-semibold text-sm">{title}</div>

      {isFieldsEditable ? (
        <div className="flex space-x-4">
          <Button
            className="w-24"
            type="primary"
            htmlType="submit"
            loading={submitInProgress}
            disabled={submitInProgress || showWarningMsg}
            data-testid="save-button"
          >
            Save
          </Button>
          <Button
            className="w-24"
            disabled={submitInProgress || showWarningMsg}
            onClick={cancelAction}
            data-testid="cancel-button"
          >
            Cancel
          </Button>
        </div>
      ) : (
        <Button
          type="primary"
          className="w-24"
          disabled={disabled}
          onClick={() => setFieldsEditable(true)}
          data-testid="edit-button"
        >
          Edit
        </Button>
      )}
    </div>
  );
};
