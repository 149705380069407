export default function InfoEditIcon() {
  return (
    <svg
      width="31"
      height="20"
      viewBox="0 0 31 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.83333 15.6667C4.61167 15.6667 2 13.055 2 9.83333C2 6.61167 4.61167 4 7.83333 4C11.055 4 13.6667 6.61167 13.6667 9.83333C13.6667 13.055 11.055 15.6667 7.83333 15.6667ZM7.83333 14.5C10.4107 14.5 12.5 12.4107 12.5 9.83333C12.5 7.256 10.4107 5.16667 7.83333 5.16667C5.256 5.16667 3.16667 7.256 3.16667 9.83333C3.16667 12.4107 5.256 14.5 7.83333 14.5ZM7.25 6.91667H8.41667V8.08333H7.25V6.91667ZM7.25 9.25H8.41667V12.75H7.25V9.25Z"
        fill="#3B3B3B"
      />
      <path
        d="M19.6583 12.1583L25.5745 6.24204L24.7496 5.41708L18.8334 11.3333V12.1583H19.6583ZM20.1416 13.325H17.6667V10.8501L24.3371 4.17964C24.565 3.95184 24.9343 3.95184 25.1621 4.17964L26.812 5.82956C27.0398 6.05736 27.0398 6.42671 26.812 6.65451L20.1416 13.325ZM17.6667 14.4916H28.1667V15.6583H17.6667V14.4916Z"
        fill="#3B3B3B"
      />
    </svg>
  );
}
