export type ActionItemType = {
  id: string;
  title: string;
  description: string;
  workflow_status: number;
  status: number;
  spec_no: any;
  spec_name: any;
  responsible_contractor: string;
  responsible_contractor_vendor: any;
  name_milestone_1: string;
  planned_milestone_1: string;
  actual_milestone_1: string;
  name_milestone_2: string;
  planned_milestone_2: string;
  actual_milestone_2: string;
  name_milestone_3: string;
  planned_milestone_3: string;
  actual_milestone_3: string;
  name_milestone_4: string;
  planned_milestone_4: string;
  actual_milestone_4: string;
  name_milestone_5: string;
  planned_milestone_5: string;
  actual_milestone_5: string;
  due_date: string;
  next_planned_deadline: string;
  next_action: string;
  risk_level: string;

  name: string; // For Material Only
  trade_partner: string; // For Material Only
};

type RiskCountType = {
  High: number;
  Medium: number;
  Low: number;
};

export type RiskMatrixType = {
  before_than_date: RiskCountType;
  after_than_date: RiskCountType;
};

type DashBoardItemType = {
  count: number;
  list: Array<ActionItemType>;
  risk_assessment?: RiskMatrixType;
  materials_with_no_planned_dates?: number;
  submittals_with_no_planned_dates?: number;
  materials_linked_with_voided_submittals?: number;
};

export type DashboardAlerts = {
  schedule_version: {
    id?: number;
    import_log_id?: string;
    schedule_name: string;
    schedule_date: string;
    created_at: string;
    is_active: string;
    activated_at: any;
    import_log: {
      id: string;
    };
  };

  activities_modified_date: null | string;
  new_submittals: {
    count?: number;
    created_date?: string;
  };
};

export type GcDashBoardType = {
  submittals: DashBoardItemType;
  materials: DashBoardItemType;
  bid_packages: DashBoardItemType;
  alerts: DashboardAlerts;
};

export enum NavigationPageEnum {
  SubmittalLog,
  MaterialLog,
  BidPackageLog
}

export enum FilterNamesEnum {
  DueIn2Week = "due_in_2_week",
  HighDueIn2Week = "high_risk_due_in_2_week",
  LowDueIn2Week = "low_risk_due_in_2_week",
  HighDueLater = "high_risk_due_later",
  LowDueLater = "low_risk_due_later",
  NoPlan = "no_plan",
  AlertNewSubmittals = "alert_new_submittals",
  IsLinkedVoidSubmittal = "is_linked_void_submittal"
}
