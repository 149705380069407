import { useMemo } from "react";
import { getGQL } from "utils/utils";
import { COLUMN_HEADERS } from "services/graphQL/ciq-queries";
import { FeatureTypesIndex } from "../constants";
import { useCIQQuery } from "./ciq-gql-hooks";

export const useSubmittalMaterialLogsHeaders = (gqlClientForProject: any) => {
  const { query: qColumnHeaders } = useMemo(() => {
    return getGQL(COLUMN_HEADERS);
  }, []);
  const { data: responseData, refetch } = useCIQQuery(qColumnHeaders, {
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });

  const columnHeaders = useMemo(() => {
    let submittalHeadersMap: any = {};
    let materialHeadersMap: any = {};
    let bidPackageHeadersMap: any = {};
    let designPackageHeadersMap: any = {};

    if (
      responseData?.project_feature_configurations &&
      responseData?.project_feature_configurations?.length > 0
    ) {
      const resArr: any[] = responseData?.project_feature_configurations;

      submittalHeadersMap = resArr.find(
        (obj) => obj.feature_id === FeatureTypesIndex.SUBMITTAL
      );
      materialHeadersMap = resArr.find(
        (obj) => obj.feature_id === FeatureTypesIndex.MATERIAL
      );
      bidPackageHeadersMap = resArr.find(
        (obj) => obj.feature_id === FeatureTypesIndex.BID_PACKAGE
      );
      designPackageHeadersMap = resArr.find(
        (obj) => obj.feature_id === FeatureTypesIndex.DESIGN_PACKAGE
      );

      const submittalHeaderMap: any = {};
      submittalHeadersMap?.field_mapping?.forEach((columnData: any) => {
        const key = columnData?.field_name;
        submittalHeaderMap[key] = columnData?.caption_en;
      });

      const materialHeaderMap: any = {};
      materialHeadersMap?.field_mapping?.forEach((columnData: any) => {
        const key = columnData?.field_name;
        materialHeaderMap[key] = columnData?.caption_en;
      });

      const bidPackageHeaderMap: any = {};
      bidPackageHeadersMap?.field_mapping?.forEach((columnData: any) => {
        const key = columnData?.field_name;
        bidPackageHeaderMap[key] = columnData?.caption_en;
      });

      const designPackageHeaderMap: any = {};
      designPackageHeadersMap?.field_mapping?.forEach((columnData: any) => {
        const key = columnData?.field_name;
        designPackageHeaderMap[key] = columnData?.caption_en;
      });

      return {
        submittalHeaders: submittalHeadersMap,
        materialHeaders: materialHeadersMap,
        submittalHeaderMap,
        materialHeaderMap,
        bidPackageHeaders: bidPackageHeadersMap,
        designPackageHeaders: designPackageHeadersMap,
        bidPackageHeaderMap,
        designPackageHeaderMap
      };
    }
    return {
      submittalHeaders: null,
      materialHeaders: null,
      submittalHeaderMap: {},
      materialHeaderMap: {},
      bidPackageHeaders: null,
      designPackageHeaders: null,
      bidPackageHeaderMap: {},
      designPackageHeaderMap: {}
    };
  }, [responseData]);

  return { columnHeaders, refetch };
};
