import { Button, Form, FormRule, Input, message, Spin } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useMutation } from "@apollo/client";
import { MUTATION_PROJECT_DEFAULT_CONFIG } from "services/graphQL/mutations";
import { ProjectContext } from "context/ProjectProvider";
import { FeatureTypes } from "entity-app/constants";

const renameFieldsInfo = (page: string) => (
  <div className="flex items-center w-full info-label pl-4">
    <div className="flex text-justify">
      Rename the column headers of the {page} log
    </div>
  </div>
);

type TRenameData = {
  page: string;
  headerTitleMap: any;
};

const renameEntityData = {
  [FeatureTypes.SUBMITTALS]: {
    page: "Submittal",
    headerTitleMap: {
      submittal_id: "ID",
      spec_no: "Spec Section",
      submittal_number: "Submittal Number",
      title: "Submittal Title",
      description: "Description",
      revision: "Revision",
      type: "Type",
      workflow_status: "Status",
      state: "State",
      assignee: "Ball In Court",
      responsible_contractor: "Responsible Contractor",
      submitter: "Submitter",
      gc_reviewer: "GC Reviewer",
      design_reviewer: "Design Reviewer",
      assigned_to_subcontractor: "Assigned To Subcontractor (Actual)",
      submitted_by_subcontractor: "Submitted By Subcontractor (Actual)",
      submitted_for_design_review: "Submitted For Design Review (Actual)",
      design_review_completed: "Design Review Completed (Actual)",
      submittal_distributed: "Submittal Distributed (Actual)",
      linked_activities: "Linked Activities",
      linked_materials: "Linked Materials",
      next_planned_deadline: "Next Deadline",
      next_action: "Next Action",
      due_date: "Due Date",
      risk_level: "Risk",
      created_at: "Created At",
      priority: "Priority",
      watchers: "Watchers",
      float: "Submittal Float"
    }
  },
  [FeatureTypes.MATERIALS]: {
    page: "Material",
    headerTitleMap: {
      material_id: "ID",
      name: "Material Title",
      description: "Material Description",
      status: "Status",
      next_planned_deadline: "Next Deadline",
      due_date: "Due Date",
      risk_level: "Risk",
      float: "Material Float",
      spec_section_no: "Spec Section",
      tag_name: "Material Tag",
      date_block_template_name: "Material Template",
      quantity: "Quantity",
      quantity_unit_id: "Quantity Unit",
      trade_partner: "Responsible Contractor",
      assignee: "Assignee",
      gc_representative: "GC Representative",
      manufacturer: "Manufacturer",
      linked_activities: "Linked Activities",
      linked_submittals: "Linked Submittals",
      material_size: "Material Size",
      size_unit_id: "Material Size Unit",
      part_number: "Part Number",
      serial_number: "Serial Number",
      next_action: "Next Action",
      warranty: "Warranty (In Years)",
      created_at: "Created At",
      wf_override_reason: "Released Early"
    }
  },
  [FeatureTypes.BID_PACKAGE]: {
    page: "Bid Package",
    headerTitleMap: {
      id: "ID",
      number: "Number",
      title: "Title",
      bid_scope: "Bid Scope",
      wave: "Wave",
      workflow_status: "Status",
      next_deadline: "Next Deadline",
      due_date: "Due Date",
      risk_level: "Risk",
      float: "Bid Package Float",
      workflow_template_id: "Bid Package Template",
      no_of_bidders: "No of Bidders",
      bidding_subcontractors: "Bidding Subcontractors",
      preconstruction_lead: "Preconstruction Lead",
      operations_lead: "Operations Lead",
      estimated_value: "Estimated Value",
      awarded_value: "Awarded Value",
      awarded_subcontractor_name: "Awarded Subcontractor Name"
    }
  },
  [FeatureTypes.DESIGN_PACKAGE]: {
    page: "Design Package",
    headerTitleMap: {
      id: "ID",
      number: "Number",
      title: "Title",
      description: "Description",
      responsible_designer_id: "Responsible Design Firm",
      design_firm_id: "Responsible Designer",
      workflow_status: "Status",
      next_deadline: "Next Deadline",
      due_date: "Due Date",
      risk: "Risk",
      float: "Float",
      workflow_template_id: "Workflow Template ID",
      milestones: "Milestones",
      gc_representative_id: "GC Representative"
    }
  }
} as { [key in FeatureTypes]: TRenameData };

function FeatureRenamedFieldSettings({
  featureId,
  columnHeader,
  disabled
}: {
  featureId: FeatureTypes;
  columnHeader: any;
  disabled: boolean;
}) {
  const { gqlClientForProject } = useContext(ProjectContext);

  const [form] = Form.useForm();
  const [isFieldsEditable, setFieldsEditable] = useState<boolean>(false);
  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);
  const [showWarningMsg, setShowWarningMsg] = useState(false);

  const renameData = useMemo(() => {
    return renameEntityData[featureId];
  }, [featureId]);

  const renameFieldRules: FormRule[] = [
    {
      required: true,
      message: "Please enter field name",
      validator: async (_, value) => {
        if (value?.trim() === "") {
          setShowWarningMsg(true);
          return Promise.reject(new Error("Please enter field name"));
        }

        setShowWarningMsg(false);
        return Promise.resolve();
      }
    }
  ];

  const [updateProjectConfigMutation] = useMutation<any>(
    MUTATION_PROJECT_DEFAULT_CONFIG,
    {
      client: gqlClientForProject
    }
  );

  const cancelAction = () => {
    form.resetFields();
    setFieldsEditable(false);
    setSubmitInProgress(false);
  };

  const updateRenameFields = (data: any) => {
    const variables = {
      feature_id: featureId,
      field_mapping: data?.field_mapping,
      date_block_action: null
    };

    const updateResponse = updateProjectConfigMutation({
      variables
    });

    return updateResponse;
  };

  const onFinishForm = async (data: any) => {
    setSubmitInProgress(true);

    const updateResponse = await updateRenameFields(data);

    if (updateResponse.errors) {
      message.error(updateResponse.errors[0]?.message);
    } else {
      message.success("Default Settings are updated successfully.");
    }
    setFieldsEditable(false);
    setSubmitInProgress(false);
  };

  useEffect(() => {
    form.setFieldsValue(columnHeader);
  }, [columnHeader, form]);

  return (
    <Form
      form={form}
      initialValues={columnHeader}
      onFinish={onFinishForm}
      className="flex flex-col"
      disabled={disabled}
      validateTrigger={["onSubmit", "onChange"]}
    >
      <div className="flex-col">
        <div className="flex w-full items-center justify-between h-[40px] py-[2px] px-4">
          <div className=" text-[#3B3B3B] font-semibold text-sm">
            RENAME FIELDS
          </div>

          {isFieldsEditable ? (
            <div className="flex space-x-4">
              <Button
                className="w-24"
                type="primary"
                htmlType="submit"
                loading={submitInProgress}
                disabled={submitInProgress || showWarningMsg}
              >
                Save
              </Button>
              <Button
                className="w-24"
                disabled={submitInProgress || showWarningMsg}
                onClick={cancelAction}
              >
                Cancel
              </Button>
            </div>
          ) : (
            <Button
              type="primary"
              className="w-24"
              disabled={disabled}
              onClick={() => setFieldsEditable(true)}
            >
              Edit
            </Button>
          )}
        </div>
        {renameFieldsInfo(renameData.page)}

        <div className="flex justify-center w-full mt-10 overflow-y overflow-x-hidden max-h-[calc(100vh-250px)]">
          {columnHeader?.field_mapping ? (
            <div className="grid grid-cols-2 py-2 gap-x-10 w-4/5">
              <Form.List name="field_mapping">
                {(fields) => (
                  <>
                    {fields.map((field, index) => {
                      const titleKey: string =
                        columnHeader?.field_mapping[index]?.field_name;

                      // CIQ-2391 Remove fields which we do not allow renaming of from the settings page
                      const fieldWrapperClasses = classNames(
                        "grid grid-cols-2 justify-between items-center",
                        {
                          hidden:
                            !columnHeader?.field_mapping[index]?.allow_rename ||
                            !renameData.headerTitleMap[titleKey]
                        }
                      );

                      return (
                        <div
                          className={fieldWrapperClasses}
                          key={`rename-${titleKey}`}
                        >
                          <p className="col-span-1 titles mr-1">
                            {renameData.headerTitleMap[titleKey] || ""}
                          </p>
                          <Form.Item
                            {...field}
                            className="col-span-1"
                            key="caption_en"
                            name={[field.name, "caption_en"]}
                            rules={renameFieldRules}
                          >
                            <Input type="text" disabled={!isFieldsEditable} />
                          </Form.Item>
                        </div>
                      );
                    })}
                  </>
                )}
              </Form.List>
            </div>
          ) : (
            <Spin />
          )}
        </div>
      </div>
    </Form>
  );
}

export default FeatureRenamedFieldSettings;
