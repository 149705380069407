import ErrorIcon from "components/svg-icons/error-icon";
import { useMemo } from "react";
import { IMaterialGroup } from "../type-definitions";
import { MaterialListItem } from "../widgets";

function NoAssigneeRhsContent({
  title,
  group
}: {
  title: string;
  group: IMaterialGroup;
}) {
  const { allowedToSendRequestItems, WFStartedItems, inactiveUserItems } =
    group.materialsByStatus;

  const materialList = useMemo(() => {
    if (inactiveUserItems?.length) {
      return inactiveUserItems;
    }
    if (allowedToSendRequestItems?.length) {
      return allowedToSendRequestItems;
    }
    if (WFStartedItems?.length) {
      return WFStartedItems;
    }

    return [];
  }, [allowedToSendRequestItems, WFStartedItems, inactiveUserItems]);

  return (
    <div className="space-y-6">
      <div className="text-sm font-semibold">{title}</div>
      {materialList?.length && (
        <div>
          <div className="flex items-start space-x-1">
            <div className="form-field-heading-2">
              Excluded from selection ({materialList.length})
            </div>
            <div
              className="danger-text flex items-center"
              data-testid="info-icon"
            >
              <ErrorIcon width={16} height={16} />
            </div>
          </div>
          <div className="mt-1 container-bg-color px-3 pt-3 pb-3.5 ">
            {materialList.map((material, index) => (
              <MaterialListItem
                material={material}
                index={index}
                key={material.id}
                additionalClassName="border-0 border-b border-solid border-color-1"
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default NoAssigneeRhsContent;
