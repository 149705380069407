import VoidSubmittalIcon from "components/svg-icons/void-submittal-icon";
import { ESubmittalStatus, SubmittalStatusToStrMap } from "constants/index";

export const voidSubmittalCellRenderer = ({ value }: any) => {
  return value === SubmittalStatusToStrMap[ESubmittalStatus.VOID] ? (
    <VoidSubmittalIcon />
  ) : (
    <div>{value}</div>
  );
};
