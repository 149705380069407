export const DESIGN_PACKAGE_LIST_VIEW = `DesignPackageListQuery {
   design_package_list_func {
    id
    auto_inc_value
    number
    title
    description
    workflow_status
    milestone_state
    milestones
    offsets
    design_firm_id
    design_firm_name
    responsible_designer_id
    responsible_designer_first_name
    responsible_designer_last_name
    responsible_designer_status_id
    gc_representative_id
    gc_representative_first_name
    gc_representative_last_name
  }
}`;

export const QUERY_GET_DESIGN_PACKAGE_COLUMN_CONFIG = `
  GetSavedDesignPackageColumnHeaders($where: project_users_bool_exp) {
    project_users(where: $where) {
      project_id
      design_package_column_config
    }
  }
`;
