import { useContext, useEffect, useMemo, useState } from "react";
import { Button, Divider, Select } from "antd";
import { CurrentSpec } from "models/types";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { ProjectContext } from "context/ProjectProvider";
import { QUERY_GET_PROJECT_SPECS } from "services/graphQL/queries";

function SpecNumberNameDropDown(props: {
  onChange: (
    id: string | null,
    number: string | null,
    name: string | null
  ) => void;
  defaultSpecNumber?: string;
  defaultSpecName?: string;
  disabled?: boolean;
  allowCreate?: boolean;
  currentSpecSection?: CurrentSpec;
  allowClear?: boolean | undefined;
  onCreateSpecSectionClick?: (
    showCreateSpecSectionModal: boolean
  ) => void | undefined;
}) {
  const {
    onChange,
    defaultSpecNumber,
    defaultSpecName,
    disabled,
    allowCreate,
    currentSpecSection,
    onCreateSpecSectionClick,
    allowClear
  } = props;

  const [currentSpec, setCurrentSpec] = useState<CurrentSpec>({
    specSectionId: undefined,
    enable: false
  });

  const { gqlClientForProject, eventLogs } = useContext(ProjectContext);

  const { data: specSections, refetch: refetchSpecs } = useCIQQuery<{
    spec_sections: Array<{
      title: string;
      id: string;
      section_number: string;
    }>;
  }>(QUERY_GET_PROJECT_SPECS, {
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });

  useEffect(() => {
    if (
      eventLogs.some(
        (log) =>
          log.data_source === "spec_sections" && log.change_type === "insert"
      )
    ) {
      refetchSpecs();
    }
  }, [eventLogs, refetchSpecs]);

  const [open, setOpen] = useState(false);

  const defaultValue = useMemo(() => {
    if (defaultSpecNumber && defaultSpecName)
      return `${defaultSpecNumber} - ${defaultSpecName}`;
    if (defaultSpecNumber) return defaultSpecNumber;
    return null;
  }, [defaultSpecNumber, defaultSpecName]);

  useEffect(() => {
    if (currentSpecSection) {
      setCurrentSpec(currentSpecSection);
    }
  }, [currentSpecSection]);

  useEffect(() => {
    if (!currentSpec?.enable) return;
    if (currentSpec && currentSpec.specSectionId) {
      const spec = specSections?.spec_sections?.find(
        (x) => x.id === currentSpec.specSectionId
      );
      onChange(currentSpec.specSectionId, spec!.section_number, spec!.title);
    } else {
      onChange(null, null, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSpec]);

  return (
    <Select
      allowClear={allowClear}
      open={open}
      className="w-full"
      placeholder="Search Spec Section"
      disabled={disabled}
      value={
        specSections && currentSpec.specSectionId
          ? currentSpec.specSectionId
          : undefined
      }
      showSearch
      defaultValue={defaultValue}
      filterOption={(inputValue, option: any) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      }
      onChange={(id: string) => {
        setCurrentSpec({ specSectionId: id, enable: true });
      }}
      options={specSections?.spec_sections?.map((spec) => ({
        value: spec.id,
        label: spec.title
          ? `${spec.section_number} - ${spec.title}`
          : spec.section_number
      }))}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      dropdownRender={
        !allowCreate
          ? undefined
          : // eslint-disable-next-line react/no-unstable-nested-components
            (menu: any) => (
              <>
                {menu}
                <Divider className="my-1" style={{ margin: "8px 0" }} />
                <div className="w-full px-1">
                  <Button
                    className="rounded-none  bg-[#3b3b3b3b] hover:bg-[#3b3b3b5c] w-full"
                    onClick={() => {
                      setOpen(false);
                      if (onCreateSpecSectionClick) {
                        onCreateSpecSectionClick(true);
                      }
                    }}
                  >
                    Add New Spec Section
                  </Button>
                </div>
              </>
            )
      }
      data-testid="spec-section-no-dropdown"
    />
  );
}

export default SpecNumberNameDropDown;
