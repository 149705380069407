import { useContext, useMemo, useState } from "react";
import { Button, Form, Input, InputNumber, Typography } from "antd";

import TextArea from "antd/lib/input/TextArea";
import { TProject } from "components/project-list-view/models";
import { useLazyQuery } from "@apollo/client";
import { SubscriptionContext } from "context/SubscriptionProvider";
import { QUERY_PROJECT_AGG } from "services/graphQL/queries";
import { DATE_FORMAT_MMDDYYYY, DateUtils, TDateTimeObj } from "utils/dateutils";
import CIQDatePicker from "components/custom-date-picker";
import { ErrorMessages } from "../../../constants";

const { Paragraph } = Typography;

type EditProjectDetailProps = {
  projectInfo: TProject;
  updateProjectData: Function;
  hasEditPermission: boolean;
};

type FormData = {
  number: string;
  name: string;
  client: string;
  start_date: TDateTimeObj;
  end_date: TDateTimeObj;
  value: number;
  details: string;
  address: string;
  spec_section: string;
};

function ProjectDetailEdit({
  projectInfo,
  updateProjectData,
  hasEditPermission
}: EditProjectDetailProps) {
  const [form] = Form.useForm();
  const [isDisableEditing, setFieldsDisabled] = useState(true);
  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);

  const { subscriptionId } = useContext(SubscriptionContext);
  const [checkForExistingProject] = useLazyQuery(QUERY_PROJECT_AGG);

  const onFinishForm = async (data: FormData) => {
    setSubmitInProgress(true);
    const body = {
      number: data.number,
      name: data.name,
      client: data.client,
      start_date: data.start_date ? DateUtils.format(data.start_date) : null,
      end_date: data.end_date ? DateUtils.format(data.end_date) : null,
      value: data.value || null,
      details: data.details,
      address: data.address,
      spec_section: data.spec_section === "1"
    };
    await updateProjectData(body);
    setSubmitInProgress(false);
    setFieldsDisabled(true);
  };

  const parnerURL = useMemo(() => {
    return `${window.location.origin}/io/${projectInfo.id}`;
  }, [projectInfo.id]);

  const formRules = {
    name: [
      {
        required: true,
        validateTrigger: "onSubmit",
        message: ErrorMessages.ProjectNameMsg
      },
      () => ({
        async validator(_: any, value: string) {
          if (value && value?.length > 0 && value !== projectInfo.name) {
            const response: any = await checkForExistingProject({
              variables: {
                where: {
                  name: { _eq: value || "" },
                  owner_subscription_id: {
                    _eq: subscriptionId
                  }
                }
              }
            });

            const projectCount =
              response?.data?.project_aggregate?.aggregate?.count ?? 0;
            if (projectCount > 0) {
              return Promise.reject(
                new Error(ErrorMessages.ProjectNameExistsMsg)
              );
            }
          }

          return Promise.resolve();
        }
      })
    ],
    number: [
      {
        required: true,
        validateTrigger: "onSubmit",
        message: ErrorMessages.ProjectNumberErrMsg
      },
      () => ({
        async validator(_: any, value: string) {
          if (value && value?.length > 0 && value !== projectInfo.number) {
            const response: any = await checkForExistingProject({
              variables: {
                where: {
                  id: { _neq: projectInfo.id },
                  number: { _eq: value || "" },
                  owner_subscription_id: {
                    _eq: subscriptionId
                  }
                }
              }
            });
            const projectCount =
              response?.data?.project_aggregate?.aggregate?.count ?? 0;
            if (projectCount) {
              return Promise.reject(new Error(ErrorMessages.ProjectNumberMsg));
            }
          }

          return Promise.resolve();
        }
      })
    ]
  };
  return (
    <div className="w-full h-[calc(100vh-150px)] bg-white overflow-y-auto">
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          number: projectInfo.number,
          name: projectInfo.name,
          client: projectInfo.client,
          start_date: projectInfo.start_date
            ? DateUtils.dateTimeObj(projectInfo.start_date)
            : "",
          end_date: projectInfo.end_date
            ? DateUtils.dateTimeObj(projectInfo.end_date)
            : "",
          value: projectInfo.value,
          details: projectInfo.details,
          address: projectInfo.address,
          spec_section: projectInfo.spec_section ? "1" : "0"
        }}
        onFinish={onFinishForm}
        className="w-full"
      >
        <div className="flex w-full justify-end space-x-2 p-2">
          {isDisableEditing ? (
            <Button
              className="w-24"
              type="primary"
              onClick={() => setFieldsDisabled(false)}
              disabled={!hasEditPermission}
              title={
                hasEditPermission ? "" : ErrorMessages.PermissionNotGranted
              }
            >
              Edit
            </Button>
          ) : (
            <div className="flex space-x-2">
              <Button
                className="w-24"
                htmlType="reset"
                disabled={isDisableEditing || submitInProgress}
                onClick={() => {
                  setFieldsDisabled(true);
                  form.resetFields();
                }}
              >
                Cancel
              </Button>
              <Button
                className="w-24"
                type="primary"
                htmlType="submit"
                loading={submitInProgress}
                disabled={isDisableEditing || submitInProgress}
              >
                Save
              </Button>
            </div>
          )}
        </div>

        <div className="w-full px-10 md:px-20 lg:px-48 justify-center">
          <div className="grid grid-cols-2 gap-x-20 gap-y-2">
            <Form.Item name="name" label="Name" rules={formRules.name}>
              <Input type="text" disabled={isDisableEditing} />
            </Form.Item>
            <Form.Item name="number" label="Number" rules={formRules.number}>
              <Input type="text" disabled={isDisableEditing} />
            </Form.Item>

            <Form.Item name="client" label="Client">
              <Input type="text" disabled={isDisableEditing} />
            </Form.Item>

            <Form.Item name="value" label="Value">
              <InputNumber
                className="w-full"
                addonBefore="$"
                min={0}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                disabled={isDisableEditing}
              />
            </Form.Item>

            <Form.Item name="start_date" label="Start date">
              <CIQDatePicker
                className="w-full date-picker-with-icon"
                format={DATE_FORMAT_MMDDYYYY}
                disabled={isDisableEditing}
              />
            </Form.Item>

            <Form.Item name="end_date" label="End date">
              <CIQDatePicker
                className="w-full date-picker-with-icon"
                format={DATE_FORMAT_MMDDYYYY}
                disabled={isDisableEditing}
              />
            </Form.Item>

            <Form.Item name="details" label="Details">
              <TextArea rows={3} disabled={isDisableEditing} />
            </Form.Item>

            <Form.Item name="address" label="Address">
              <TextArea rows={3} disabled={isDisableEditing} />
            </Form.Item>

            <Form.Item label="PROJECT CREATED BY:" className="font-medium">
              {`${projectInfo?.created_by_user?.first_name} ${
                projectInfo?.created_by_user?.last_name
              } on ${DateUtils.format(projectInfo?.created_at)}`}
            </Form.Item>
            <Form.Item label="TIMEZONE" className="font-medium uppercase">
              {`${DateUtils.dateTimeObj().format("([GMT]Z)")} - ${
                projectInfo.timezone.name
              }`}
            </Form.Item>
            <Form.Item label="Submittal Integration" className="text-xs">
              <Paragraph copyable={{ text: parnerURL }}>
                Copy URL for external portals
              </Paragraph>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
}
export default ProjectDetailEdit;
