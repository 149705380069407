import NotFoundPage from "pages/not-found";
import {
  ProjectContext,
  TProjectContext,
  isPermissionNotGrantted
} from "context/ProjectProvider";
import { useContext, useMemo } from "react";
import RiskReportCardIcon from "components/svg-icons/risk-report-card-icon";
import UpcomingSubmittalsCardIcon from "components/svg-icons/upcoming-submittal-card-icon";
import { useHistory, useParams } from "react-router";
import ScheduleReportIcon from "components/svg-icons/schedule-report-icon";
import { Spin } from "antd";
import ErrorBoundary from "components/error-boundary";
import ReportCard from "./components/report-card";
import { ErrorMessages, ProjectPermissionEnum } from "../../constants";
import UpcomingMilestonesReportCardWrapper from "./upcoming-milestones/upcoming-milestones-report-card-wrapper";

function ReportsPageManu() {
  const history = useHistory();
  const { projectId } = useParams() as any;
  const {
    tokenContents,
    isFeatureFlagEnabled,
    isProductEntitlementsDataAvailable
  }: TProjectContext = useContext(ProjectContext);

  const isScheduleLookAheadReport = isFeatureFlagEnabled(
    "SCHEDULE_LOOK_AHEAD_REPORT"
  );

  const isReportsForUpcomingMilestonesEnabled = isFeatureFlagEnabled(
    "UPCOMING_MILESTONES_NOTIFICATION"
  );

  const cardDetails = useMemo(() => {
    return {
      risk: (
        <div>
          <div>
            Generate this report to identify the risk associated with all the
            workflows in your project.{" "}
          </div>
          <div className="mt-2">
            <ul className="list-disc mx-4 p-0">
              <li>CSV Export</li>
            </ul>
          </div>
        </div>
      ),
      upcomingProcurement: (
        <div>
          <div>
            Generate this report to review due dates of procurement items and
            their impact on subsequent workflows. Engage with relevant
            subcontractors regarding urgent submittals and materials.
          </div>
          <div className="mt-2">
            <ul className="list-disc mx-4 p-0">
              <li>CSV Export</li>
            </ul>
          </div>
        </div>
      ),
      schedule: (
        <div>
          <div>
            Generate this report to view scheduled activities within a specific
            date range, as well as the procurement readiness for the relevant
            activities.
          </div>
          <div className="mt-2">
            <ul className="list-disc mx-4 p-0">
              <li>CSV Export</li>
            </ul>
          </div>
        </div>
      ),
      upcomingMilestones: (
        <div>
          <div>
            Generate weekly emails and/or view this report to review upcoming
            tasks based on next milestone deadlines and ensure timely follow-up.
          </div>
          <div className="mt-2">
            <ul className="list-disc mx-4 p-0">
              <li className="hidden">CSV Export</li>
              <li>Set up an email alert</li>
            </ul>
          </div>
        </div>
      )
    };
  }, []);

  const cannotViewProjectReports = tokenContents
    ? isPermissionNotGrantted(
        ProjectPermissionEnum.ViewProjectReports,
        tokenContents?.role
      )
    : false;

  if (cannotViewProjectReports) {
    return (
      <NotFoundPage
        status="403"
        title="403"
        subTitle={ErrorMessages.UnauthorizedPageAccess}
      />
    );
  }

  if (!isProductEntitlementsDataAvailable) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full w-full">
      <div className="w-full uppercase px-4 py-2 font-medium">Reports</div>
      <div className="flex h-full bg-white px-4">
        <div className="flex flex-wrap h-full gap-x-10 px-4">
          <ReportCard
            title="Risk Report"
            icon={<RiskReportCardIcon size={48} />}
            onClick={() => {
              history.push(`/project/${projectId}/reports/risk`);
            }}
            detail={cardDetails.risk}
          />

          <ReportCard
            title="Upcoming Due Dates Review"
            icon={<UpcomingSubmittalsCardIcon size={48} />}
            onClick={() => {
              history.push(`/project/${projectId}/reports/upcoming`);
            }}
            detail={cardDetails.upcomingProcurement}
          />

          {isScheduleLookAheadReport && (
            <ReportCard
              title="Schedule Lookahead Report"
              icon={<ScheduleReportIcon size={48} />}
              onClick={() => {
                history.push(`/project/${projectId}/reports/activities`);
              }}
              detail={cardDetails.schedule}
            />
          )}

          {isReportsForUpcomingMilestonesEnabled && (
            <ErrorBoundary>
              <UpcomingMilestonesReportCardWrapper cardDetails={cardDetails} />
            </ErrorBoundary>
          )}
        </div>
      </div>
    </div>
  );
}

export default ReportsPageManu;
