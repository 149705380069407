import { LinkCellRenderer } from "admin-app/components/cellRenderer";
import AddOrganizationForm from "admin-app/components/forms/add-organization";
import SideModelPanel from "admin-app/components/side-model";
import { TOrganizationLog } from "admin-app/models";
import { ORGANIZATION_LIST } from "admin-app/services/ciq-queries";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button } from "antd";
import SearchInput from "components/search-input";
import { GridLoadingIndicator } from "components/widgets";
import { useQuerySubscription } from "hooks/common";
import { useMemo, useRef, useState, useEffect, useCallback } from "react";

import {
  DATE_FORMAT_MMDDYYYY_HHMM_A,
  DateFilter,
  DateUtils
} from "utils/dateutils";
import { getGQL } from "utils/utils";

function OrganizationDashboard() {
  const gridRef = useRef<AgGridReact<any>>(null);
  const [openAddOrganizationModel, setOpenAddOrganizationModel] =
    useState(false);
  const [isGridReady, setGridReady] = useState(false);
  const [stats, setStats] = useState("");

  const { query: qOrganizationQuery, sub: sOrganizationQuery } =
    getGQL(ORGANIZATION_LIST);
  const { data: OrganizationData } = useQuerySubscription<{
    organization: Array<TOrganizationLog>;
  }>(qOrganizationQuery, sOrganizationQuery, {});

  const columnDefs: ColDef[] = [
    {
      colId: "name",
      field: "name",
      headerName: "ORGANIZATION NAME",
      cellRenderer: LinkCellRenderer,
      tooltipField: "name"
    },
    {
      colId: "noOfSubcriptions",
      field: "organization_subscriptions_aggregate.aggregate.count",
      headerName: "NO. OF SUBSCRIPTIONS",
      width: 230,
      tooltipField: "organization_subscriptions_aggregate.aggregate.count"
    },
    {
      colId: "create_by",
      headerName: "CREATED BY",
      valueGetter: ({ data }) => {
        return `${data.created_by_user.first_name} ${data.created_by_user.last_name}`;
      },
      tooltipValueGetter: ({ value }) => value
    },
    {
      colId: "created_at",
      field: "created_at",
      headerName: "CREATED ON",
      comparator: (value1, value2) =>
        DateFilter.customComparator(
          value1,
          value2,
          DATE_FORMAT_MMDDYYYY_HHMM_A
        ),
      valueGetter: ({ data }) =>
        DateUtils.format(data.created_at, DATE_FORMAT_MMDDYYYY_HHMM_A),
      tooltipValueGetter: ({ value }) => value
    }
  ];

  const defaultColDef: {} = useMemo(() => {
    return {
      sortable: true,
      editable: false,
      filter: false,
      resizable: true,
      menuTabs: []
    };
  }, []);

  const updateStats = useCallback(() => {
    if (isGridReady && OrganizationData?.organization) {
      if (gridRef.current?.api?.isAnyFilterPresent()) {
        setStats(
          `${gridRef.current?.api.getDisplayedRowCount()} of ${
            OrganizationData?.organization.length
          } Organizations`
        );
      } else {
        setStats(`${OrganizationData?.organization.length} Organizations`);
      }
    }
  }, [OrganizationData?.organization, isGridReady]);

  useEffect(
    () => updateStats(),
    [updateStats, OrganizationData?.organization, isGridReady]
  );

  useEffect(() => {
    function handleResize() {
      if (isGridReady && gridRef && gridRef.current) {
        gridRef?.current?.api?.sizeColumnsToFit();
      }
    }
    window.addEventListener("resize", handleResize);
  }, [isGridReady]);

  return (
    <div className="flex flex-col h-full p-2">
      <div className="flex w-full justify-between items-end p-2">
        <div className="uppercase font-medium">{stats}</div>

        <div className="flex space-x-2 items-center">
          <SearchInput
            placeholder="Search Organizations"
            onChange={(text) => {
              gridRef.current?.api?.setQuickFilter(text);
              updateStats();
            }}
          />
          <Button
            className="px-5"
            onClick={() => setOpenAddOrganizationModel(true)}
          >
            Add Organization
          </Button>
        </div>
      </div>

      <div className="grow flex-col ag-theme-alpine p-2">
        <AgGridReact<TOrganizationLog>
          ref={gridRef}
          rowData={OrganizationData?.organization}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={36}
          loadingOverlayComponent={GridLoadingIndicator}
          onGridReady={() => {
            setGridReady(true);
            updateStats();
            gridRef.current?.api.sizeColumnsToFit();
          }}
          overlayNoRowsTemplate="There is no organizations created yet, please click 'Add Organization' button to create organizations."
        />
      </div>
      {openAddOrganizationModel && (
        <SideModelPanel
          title="Add Organization"
          open={openAddOrganizationModel}
          onCancel={() => setOpenAddOrganizationModel(false)}
        >
          <AddOrganizationForm
            onClose={() => setOpenAddOrganizationModel(false)}
          />
        </SideModelPanel>
      )}
    </div>
  );
}
export default OrganizationDashboard;
