import { Form, message, Radio } from "antd";
import { useContext, useEffect, useState } from "react";
import { ProjectContext } from "context/ProjectProvider";
import { useMutation } from "@apollo/client";
import { MUTATION_UPDATE_PROJECT_BY_ID } from "services/graphQL/mutations";
import { headerView } from "./header-view";

export default function SubmittalNumbering({
  disabled
}: {
  disabled: boolean;
}) {
  const [form] = Form.useForm();

  const { gqlClientForProject, projectDetails: projectInfo } =
    useContext(ProjectContext);

  const [isFieldsEditable, setFieldsEditable] = useState<boolean>(false);
  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);

  useEffect(() => {
    form.setFieldsValue({
      spec_section: projectInfo?.spec_section
    });
  }, [form, projectInfo]);

  const cancelAction = () => {
    form.resetFields();
    setFieldsEditable(false);
    setSubmitInProgress(false);
  };

  const [updateProjectByIdMutation] = useMutation<any>(
    MUTATION_UPDATE_PROJECT_BY_ID,
    {
      client: gqlClientForProject
    }
  );

  const submittalNumberingInfo = (
    <div className="flex items-start w-full info-label pl-4">
      <div className="space-y-2">
        <span>Select the method of numbering of Submittal ID.</span>
        <ul className="list-disc space-y-2">
          <li>
            <span className="font-semibold">With spec section:</span> Submittal
            ID will contain the spec number as a prefix to the sequentially
            generated ID. Eg: 031000 - 1, 041000 - 2, etc.
          </li>
          <li>
            <span className="font-semibold">Without spec section:</span> IDs
            will be sequential numbers starting from 1.
          </li>
        </ul>
      </div>
    </div>
  );

  const updateSubmittalNumbering = (data: any) => {
    const projectValues = {
      spec_section: data.spec_section
    };
    const variables = {
      projectId: projectInfo?.id,
      projectValues
    };

    const updateResponse = updateProjectByIdMutation({
      variables
    });

    return updateResponse;
  };

  const onFinish = async (data: any) => {
    setSubmitInProgress(true);
    const updateResponse = await updateSubmittalNumbering(data);
    if (updateResponse.errors) {
      message.error(updateResponse.errors[0]?.message);
    } else {
      message.success("Default Settings are updated successfully.");
    }
    setFieldsEditable(false);
    setSubmitInProgress(false);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      initialValues={{
        spec_section: projectInfo?.spec_section
      }}
      className="flex flex-col h-full"
      validateTrigger={["onSubmit", "onChange"]}
    >
      {headerView({
        title: "SUBMITTAL NUMBERING",
        disabled,
        cancelAction,
        isFieldsEditable,
        setFieldsEditable,
        showWarningMsg: false,
        submitInProgress
      })}
      {submittalNumberingInfo}
      <div className="flex pl-4 w-full mt-9 bg-white">
        <Form.Item name="spec_section">
          <Radio.Group disabled={!isFieldsEditable}>
            <Radio value={false} data-testid="radioGroupWithout">
              Without Spec Section
            </Radio>
            <Radio value data-testid="radioGroupWith">
              With Spec Section
            </Radio>
          </Radio.Group>
        </Form.Item>
      </div>
    </Form>
  );
}
