import { SettingOutlined, SwapOutlined } from "@ant-design/icons";
import { Badge, Popover, Space } from "antd";
import { useState } from "react";

function ReverseLinkingSettings({
  selectedScheduleId,
  reverseDependency,
  onChangeReverseLinking
}: {
  selectedScheduleId: string;
  reverseDependency: boolean;
  onChangeReverseLinking: (props: {
    selectedScheduleId: String;
    reverse_dependency: boolean;
  }) => void;
}) {
  const [open, setOpen] = useState(false);
  return (
    <Popover
      open={open}
      trigger="click"
      onOpenChange={(visible) => setOpen(visible)}
      className="px-1 flex"
      placement="bottom"
      overlayClassName="popoverNoPadding"
      overlayInnerStyle={{ borderRadius: "6px" }}
      content={
        <div className="flex flex-col">
          <div className="px-4 py-3 opacity-40 font-semibold text-xs">
            Advanced Settings
          </div>
          <Space
            className="flex px-4 pb-2 items-start cursor-pointer hover:bg-black hover:bg-opacity-5"
            onClick={() => {
              setOpen(false);
              onChangeReverseLinking({
                selectedScheduleId,
                reverse_dependency: !reverseDependency
              });
            }}
          >
            <Badge status="default" offset={[-2, 4]} dot={reverseDependency}>
              <SwapOutlined className="text-[#8C8C8C] z-10" />
            </Badge>
            <div className="flex-col">
              <div className="text-black">
                {reverseDependency
                  ? "Remove Reversed Dependency"
                  : "Reverse Dependency"}
              </div>
              <div className="text-[#555555] text-xs">
                {reverseDependency
                  ? "Revert to using Material as the Predecessor."
                  : "Make Activity the Predecessor for calculations."}
              </div>
            </div>
          </Space>
        </div>
      }
    >
      <Badge status="default" offset={[-5, 0]} dot={reverseDependency}>
        <SettingOutlined
          title="Advanced Settings"
          className="cursor-pointer z-10"
        />
      </Badge>
    </Popover>
  );
}
export default ReverseLinkingSettings;
