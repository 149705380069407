import { Tabs } from "antd";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import FeatureTemplateProjectSettings from "components/manage-feature-wf-templates/feature-template-project-settings";
import { FeatureTypes } from "entity-app/constants";
import FeatureProjectRiskThresholdSettings from "components/feature-project-risk-threshold-settings";
import FeatureRenamedFieldSettings from "components/feature-rename-fields-settings";

function ProjectBidPackageSettings(props: {
  bidPackageHeader: any;
  disabled: boolean;
}) {
  const { bidPackageHeader, disabled } = props;

  const history = useHistory();
  const { projectId, tab3Id } = useParams() as any;
  const [selectedTab, setSelectedTab] = useState<string>(tab3Id || "templates");

  const tabItems = [
    {
      label: "Bid Package Templates",
      key: "templates",
      children: (
        <FeatureTemplateProjectSettings featureId={FeatureTypes.BID_PACKAGE} />
      )
    },
    {
      label: "Rename Fields",
      key: "rename-fields",
      children: (
        <FeatureRenamedFieldSettings
          featureId={FeatureTypes.BID_PACKAGE}
          disabled={disabled}
          columnHeader={bidPackageHeader}
        />
      )
    },
    {
      label: "Risk Threshold",
      key: "risk-threshold",
      children: (
        <FeatureProjectRiskThresholdSettings
          featureId={FeatureTypes.BID_PACKAGE}
        />
      )
    }
  ];

  return (
    <div className="w-full bg-white h-full module-settings--tabs">
      <Tabs
        tabPosition="left"
        tabBarGutter={2}
        tabBarStyle={{ width: "180px" }}
        activeKey={selectedTab}
        items={tabItems}
        onChange={(activeKey) => {
          history.push(
            `/project/${projectId}/settings/modules/bid-packages/${activeKey.toString()}`
          );
          setSelectedTab(activeKey);
        }}
        destroyInactiveTabPane
      />
    </div>
  );
}

export default ProjectBidPackageSettings;
