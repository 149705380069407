import { useRef } from "react";
import { Link, useParams } from "react-router-dom";
import {
  DATE_FORMAT_MMDDYYYY,
  DATE_FORMAT_YYYYMMDD,
  DateFilter,
  DateUtils
} from "utils/dateutils";
import { RiskLevelType } from "components/date-block/models";
import { TActivitiesV3, TLinkedMaterial, TLinkedSubmittal } from "./models";
import "./style.scss";
import { getRiskAssessmentForEntity } from "./utils";
import MaterialsGroupDiffV6 from "./MaterialGroupDiffV6";
import SubmittalGroupDiffV6 from "./SubmittalGroupDiffV6";
import { EntityType } from "../../../../constants/index";

function ImpactedSubmittalEntityBoxHeader({
  submittal,
  projectId,
  linkedToEndDate
}: {
  submittal: any;
  projectId: any;
  linkedToEndDate: any;
}) {
  return (
    <div className="impactedEntityBoxHeader">
      <h4 className="font-bold m-0">
        Submittal:
        <Link
          to={`/project/${projectId}/submittals/${submittal.submittal_id}`}
          className="pl-2"
        >
          <i className="mr-2">
            {submittal.SDB?.old?.submittal_sequence_id} -{" "}
            {submittal.SDB?.old?.spec_no}
          </i>
          {submittal.SDB.old.title}
        </Link>
      </h4>
      <div className="text-right font-semibold">
        {linkedToEndDate ? "Linked with End Date" : ""}
      </div>
    </div>
  );
}

function ImpactedMaterialEntityBoxHeader({
  material,
  projectId,
  linkedToEndDate,
  isImplicit
}: {
  material: any;
  projectId: any;
  linkedToEndDate: any;
  isImplicit: any;
}) {
  return (
    <div className="impactedEntityBoxHeader">
      <h4 className="font-bold m-0">
        Material:
        {material.implicit !== true ? (
          <Link
            to={`/project/${projectId}/materials/${material.material_id}`}
            className="pl-2"
          >
            {isImplicit ? (
              `Material for Submittal ${material?.name}`
            ) : (
              <span>
                <i className="mr-2">
                  {material.MDB?.old?.material_sequence_id}
                </i>
                {material.MDB.old.name}
              </span>
            )}
          </Link>
        ) : (
          `Material for Submittal ${material?.name}`
        )}
      </h4>
      <div className="text-right font-semibold">
        {linkedToEndDate ? "Linked with End Date" : ""}
      </div>
    </div>
  );
}

function ActivityInfo({ activity }: { activity: TActivitiesV3 }) {
  return (
    <div className="impactingActivity">
      <h4 className="m-0">
        <span className="font-bold ">Impacting Activity:</span>
        <i className="mr-2 pl-2">{activity.id}</i>
        {activity.text}
      </h4>
      {activity.deleted ? (
        <div className="text-right">
          Linked activity has been removed in the selected schedule. Please
          relink to a suitable activity, if required.
        </div>
      ) : (
        <div className="text-right">
          <span>
            <b>Old Activity Date:</b>{" "}
            {DateUtils.format(activity.old_start, DATE_FORMAT_MMDDYYYY)}
          </span>
          <span className="ml-4">
            <b>New Activity Date:</b>{" "}
            {DateUtils.format(activity.new_start, DATE_FORMAT_MMDDYYYY)}
          </span>
          <span className="ml-4">
            <b>Delta:</b>{" "}
            {DateFilter.customComparator(
              activity.new_start,
              activity.old_start,
              DATE_FORMAT_YYYYMMDD
            ) < 0
              ? "-"
              : "+"}
            {activity.delta}d
          </span>
        </div>
      )}
    </div>
  );
}

function ScheduleImpactV3({
  activity,
  entityType
}: {
  activity: TActivitiesV3;
  entityType: EntityType;
}) {
  const { projectId } = useParams() as any;
  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <div>
      {entityType === EntityType.Submittal &&
        (activity.linked_submittals || []).map(
          (submittal: TLinkedSubmittal, i: number) => {
            const riskAssessment = getRiskAssessmentForEntity(submittal?.SDB);
            const key = `${i}-${submittal.submittal_id}`;
            const linkedToEndDate =
              submittal.old_governing_task?.find(
                (x) => x.activity_id === activity.id
              )?.linked_to_end_date || false;

            return (
              <div key={key}>
                <ImpactedSubmittalEntityBoxHeader
                  submittal={submittal}
                  projectId={projectId}
                  linkedToEndDate={linkedToEndDate}
                />
                <div>
                  <div className="impactBoxContainerContent">
                    {riskAssessment ? (
                      <div>
                        <b className="mr-1">
                          Risk assessment after Schedule Change:
                        </b>
                        <span
                          className={
                            riskAssessment.level === RiskLevelType.High
                              ? "text-[#F3523A]"
                              : ""
                          }
                        >
                          {riskAssessment.text}
                        </span>
                      </div>
                    ) : null}
                    <ActivityInfo activity={activity} />
                  </div>
                  <div className="impactBoxContainerContent" ref={containerRef}>
                    <SubmittalGroupDiffV6
                      submittal={submittal}
                      activityComparison={activity}
                      projectId={projectId}
                    />
                  </div>
                </div>
              </div>
            );
          }
        )}

      {entityType === EntityType.Material &&
        (activity.linked_materials || []).map(
          (material: TLinkedMaterial, i: number) => {
            const riskAssessment = getRiskAssessmentForEntity(material?.MDB);
            const key = `${i}-${material.material_id}`;
            const linkedToEndDate =
              material.old_governing_task?.find(
                (x) => x.activity_id === activity.id
              )?.linked_to_end_date || false;

            const isImplicit = material.MDB.old?.implicit;

            return (
              <div key={key}>
                <ImpactedMaterialEntityBoxHeader
                  material={material}
                  projectId={projectId}
                  linkedToEndDate={linkedToEndDate}
                  isImplicit={isImplicit}
                />
                <div key={key}>
                  <div className="impactBoxContainerContent">
                    {riskAssessment ? (
                      <div>
                        <b className="mr-1">Risk assessment:</b>
                        <span
                          className={
                            riskAssessment.level === RiskLevelType.High
                              ? "text-[#F3523A]"
                              : ""
                          }
                        >
                          {riskAssessment.text}
                        </span>
                      </div>
                    ) : null}

                    <ActivityInfo activity={activity} />
                  </div>
                  <div className="impactBoxContainerContent" ref={containerRef}>
                    <MaterialsGroupDiffV6
                      material={material}
                      activityComparison={activity}
                      projectId={projectId}
                    />
                  </div>
                </div>
              </div>
            );
          }
        )}
    </div>
  );
}
export default ScheduleImpactV3;
