import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import modal from "antd/lib/modal";
import {
  dateCellRenderer,
  FeatureTemplateListActionsCellRenderer,
  featureTemplateListDefaultCellRenderer,
  featureTemplateListNameCellRenderer
} from "components/cell-renders";
import { GridLoadingIndicator } from "components/widgets";
import { useEffect, useMemo, useRef, useState } from "react";
import { DateFilter, DateUtils } from "utils/dateutils";
import { InfoMessages } from "../../constants";

const getCreatedDate = (params: any) => {
  const dateStr: string = params.data.created_at;
  if (!dateStr) return "";
  return DateUtils.format(dateStr);
};

const getCreatedUser = (params: any) => {
  const user = params.data?.created_by_user;
  return user ? `${user.first_name} ${user.last_name}` : "";
};

export default function FeatureTemplateList(props: {
  setdetailsViewData: (data: any) => void;
  workflowTemplates: Array<any> | undefined;
  canEditFeatureDBTemplate: boolean;
  onChangeDefaultTemplate: Function;
  savingDefaultTemplate: boolean;
  onWorkflowCopy: (payload: any) => void;
  onToggleTemplateDisabled: (data: any) => void;
}) {
  const {
    setdetailsViewData,
    workflowTemplates,
    canEditFeatureDBTemplate,
    onChangeDefaultTemplate,
    savingDefaultTemplate,
    onWorkflowCopy,
    onToggleTemplateDisabled
  } = props;

  const gridRef = useRef<AgGridReact>(null);

  const [isGridReady, setGridReady] = useState(false);

  const columnDefs = useMemo<ColDef[]>(() => {
    return [
      {
        headerName: "TEMPLATE NAME",
        headerTooltip: "TEMPLATE NAME",
        field: "name",
        colId: "name",
        cellRenderer: featureTemplateListNameCellRenderer,
        menuTabs: [],
        minWidth: 350,
        filter: false,
        sort: "asc"
      },
      {
        headerName: "CREATED BY",
        headerTooltip: "CREATED BY",
        colId: "created_by",
        comparator: DateFilter.comparator,
        menuTabs: [],
        valueGetter: getCreatedUser,
        tooltipValueGetter: getCreatedUser
      },
      {
        headerName: "CREATED ON",
        headerTooltip: "CREATED ON",
        colId: "created_at",
        field: "created_at",
        cellRenderer: dateCellRenderer,
        comparator: DateFilter.comparator,
        menuTabs: [],
        tooltipValueGetter: getCreatedDate
      },
      {
        headerName: "DEFAULT",
        headerTooltip: "DEFAULT",
        field: "default",
        colId: "default",
        width: 90,
        maxWidth: 110,
        cellRenderer: featureTemplateListDefaultCellRenderer,
        cellRendererParams: {
          savingDefaultTemplate,
          canEditFeatureDBTemplate
        },
        menuTabs: [],
        sortable: false
      },
      {
        headerName: "ACTIONS",
        headerTooltip: "ACTIONS",
        cellRenderer: FeatureTemplateListActionsCellRenderer,
        cellRendererParams: {
          savingDefaultTemplate,
          canEditFeatureDBTemplate,
          featureTemplates: workflowTemplates
        },
        menuTabs: [],
        minWidth: 250,
        sortable: false,
        filter: false
      }
    ];
  }, [savingDefaultTemplate, canEditFeatureDBTemplate, workflowTemplates]);

  const defaultColDef: {} = useMemo<ColDef>(() => {
    return {
      sortable: true,
      autoSizeAllColumns: true,
      autoHeight: true,
      resizable: true,
      headerClass: "grid-header-style"
    };
  }, []);

  const onTemplateNameClick = (cellData: any) => {
    // console.log("onTemplateNameClick ", cellData);
    if (savingDefaultTemplate) return;
    setdetailsViewData({
      data: cellData,
      openInEditMode: false
    });
  };

  const onTemplateEditClick = (cellData: any) => {
    // console.log("onTemplateEditClick ", cellData);
    setdetailsViewData({
      data: cellData,
      openInEditMode: true
    });
  };

  const onTemplateDefaultChange = (cellData: any) => {
    modal.confirm({
      title: "Confirmation",
      content: InfoMessages.defaultTemplateChangeCOnfirmation,
      onOk() {
        onChangeDefaultTemplate(cellData);
      },
      okText: "Yes",
      cancelText: "Cancel"
    });
  };

  const onToggleTemplateDisabledConfirm = (
    cellData: any,
    newToggleState: boolean
  ) => {
    if (cellData.default) {
      modal.error({
        title: "Cannot Disable Default Template",
        content: "Set an another template as default to disable this template.",
        onOk() {},
        okText: "Ok"
      });
      return;
    }

    modal.confirm({
      title: "Confirmation",
      content: newToggleState
        ? InfoMessages.confirmEnableMDBTemplate
        : InfoMessages.confirmDisableMDBTemplate,
      onOk() {
        onToggleTemplateDisabled(cellData);
      },
      okText: "Yes",
      cancelText: "Cancel"
    });
  };

  useEffect(() => {
    function handleResize() {
      if (isGridReady && gridRef && gridRef.current) {
        gridRef?.current?.api?.sizeColumnsToFit();
      }
    }
    window.addEventListener("resize", handleResize);
  }, [gridRef, isGridReady]);

  return (
    <div>
      <div className="text-[#3B3B3B] font-semibold text-sm py-2 mb-2">
        {workflowTemplates ? `${workflowTemplates.length} ` : ""}
        TEMPLATES
      </div>
      <div className="h-[500px] ag-theme-alpine">
        <AgGridReact<any>
          ref={gridRef}
          onFirstDataRendered={() => {
            setGridReady(true);
            gridRef?.current?.api?.sizeColumnsToFit();
          }}
          rowData={workflowTemplates}
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows // Optional - set to 'true' to have rows animate when sorted
          loadingOverlayComponent={GridLoadingIndicator}
          suppressDragLeaveHidesColumns
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          suppressContextMenu
          context={{
            onTemplateNameClick,
            onTemplateEditClick,
            onTemplateDefaultChange,
            onWorkflowCopy,
            onToggleTemplateDisabledConfirm
          }}
        />
      </div>
    </div>
  );
}
