export default function VoidSubmittalIcon({
  type = "button"
}: {
  type?: "button" | "text";
}) {
  return (
    <strong
      className={
        type === "button"
          ? "px-2 py-1 mr-1 rounded bg-void-100 text-xs text-black text-opacity-50"
          : "text-xs text-black text-opacity-50 pr-1"
      }
    >
      Void
    </strong>
  );
}
