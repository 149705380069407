export default function CriticalActivityIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g clipPath="url(#clip0_1505_5155)">
        <path
          d="M8.75 11.0835L4.95833 11.0835C3.50859 11.0835 2.33333 9.90824 2.33333 8.4585C2.33333 7.00874 3.50859 5.8335 4.95833 5.8335L9.04167 5.8335C9.84707 5.8335 10.5 5.18057 10.5 4.37516C10.5 3.56976 9.84707 2.91683 9.04167 2.91683L5.15042 2.91683C4.91018 3.59653 4.26196 4.0835 3.5 4.0835C2.5335 4.0835 1.75 3.30002 1.75 2.3335C1.75 1.36697 2.5335 0.583497 3.5 0.583497C4.26196 0.583497 4.91018 1.07046 5.15042 1.75016L9.04167 1.75016C10.4914 1.75016 11.6667 2.92541 11.6667 4.37516C11.6667 5.82492 10.4914 7.00016 9.04167 7.00016L4.95833 7.00016C4.15292 7.00016 3.5 7.65308 3.5 8.4585C3.5 9.26391 4.15292 9.91683 4.95833 9.91683L8.75 9.91683L8.75 8.16683L11.6667 10.5002L8.75 12.8335L8.75 11.0835Z"
          fill="#2F54EB"
        />
      </g>
      <defs>
        <clipPath id="clip0_1505_5155">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
