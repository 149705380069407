import {
  DraggableModal,
  DraggableModalProvider
} from "ant-design-draggable-modal";
import "./submittal-insights.scss";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ColDef } from "ag-grid-community";
import SearchInput from "components/search-input";

function SubmittalInsights(params: {
  showModal: boolean;
  hideModal: () => void;
  submittalInsights: any;
}) {
  const { showModal, hideModal, submittalInsights } = params;
  const gridRef = useRef<AgGridReact>(null);
  const [stats, setStats] = useState("");

  const modalInitialWidth = (85 * window.innerWidth) / 100;
  const modalInitialHeight = (70 * window.innerHeight) / 100;

  const defaultColDef: {} = useMemo(() => {
    return {
      sortable: true,
      filter: false,
      resizable: true,
      menuTabs: [],
      sortingOrder: ["asc", "desc"],
      suppressColumnsToolPanel: false
    };
  }, []);

  const columnDefs = useMemo<ColDef[]>(() => {
    return [
      {
        colId: "project_name",
        field: "project_name",
        headerName: "Project Name",
        headerTooltip: "PROJECT NAME",
        tooltipField: "project_name",
        sortable: true,
        filter: true,
        menuTabs: ["filterMenuTab"]
      },
      {
        colId: "title",
        field: "title",
        headerName: "Submittal",
        headerTooltip: "SUBMITTAL",
        tooltipField: "title",
        filter: false,
        sortable: true,
        menuTabs: []
      },
      {
        colId: "submittal_type",
        field: "submittal_type",
        headerName: "Type",
        headerTooltip: "TYPE",
        tooltipField: "submittal_type",
        sortable: true,
        filter: true,
        menuTabs: ["filterMenuTab"]
      },
      {
        colId: "submittal_prep_time",
        field: "submittal_prep_time",
        headerName: "SC Submittal Prep time (In Calender Days)",
        headerTooltip: "SC SUBMITTAL PREP TIME (IN CALENDER DAYS)",
        tooltipField: "submittal_prep_time",
        filter: false,
        sortable: true,
        menuTabs: []
      },
      {
        colId: "design_review_time",
        field: "design_review_time",
        headerName: "Design Review time  (In Calender Days)",
        headerTooltip: "DESIGN REVIEW TIME  (IN CALENDER DAYS)",
        tooltipField: "design_review_time",
        filter: false,
        sortable: true,
        menuTabs: []
      },
      {
        colId: "total_workflow_time",
        field: "total_workflow_time",
        headerName: "Total Submittal workflow time (In Calender Days)",
        headerTooltip: "TOTAL SUBMITTAL WORKFLOW TIME  (IN CALENDER DAYS)",
        tooltipField: "total_workflow_time",
        filter: false,
        sortable: true,
        menuTabs: []
      },
      {
        colId: "total_revisions",
        field: "total_revisions",
        headerName: "No.of Revisions",
        headerTooltip: "NO. OF REVISIONS",
        tooltipField: "total_revisions",
        sortable: true,
        filter: true,
        menuTabs: ["filterMenuTab"]
      },
      {
        colId: "responsible_contractor_name",
        field: "responsible_contractor_name",
        headerName: "Responsible Contractor",
        headerTooltip: "RESPONSIBLE CONTRACTOR",
        tooltipField: "responsible_contractor_name",
        sortable: true,
        filter: true,
        menuTabs: ["filterMenuTab"]
      }
    ];
  }, []);

  useEffect(() => {
    if (submittalInsights) {
      setStats(
        `${submittalInsights?.length} Submittal${
          submittalInsights?.length < 2 ? "" : "s"
        }`
      );
    }
  }, [submittalInsights]);

  const getSubmittalCountTitle = submittalInsights ? (
    <div className=" font-semibold uppercase">{stats} </div>
  ) : (
    <div />
  );

  const onFiltersApplied = () => {
    if (gridRef.current?.api?.isAnyFilterPresent()) {
      setStats(
        `${gridRef.current?.api?.getDisplayedRowCount()} of ${
          submittalInsights?.length
        } Submittals`
      );
    } else {
      setStats(
        `${submittalInsights?.length} Submittal${
          submittalInsights?.length < 2 ? "" : "s"
        }`
      );
    }
  };

  const onFilterTextBoxChanged = useCallback(
    (value: string) => {
      gridRef?.current?.api?.setQuickFilter(value);
    },
    [gridRef]
  );

  return (
    <DraggableModalProvider>
      <DraggableModal
        title="Insights"
        open={showModal}
        closable
        onCancel={() => hideModal()}
        footer={null}
        initialHeight={modalInitialHeight}
        initialWidth={modalInitialWidth}
        zIndex={110}
        className="submittal-insights"
        destroyOnClose
      >
        <div className="flex flex-col h-full">
          <div className="flex w-full justify-between items-center mb-1">
            {getSubmittalCountTitle}
            <SearchInput placeholder="" onChange={onFilterTextBoxChanged} />
          </div>

          <div className="ag-theme-alpine h-full">
            <AgGridReact<any>
              ref={gridRef}
              onGridReady={() => {
                gridRef?.current?.api?.sizeColumnsToFit();
              }}
              rowSelection="single"
              rowData={submittalInsights}
              columnDefs={columnDefs} // Column Defs for Columns
              defaultColDef={defaultColDef} // Default Column Properties
              animateRows // Optional - set to 'true' to have rows animate when sorted
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              headerHeight={36}
              noRowsOverlayComponent={() => {
                return "Unable to provide insights as similar submittals were not found.";
              }}
              onModelUpdated={(event) => {
                if (event?.api?.getDisplayedRowCount() === 0) {
                  event?.api?.showNoRowsOverlay();
                } else {
                  event?.api?.hideOverlay();
                }
              }}
              onFilterChanged={onFiltersApplied}
            />
          </div>
        </div>
      </DraggableModal>
    </DraggableModalProvider>
  );
}

export default SubmittalInsights;
