import { ArrowLeftOutlined } from "@ant-design/icons";
import { message, Space } from "antd";
import DefaultTag from "components/widgets/default-tag";
import WorkflowTemplateEditor from "components/workflow-template-editor";

import {
  isPermissionNotGrantted,
  ProjectContext,
  TProjectContext
} from "context/ProjectProvider";
import { useCIQMutation, useCIQQuerySubscription } from "hooks/ciq-gql-hooks";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GET_PROJECT_DB_TEMPLATES } from "services/graphQL/ciq-queries";
import { TSaveWorkflowModelInput } from "components/workflow-template-editor/model";
import { FeatureTypes } from "entity-app/constants";
import {
  MUTATION_COPY_PROJECT_DB_TEMPLATE,
  MUTATION_SET_DEFAULT_PROJECT_DB_TEMPLATE,
  MUTATION_UPDATE_PROJECT_MDB_TEMPLATE,
  MUTATION_UPDATE_PROJECT_TEMPLATE_API
} from "services/graphQL/mutations";
import {
  SuccessMessages,
  ErrorMessages,
  ProjectPermissionEnum
} from "../../constants";
import FeatureTemplateList from "./feature-template-list";

const instructionsTextForFeatures = {
  [FeatureTypes.DESIGN_PACKAGE]:
    "Create the design package date block templates you wish to use in the project. It's important to note that modifications made to the template here will impact the design packages that utilize it.",
  [FeatureTypes.BID_PACKAGE]:
    "Create the bid package date block templates you wish to use in the project. It's important to note that modifications made to the template here will impact the bid packages that utilize it.",
  [FeatureTypes.MATERIALS]:
    "Create the material date block templates you wish to use in the project. It's important to note that modifications made to the template here will impact the materials that utilize it."
};

function FeatureTemplateProjectSettings(props: { featureId: number }) {
  const { featureId } = props;

  const { gqlClientForProject, tokenContents }: TProjectContext =
    useContext(ProjectContext);

  const [detailsViewData, setdetailsViewData] = useState<any>(null);
  const [savingDefaultTemplate, setSavingDefaultTemplate] = useState(false);
  const [featureTemplates, setfeatureTemplates] = useState<any>();

  const { data: DBTemplates } = useCIQQuerySubscription<{
    project_workflow_templates: Array<any>;
  }>(GET_PROJECT_DB_TEMPLATES, {
    variables: {
      featureId
    },
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });

  const [saveProjectTemplateMutation] = useCIQMutation(
    MUTATION_UPDATE_PROJECT_TEMPLATE_API,
    {
      client: gqlClientForProject
    }
  );

  const [setDefaultDBTemplate] = useCIQMutation(
    MUTATION_SET_DEFAULT_PROJECT_DB_TEMPLATE,
    {
      client: gqlClientForProject
    }
  );

  const clonedDBTemplatesSoruce = useMemo(() => {
    if (!DBTemplates || !DBTemplates.project_workflow_templates) return null;
    return structuredClone(DBTemplates.project_workflow_templates);
  }, [DBTemplates]);

  useEffect(() => {
    if (!DBTemplates || !DBTemplates.project_workflow_templates) return;
    setfeatureTemplates(clonedDBTemplatesSoruce);
  }, [DBTemplates, clonedDBTemplatesSoruce]);

  const [copyDBTemplateMutation] = useCIQMutation(
    MUTATION_COPY_PROJECT_DB_TEMPLATE,
    {
      client: gqlClientForProject
    }
  );

  const [disableDBTemplate] = useCIQMutation(
    MUTATION_UPDATE_PROJECT_MDB_TEMPLATE,
    {
      client: gqlClientForProject
    }
  );

  const canEditFeatureDBTemplate = !isPermissionNotGrantted(
    ProjectPermissionEnum.EditMaterialDBTemplate,
    tokenContents?.role!
  );

  const templateObjectForDetailsView = useMemo(() => {
    if (!detailsViewData || !DBTemplates?.project_workflow_templates)
      return null;
    return DBTemplates.project_workflow_templates.find((template: any) => {
      return template.id === detailsViewData.data.id;
    });
  }, [DBTemplates, detailsViewData]);

  // console.log("templateObjectForDetailsView ", templateObjectForDetailsView);

  const onSaveTemplate = async (data: TSaveWorkflowModelInput) => {
    const res = await saveProjectTemplateMutation({
      variables: data
    });
    if (res.data) {
      message.success("Template updated successfully.");
    }
    if (res.errors && res.errors.length > 0) {
      message.error(res.errors[0].message);
    }
  };

  const onChangeDefaultTemplate = useCallback(
    async (cellData: any) => {
      if (!DBTemplates || !DBTemplates?.project_workflow_templates) return;
      setSavingDefaultTemplate(true);

      const updatedTemplates = DBTemplates.project_workflow_templates.map(
        (template: any) => {
          if (cellData.id === template.id) {
            return {
              ...template,
              default: true
            };
          }
          return {
            ...template,
            default: false
          };
        }
      );

      setfeatureTemplates([...updatedTemplates]);

      const payload: any = {
        variables: {
          templateId: cellData.id,
          feature_id: featureId
        }
      };

      const setDefaultTemplateResponse = await setDefaultDBTemplate(payload);

      if (setDefaultTemplateResponse.success) {
        message.success(SuccessMessages.DBDefaultTemplateSet);
      } else {
        setfeatureTemplates(clonedDBTemplatesSoruce);
      }
      setSavingDefaultTemplate(false);
    },
    [DBTemplates, clonedDBTemplatesSoruce, featureId, setDefaultDBTemplate]
  );

  const onWorkflowCopy = async (payload: any) => {
    const copyTemplateResponse = await copyDBTemplateMutation(payload);
    if (copyTemplateResponse.success) {
      message.success(SuccessMessages.DBTemplateCopiedSuccessfully);
    }
  };

  const onToggleTemplateDisabled = async (data: any) => {
    setSavingDefaultTemplate(true);

    const clonedTemplatesToRevert = structuredClone(featureTemplates);
    const updatedToggleMaterialList = featureTemplates.map((template: any) => {
      if (template.id === data.id) {
        return {
          ...template,
          disabled: !data.disabled
        };
      }
      return template;
    });

    setfeatureTemplates(updatedToggleMaterialList);

    const toggleDisableResp = await disableDBTemplate({
      variables: {
        pk_columns: { id: data.id },
        _set: { disabled: !data.disabled }
      }
    });

    if (!toggleDisableResp.success) {
      setfeatureTemplates(clonedTemplatesToRevert);
      message.error(ErrorMessages.disableTemplateFailed);
      return;
    }
    setSavingDefaultTemplate(false);
    message.success(
      !data.disabled
        ? SuccessMessages.DBTemplateDisabledSuccessfully
        : SuccessMessages.DBTemplateEnabledSuccessfully
    );
  };

  return (
    <div className="py-[2px] px-4">
      {!detailsViewData && (
        <FeatureTemplateList
          setdetailsViewData={setdetailsViewData}
          workflowTemplates={featureTemplates}
          canEditFeatureDBTemplate={canEditFeatureDBTemplate}
          onChangeDefaultTemplate={onChangeDefaultTemplate}
          savingDefaultTemplate={savingDefaultTemplate}
          onWorkflowCopy={onWorkflowCopy}
          onToggleTemplateDisabled={onToggleTemplateDisabled}
        />
      )}
      {templateObjectForDetailsView && (
        <div>
          <div className="flex items-center space-x-3 py-1 mt-0.5">
            <Space
              className="flex items-center cursor-pointer"
              onClick={() => {
                setdetailsViewData(null);
              }}
            >
              <ArrowLeftOutlined />
              <span className="text-base font-medium uppercase whitespace-pre">
                {templateObjectForDetailsView.name}
              </span>
            </Space>
            {templateObjectForDetailsView.default && <DefaultTag />}
          </div>
          <div>
            {
              instructionsTextForFeatures[
                featureId as keyof typeof instructionsTextForFeatures
              ]
            }
          </div>
          <div className="py-3">
            <WorkflowTemplateEditor
              milestones={
                templateObjectForDetailsView.project_template_milestones
              }
              workflowTemplate={templateObjectForDetailsView}
              otherTemplateNames={DBTemplates?.project_workflow_templates
                .filter((x: any) => x.id !== templateObjectForDetailsView.id)
                .map((x: any) => x?.name?.toLowerCase())}
              disable={
                !canEditFeatureDBTemplate ||
                templateObjectForDetailsView.disabled
              }
              openInEditMode={detailsViewData.openInEditMode}
              onSave={onSaveTemplate}
              isTemplateUsed={
                templateObjectForDetailsView?.date_blocks_aggregate?.aggregate
                  ?.count > 0
              }
              featureId={featureId}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default FeatureTemplateProjectSettings;
