function ClockIcon() {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.9375 0.6875C4.13402 0.6875 3.34857 0.925761 2.6805 1.37215C2.01242 1.81855 1.49172 2.45302 1.18424 3.19535C0.87676 3.93767 0.796309 4.75451 0.953062 5.54255C1.10981 6.3306 1.49673 7.05447 2.06488 7.62262C2.63303 8.19077 3.3569 8.57769 4.14495 8.73444C4.933 8.89119 5.74983 8.81074 6.49215 8.50326C7.23448 8.19578 7.86895 7.67508 8.31535 7.007C8.76174 6.33893 9 5.55349 9 4.75C8.99886 3.67291 8.57049 2.64026 7.80887 1.87863C7.04725 1.11701 6.01459 0.688637 4.9375 0.6875ZM4.9375 8.1875C4.25763 8.1875 3.59302 7.98589 3.02773 7.60818C2.46244 7.23046 2.02184 6.6936 1.76167 6.06547C1.50149 5.43735 1.43342 4.74619 1.56605 4.07938C1.69869 3.41257 2.02608 2.80006 2.50682 2.31932C2.98757 1.83858 3.60007 1.51119 4.26688 1.37855C4.93369 1.24591 5.62486 1.31399 6.25298 1.57416C6.8811 1.83434 7.41796 2.27493 7.79568 2.84023C8.1734 3.40552 8.375 4.07013 8.375 4.75C8.37397 5.66136 8.01147 6.53511 7.36704 7.17954C6.72261 7.82397 5.84887 8.18647 4.9375 8.1875ZM7.4375 4.75C7.4375 4.83288 7.40458 4.91237 7.34597 4.97097C7.28737 5.02958 7.20788 5.0625 7.125 5.0625H4.9375C4.85462 5.0625 4.77514 5.02958 4.71653 4.97097C4.65793 4.91237 4.625 4.83288 4.625 4.75V2.5625C4.625 2.47962 4.65793 2.40013 4.71653 2.34153C4.77514 2.28292 4.85462 2.25 4.9375 2.25C5.02038 2.25 5.09987 2.28292 5.15847 2.34153C5.21708 2.40013 5.25 2.47962 5.25 2.5625V4.4375H7.125C7.20788 4.4375 7.28737 4.47042 7.34597 4.52903C7.40458 4.58763 7.4375 4.66712 7.4375 4.75Z"
        fill="#3B3B3B"
        fillOpacity="0.8"
      />
    </svg>
  );
}

export default ClockIcon;
