import ScrollIcon from "components/svg-icons/scroll-icon";
import { MailOutlined } from "@ant-design/icons";
import moment from "moment";
import { getCurrentTimeZoneInfo } from "utils/dateutils";
import { useState } from "react";
import ErrorBoundary from "components/error-boundary";
import ReportCardV2 from "../components/report-card-v2";
import { useUpcomingMilestones } from "./hooks/upcoming-milestones";
import ConfigureUpcomingMilestones from "./configure-upcoming-milestones";

function EmailAlertStatusComponent(props: any) {
  const { schedule, isDataLoaded } = props;

  if (!isDataLoaded)
    return (
      <div className="bg-[#F7F6F4] text-center text-sm px-3 py-2">
        <div>&nbsp;</div>
      </div>
    );

  const enabled = schedule?.enabled;

  if (isDataLoaded && !enabled) {
    return (
      <div
        className="bg-[#F7F6F4] text-center text-sm px-3 py-2"
        data-test-id="email-alert-status-off"
      >
        <div className="text-[#000000D9]">Email Alerts Off</div>
      </div>
    );
  }

  const timein12HourFormat = moment(schedule.schedule_input.hour, "HH").format(
    "hA"
  );

  const projectTimeZone = getCurrentTimeZoneInfo();

  return (
    <div
      className="bg-[#E6F4FF] text-center text-xs px-3 py-2 flex items-center"
      data-test-id="email-alert-status-on"
    >
      <div className="flex items-center leading-none">
        <MailOutlined
          className="text-[#00000073]"
          style={{ fontSize: "14px" }}
        />{" "}
        <span className="text-[#555555] font-bold text-sm ml-1">Active</span>
      </div>
      <div className="grow text-right leading-none">
        Every {schedule.schedule_input.week_day} at {timein12HourFormat} (
        {projectTimeZone.code})
      </div>
    </div>
  );
}

function UpcomingMilestonesReportCardWrapper(props: any) {
  const { cardDetails } = props;

  const [openConfigureUpcomingMilestones, setOpenConfigureUpcomingMilestones] =
    useState(false);

  const upcomingMilestonesHook = useUpcomingMilestones();

  const { firstNotificationSchedule, notificationSchedules } =
    upcomingMilestonesHook;

  return (
    <>
      <ReportCardV2
        title="Procurement Follow-Ups"
        icon={<ScrollIcon size={48} />}
        onViewClick={() => {}}
        onConfigureClick={() => {
          setOpenConfigureUpcomingMilestones(true);
        }}
        detail={cardDetails.upcomingMilestones}
        status={
          <ErrorBoundary>
            <EmailAlertStatusComponent
              schedule={firstNotificationSchedule}
              isDataLoaded={
                !notificationSchedules?.loading && !notificationSchedules?.error
              }
            />
          </ErrorBoundary>
        }
      />
      <ErrorBoundary>
        <ConfigureUpcomingMilestones
          open={openConfigureUpcomingMilestones}
          setOpen={setOpenConfigureUpcomingMilestones}
          upcomingMilestonesHook={upcomingMilestonesHook}
        />
      </ErrorBoundary>
    </>
  );
}

export default UpcomingMilestonesReportCardWrapper;
