import { Button } from "antd";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-enterprise";
import {
  isPermissionNotGrantted,
  ProjectContext,
  TProjectContext
} from "context/ProjectProvider";
import { useSubscription } from "@apollo/client";
import { SUBSCRIPTION_GET_SCHEDULE_VERSIONS } from "services/graphQL/subscriptions";
import { DateFilter, DateUtils } from "utils/dateutils";
import "./project-schedule.scss";
import { ProjectPermissionEnum } from "constants/index";
import { GridLoadingIndicator } from "components/widgets";
import ImportSchedulerTasks from "components/import-schedule-tasks";

import SearchInputField from "pages/submittal-schedule-linking/search-input";
import { ScheduleTaskType } from "pages/submittal-schedule-linking/models";
import { GridOptions } from "ag-grid-community";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import ScheduleDetailspage from "./project-schedule-details";

function ProjectSchedule() {
  const location = useLocation();
  const gridRef = useRef<AgGridReact<ScheduleTaskType>>(null);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const { projectId } = useParams() as any;
  const paramSelectedVersionId = new URLSearchParams(location.search).get(
    "selectedVersionId"
  );

  const [isGridReady, setGridReady] = useState(false);
  const [scheduleVersions, setScheduleVersions] = useState<Array<any>>();
  const {
    tokenRetrievalState,
    gqlClientForProject,
    tokenContents
  }: TProjectContext = useContext(ProjectContext);
  const [showImportSchedularDrawer, setshowImportSchedularDrawer] =
    useState(false);

  const [searchValue, setSearchValue] = useState("");

  const [overlayMessage, setOverlayMessage] = useState("");
  const [selectedVersion, setSelectedVersion] = useState<any>();
  const { data: scheduleListData } = useSubscription(
    SUBSCRIPTION_GET_SCHEDULE_VERSIONS,
    {
      shouldResubscribe: true,
      client: gqlClientForProject
    }
  );

  useEffect(() => {
    if (paramSelectedVersionId && scheduleVersions) {
      const foundVersion = scheduleVersions.find(
        (ver: any) => ver.import_log_id === paramSelectedVersionId
      );

      setSelectedVersion(foundVersion);
    } else {
      setSelectedVersion(null);
    }
  }, [paramSelectedVersionId, scheduleVersions]);

  const scheduleVersionNameCellRenderer = (params: any) => {
    return (
      <div className="flex">
        {params.data.is_active && (
          <p className="absolute w-full text-right h-3 -mt-3 pr-6 text-[#3b3b3b] font-medium text-[8px]">
            Active
          </p>
        )}
        <Link
          className="gridNameLink truncate"
          to={`/project/${projectId}/schedule/project-schedule?&selectedVersionId=${params.data?.import_log_id}`}
        >
          {params?.data?.schedule_name || ""}
        </Link>
      </div>
    );
  };

  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: "Sr No.",
      valueGetter: "node.rowIndex + 1",
      headerTooltip: "SERIAL NO.",
      tooltipField: "node.rowIndex + 1",
      menuTabs: [],
      width: 80,
      sortable: true
    },
    {
      colId: "schedule_name",
      field: "schedule_name",
      headerName: "Schedule Name",
      headerTooltip: "SCHEDULE NAME",
      tooltipField: "schedule_name",
      maxWidth: 500,
      cellRenderer: scheduleVersionNameCellRenderer
    },
    {
      colId: "schedule_date",
      field: "schedule_date",
      headerName: "Schedule Date",
      headerTooltip: "SCHEDULE DATE",
      valueGetter: ({ data }: any) => {
        return data?.schedule_date
          ? DateUtils.format(data?.schedule_date, "MM-DD-YYYY")
          : "";
      },
      comparator: DateFilter.comparator,
      tooltipValueGetter: (param: any) => param.value,
      filter: true
    },
    {
      colId: "schedule_type",
      field: "schedule_type",
      headerName: "Caption",
      headerTooltip: "CAPTION",
      valueGetter: (param: any) => {
        const scheduleType = param.data?.schedule_type;
        return scheduleType !== ("undefined" || null) ? scheduleType : "";
      },
      tooltipValueGetter: (param) => param.value
    },
    {
      colId: "uploaded_by",
      field: "uploaded_by",
      headerName: "Uploaded By",
      headerTooltip: "UPLOADED BY",
      valueGetter: (param: any) => {
        const user = param.data?.import_log?.created_by_user;
        return `${user?.first_name || ""} ${user?.last_name || ""}`;
      },
      tooltipValueGetter: (param: any) => param.value
    },
    {
      colId: "created_at",
      field: "created_at",
      headerName: "Uploaded On",
      headerTooltip: "UPLOADED ON",
      valueGetter: ({ data }: any) => {
        const createdAt = data?.import_log?.created_at;
        return createdAt ? DateUtils.format(createdAt, "MM-DD-YYYY") : "";
      },
      comparator: DateFilter.comparator,
      tooltipValueGetter: (param: any) => param.value,
      filter: true,
      sort: "desc"
    }
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      autoSizeAllColumns: true,
      autoHeight: true,
      resizable: true,
      menuTabs: []
    };
  }, []);

  const gridOptions: GridOptions = {
    defaultColDef,
    suppressCopyRowsToClipboard: true
  };

  const setDrawerAction = (drawerAction: boolean) => {
    setshowImportSchedularDrawer(drawerAction);
  };

  useEffect(() => {
    if (
      !scheduleListData ||
      scheduleListData?.schedule_import_details?.length === 0
    ) {
      setOverlayMessage("Import a schedule to get started");
      setScheduleVersions([]);
      gridRef.current?.api?.showNoRowsOverlay();
    } else {
      setOverlayMessage("No rows to show.");
      const tempArr: any[] = scheduleListData?.schedule_import_details?.sort(
        (v1: any, v2: any) => (v1.is_active > v2.is_active ? -1 : 1)
      );
      setScheduleVersions(tempArr || []);
    }
  }, [scheduleListData]);

  useEffect(() => {
    function handleResize() {
      if (isGridReady && gridRef && gridRef.current) {
        gridRef?.current?.api?.sizeColumnsToFit();
      }
    }
    window.addEventListener("resize", handleResize);
  }, [isGridReady]);

  const getSearchString = (str: string) => {
    setSearchValue(str);
  };

  return (
    <div className="schedule-versions gap-y-2 h-full">
      {selectedVersion ? (
        <div className="flex w-full h-[calc(100vh-150px)]">
          <ScheduleDetailspage
            gqlClientForProject={gqlClientForProject}
            tokenContents={tokenContents}
            selectedfile={selectedVersion}
          />
        </div>
      ) : (
        <div>
          <div className="flex w-full justify-end items-center gap-2">
            <SearchInputField
              gridRef={gridRef}
              searchText={searchValue}
              getSearchText={getSearchString}
            />
            <Button
              hidden={isPermissionNotGrantted(
                ProjectPermissionEnum.ImportSchedule,
                tokenContents?.role!
              )}
              onClick={() => setshowImportSchedularDrawer(true)}
            >
              Import Schedule
            </Button>
          </div>
          <div className="flex-col h-[calc(100vh-150px)] mt-1">
            <div className="flex flex-col  h-full">
              <div style={gridStyle} className="ag-theme-alpine">
                <AgGridReact<ScheduleTaskType>
                  ref={gridRef}
                  rowData={scheduleVersions}
                  columnDefs={columnDefs}
                  gridOptions={gridOptions}
                  defaultColDef={defaultColDef}
                  animateRows
                  onGridReady={() => {
                    setGridReady(true);
                    gridRef.current?.api.sizeColumnsToFit();
                  }}
                  overlayNoRowsTemplate={overlayMessage}
                  loadingOverlayComponent={GridLoadingIndicator}
                  tooltipShowDelay={0}
                  tooltipHideDelay={2000}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {showImportSchedularDrawer && (
        <ImportSchedulerTasks
          tokenRetrievalState={tokenRetrievalState}
          setDrawerOpen={setDrawerAction}
          showImportLogDrawer={showImportSchedularDrawer}
        />
      )}
    </div>
  );
}

export default ProjectSchedule;
