/* eslint-disable no-plusplus */
/* eslint-disable default-case */
import { useState, useMemo, useEffect, useRef, useCallback } from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import { Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { ColDef, GetRowIdFunc, GetRowIdParams } from "ag-grid-community";
import { isPermissionNotGrantted } from "context/ProjectProvider";
import { GridLoadingIndicator } from "components/widgets";
import { useSubscription } from "@apollo/client";
import { SUBSCRIPTION_DISTRIBUTION_GROUP_LIST } from "services/graphQL/subscriptions";
import { ActionBar } from "components/navbar";
import SearchInput from "components/search-input";
import "ag-grid-community/styles/ag-grid.css";
import "./distribution-group.scss";
import { useParams } from "react-router";
import { dlGrpUserNameCellRenderer } from "components/cell-renders";
import { ErrorMessages, ProjectPermissionEnum } from "../../constants";
import CreateDLGroup from "./create-distribution-group";

function DistributionGroup(props: {
  gqlClientForProject: any;
  tokenContents: any;
}) {
  const { gqlClientForProject, tokenContents } = props;
  const gridRef = useRef<AgGridReact>(null);
  const [isGridReady, setGridReady] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState<any>("");
  const { projectId } = useParams() as any;
  const [groupusers, setGroupData] = useState<any>();

  const { data: distributionGroupData } = useSubscription(
    SUBSCRIPTION_DISTRIBUTION_GROUP_LIST,
    {
      shouldResubscribe: true,
      client: gqlClientForProject,
      variables: {
        where: {
          subscription: { project_users: { project_id: { _eq: projectId } } }
        }
      }
    }
  );

  useEffect(() => {
    if (distributionGroupData) {
      const grpusers = [];
      for (
        let index = 0;
        index < distributionGroupData?.distribution_group.length;
        index++
      ) {
        const group = distributionGroupData.distribution_group[index];

        if (group?.distribution_group_user?.length === 0) {
          const newuser: any = {};
          newuser.groupname = group?.name;
          newuser.grpid = group?.id;
          newuser.email = "";
          newuser.name = "";
          newuser.id = "";
          newuser.company = "";
          newuser.role = "";
          newuser.rowid = group?.id;
          grpusers.push(newuser);
        }
        for (let j = 0; j < group.distribution_group_user.length; j++) {
          const grpuser: any = group.distribution_group_user[j];
          if (grpuser) {
            const newuser: any = {};
            newuser.groupname = group?.name;
            newuser.grpid = group?.id;
            newuser.email = grpuser?.user?.email;
            newuser.name = `${grpuser?.user?.first_name} ${grpuser?.user?.last_name}`;
            newuser.id = grpuser?.user?.id;
            newuser.role = "";
            newuser.company = "";
            newuser.rowid = `${group?.id}=${grpuser?.user?.id}`;

            if (grpuser?.user?.project_users?.length > 0) {
              newuser.status_id = grpuser?.user?.project_users[0]?.status_id;
              newuser.role = grpuser?.user?.project_users[0].project_role?.name;
              if (grpuser?.user?.project_users[0]?.subscription) {
                const arr: any[] =
                  grpuser?.user?.project_users[0]?.subscription
                    .organization_subscriptions;
                newuser.company =
                  !arr || arr.length === 0 ? "" : arr[0].organization?.name;
              } else if (grpuser?.user?.project_users[0].subscription_vendor) {
                newuser.company =
                  grpuser?.user?.project_users[0]?.subscription_vendor?.name;
              }
            }
            grpusers.push(newuser);
          }
        }
      }
      setGroupData(grpusers);
    }
  }, [distributionGroupData]);

  const showModal = () => {
    setSelectedGroupId(null);
    setOpen(true);
  };

  const hideModal = () => {
    setSelectedGroupId(null);
    setOpen(false);
  };

  function editComponent(params: any) {
    if (!params?.node?.group) return "";
    return (
      <div className="pencil-icon" style={{ fontSize: "16px" }}>
        <EditOutlined />
      </div>
    );
  }

  function groupNameRenderer(params: any) {
    const { context, value } = params;

    if (context.groups.length === 0) return "";

    const group = context.groups.find((grp: any) => {
      return grp.id === value;
    });

    return <span>{group?.name || ""}</span>;
  }

  const gridContext = {
    groups: distributionGroupData?.distribution_group || []
  };

  // Each Column Definition results in one Column.
  const columnDefs = useMemo<ColDef[]>(() => {
    return [
      {
        headerName: "Distribution Group",
        field: "grpid",
        colId: "grpid",
        cellRenderer: groupNameRenderer,
        rowGroup: true,
        hide: true,
        menuTabs: [],
        sort: "asc"
      },
      {
        headerName: "Name",
        headerTooltip: "NAME",
        tooltipField: "name",
        field: "name",
        colId: "name",
        cellRenderer: dlGrpUserNameCellRenderer,
        menuTabs: []
      },
      {
        headerName: "Email",
        headerTooltip: "EMAIL",
        tooltipField: "email",
        field: "email",
        colId: "email",
        menuTabs: []
      },
      {
        headerName: "Company",
        headerTooltip: "COMPANY",
        tooltipField: "company",
        field: "company",
        colId: "company",
        menuTabs: []
      },
      {
        headerName: "Role",
        headerTooltip: "ROLE",
        tooltipField: "role",
        field: "role",
        colId: "role",
        menuTabs: []
      },
      {
        colId: "edit_pencil",
        headerName: "",
        menuTabs: [],
        resizable: true,
        maxWidth: 100,
        cellRenderer: editComponent,
        hide: isPermissionNotGrantted(
          ProjectPermissionEnum.EditDistributionGroup,
          tokenContents?.role!
        )
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributionGroupData]);

  const defaultColDef: {} = useMemo<ColDef>(() => {
    return {
      sortable: true,
      autoSizeAllColumns: true,
      autoHeight: true,
      resizable: true,
      headerClass: "header-style"
    };
  }, []);

  const autoGroupColumnDef: {} = useMemo<ColDef>(() => {
    return {
      headerName: "Distribution Group",
      headerTooltip: "DISTRIBUTION GROUP",
      tooltipValueGetter: (params) => {
        return params.value;
      },
      menuTabs: [],
      cellRendererParams: {
        suppressCount: true
      }
    };
  }, []);

  const onFilterTextBoxChanged = useCallback(
    (value: string) => {
      gridRef?.current?.api?.setQuickFilter(value);
    },
    [gridRef]
  );

  useEffect(() => {
    function handleResize() {
      if (isGridReady && gridRef && gridRef.current) {
        gridRef?.current?.api?.sizeColumnsToFit();
      }
    }
    window.addEventListener("resize", handleResize);
  }, [isGridReady]);

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams) => {
      return params?.data?.rowid || "";
    };
  }, []);

  return (
    <div className="flex flex-col h-[calc(100vh-205px)] distribution-group">
      <ActionBar noXPadding>
        <div className="flex w-full justify-end space-x-4 items-center">
          <SearchInput
            placeholder="Search Groups"
            onChange={onFilterTextBoxChanged}
          />
          <Button
            onClick={showModal}
            title={
              isPermissionNotGrantted(
                ProjectPermissionEnum.CreateDistributionGroup,
                tokenContents?.role!
              )
                ? ErrorMessages.PermissionNotGranted
                : "Create Distribution Group"
            }
            disabled={isPermissionNotGrantted(
              ProjectPermissionEnum.CreateDistributionGroup,
              tokenContents?.role!
            )}
          >
            Create Distribution Group
          </Button>
        </div>
      </ActionBar>

      <div className="grow ag-theme-alpine">
        <AgGridReact<any>
          ref={gridRef}
          onGridReady={() => {
            setGridReady(true);
            gridRef?.current?.api?.sizeColumnsToFit();
          }}
          rowData={groupusers}
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          autoGroupColumnDef={autoGroupColumnDef}
          animateRows // Optional - set to 'true' to have rows animate when sorted
          loadingOverlayComponent={GridLoadingIndicator}
          suppressDragLeaveHidesColumns
          onCellClicked={(e) => {
            if (e.column.getColId() === "edit_pencil" && e.node.group) {
              setSelectedGroupId(e.data?.grpid || "");
              if (!e.data) {
                setSelectedGroupId(e.node?.key || "");
              }
              setOpen(true);
            }
          }}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          getRowId={getRowId}
          context={gridContext}
        />
      </div>
      {open && (
        <CreateDLGroup
          selectedGroupId={selectedGroupId}
          setDrawerOpen={hideModal}
          showDrawerOpen={open}
          modelTitle={
            selectedGroupId
              ? "Edit Distribution Group"
              : "Create Distribution Group"
          }
        />
      )}
    </div>
  );
}

export default DistributionGroup;
