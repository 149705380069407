import { GET_MATERIAL_BY_IDS_FOR_REQUEST_DURATION } from "services/graphQL/ciq-queries";
import { ProjectContext } from "context/ProjectProvider";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { useContext, useMemo } from "react";
import { AcceptanceStatus, EmailInviteStatus } from "../../../constants";
import { IMaterial, IMaterialGroup } from "../type-definitions";

function filterMaterials(materials: IMaterial[]): {
  WFStartedItems: IMaterial[];
  requestAlreadySentItems: IMaterial[];
  allowedToSendRequestItems: IMaterial[];
  inactiveUserItems: IMaterial[];
} {
  const WFStartedItems = materials.filter((material) => {
    return (
      material.date_block_materials[0].workflow_status !== "WF_NOT_STARTED"
    );
  });
  const requestAlreadySentItems = materials.filter((material) => {
    return (
      material.estimate_invite_features[0]?.estimated_invite.delivery_status ===
      EmailInviteStatus.REQUEST_SENT
    );
  });

  const allowedToSendRequestItems = materials.filter((material) => {
    return (
      material.date_block_materials[0].workflow_status === "WF_NOT_STARTED" &&
      material.estimate_invite_features[0]?.estimated_invite.delivery_status !==
        EmailInviteStatus.REQUEST_SENT
    );
  });
  const inactiveUserItems = materials.filter((material) => {
    return (
      material.material_assignee?.project_users?.[0]?.status?.id ===
      AcceptanceStatus.DEACTIVATED
    );
  });
  return {
    WFStartedItems,
    requestAlreadySentItems,
    allowedToSendRequestItems,
    inactiveUserItems
  };
}

function groupMaterials(materials: IMaterial[]): {
  assignedGroups: IMaterialGroup[];
  unregisteredGroup: IMaterialGroup | null;
  unassignedGroup: IMaterialGroup | null;
  inactiveUserGroup: IMaterialGroup | null;
} {
  const groupMap = new Map<string, Map<string, IMaterial[]>>();

  const unassignedMaterials: IMaterial[] = [];
  const unregisteredMaterials: IMaterial[] = [];
  const inactiveUserMaterials: IMaterial[] = [];

  materials.forEach((material) => {
    // Handle completely unassigned materials
    if (
      !material.assignee &&
      (!material.assignee_unregistered ||
        material.assignee_unregistered.trim() === "")
    ) {
      unassignedMaterials.push(material);
      return;
    }

    // Handle materials with unregistered assignees
    if (
      !material.assignee &&
      material.assignee_unregistered &&
      material.assignee_unregistered.trim() !== ""
    ) {
      unregisteredMaterials.push(material);
      return;
    }

    if (
      material.assignee &&
      material.material_assignee?.project_users?.[0]?.status?.id ===
        AcceptanceStatus.DEACTIVATED
    ) {
      inactiveUserMaterials.push(material);
      return;
    }

    // Handle materials with registered assignees
    if (
      material.assignee &&
      material.material_assignee?.project_users?.[0]?.status?.id !==
        AcceptanceStatus.DEACTIVATED
    ) {
      const companyId = material.materials_trade_partner?.id || "no_company";
      if (!groupMap.has(companyId)) {
        groupMap.set(companyId, new Map());
      }

      const companyGroups = groupMap.get(companyId)!;
      const assigneeKey = material.assignee;

      if (!companyGroups.has(assigneeKey)) {
        companyGroups.set(assigneeKey, []);
      }

      companyGroups.get(assigneeKey)!.push(material);
    }
  });

  // Transform the map into separate structures
  const assignedGroups: IMaterialGroup[] = [];

  // Add company-based groups for assigned users
  groupMap.forEach((companyGroups) => {
    companyGroups.forEach((m) => {
      assignedGroups.push({
        groupType: "assignee_actual",
        groupId: m[0].material_assignee?.id!,
        subcontractorCompany: m[0]?.materials_trade_partner
          ? {
              name: m[0].materials_trade_partner.name,
              id: m[0].materials_trade_partner.id
            }
          : null,
        materials: m,
        assigneeUser: m[0].material_assignee,
        materialsByStatus: filterMaterials(m)
      });
    });
  });

  // Create unregistered group if there are any materials
  const unregisteredGroup =
    unregisteredMaterials.length > 0
      ? {
          groupType: "assignee_unregistered" as const,
          groupId: "unregistered",
          subcontractorCompany: null,
          materials: unregisteredMaterials,
          assigneeUser: null,
          materialsByStatus: filterMaterials(unregisteredMaterials)
        }
      : null;

  // Create unassigned group if there are any materials
  const unassignedGroup =
    unassignedMaterials.length > 0
      ? {
          groupType: "unassigned" as const,
          groupId: "unassigned",
          subcontractorCompany: null,
          materials: unassignedMaterials,
          assigneeUser: null,
          materialsByStatus: filterMaterials(unassignedMaterials)
        }
      : null;

  const inactiveUserGroup =
    inactiveUserMaterials.length > 0
      ? {
          groupType: "inactive_user" as const,
          groupId: "inactive_user",
          subcontractorCompany: null,
          materials: inactiveUserMaterials,
          assigneeUser: null,
          materialsByStatus: filterMaterials(inactiveUserMaterials)
        }
      : null;

  // Sort assigned groups
  assignedGroups.sort((a, b) => {
    const companyA = a.subcontractorCompany?.name || "";
    const companyB = b.subcontractorCompany?.name || "";

    const companyComparison = companyA.localeCompare(companyB);
    if (companyComparison !== 0) {
      return companyComparison;
    }

    const assigneeA = a.assigneeUser?.first_name || "";
    const assigneeB = b.assigneeUser?.first_name || "";
    return assigneeA.localeCompare(assigneeB);
  });

  return {
    assignedGroups,
    unregisteredGroup,
    unassignedGroup,
    inactiveUserGroup
  };
}

function useRequestDurationsMulti(selectedItems: IMaterial[]) {
  const { gqlClientForProject } = useContext(ProjectContext);

  const { data, loading } = useCIQQuery<{
    material: IMaterial[];
  }>(GET_MATERIAL_BY_IDS_FOR_REQUEST_DURATION, {
    client: gqlClientForProject,
    variables: {
      materialIds: selectedItems.map((item) => item.id)
    }
  });

  const structuredData = useMemo(() => {
    if (!data) return null;

    const groupedMaterials = groupMaterials(data.material);

    return { groupedMaterials };
  }, [data]);

  return {
    data: structuredData ? { ...structuredData } : null,
    loading
  };
}

export default useRequestDurationsMulti;
