import "./index.scss";
import { useContext, useEffect, useState } from "react";
import { ProjectContext } from "context/ProjectProvider";
import { getActivitiesModifiedCount, getDashBoardData } from "services/auth";
import { useHistory, useParams } from "react-router";
import { AppContext } from "context/AppProvider";
import { Button } from "antd";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { client } from "services/axios";
import { BASE_URL } from "config/config";
import ActionItems from "./action-items";
import RiskUpdateComponent from "./risk-update";
import AlertDashboard from "./alert/alert-dashboard";
import PlanningDataQualityReport from "./data-quality-report";
import { FilterNamesEnum, GcDashBoardType, NavigationPageEnum } from "./models";

function GCProjectDashboard2() {
  const history = useHistory();
  const { projectId } = useParams() as any;
  const {
    tokenRetrievalState,
    projectDetails,
    projectToken,
    isFeatureFlagEnabled
  } = useContext(ProjectContext);
  const isSupersetDashboardEnabled = isFeatureFlagEnabled("SUPERSET_DASHBOARD");
  const {
    setSubmittalListFilter,
    setMaterialListFilter,
    setBidPackageListFilter
  } = useContext(AppContext);
  const [dashboardData, setDashboardData] = useState<GcDashBoardType>();
  const [activitiesModifiedCount, setactivitiesModifiedCount] = useState();
  const [showOverviewDashboard, setShowOverviewDashboard] = useState(true);
  const [showAnalyticsDashboard, setShowAnalyticsDashboard] = useState(false);

  const goToFiltered = (
    page: NavigationPageEnum,
    filter: FilterNamesEnum,
    value = undefined
  ) => {
    switch (page) {
      case NavigationPageEnum.MaterialLog:
        setMaterialListFilter(filter.toString());
        history.push("./materials");
        break;
      case NavigationPageEnum.SubmittalLog:
        setSubmittalListFilter({ key: filter.toString(), value });
        history.push("./submittals");
        break;
      case NavigationPageEnum.BidPackageLog:
        setBidPackageListFilter({ key: filter.toString(), value });
        history.push("./bid-packages");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const getDashBoardFuc = async () => {
      const response = await getDashBoardData(tokenRetrievalState.token, {});
      if (response.success) {
        const { data } = response;
        setDashboardData(data.success);
      } else {
        console.log(response.error);
      }
    };

    const getActivitiesModified = async () => {
      const response = await getActivitiesModifiedCount(
        tokenRetrievalState.token,
        {}
      );
      if (response.success) {
        const { data } = response;
        setactivitiesModifiedCount(data.success);
      } else {
        console.log(response.error);
      }
    };

    if (tokenRetrievalState && tokenRetrievalState.token) {
      getDashBoardFuc();
      getActivitiesModified();
    }
  }, [tokenRetrievalState]);

  useEffect(() => {
    const postData = async () => {
      try {
        const container = document.getElementById("my-superset-container");
        if (!container) {
          console.error("Dashboard container not found.");
          return;
        }

        const tokens = await client.post(
          `${BASE_URL}/superset/dashboard`,
          {},
          {
            headers: {
              Authorization: `Bearer ${projectToken}`,
              "Content-Type": "application/json"
            }
          }
        );

        embedDashboard({
          id: tokens.data.dashboard_id,
          supersetDomain: "https://dev-superset.constructiviq.com",
          mountPoint: container,
          fetchGuestToken: () => Promise.resolve(tokens.data.token),
          dashboardUiConfig: {
            hideTitle: true,
            hideChartControls: false,
            hideTab: true,
            filters: {
              visible: false,
              expanded: false,
              allowEdit: false
            }
          }
        });

        const iframe = document.querySelector("iframe");
        if (iframe) {
          iframe.style.width = "100%";
          iframe.style.height = "89vh";
          iframe.style.border = "none";
          iframe.style.overflow = "hidden";
        }
      } catch (error) {
        console.error("Error posting data:", error);
        throw error;
      }
    };

    if (!showAnalyticsDashboard) return;
    postData();
  }, [showAnalyticsDashboard, projectToken]);

  const handleOverviewDashboard = () => {
    setShowOverviewDashboard(true);
    setShowAnalyticsDashboard(false);
  };

  const handleAnalyticsDashboard = () => {
    setShowOverviewDashboard(false);
    setShowAnalyticsDashboard(true);
  };

  return (
    <div className="flex flex-col h-full gc-project-dashboard">
      <div className="w-full py-2 px-6 uppercase font-medium container">
        <Button
          onClick={handleOverviewDashboard}
          type="link"
          className="project-dashboard-button"
          style={{
            fontWeight: showOverviewDashboard ? "bold" : "normal"
          }}
        >
          Project Dashboard
        </Button>
        {isSupersetDashboardEnabled && (
          <Button
            onClick={handleAnalyticsDashboard}
            type="link"
            className="analytics-dashboard-button"
            style={{
              fontWeight: showAnalyticsDashboard ? "bold" : "normal"
            }}
          >
            Analytics Dashboard
          </Button>
        )}
      </div>
      {showOverviewDashboard && (
        <div className="w-full bg-white flex px-5 py-2 hideScroll">
          <div className="h-full grow pr-5">
            <ActionItems
              projectId={projectId}
              dashboardData={dashboardData}
              projectDetails={projectDetails}
              goToFiltered={goToFiltered}
            />
            <RiskUpdateComponent
              dashboardData={dashboardData}
              goToFiltered={goToFiltered}
            />
          </div>
          <div className="h-full w-[500px]">
            <div className="pt-11">
              <AlertDashboard
                projectId={projectId}
                activities_modified={activitiesModifiedCount}
                alerts={dashboardData?.alerts}
                goToFiltered={goToFiltered}
              />
            </div>
            <div className="pt-14">
              <PlanningDataQualityReport
                dashboardData={dashboardData}
                goToFiltered={goToFiltered}
              />
            </div>
          </div>
        </div>
      )}
      {showAnalyticsDashboard && (
        <div
          id="my-superset-container"
          style={{
            width: "100%",
            height: "89vh",
            border: "none"
          }}
        />
      )}
    </div>
  );
}
export default GCProjectDashboard2;
