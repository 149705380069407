import { ColDef, Column, ColumnApi, GridApi } from "ag-grid-community";
import { DateFilter, DateUtils } from "utils/dateutils";

type MaxMileStoneInfo = {
  numberOfMaxMileStones: number;
  numberOfMaxOffsets: number;
};
type TColIds = {
  colId: string;
  children: {
    index: number;
    colIds: string[];
  }[];
};

const getCurrentMilestone = (
  data: { milestones: Array<any> },
  sequenceNo: number
) => {
  return data.milestones.find((m) => m.sequence_no === sequenceNo);
};

const getCurrentDuration = (
  data: { milestones: Array<any>; offsets: Array<any> },
  sequenceNo: number
) => {
  const currentMilestoneID = getCurrentMilestone(data, sequenceNo)?.id;
  return data.offsets.find(
    (o) => o.from_milestone_instance_id === currentMilestoneID
  );
};

export function EntityMilestoneAndDurationColumns() {
  const groupColInitData = {
    width: 0,
    maxWidth: 0,
    headerClass: "!p-0 !w-0",
    filter: false,
    editable: false,
    menuTabs: [],
    suppressMovable: true,
    getQuickFilterText: () => "",
    hide: true
  };

  const colInitData = {
    hide: true,
    suppressColumnsToolPanel: true,
    suppressMovable: true,
    menuTabs: ["filterMenuTab"],
    editable: false,
    width: 180,
    keyCreator: ({ value }: any) => value,
    getQuickFilterText: () => ""
  };
  const totalPossibleMileStones = 10;

  const milestoneHead = {
    ...groupColInitData,
    headerName: "MILESTONES",
    colId: "_milestones"
  };
  const durationHead = {
    ...groupColInitData,
    headerName: "DURATIONS",
    colId: "_durations"
  };
  const allColumns = [milestoneHead, durationHead] as ColDef[];

  const milestoneChildColIds = {
    colId: milestoneHead.colId,
    children: []
  } as TColIds;
  const durationChildColIds = {
    colId: durationHead.colId,
    children: []
  } as TColIds;

  const columnIds = [milestoneChildColIds, durationChildColIds];

  for (let index = 1; index <= totalPossibleMileStones; index = 1 + index) {
    const mColId1 = `m1_${index}`;
    const mColId2 = `m2_${index}`;
    const mColId3 = `m3_${index}`;
    milestoneChildColIds.children.push({
      index,
      colIds: [mColId1, mColId2, mColId3]
    });

    const dColId1 = `du1_${index}`;
    const dColId2 = `du2_${index}`;
    durationChildColIds.children.push({
      index,
      colIds: [dColId1, dColId2]
    });

    allColumns.push({
      ...colInitData,
      colId: mColId1,
      headerName: `Milestone  ${index}`,
      valueGetter: ({ data }: any) => {
        const currentMilestone = getCurrentMilestone(data, index);
        return currentMilestone ? currentMilestone.name : undefined;
      }
    });
    allColumns.push({
      ...colInitData,
      colId: mColId2,
      headerName: `Planned Date  ${index}`,
      valueGetter: ({ data }: any) => {
        const currentMilestone = getCurrentMilestone(data, index);
        return currentMilestone?.planned_date
          ? DateUtils.format(currentMilestone.planned_date)
          : undefined;
      },
      comparator: DateFilter.comparator
    });
    allColumns.push({
      ...colInitData,
      colId: mColId3,
      headerName: `Actual Date  ${index}`,
      valueGetter: ({ data }: any) => {
        const currentMilestone = getCurrentMilestone(data, index);
        return currentMilestone?.actual_date
          ? DateUtils.format(currentMilestone.actual_date)
          : undefined;
      },
      comparator: DateFilter.comparator
    });
    allColumns.push({
      ...colInitData,
      colId: dColId1,
      headerName: `Duration Name ${index}`,
      valueGetter: ({ data }: any) => {
        const currentDuration = getCurrentDuration(data, index);
        return currentDuration ? currentDuration.name : undefined;
      }
    });
    allColumns.push({
      ...colInitData,
      colId: dColId2,
      headerName: `Duration ${index}`,
      valueGetter: ({ data }: any) => {
        const currentDuration = getCurrentDuration(data, index);
        return currentDuration ? currentDuration.duration : undefined;
      }
    });
  }

  type Props = {
    column: Column | null;
    columnApi: ColumnApi;
  };

  const onColumnVisible = (e: Props, milestoneInfo: MaxMileStoneInfo) => {
    const { column, columnApi } = e;
    const colId = column?.getColId();
    const extraColumn = columnIds.find((c) => c.colId === colId);
    if (!extraColumn) return;

    const isVisible = column?.isVisible() || false;
    const colIdsColumns = [] as string[];
    const hideColumnIds = [] as string[];
    let count = milestoneInfo.numberOfMaxMileStones;
    if (extraColumn.colId === "_durations") {
      count = milestoneInfo.numberOfMaxOffsets;
    }

    extraColumn.children.forEach((c) => {
      if (count >= c.index) {
        c.colIds.forEach((id) => colIdsColumns.push(id));
      } else {
        c.colIds.forEach((id) => hideColumnIds.push(id));
      }
    });

    columnApi.setColumnsVisible(colIdsColumns, isVisible);
    columnApi.setColumnsVisible(hideColumnIds, false);
  };

  const updateAllMileStoneInitialVisibility = (
    columnApi: ColumnApi,
    milestoneInfo: MaxMileStoneInfo
  ) => {
    if (!columnApi) return;

    const colIds = columnIds.map((x) => x.colId);
    colIds.forEach((colId) => {
      const column = columnApi.getColumn(colId);
      if (column) {
        onColumnVisible(
          {
            column,
            columnApi
          },
          milestoneInfo
        );
      }
    });
  };

  return { allColumns, onColumnVisible, updateAllMileStoneInitialVisibility };
}

export const getMaxNumberOfMilestone = (props: {
  api: GridApi<any> | undefined;
}) => {
  const { api } = props;

  if (!api) return null;
  const count = api?.getDisplayedRowCount();

  let maxMilestoneCount = 0;
  let maxOffsetCount = 0;
  for (let index = 0; index < count; index = 1 + index) {
    const element = api?.getDisplayedRowAtIndex(index);
    if (element && element.data) {
      if (element.data.milestones?.length > maxMilestoneCount) {
        maxMilestoneCount = element.data.milestones.length;
      }
      if (element.data.offsets?.length > maxOffsetCount) {
        maxOffsetCount = element.data.offsets.length;
      }
    }
  }

  return {
    numberOfMaxOffsets: maxOffsetCount,
    numberOfMaxMileStones: maxMilestoneCount
  };
};
