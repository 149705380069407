import { Button, Divider, Form, Modal, Select, message } from "antd";
import FormItem from "antd/es/form/FormItem";
import { SUBSCRIPTION_COMPANY_LIST } from "services/graphQL/subscriptions";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { QUERY_USER_TYPE } from "services/graphQL/queries";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { MUTATION_INSERT_COMPANY_TO_PROJECT } from "services/graphQL/mutations";
import { ProjectContext } from "context/ProjectProvider";
import { useParams } from "react-router";
import { v4 as uuidV4 } from "uuid";
import ClearIcon from "components/svg-icons/clear-icon";
import NewCompanyForm, {
  NewCompanyFormRef
} from "components/new-company-form/new-company-form";
import { CloseOutlined } from "@ant-design/icons";
import {
  RoleSubscriptionEnum,
  SubscriptionContext
} from "context/SubscriptionProvider";
import { EUserTypes, ErrorMessages } from "../../constants";

type Props = {
  showAddProjectCompanyModel: boolean;
  setDrawerVisibility: React.Dispatch<React.SetStateAction<boolean>>;
};

function CustomDropdownRender({
  menu,
  onAddNewCompany,
  showAddNewCompanyButton
}: {
  menu: React.ReactNode;
  onAddNewCompany: () => void;
  showAddNewCompanyButton: boolean;
}) {
  return (
    <>
      {menu}
      {showAddNewCompanyButton && (
        <>
          <Divider style={{ margin: "8px 0" }} />
          <div className="w-full px-1">
            <Button
              className="rounded-none bg-[#3b3b3b3b] hover:bg-[#3b3b3b5c] w-full"
              onClick={onAddNewCompany}
            >
              Add New Company
            </Button>
          </div>
        </>
      )}
    </>
  );
}

function InviteProjectCompanies(props: Props) {
  const { setDrawerVisibility, showAddProjectCompanyModel } = props;
  const { projectId } = useParams() as any;

  const { gqlClientForProject } = useContext(ProjectContext);
  const { subscriptionRole } = useContext(SubscriptionContext);
  const { data: companyData, loading: companyDataLoading } = useSubscription(
    SUBSCRIPTION_COMPANY_LIST,
    {
      variables: {
        where: {
          _not: { project_vendors: { project_id: { _eq: projectId } } }
        }
      },
      shouldResubscribe: true,
      client: gqlClientForProject,
      skip: !gqlClientForProject
    }
  );

  const { data: companyTypes, loading: loadingUserType } =
    useQuery(QUERY_USER_TYPE);

  const [addCompanyToProject] = useMutation<any>(
    MUTATION_INSERT_COMPANY_TO_PROJECT,
    {
      client: gqlClientForProject
    }
  );

  const [form] = Form.useForm();
  const companiesAddded = Form.useWatch("companies", form);
  const addCompanyFormRef = useRef<NewCompanyFormRef>(null);

  const [responseLoading, setResponseLoading] = useState(false);
  const [showAddNewCompanyFormState, setShowAddNewCompanyFormState] = useState<{
    isOpen: boolean;
    openedForIndex: number | null;
  }>({
    isOpen: false,
    openedForIndex: null
  });

  const permCanAddNewCompanyAtSubscriptionLevel = useMemo(() => {
    return subscriptionRole > RoleSubscriptionEnum.subscription_viewer;
  }, [subscriptionRole]);

  const onSendInvite = async ({ companies }: { companies: Array<any> }) => {
    setResponseLoading(true);
    const companiesList = companies.map(({ name, company_type }: any) => {
      return { name, company_type };
    });
    const companyResponse = await addCompanyToProject({
      variables: { companyData: companiesList, projectId }
    });
    setResponseLoading(false);
    if (companyResponse.data) {
      message.success(ErrorMessages.CompanyAddedSuccessMessage);
      setDrawerVisibility(false);
    } else {
      message.error(ErrorMessages.CompanyAddedFailureMessage);
      console.error(companyResponse.errors);
    }
  };

  const filteredCompanyTypes = useMemo(() => {
    return companyTypes?.user_types?.filter((companyType: any) => {
      if (EUserTypes[companyType.id]) return true;
      return false;
    });
  }, [companyTypes]);

  const onNewCompany = (data: any) => {
    if (data?.insert_subscription_vendors_one?.name) {
      form.setFieldsValue({
        companies: form
          .getFieldValue("companies")
          .map((item: any, index: number) => {
            if (index === showAddNewCompanyFormState.openedForIndex) {
              return {
                ...item,
                name: data.insert_subscription_vendors_one.name
              };
            }
            return item;
          })
      });

      setShowAddNewCompanyFormState({
        isOpen: false,
        openedForIndex: null
      });
    }
  };

  const handleDropdownRender = useCallback(
    (menu: React.ReactNode, index: number) => (
      <CustomDropdownRender
        menu={menu}
        onAddNewCompany={() => {
          setShowAddNewCompanyFormState({
            isOpen: true,
            openedForIndex: index
          });
          addCompanyFormRef.current?.focus();
          setTimeout(() => {
            if (addCompanyFormRef.current)
              addCompanyFormRef.current.scrollIntoView();
          }, 100);
        }}
        showAddNewCompanyButton={permCanAddNewCompanyAtSubscriptionLevel}
      />
    ),
    [permCanAddNewCompanyAtSubscriptionLevel]
  );

  return (
    <Modal
      className="custom-drawer with-body-scroll"
      title="Add Company"
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ minHeight: "calc(100vh - 92px)" }}
      footer={null}
      open={showAddProjectCompanyModel}
      onCancel={() => {
        setDrawerVisibility(false);
      }}
      destroyOnClose
    >
      <div className="body-content-wrapper">
        <Form
          className="overflow-y-auto"
          preserve={false}
          form={form}
          scrollToFirstError
          layout="vertical"
          onFinish={onSendInvite}
          initialValues={{
            companies: [{ key: uuidV4() }]
          }}
          disabled={showAddNewCompanyFormState.isOpen}
        >
          <Form.List name="companies">
            {(fields, { add, remove }) => (
              <div data-testid="company-list">
                {fields.map((field, index) => {
                  const companyNameKey = `company-field-${field.key}`;
                  const companyTypeKey = `company-type-field-${field.key}`;
                  return (
                    <div
                      key={field.key}
                      className="flex justify-between space-x-1 px-2"
                    >
                      <FormItem
                        {...field}
                        name={[field.name, "name"]}
                        label="Company Name"
                        key={companyNameKey}
                        rules={[
                          {
                            required: true,
                            message: ErrorMessages.CompanyNameReqd
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value) return Promise.resolve();
                              const fieldValues = getFieldValue("companies");
                              if (
                                fieldValues.filter((d: any) => d.name === value)
                                  .length !== 1
                              ) {
                                return Promise.reject(
                                  new Error("Duplicate values are not allowed.")
                                );
                              }
                              return Promise.resolve();
                            }
                          })
                        ]}
                        className="w-full"
                        data-testid="company-name-form-item"
                      >
                        <Select
                          className={
                            fields.length > 1
                              ? "max-w-[230px] text-ellipsis"
                              : "max-w-[245px] text-ellipsis"
                          }
                          showSearch
                          placeholder="Search or select a company"
                          loading={companyDataLoading}
                          filterOption={(input, option: any) =>
                            option.value
                              .toLowerCase()
                              .includes(input.toString().toLowerCase())
                          }
                          virtual={false}
                          options={
                            companyData
                              ? companyData.subscription_vendors.map(
                                  (c: any) => {
                                    return {
                                      label: c.name,
                                      value: c.name
                                    };
                                  }
                                )
                              : []
                          }
                          dropdownRender={(menu) =>
                            handleDropdownRender(menu, index)
                          }
                          data-testid="company-name-select"
                        />
                      </FormItem>
                      <FormItem
                        {...field}
                        key={companyTypeKey}
                        className="w-full"
                        name={[field.name, "company_type"]}
                        label="Role of Company"
                        data-testid="company-type-form-item"
                      >
                        <Select
                          loading={loadingUserType}
                          className="w-full"
                          options={
                            companyTypes && filteredCompanyTypes
                              ? filteredCompanyTypes.map((type: any) => {
                                  return {
                                    label: type.name,
                                    value: type.id
                                  };
                                })
                              : []
                          }
                          data-testid="company-type-select"
                        />
                      </FormItem>
                      {form.getFieldValue("companies").length > 1 && (
                        <Button
                          icon={<ClearIcon />}
                          disabled={form.getFieldValue("companies").length <= 1}
                          className="h-[20px] w-[20px]  border-none mt-6"
                          onClick={() => remove(field.name)}
                          data-testid="remove-company-button"
                        />
                      )}
                    </div>
                  );
                })}
                <Form.Item data-testid="add-more-form-item">
                  <div className="flex flex-col items-end">
                    <Button
                      data-testid="add-more-button"
                      className="add-more-button mr-2"
                      disabled={responseLoading}
                      onClick={() => {
                        add({ key: uuidV4() });
                        setShowAddNewCompanyFormState({
                          isOpen: false,
                          openedForIndex: null
                        });
                      }}
                    >
                      Add More
                    </Button>
                    <Divider className="" />
                    <Button
                      className="add-companies-btn mr-2"
                      loading={responseLoading}
                      disabled={responseLoading}
                      htmlType="submit"
                      type="primary"
                      data-testid="add-companies-button"
                    >
                      {companiesAddded?.length > 1
                        ? "Add Companies"
                        : "Add Company"}
                    </Button>
                  </div>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Form>
        {showAddNewCompanyFormState.isOpen && (
          <div className="mt-2" data-testid="add-new-company-form-section">
            <div className="bg-[#F7F6F4] py-3 px-3 border-0 border-b border-solid border-b-[#3B3B3B4D] mb-3 text-base text-[#3B3B3BE5] flex justify-between items-center">
              <span>New Company</span>
              <Button
                type="text"
                onClick={() => {
                  setShowAddNewCompanyFormState({
                    isOpen: false,
                    openedForIndex: null
                  });
                }}
                className="border-none p-0"
                size="small"
              >
                <CloseOutlined style={{ color: "gray", fontSize: "16px" }} />
              </Button>
            </div>
            <NewCompanyForm
              ref={addCompanyFormRef}
              onNewCompany={onNewCompany}
            />
          </div>
        )}
      </div>
    </Modal>
  );
}
export default InviteProjectCompanies;
