import moment, { Moment } from "moment";

interface IDateUtils {
  dateTimeObj(
    date?: TDateTimeObj | string | Date | number | null,
    format?: string
  ): moment.Moment;
  format(date: any, format?: string | { in?: string; out?: string }): string;
  formatDateWithLunchTime(date: any, inFormat?: string): string;
}

export const ISO_DATE_FORMAT_1 = "YYYY-MM-DDTHH:mm:ssZZ";
export const DATE_FORMAT_MMDDYYYY = "MM-DD-YYYY";
export const DATE_FORMAT_YYYYMMDD = "YYYY-MM-DD";
export const DATE_FORMAT_MMDDYYYY_HHMM_A = "MM-DD-YYYY hh:mm A";
export const DATE_FORMAT_MMDDYYYY_HHMM_A_Z = "MM-DD-YYYY hh:mm A (z)";
export const DATE_FORMAT_MMDDYYYY_HHMM_Z = "DD-MMM-YYYY HH:mm:ss Z";
export const INPUT_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss.sssZ";

export type TDateTimeObj = Moment;
export type AmPmType = "AM" | "PM" | "am" | "pm";

export const DateUtils: IDateUtils = {
  dateTimeObj(date?: TDateTimeObj | string | Date | number, format?: string) {
    if (!date) return moment();
    if (moment.isMoment(date) || date instanceof Date) return moment(date);
    if (typeof date === "string") {
      return moment(
        date,
        format || [
          "MM-DD-YYYY",
          "YYYY-MM-DD",
          "YYYY-MM-DDTHH:mm:ssZZ",
          "YYYY-MM-DD HH:mm"
        ]
      );
    }
    return moment(date);
  },

  format(date: any, format?: string | { in?: string; out?: string }) {
    if (!date) return "";
    if (format && typeof format === "object") {
      return moment(date, format.in).format(format.out || DATE_FORMAT_MMDDYYYY);
    }

    return moment(date).format(format || DATE_FORMAT_MMDDYYYY);
  },

  formatDateWithLunchTime(date: any, inFormat?: string) {
    if (!date) return "";
    try {
      const formattedDate = moment(
        date,
        inFormat || ["MM-DD-YYYY", "YYYY-MM-DD"]
      ).format(DATE_FORMAT_YYYYMMDD);
      return `${formattedDate}T13:23:24+00:00`;
    } catch (ex) {
      return "";
    }
  }
};

export const DateFilter = {
  comparator: (value1: string | null, value2: string | null): number => {
    if (!value1 && !value2) {
      return 0;
    }

    if (value1 && !value2) {
      return -1;
    }

    if (!value1 && value2) {
      return 1;
    }

    const date1 = value1
      ? DateUtils.dateTimeObj(value1, DATE_FORMAT_MMDDYYYY)
      : null;
    const date2 = value2
      ? DateUtils.dateTimeObj(value2, DATE_FORMAT_MMDDYYYY)
      : null;

    if (date1?.isSame(date2)) {
      return 0;
    }
    return date1 && date2 && date1.isAfter(date2) ? 1 : -1;
  },

  customComparator: (
    value1: string | null,
    value2: string | null,
    format: string = DATE_FORMAT_MMDDYYYY
  ): number => {
    if (!value1 && !value2) {
      return 0;
    }

    if (value1 && !value2) {
      return -1;
    }

    if (!value1 && value2) {
      return 1;
    }

    const date1 = value1 ? DateUtils.dateTimeObj(value1, format) : null;
    const date2 = value2 ? DateUtils.dateTimeObj(value2, format) : null;

    if (date1?.isSame(date2)) {
      return 0;
    }
    return date1 && date2 && date1.isAfter(date2) ? 1 : -1;
  },
  comparatorWithoutFormat: (
    value1: string | null,
    value2: string | null
  ): number => {
    if (!value1 && !value2) {
      return 0;
    }

    if (value1 && !value2) {
      return -1;
    }

    if (!value1 && value2) {
      return 1;
    }

    const date1 = value1 ? DateUtils.dateTimeObj(value1) : null;
    const date2 = value2 ? DateUtils.dateTimeObj(value2) : null;

    if (date1?.isSame(date2)) {
      return 0;
    }
    return date1 && date2 && date1.isAfter(date2) ? 1 : -1;
  }
};

export const getCurrentTimeZoneInfo = () => {
  const dt = moment();
  return {
    code: dt.format("z")
  };
};

export const convert24HourTo12Hour = (hourIn24Format: number) => {
  if (hourIn24Format === 0) {
    return { hour: 12, minute: 0, amPm: "AM" };
  }

  if (hourIn24Format > 0 && hourIn24Format < 12) {
    return { hour: hourIn24Format, minute: 0, amPm: "AM" };
  }

  if (hourIn24Format === 12) {
    return { hour: 12, minute: 0, amPm: "PM" };
  }

  if (hourIn24Format > 12 && hourIn24Format < 24) {
    return { hour: hourIn24Format - 12, minute: 0, amPm: "PM" };
  }

  return { hour: 0, minute: 0, amPm: "AM" };
};

export const convert12HourTo24Hour = (
  hourIn12Format: number,
  amPm: AmPmType
) => {
  if (hourIn12Format === 12 && amPm === "AM") {
    return 0;
  }

  if (hourIn12Format === 12 && amPm === "PM") {
    return 12;
  }

  if (hourIn12Format > 0 && hourIn12Format < 12 && amPm === "AM") {
    return hourIn12Format;
  }

  if (hourIn12Format > 0 && hourIn12Format < 12 && amPm === "PM") {
    return hourIn12Format + 12;
  }

  return 0;
};

export const getHoursAndMinutesInVariousFormats = () => {
  const hoursIn24HourFormat = Array.from({ length: 24 }, (_, i) => i);
  const hoursIn12HourFormat = Array.from({ length: 13 }, (_, i) => i).filter(
    (hour) => hour !== 0
  );
  const minutes = Array.from({ length: 60 }, (_, i) => i);

  // option object with label and value
  const hoursIn12HourFormatOptions = hoursIn12HourFormat.map((hour) => ({
    label: hour,
    value: hour
  }));

  const hoursIn24HourFormatOptions = hoursIn24HourFormat.map((hour) => ({
    label: hour,
    value: hour
  }));

  const minutesOptions = minutes.map((minute) => ({
    label: minute,
    value: minute
  }));

  return {
    options: {
      hoursIn12HourFormatOptions,
      hoursIn24HourFormatOptions,
      minutesOptions
    },
    values: {
      hoursIn12HourFormat,
      hoursIn24HourFormat,
      minutes
    }
  };
};
