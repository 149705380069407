import { Tabs } from "antd";
import "../module-settings.scss";

import { useState } from "react";

import { useHistory, useParams } from "react-router";
import FeatureTemplateProjectSettings from "components/manage-feature-wf-templates/feature-template-project-settings";
import { FeatureTypes } from "entity-app/constants";
import FeatureProjectRiskThresholdSettings from "components/feature-project-risk-threshold-settings";
import FeatureRenamedFieldSettings from "components/feature-rename-fields-settings";

function MaterialSettings(props: { disabled: boolean; materialheader: any }) {
  const { disabled, materialheader } = props;
  const history = useHistory();
  const { projectId, tab3Id } = useParams() as any;
  const [selectedTab, setSelectedTab] = useState<string>(tab3Id || "templates");

  const tabItems = [
    {
      label: "Material Templates",
      key: "templates",
      children: (
        <FeatureTemplateProjectSettings featureId={FeatureTypes.MATERIALS} />
      )
    },
    {
      label: "Rename Fields",
      key: "rename-field",
      children: (
        <FeatureRenamedFieldSettings
          featureId={FeatureTypes.MATERIALS}
          disabled={disabled}
          columnHeader={materialheader}
        />
      )
    },
    {
      label: "Risk Threshold",
      key: "risk-threshold",
      children: (
        <FeatureProjectRiskThresholdSettings
          featureId={FeatureTypes.MATERIALS}
        />
      )
    }
  ];

  return (
    <div className="w-full bg-white h-full module-settings--tabs">
      <Tabs
        tabPosition="left"
        tabBarGutter={2}
        tabBarStyle={{ width: "180px" }}
        activeKey={selectedTab}
        items={tabItems}
        onChange={(activeKey) => {
          history.push(
            `/project/${projectId}/settings/modules/materials/${activeKey.toString()}`
          );
          setSelectedTab(activeKey);
        }}
        destroyInactiveTabPane
      />
    </div>
  );
}

export default MaterialSettings;
