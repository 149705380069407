import { Typography } from "antd";
import { IMaterial } from "./type-definitions";
import { mergeSpecSectionNoandName } from "./widgets";

const { Paragraph } = Typography;

function MaterialDetailsSection({ material }: { material: IMaterial }) {
  const specSectionString = mergeSpecSectionNoandName(
    material.spec_section_no,
    material.spec_section_name
  );

  return (
    <div>
      <div className="pl-3 lg:pl-6 space-y-6 hidden md:block">
        {specSectionString && (
          <div>
            <div className="text-color-1">Spec Section</div>
            <div className="text-color-3">{specSectionString}</div>
          </div>
        )}
        {material.material_tag && (
          <div>
            <div className="text-color-1">Tag</div>
            <div className="text-color-3">{material.material_tag}</div>
          </div>
        )}
        {material.description && (
          <div>
            <div className="text-color-1">Description</div>
            <div className="text-color-3">
              <Paragraph>{material.description}</Paragraph>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MaterialDetailsSection;
