import "./add-company.scss";
import { Modal } from "antd";
import AddCompanyForm from "components/new-company-form/new-company-form";

function AddCompany(props: {
  editCompany?: any;
  isFormEditOnly?: boolean;
  setDrawerOpen: Function;
  showDrawerOpen: boolean;
  modelHeader: any;
}) {
  const {
    editCompany,
    isFormEditOnly = true,
    setDrawerOpen,
    showDrawerOpen,
    modelHeader
  } = props;

  return (
    <Modal
      className="custom-drawer"
      title={modelHeader}
      width={420}
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ height: "calc(100vh - 93px)" }}
      footer={null}
      open={showDrawerOpen}
      onCancel={() => {
        setDrawerOpen(false);
      }}
      destroyOnClose
    >
      <AddCompanyForm
        editCompany={editCompany}
        isFormEditOnly={isFormEditOnly}
        onNewCompany={() => setDrawerOpen(false)}
        onUpdateCompany={() => setDrawerOpen(false)}
      />
    </Modal>
  );
}
export default AddCompany;
