import { useQuery } from "@apollo/client";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {
  IdLinkComponent,
  SubmittalIdLinkCellRenderer
} from "components/cell-renders";
import { ProjectContext } from "context/ProjectProvider";
import { SubmittalLog } from "models/submittal-log";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams, withRouter } from "react-router";
import { QUERY_SUBMITTAL_TYPES } from "services/graphQL/queries";
import ASubmittalListFilterComponent from "partner-app/components/a-submittal-list-filter-bar";
import { ASubmittalStatusCellRenderer } from "partner-app/components/cell-renderer/cell-renderer";
import { isProjectInIntegrationMode, matchUserProjectRole } from "utils/utils";
import { useSubmittalList } from "hooks/submittal";
import {
  useIntegrationProjectParticipants,
  useProjectParticipants
} from "hooks/project-participants";
import { EUserTypes, SubmittalStatusToStrMap } from "../../../constants";

function ASubmittalList() {
  const { projectId } = useParams() as any;
  const gridRef = useRef<AgGridReact<SubmittalLog>>(null);

  const [isGridReady, setGridReady] = useState(false);
  const [skipSubscribe, setSkipSubscribe] = useState(true);
  const [typesMap, setTypesMap] = useState(null);
  const [statusesMap, setStatusesMap] = useState({});
  const [gridSearchtext, setGridSearchtext] = useState("");

  const {
    gqlClientForProject,
    tokenContents,
    columnHeaders: { submittalHeaderMap },
    projectDetails
  } = useContext(ProjectContext);

  const isIntegrationMode = isProjectInIntegrationMode(
    projectDetails ? projectDetails?.mode : 0
  );

  const isLoggedInUserGC = matchUserProjectRole(
    EUserTypes.GENERAL_CONTRACTOR,
    tokenContents?.role
  );

  const { projectParticipants: WFProjectParticipants } = useProjectParticipants(
    isIntegrationMode || !isLoggedInUserGC
  );
  const { data: INTGProjectParticipants } = useIntegrationProjectParticipants(
    !isIntegrationMode || !isLoggedInUserGC
  );

  const projectParticipants = isIntegrationMode
    ? INTGProjectParticipants
    : WFProjectParticipants;

  const { data: submittalListData } = useSubmittalList({
    gqlClientForProject,
    projectDetails,
    isIntegrationMode,
    skipSubscribe,
    tokenContents
  });

  const { data: submittalTypes } = useQuery(QUERY_SUBMITTAL_TYPES);

  useEffect(() => {
    if (submittalTypes) {
      const tmpTypesMap: any = {};
      submittalTypes.configurations_value.forEach((type: any) => {
        tmpTypesMap[type.id] = type;
      });
      setTypesMap(tmpTypesMap);
    }
  }, [submittalTypes]);

  useEffect(() => {
    const statusMap: any = {};
    Object.keys(SubmittalStatusToStrMap).forEach((keyStatusId: any) => {
      statusMap[keyStatusId] = {
        name: SubmittalStatusToStrMap[keyStatusId]
      };
    });
    setStatusesMap(statusMap);
  }, []);

  useEffect(() => {
    // Note: to handle a glitch in useSubscription hook.. navigate to a submittal detail page from the submittal list and navigate back immediately before the details page loads using "All submittals" navbutton causing the submittal list to not load. mainly happening due to subscription to SUBSCRIPTION_SUBMITTAL_STATUSES.. timeout allows the previous subscription to get cleared.
    setTimeout(() => {
      setSkipSubscribe(false);
    });
  }, []);

  useEffect(() => {
    function handleResize() {
      if (isGridReady && gridRef && gridRef.current) {
        gridRef?.current?.api?.sizeColumnsToFit();
      }
    }
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [isGridReady]);

  useEffect(() => {
    gridRef.current?.api?.setQuickFilter(gridSearchtext);
  }, [gridSearchtext]);

  const columnDefs = useMemo<ColDef[]>(() => {
    return [
      {
        colId: "submittal_sequence_id",
        field: "submittal_sequence_id",
        headerName: submittalHeaderMap?.submittal_id?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.submittal_id?.toUpperCase() || "",
        width: 110,
        sort: "asc",
        menuTabs: [],
        editable: false,
        cellRenderer: IdLinkComponent,
        cellRendererParams: {
          projectId
        },
        tooltipValueGetter: ({ data, value, context }) => {
          const cellText =
            context.projectDetails?.spec_section && data.spec_no
              ? `${data.spec_no} - ${value}`
              : `${value}`;
          return cellText;
        }
      },
      {
        colId: "title",
        field: "title",
        headerName: submittalHeaderMap?.title?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.title?.toUpperCase() || "",
        tooltipValueGetter: ({ value }) => value,
        cellRenderer: SubmittalIdLinkCellRenderer,
        cellRendererParams: {
          projectId
        }
      },
      {
        colId: "description",
        field: "description",
        headerName: submittalHeaderMap?.description?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.description?.toUpperCase() || "",
        tooltipValueGetter: ({ value }) => value
      },
      {
        colId: "revision",
        field: "revision",
        headerName: submittalHeaderMap?.revision?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.revision?.toUpperCase() || "",
        tooltipField: "revision",
        width: 120
      },
      {
        colId: "type",
        field: "type",
        headerName: submittalHeaderMap?.type?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.type?.toUpperCase() || "",
        valueGetter: ({ data, context }: any) => {
          try {
            return context.typesMap[data.type].value;
          } catch (ex) {
            return "";
          }
        },
        tooltipValueGetter: ({ value }) => value
      },
      {
        colId: "status",
        field: "workflow_status",
        headerName: submittalHeaderMap?.workflow_status?.toUpperCase() || "",
        headerTooltip: submittalHeaderMap?.workflow_status?.toUpperCase() || "",
        width: 250,
        cellRenderer: ASubmittalStatusCellRenderer,
        cellRendererParams: {
          dataMapVar: "statusesMap",
          valueVar: "id",
          labelVar: "name"
        }
      }
    ];
  }, [projectId, submittalHeaderMap]);

  const defaultColDef: {} = useMemo(() => {
    return {
      sortable: false,
      editable: false,
      filter: false,
      resizable: true,
      menuTabs: []
    };
  }, []);

  return (
    <div className="grow flex flex-col">
      <ASubmittalListFilterComponent
        onSearch={setGridSearchtext}
        stats={
          submittalListData
            ? `${submittalListData?.length} Submittals`
            : "Loading Submittals"
        }
      />
      <div className="grow bg-white ag-theme-alpine p-2">
        <AgGridReact<SubmittalLog>
          tooltipMouseTrack
          ref={gridRef}
          rowData={submittalListData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={36}
          groupSelectsChildren
          suppressRowClickSelection
          suppressAggFuncInHeader
          suppressDragLeaveHidesColumns
          readOnlyEdit
          animateRows={false}
          debounceVerticalScrollbar
          suppressMovableColumns
          onGridReady={() => {
            setGridReady(true);
            gridRef.current?.api?.sizeColumnsToFit();
          }}
          rowHeight={62}
          context={{
            projectDetails,
            typesMap,
            statusesMap,
            isIntegrationMode,
            projectParticipants
          }}
          suppressContextMenu
          overlayNoRowsTemplate="Import a log or click New submittal to create a log entry."
        />
      </div>
    </div>
  );
}
export default withRouter(ASubmittalList);
