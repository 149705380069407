import TickIcon from "components/svg-icons/tick-icon";
import "./links.scss";
import ClockIcon from "components/svg-icons/clock-icon";

const linkedCell = (
  title: string,
  value: any,
  titleClassname: string = "",
  valueClassname: string = ""
) => (
  <div className="flex space-x-1.5 px-3 links-card items-center">
    <div className={`text-xs font-normal text-[#8E8E8E] ${titleClassname}`}>
      {title}
    </div>
    <div className={`${valueClassname}`} title={value}>
      {value}
    </div>
  </div>
);

export default linkedCell;

export function WFStatusCell(props: {
  title: string;
  value: string;
  milestoneCompleted: boolean;
}) {
  const { title = "", value = "", milestoneCompleted = false } = props;

  const getStatusIcon = (dateStr: string, isMilestoneCompleted: boolean) => {
    if (dateStr) {
      return isMilestoneCompleted ? (
        <span data-testid="tick-icon">
          <TickIcon />
        </span>
      ) : (
        <span data-testid="clock-icon">
          <ClockIcon />
        </span>
      );
    }
    return <span data-testid="no-icon" />;
  };

  return (
    <div className="grid grid-cols-[auto_auto_12px] gap-x-2 text-xs">
      <div className="font-normal text-[#8E8E8E]" data-testid="title">
        {title}
      </div>
      <div className="text-right" title={value} data-testid="value">
        {value || "-"}
      </div>

      <div>{getStatusIcon(value, milestoneCompleted)}</div>
    </div>
  );
}
