import { InboxOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Spin,
  UploadFile,
  UploadProps
} from "antd";
import FormItem from "antd/es/form/FormItem";
import { RcFile } from "antd/lib/upload";
import Dragger from "antd/lib/upload/Dragger";
import { useState } from "react";
import { BASE_URL } from "services/endpoints";
import "./import-schedule.scss";
import { DATE_FORMAT_MMDDYYYY } from "utils/dateutils";
import CIQDatePicker from "components/custom-date-picker";
import { SUTTER_DEMO } from "config/config";

export default function ImportSchedulerTasks({
  tokenRetrievalState,
  setDrawerOpen,
  showImportLogDrawer
}: {
  tokenRetrievalState: { token: string };
  setDrawerOpen: any;
  showImportLogDrawer: boolean;
}) {
  const acceptedExtensions = SUTTER_DEMO
    ? ["xml", "xer", "mpp", "csv"]
    : ["xml", "xer", "mpp"];

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [unsupportedFileSelected, setUnsupportedFileSelected] = useState(false);
  const [form] = Form.useForm();

  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      const extension =
        file?.name?.substring(
          file.name.lastIndexOf(".") + 1,
          file?.name?.length
        ) || "";

      let fileType = "P6";
      if (extension === "mpp") {
        fileType = "MSP";
      } else if (extension === "csv") {
        fileType = "CSV";
      }
      formData.append("file", file as RcFile);
      formData.append("schedule_name", form.getFieldValue("scheduleName"));
      formData.append("schedule_type", form.getFieldValue("scheduleType"));
      formData.append("file_type", fileType);
      formData.append(
        "schedule_date",
        form.getFieldValue("scheduleDate").format(DATE_FORMAT_MMDDYYYY)
      );
    });

    setUploading(true);
    setDrawerOpen(true);
    fetch(`${BASE_URL}/scheduler/import`, {
      method: "POST",
      body: formData,
      headers: {
        authorization: `Bearer ${tokenRetrievalState.token}`
      }
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("Result:", result);
        if (result.success) {
          setDrawerOpen(false);
          message.success(result.success.message);
        } else {
          message.error("import failed.");
        }
        setDrawerOpen(false);
      })
      .catch(() => {
        message.error("import failed.");
      })
      .finally(() => {
        setUploading(false);
        setDrawerOpen(false);
      });
  };

  const props: UploadProps = {
    style: { borderColor: unsupportedFileSelected === true ? "red" : "" },
    accept: SUTTER_DEMO ? "text/xml, .xer, .mpp, .csv" : "text/xml, .xer, .mpp",
    fileList,
    onRemove: () => {
      setFileList([]);
    },
    onDrop: (e) => {
      if (e.dataTransfer.files.length > 0) {
        const file = e.dataTransfer.files[0];
        const fileName = file.name.toLowerCase();
        const extension =
          fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) ||
          "";
        if (!acceptedExtensions.some((x) => x === extension)) {
          setUnsupportedFileSelected(true);
          setFileList([]);
          message.error("Please select .XML .XER and .MPP files only");
          return false;
        }
        setUnsupportedFileSelected(false);
        return false;
      }
      return false;
    },
    beforeUpload: (file) => {
      const fileName = file.name.toLowerCase();
      const extension =
        fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) ||
        "";
      if (!acceptedExtensions.some((x) => x === extension)) {
        setUnsupportedFileSelected(true);
        setFileList([]);
        message.error("Please select .XML .XER and .MPP files only");
        return false;
      }
      setUnsupportedFileSelected(false);
      setFileList([file]);
      return false;
    },
    showUploadList: {
      showDownloadIcon: false,
      showPreviewIcon: false
    }
  };

  return (
    <Modal
      className="import-schedule custom-drawer"
      title="Import Schedule"
      width={420}
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ height: "calc(100vh - 92px)" }}
      footer={null}
      open={showImportLogDrawer}
      onCancel={() => {
        setDrawerOpen(false);
      }}
      destroyOnClose
    >
      <div className="space-y-4 w-full px-3">
        <div className="w-full">
          <Dragger {...props} disabled={uploading}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            {fileList.length > 0 ? (
              <p className="ant-upload-text">{fileList[0].name}</p>
            ) : (
              <div>
                <p className="ant-upload-text">
                  Click or drag a file to upload
                </p>
                <p className="ant-upload-hint">
                  Only {SUTTER_DEMO && ".csv,"} .xml, .xer and .mpp files are
                  supported.
                </p>
              </div>
            )}
          </Dragger>
        </div>
        <Form form={form} layout="vertical" onFinish={handleUpload}>
          <div className="flex space-x-2">
            <FormItem
              name="scheduleName"
              label="SCHEDULE NAME"
              className="w-1/2 placeholder:text-xs"
              hidden={fileList.length === 0}
              rules={[
                {
                  required: true,
                  message: "Enter schedule name"
                }
              ]}
            >
              <Input
                className="h-9 border-solid border border-[#3B3B3B4D] placeholder:text-xs"
                placeholder="Enter schedule name"
                disabled={uploading}
              />
            </FormItem>
            <FormItem
              name="scheduleDate"
              label="SCHEDULE DATE"
              className="w-1/2 placeholder:text-xs"
              hidden={fileList.length === 0}
              rules={[
                {
                  type: "object",
                  required: true,
                  message: "Select a date"
                }
              ]}
            >
              <CIQDatePicker
                className="w-full h-9 border-solid border border-[#3B3B3B4D] date-picker-with-icon"
                placeholder="Select schedule date"
                format={DATE_FORMAT_MMDDYYYY}
                disabled={uploading}
              />
            </FormItem>
          </div>
          <FormItem
            name="scheduleType"
            className="w-1/2 pr-[6px]"
            label="SCHEDULE CAPTION"
            hidden={fileList.length === 0}
          >
            <Input
              className="h-9 border-solid border border-[#3B3B3B4D] placeholder:text-xs"
              placeholder="Enter schedule caption"
              disabled={uploading}
            />
          </FormItem>
          <Divider className="my-2" />
          {uploading ? (
            <Spin
              className="mt-4 text-[#3b3b3b] w-full"
              tip="Import is in progress, this may take some time."
              size="default"
            />
          ) : (
            <div className="flex justify-end space-x-2 -mt-3">
              <Button
                onClick={() => {
                  form.resetFields();
                  setFileList([]);
                }}
                style={{ marginTop: 16 }}
                disabled={uploading}
                hidden={fileList.length === 0}
              >
                Clear
              </Button>
              <Button
                disabled={uploading || fileList.length === 0}
                loading={uploading}
                htmlType="submit"
                style={{ marginTop: 16 }}
                type="primary"
              >
                {uploading ? "Importing" : "Import"}
              </Button>
            </div>
          )}
        </Form>
      </div>
    </Modal>
  );
}
