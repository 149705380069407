// upcoming milestones hook

import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import { useCIQMutation, useCIQQuery } from "hooks/ciq-gql-hooks";
import { useContext, useEffect, useMemo, useState } from "react";
import { GET_NOTIFICATION_SCHEDULES } from "services/graphQL/ciq-queries";
import { MUTATION_UPDATE_NOTIFICATION_SCHEDULE } from "services/graphQL/mutations";

interface IUser {
  email: string;
  first_name: string;
  id: string;
  last_name: string;
}

interface IDistributionGroup {
  id: string;
  name: string;
}

interface INotificationScheduleRecipient {
  distribution_group: IDistributionGroup | null;
  user: IUser | null;
}

export interface IScheduleInput {
  hour: number;
  frequency: "daily" | "weekly" | "monthly";
  week_day: string;
}

export interface INotificationSchedule {
  id: string;
  schedule_input: string;
  enabled: boolean;
  notification_schedule_recipients: INotificationScheduleRecipient[];
}

const frequencyOptions: { label: string; value: string }[] = [
  {
    label: "Daily",
    value: "daily"
  },
  {
    label: "Weekly",
    value: "weekly"
  },
  {
    label: "Monthly",
    value: "monthly"
  }
];

const weekDaysOptions: { label: string; value: string }[] = [
  {
    label: "Monday",
    value: "Monday"
  },
  {
    label: "Tuesday",
    value: "Tuesday"
  },
  {
    label: "Wednesday",
    value: "Wednesday"
  },
  {
    label: "Thursday",
    value: "Thursday"
  },
  {
    label: "Friday",
    value: "Friday"
  },
  {
    label: "Saturday",
    value: "Saturday"
  },
  {
    label: "Sunday",
    value: "Sunday"
  }
];

export const useUpcomingMilestones = () => {
  const { gqlClientForProject, eventLogs }: TProjectContext =
    useContext(ProjectContext);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data, loading, error, refetch } = useCIQQuery<{
    notification_schedules: INotificationSchedule[];
  }>(GET_NOTIFICATION_SCHEDULES, {
    client: gqlClientForProject,
    skip: !gqlClientForProject
  });

  const [setNotificationScheduleMutation] = useCIQMutation(
    MUTATION_UPDATE_NOTIFICATION_SCHEDULE,
    {
      client: gqlClientForProject
    }
  );

  const setNotificationSchedule = async (payload: any) => {
    setIsSubmitting(true);
    const result = await setNotificationScheduleMutation({
      variables: payload
    });
    setIsSubmitting(false);
    return result;
  };

  const firstNotificationSchedule = useMemo(() => {
    const firstItem = data?.notification_schedules[0];
    if (!firstItem) {
      return null;
    }
    return {
      ...firstItem,
      schedule_input: JSON.parse(firstItem.schedule_input)
    };
  }, [data]);

  useEffect(() => {
    if (eventLogs.length) {
      const notificationScheduleEventLogs = eventLogs.filter(
        (eventLog: any) => {
          return eventLog.data_source === "notification_schedules";
        }
      );

      if (notificationScheduleEventLogs.length) {
        refetch();
      }
    }
  }, [eventLogs, refetch]);

  return {
    frequencyOptions,
    weekDaysOptions,
    notificationSchedules: { data, loading, error },
    firstNotificationSchedule,
    setNotificationSchedule,
    isSubmitting,
    refetch
  };
};
