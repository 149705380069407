function TickIcon() {
  return (
    <svg
      width="10"
      height="9"
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4.73864L1.56818 3.17045L3.35795 4.92614L7.55114 0.75L9.11932 2.31818L3.35795 8.04545L0 4.73864Z"
        fill="black"
        fillOpacity="0.85"
      />
    </svg>
  );
}

export default TickIcon;
