export default function DisabledPlusAddIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.25">
        <rect width="18" height="18" rx="1" fill="#5C5C5C" />
        <path
          d="M1 0H17C17.5523 0 18 0.44772 18 1V17C18 17.5523 17.5523 18 17 18H1C0.44772 18 0 17.5523 0 17V1C0 0.44772 0.44772 0 1 0ZM8 8H4V10H8V14H10V10H14V8H10V4H8V8Z"
          fill="#DEDEDE"
        />
      </g>
    </svg>
  );
}
