import { Button, Divider, message, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useMemo, useState } from "react";
import { DateUtils } from "utils/dateutils";
import PersonCheckIcon from "components/svg-icons/person-check-icon";
import { useRequestDurations } from "./hooks/request-durations";
import "./requested-duration-details.scss";

function RequestedDurationDetails({
  selectedItem,
  onCancel
}: {
  selectedItem: { id: string };
  onCancel: () => void;
}) {
  const {
    data: queryResponse,
    loading,
    sendInvite,
    sendingInvite
  } = useRequestDurations([selectedItem.id]);

  const materialData: any = useMemo(() => {
    if (!queryResponse) return null;
    const material = queryResponse.material[0];
    return {
      id: material.id,
      name: material.name,
      materialAssignee: material.material_assignee,
      assigneeName: material.material_assignee
        ? `${material.material_assignee?.first_name} ${material.material_assignee?.last_name}`
        : "",
      assigneeEmail: material.material_assignee?.email,
      tradePartner: material?.materials_trade_partner?.name,
      estimatedInvite: material?.estimate_invite_features[0]?.estimated_invite,
      inviteCreatedAt:
        material?.estimate_invite_features[0]?.estimated_invite?.created_at
    };
  }, [queryResponse]);

  const [notes, setNotes] = useState("");

  useEffect(() => {
    if (materialData) {
      setNotes(materialData?.estimatedInvite?.notes);
    }
  }, [materialData]);

  const resendInvite = async () => {
    const variables = {
      sendEstimateInviteData: [
        {
          assignee: materialData?.materialAssignee.id,
          material_ids: [materialData.id],
          notes
        }
      ]
    };

    const response = await sendInvite(variables);

    if (response.success) {
      message.success("Request sent successfully via email.");
      onCancel();
    }
  };

  if (loading && !queryResponse)
    return (
      <div
        className="min-h-[260px] flex justify-center items-center"
        data-testid="loading-spinner"
      >
        <Spin size="small" />
      </div>
    );

  return (
    <div className="px-4 py-2.5">
      <div className="w-full bg-[#F7F6F4] p-2 py-3 flex gap-x-2">
        <div className="flex items-center px-2 text-[#3B3B3BCC]">
          <PersonCheckIcon width="22px" height="22px" />
        </div>
        <div className="flex flex-col">
          <div className="text-color-1 text-xs">Last Request Date & Time</div>
          <div className=" text-sm">
            {DateUtils.format(materialData?.inviteCreatedAt, "MM-DD-YYYY")} at{" "}
            {DateUtils.format(materialData?.inviteCreatedAt, "hh:mm A (z)")}
          </div>
        </div>
      </div>

      <div className="mt-6">
        <div className="form-field-heading-2">recipient</div>
        <div>
          {materialData?.materialAssignee && (
            <div className="text-sm">
              <div>{materialData?.tradePartner}</div>
              <div>
                {materialData?.assigneeName} &lt;
                {materialData?.assigneeEmail}&gt;
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-6">
        <div className="form-field-heading-2">Notes</div>
        <TextArea
          rows={2}
          autoSize={{ minRows: 2, maxRows: 2 }}
          value={notes}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
          autoFocus
        />
      </div>
      <Divider className="m-0 p-0 mt-3 pt-2.5" />
      <div className="flex justify-end gap-2">
        <Button
          type="default"
          onClick={onCancel}
          data-testid="cancel-button"
          disabled={sendingInvite}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          data-testid="send-request-button"
          onClick={resendInvite}
          loading={sendingInvite}
        >
          Resend Request
        </Button>
      </div>
    </div>
  );
}

export default RequestedDurationDetails;
