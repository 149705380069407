import { Button, Collapse, Form, Tooltip } from "antd";
import { Link, useHistory } from "react-router-dom";

import "../links.scss";
import { QUERY_SUBMITTAL_TYPES } from "services/graphQL/queries";
import { useCallback, useEffect, useMemo, useState } from "react";
import LinkIcon from "components/svg-icons/link-icon";
import AsterixIcon from "components/svg-icons/asterix-icon";
import { DateUtils } from "utils/dateutils";
import { ProjectSettingType } from "context/ProjectProvider";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { RiskLevelType } from "components/date-block/models";
import { mergedStrings, sortAlphanumeric, sortLinkedItems } from "utils/utils";
import VoidSubmittalIcon from "components/svg-icons/void-submittal-icon";
import { SwapOutlined } from "@ant-design/icons";
import {
  ErrorMessages,
  ESubmittalStatus,
  SubmittalStatusToStrMap
} from "../../../constants";
import linkedCell, { WFStatusCell } from "../linkedCell";
import CriticalActivityIndicator from "../critical-activity-indicator";

const { Panel } = Collapse;

type TMaterialLinksProps = {
  materialDetailsState: any;
  projectId: string;
  canOpenLinkingPage: boolean;
  canEditLinkingPage: boolean;
  projectDetails: ProjectSettingType | undefined;
};

function MaterialLinks(props: TMaterialLinksProps) {
  const {
    materialDetailsState,
    canOpenLinkingPage,
    canEditLinkingPage,
    projectId,
    projectDetails
  } = props;

  const [submittalTypes, setSubmittalTypes] = useState([]);

  const { data: submittalTypesData } = useCIQQuery(QUERY_SUBMITTAL_TYPES);
  const history = useHistory();

  useEffect(() => {
    setSubmittalTypes(submittalTypesData?.configurations_value);
  }, [submittalTypesData]);

  const submittalWFStatusUsingDateBlock = useCallback((submittalLink: any) => {
    // console.log("submittalLink", submittalLink);
    const submittalDB = submittalLink?.submittal?.date_block_submittals[0];

    const submittalWFStarted =
      submittalLink?.submittal?.workflow_status !== ESubmittalStatus.CREATE &&
      submittalLink?.submittal?.workflow_status !== ESubmittalStatus.VOID;

    const submitDate =
      submittalDB.actual_milestone_2 ||
      (submittalWFStarted
        ? submittalDB.projected_milestone_2
        : submittalDB.planned_milestone_2);
    const designReviewDate =
      submittalDB.actual_milestone_4 ||
      (submittalWFStarted
        ? submittalDB.projected_milestone_4
        : submittalDB.planned_milestone_4);

    return {
      title: submittalLink?.submittal?.title,
      submit_by_subcontractor: {
        label: `${submittalDB.name_milestone_2}:`,
        date: submitDate ? DateUtils.format(submitDate, "MM-DD-YY") : "",
        milestoneCompleted: !!submittalDB.actual_milestone_2
      },
      design_review_complete: {
        label: `${submittalDB.name_milestone_4}:`,
        date: designReviewDate
          ? DateUtils.format(designReviewDate, "MM-DD-YY")
          : "",
        milestoneCompleted: !!submittalDB.actual_milestone_4
      }
    };
  }, []);

  const submittalPanelHeader = (link: any) => {
    const displaySubmittalId =
      projectDetails?.spec_section && link.spec_no
        ? `${link.spec_no} - ${link.submittal_sequence_id}`
        : `${link.submittal_sequence_id}`;
    return (
      <div
        className="flex items-center"
        title={`${displaySubmittalId}-${link?.title}`}
      >
        <span className="flex items-center mr-2">
          {link?.submittal?.workflow_status === ESubmittalStatus.VOID ? (
            <VoidSubmittalIcon />
          ) : (
            <LinkIcon />
          )}
        </span>
        <i className="opacity-60">
          {displaySubmittalId}
          <span className="mx-1">-</span>
        </i>
        <span className="w-full truncate overflow-hidden mr-5">
          {link?.title}
        </span>
      </div>
    );
  };

  // const getSpecInfo = (spec_section_no: string, spec_section_name: string) => {
  //   if (spec_section_no && spec_section_name)
  //     return `${spec_section_no} - ${spec_section_name}`;
  //   if (spec_section_no) return spec_section_no;
  //   return "";
  // };

  // const getSubmitterInfo = (submittal: any) => {
  //   if (submittal.submittal_user) {
  //     return `${submittal.submittal_user.first_name} ${submittal.submittal_user.last_name} - ${submittal.submittal_user.project_users[0].subscription_vendor.name}`;
  //   }
  //   if (submittal.submitter_unregistered) {
  //     return submittal.submitter_unregistered;
  //   }
  //   return "NA";
  // };

  const getSubmittalType = useCallback(
    (typeId: any) => {
      if (!submittalTypes || !submittalTypes.length) return "";
      const foundType: any = submittalTypes.find((sType: any) => {
        return sType.id === typeId;
      });
      return foundType.value;
    },
    [submittalTypes]
  );

  const schedulePanelHeader = (link: any) => {
    const headerMarkup = (
      <div className="flex-col">
        <div
          className="flex items-center"
          title={`${link?.gantt_task?.source_task_id}-${link?.gantt_task?.text}`}
        >
          {link?.gantt_task?.critical ? (
            <span className="flex mr-2">
              <CriticalActivityIndicator />
            </span>
          ) : (
            <span className="flex items-center mr-2">
              <LinkIcon />
            </span>
          )}
          <i className="opacity-60">
            {link?.gantt_task?.source_task_id}
            {link?.gantt_task?.source_task_id && !link.driving_task && (
              <span className="mx-1">-</span>
            )}
          </i>
          {link.driving_task && (
            <span className="flex items-center mx-1 mb-1">
              <AsterixIcon />
            </span>
          )}
          <span className="flex-grow truncate mr-1">
            {link?.gantt_task?.text}
          </span>

          {(link?.gantt_task?.start_date || link?.gantt_task?.end_date) && (
            <i className="opacity-60 px-1 w-[95px] mr-16">
              {link?.gantt_task?.start_date && link.linked_to_end_date === false
                ? `Start: ${DateUtils.format(link?.gantt_task?.start_date)}`
                : null}
              {link?.gantt_task?.end_date && link.linked_to_end_date === true
                ? `End: ${DateUtils.format(link?.gantt_task?.end_date)}`
                : null}
            </i>
          )}
        </div>
      </div>
    );

    if (canOpenLinkingPage) {
      return (
        <Link
          to={`/project/${projectId}/schedule/material-linking?&materialId=${materialDetailsState?.id}`}
          className="text-inherit hover:text-inherit"
        >
          {headerMarkup}
        </Link>
      );
    }
    return headerMarkup;
  };

  const linkedMaterialSubmittals = useMemo(() => {
    const mappedItems = materialDetailsState?.submittal_material_links?.map(
      (link: any) => {
        const dateBlockSubmittal = link?.submittal?.date_block_submittals
          ?.length
          ? link?.submittal?.date_block_submittals[0]
          : null;
        return {
          ...link,
          workflow_status: link?.submittal?.workflow_status,
          risk_level: dateBlockSubmittal?.risk_level
        };
      }
    );

    return sortLinkedItems(mappedItems || [], "submittal_sequence_id");
  }, [materialDetailsState?.submittal_material_links]);

  const linkedMaterialActivities = useMemo(() => {
    const mappedItems = materialDetailsState?.material_schedule_links?.map(
      (link: any) => {
        return {
          ...link,
          activity_id: link?.gantt_task?.source_task_id
        };
      }
    );
    return sortAlphanumeric(mappedItems || [], "activity_id");
  }, [materialDetailsState?.material_schedule_links]);

  const cssForValue = "text-xs font-normal text-[#000000CC]";
  return (
    <div>
      <Form.Item
        label={
          <div className="flex space-x-2 items-center">
            <span data-testid="sub-title-count">
              SUBMITTALS
              {linkedMaterialSubmittals?.length
                ? ` (${linkedMaterialSubmittals?.length})`
                : ""}
            </span>
          </div>
        }
      >
        {linkedMaterialSubmittals?.length > 0 ? (
          <Collapse expandIconPosition="end" className="custom-collapse">
            {linkedMaterialSubmittals?.map((link: any) => {
              const { submittal } = link;
              if (!submittal)
                return (
                  <Panel
                    header={
                      <Tooltip title={ErrorMessages.SubmittalAccessDenied}>
                        {submittalPanelHeader(link)}
                      </Tooltip>
                    }
                    key={link.submittal_id}
                    className="custom-panel"
                    collapsible="disabled"
                  />
                );
              const type = getSubmittalType(submittal.type);
              const status =
                SubmittalStatusToStrMap[submittal?.workflow_status];
              const riskLevel = submittal?.date_block_submittals?.length
                ? submittal?.date_block_submittals[0]?.risk_level
                : "";
              const specSection = mergedStrings([
                submittal?.spec_no ?? "",
                submittal?.spec_name ?? ""
              ]);
              const submittalWFStatus = submittalWFStatusUsingDateBlock(link);
              return (
                <Panel
                  header={submittalPanelHeader(link)}
                  key={submittal.id}
                  className={
                    riskLevel === RiskLevelType.High
                      ? "custom-panel risk-panel truncate"
                      : "custom-panel truncate"
                  }
                >
                  <div className="flex-col space-y-2.5">
                    <div className="flex w-full -mb-2">
                      {linkedCell(
                        "Spec Section:",
                        `${specSection}`,
                        "",
                        `${cssForValue} truncate max-w-[300px]`
                      )}
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="grid grid-rows-2 gap-1">
                        {linkedCell("Type:", type, "w-[38px]", cssForValue)}
                        {linkedCell("Status:", status, "", cssForValue)}
                      </div>
                      <div className="grid grid-rows-2">
                        {linkedCell(
                          "Revision:",
                          submittal?.revision,
                          "",
                          cssForValue
                        )}
                        {linkedCell(
                          "Risk:",
                          riskLevel,
                          "w-[52px]",
                          riskLevel === RiskLevelType.High
                            ? "date-block-risk-high text-xs font-semibold"
                            : cssForValue
                        )}
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <div className="pl-3">
                        <WFStatusCell
                          title={
                            submittalWFStatus?.submit_by_subcontractor?.label
                          }
                          value={
                            submittalWFStatus?.submit_by_subcontractor?.date
                          }
                          milestoneCompleted={
                            submittalWFStatus?.submit_by_subcontractor
                              ?.milestoneCompleted
                          }
                        />
                        <div>
                          <WFStatusCell
                            title={
                              submittalWFStatus?.design_review_complete?.label
                            }
                            value={
                              submittalWFStatus?.design_review_complete?.date
                            }
                            milestoneCompleted={
                              submittalWFStatus?.design_review_complete
                                ?.milestoneCompleted
                            }
                          />
                        </div>
                      </div>

                      <div className="pr-2">
                        <Button
                          size="small"
                          className="text-xs"
                          onClick={() => {
                            history.push(
                              `/project/${projectId}/submittals/${submittal?.id}`
                            );
                          }}
                        >
                          View Submittal
                        </Button>
                      </div>
                    </div>
                  </div>
                </Panel>
              );
            })}
          </Collapse>
        ) : (
          <span className="text-xs text-neutral-400">
            No links added{" "}
            {canEditLinkingPage && (
              <Link
                data-testid="link-to-add"
                to={`/project/${projectId}/schedule/material-linking?&materialId=${materialDetailsState?.id}`}
              >
                Click to add
              </Link>
            )}
          </span>
        )}
      </Form.Item>
      <Form.Item
        label={
          <span>
            SCHEDULE ACTIVITIES
            {linkedMaterialActivities?.length
              ? ` (${linkedMaterialActivities?.length})`
              : ""}
          </span>
        }
      >
        {linkedMaterialActivities?.length > 0 ? (
          <Collapse
            accordion
            expandIconPosition="end"
            className="custom-collapse"
          >
            {linkedMaterialActivities?.map((link: any) => {
              const floatVal = Math.floor(
                (link?.gantt_task?.total_slack || 0) / 8
              );

              return (
                <Panel
                  header={schedulePanelHeader(link)}
                  key={link.task_id}
                  className="custom-panel"
                >
                  {link?.reverse_dependency ? (
                    <div className="flex items-center bg-[#EFEFEF] rounded h-6 space-x-1 px-2 w-[186px]">
                      <span className="flex items-center">
                        <SwapOutlined className="text-[#8C8C8C]" />
                      </span>
                      <span className="flex items-center font-normal text-xs">
                        Activity is the Predecessor
                      </span>
                    </div>
                  ) : (
                    <div className="w-full text-[10px] px-2">
                      <div className="flex">
                        <div className="flex w-full space-x-1">
                          <span className="text-[#8E8E8E] font-medium">
                            {link?.linked_to_end_date
                              ? "Planned End Date:"
                              : "Planned Start Date:"}
                          </span>
                          <span className="text-[#000000CC]">
                            {link?.linked_to_end_date
                              ? DateUtils.format(
                                  link?.gantt_task.end_date,
                                  "MM-DD-YYYY"
                                )
                              : DateUtils.format(
                                  link?.gantt_task.start_date,
                                  "MM-DD-YYYY"
                                )}
                          </span>
                        </div>
                        {link?.gantt_task?.actual_start_date && (
                          <div className="flex w-full space-x-1">
                            <span className="text-[#8E8E8E] font-medium">
                              Actual Start Date:
                            </span>
                            <span className="text-[#000000CC]">
                              {DateUtils.format(
                                link?.gantt_task?.actual_start_date,
                                "MM-DD-YYYY"
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="flex">
                        <div className="flex w-full space-x-1">
                          <span className="text-[#8E8E8E] font-medium">
                            Float:
                          </span>
                          <span
                            className={`text-[#000000CC] ${
                              link?.gantt_task?.critical ? "font-bold" : ""
                            }`}
                          >
                            {floatVal} days
                          </span>
                        </div>
                        {link?.gantt_task?.actual_start_date &&
                          link?.gantt_task?.progress !== 100 &&
                          !link?.gantt_task?.actual_finish_date && (
                            <div className="flex w-full space-x-1">
                              <span className="text-[#8E8E8E] font-medium w-[146px]">
                                Progress:
                              </span>
                              <span className="text-[#000000CC] w-full">
                                {`${link?.gantt_task?.progress}%`}
                              </span>
                            </div>
                          )}
                        {link?.gantt_task?.progress === 100 &&
                          link?.gantt_task?.actual_finish_date && (
                            <div className="flex w-full space-x-1">
                              <span className="text-[#8E8E8E] font-medium w-[146px]">
                                Actual End date:
                              </span>
                              <span className="text-[#000000CC] w-full">
                                {DateUtils.format(
                                  link?.gantt_task?.actual_finish_date,
                                  "MM-DD-YYYY"
                                )}
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                  )}
                </Panel>
              );
            })}
          </Collapse>
        ) : (
          <span className="text-xs text-neutral-400">
            No links added{" "}
            {canEditLinkingPage && (
              <Link
                to={`/project/${projectId}/schedule/material-linking?&materialId=${materialDetailsState?.id}`}
              >
                Click to add
              </Link>
            )}
          </span>
        )}
      </Form.Item>
    </div>
  );
}

export default MaterialLinks;
