import { ColDef, ColGroupDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { GridLoadingIndicator } from "components/widgets";
import { useEffect, useState } from "react";

function CiqAgGridUtil<TData = any>(props: {
  rowData: Array<TData> | null | undefined;
  columnDefs: (ColDef<TData> | ColGroupDef<TData>)[];
  defaultColDef?: ColDef<TData>;
  context?: any;
  overlayNoRowsTemplate?: any;
  gridRef: React.RefObject<AgGridReact<TData>>;
}) {
  const {
    gridRef,
    rowData,
    columnDefs,
    context,
    defaultColDef = {
      sortable: true,
      editable: false,
      filter: false,
      resizable: true,
      menuTabs: []
    },
    overlayNoRowsTemplate = "No Data Found"
  } = props;

  const [isGridReady, setGridReady] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (isGridReady && gridRef && gridRef.current) {
        gridRef?.current?.api?.sizeColumnsToFit();
      }
    }
    window.addEventListener("resize", handleResize);
  }, [gridRef, isGridReady]);
  return (
    <div className="grow w-full h-full pt-1 ag-theme-alpine">
      <AgGridReact<TData>
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        headerHeight={36}
        loadingOverlayComponent={GridLoadingIndicator}
        onGridReady={() => {
          setGridReady(true);
          gridRef.current?.api.sizeColumnsToFit();
        }}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        context={context}
      />
    </div>
  );
}
export default CiqAgGridUtil;
