import { PaperClipOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import AttachmentTag from "components/widgets/attachment-tag";
import UserAvatar from "components/widgets/user-avatar";
import { useMemo, useRef, useState } from "react";
import { getUser } from "services/auth";
import {
  downloadFileFromS3andOpenInNewTab,
  fileSelectValidator
} from "utils/utils";
import { getFileDownloadURL } from "services/file-mgmt";
import { ActivityCardPermissions } from "models/types";
import FileValidatorErrorRenderer from "components/widgets/file-validator-error-renderer";
import { TNewDateBlock } from "components/date-block/models";

import { useFeatureComments } from "entity-app/hooks/feature-comments";

import {
  ErrorMessages,
  ConfigForMaterialCommentFiles,
  MaterialNotReleaseStatus
} from "../../constants";
import FeatureActivityCardItem from "./feature-activity-item";

const FILE_UPLOAD_LIMIT = 5;

const fileSelectionLimitReachedMsg =
  ErrorMessages.comments.fileSelectionLimitReached.replace(
    "$count$",
    FILE_UPLOAD_LIMIT.toString()
  );

function FeatureCommentsTabComponent(props: {
  permissions: ActivityCardPermissions;
  dateBlock?: TNewDateBlock;
  featureKey: string;
  featureId: string;
}) {
  const { permissions = [], dateBlock, featureKey, featureId } = props;

  const fileInputRef: any = useRef(null);
  const [filesToUpload, setFilesToUpload] = useState<any>([]);
  const [commentFieldValue, setcommentFieldValue] = useState<any>("");

  const {
    comments: commentsData,
    submitComment,
    isSubmittingComment,
    submittingCommentId
  } = useFeatureComments(featureId, featureKey);

  // console.log("commentsData ", commentsData);

  const loggedInUserInfo = useMemo(getUser, []);
  const loggedInUserFullName = loggedInUserInfo
    ? `${loggedInUserInfo["first-name"] ?? ""} ${
        loggedInUserInfo["last-name"] ?? ""
      }`.trim()
    : "";

  const filestoUploadCount = filesToUpload.length;

  const fileLimitReached = filestoUploadCount === FILE_UPLOAD_LIMIT;

  const onFileSelect = (event: any) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray: File[] = Array.from(selectedFiles);
    const fileValidatorResponse = fileSelectValidator(
      selectedFilesArray,
      ConfigForMaterialCommentFiles
    );

    if (!fileValidatorResponse.allFilesPassed) {
      message.error(
        <FileValidatorErrorRenderer
          errors={fileValidatorResponse.errorMessages}
        />,
        6
      );
    }

    const selectFilesCount = fileValidatorResponse.passedFiles.length;
    if (filestoUploadCount + selectFilesCount > FILE_UPLOAD_LIMIT) {
      message.warning(fileSelectionLimitReachedMsg);
      return;
    }

    const newFiles = fileValidatorResponse.passedFiles.map((file: any) => {
      return {
        name: file.name,
        file
      };
    });
    if (filesToUpload.length + newFiles.length > FILE_UPLOAD_LIMIT) {
      message.warning(fileSelectionLimitReachedMsg);
      return;
    }
    setFilesToUpload([...filesToUpload, ...newFiles]);
    fileInputRef.current.value = "";
  };

  const onFileRemove = (index: number) => {
    const files = filesToUpload.filter((file: any, fIdx: number) => {
      return index !== fIdx;
    });
    setFilesToUpload(files);
  };

  const onSubmit = async () => {
    const response = await submitComment(commentFieldValue, filesToUpload);
    if (response.success) {
      setcommentFieldValue("");
      setFilesToUpload([]);
    }
  };

  const onFileClick = async (data: any) => {
    message.success(ErrorMessages.comments.fileDownloadStarted);
    try {
      const fileDownloadURLResp: any = await getFileDownloadURL([
        data.blob_key
      ]);
      await downloadFileFromS3andOpenInNewTab(
        fileDownloadURLResp.data.success.urls[data.blob_key],
        data.file_type
      );
    } catch (ex: any) {
      console.log("ex ", ex);
      const errorMsg: string =
        ex.response?.data?.error ||
        ex.message ||
        "Unable to download attachment";
      message.error(errorMsg);
    }
  };

  const getWFStepLabelForComment = (comment: any) => {
    let workflowStepText: any = "";
    if (!dateBlock) return workflowStepText;
    if (
      comment.workflow_template_id &&
      comment.workflow_step &&
      comment.workflow_template_id === dateBlock.workflow_template_id &&
      dateBlock
    ) {
      workflowStepText =
        dateBlock[comment.workflow_step as keyof TNewDateBlock];
    }

    if (comment.workflow_template_id && !comment.workflow_step) {
      workflowStepText = MaterialNotReleaseStatus;
    }
    return workflowStepText;
  };

  const cannotCreateComment = !permissions.includes("create_comment");

  return (
    <div className="CommentsTabComponent h-full max-h-full overflow-hidden flex flex-col min-h-0">
      <div className="px-2 pb-4 pt-0.5 border-0 border-[#00000033] border-b border-solid">
        <div className="mt-2 relative flex gap-x-1">
          <div>
            <div className="w-9 h-9 overflow-hidden">
              <UserAvatar
                fullName={loggedInUserFullName}
                classList="font-semibold"
              />
            </div>
          </div>
          <div className="w-full">
            <TextArea
              rows={3}
              placeholder="Add comment (max. 300 characters)"
              style={{ resize: "none" }}
              onChange={(event: any) => {
                setcommentFieldValue(event.target.value);
              }}
              value={commentFieldValue}
              disabled={cannotCreateComment || isSubmittingComment}
              title={
                cannotCreateComment ? ErrorMessages.PermissionNotGranted : ""
              }
              maxLength={300}
              showCount
            />
          </div>
          <button
            type="button"
            className="bg-transparent border-0 absolute bottom-6 right-3.5 flex items-center p-1 cursor-pointer"
            onClick={() => {
              fileInputRef.current.click();
            }}
            title={fileLimitReached ? fileSelectionLimitReachedMsg : ""}
            disabled={
              cannotCreateComment || fileLimitReached || isSubmittingComment
            }
          >
            <PaperClipOutlined
              className={
                cannotCreateComment || fileLimitReached || isSubmittingComment
                  ? "text-gray-400"
                  : ""
              }
            />
          </button>
          <input
            style={{ display: "none" }}
            ref={fileInputRef}
            type="file"
            multiple
            onChange={onFileSelect}
            disabled={cannotCreateComment}
          />
        </div>
        <div className="mt-2 flex justify-end items-start gap-2">
          {filesToUpload.length ? (
            <div className="grow flex flex-wrap items-center gap-x-1.5 gap-y-1.5">
              {filesToUpload.map((file: any, index: number) => {
                return (
                  <AttachmentTag
                    onDelete={() => {
                      onFileRemove(index);
                    }}
                    showDelete={!isSubmittingComment}
                    item={file}
                  />
                );
              })}
            </div>
          ) : null}
          <div className="w-[100px] text-right">
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                cannotCreateComment ||
                (!commentFieldValue.trim() && !filestoUploadCount) ||
                isSubmittingComment
              }
              loading={isSubmittingComment}
              onClick={onSubmit}
              title={
                cannotCreateComment ? ErrorMessages.PermissionNotGranted : ""
              }
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
      {commentsData?.comment && (
        <div className="overflow-auto px-2">
          {commentsData?.comment
            .filter((comment: any) => {
              return comment.id !== submittingCommentId;
            })
            .map((comment: any) => {
              const workflowStepText: any = getWFStepLabelForComment(comment);
              return (
                <FeatureActivityCardItem
                  key={comment.id}
                  commentData={comment}
                  onFileClick={onFileClick}
                  workflowStepText={workflowStepText}
                />
              );
            })}
        </div>
      )}
    </div>
  );
}

export default FeatureCommentsTabComponent;
