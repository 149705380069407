/* eslint-disable no-nested-ternary */
import { useState, useEffect, useMemo } from "react";
import {
  Button,
  Card,
  Form,
  Modal,
  Select,
  Tooltip,
  Upload,
  message
} from "antd";
import {
  LoadingOutlined,
  CheckCircleFilled,
  LinkOutlined,
  DeleteOutlined,
  CloseCircleOutlined
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { SubmittalLog } from "models/submittal-log";
import { CloseAndDistributeSubmittal } from "components/close-and-distribute";
import BtnAlternateAssign from "components/widgets/btn-alternate-assign";
import { DateUtils } from "utils/dateutils";
import DatePickerWithIcon from "components/date-picker-with-icon/date-picker-with-icon";
import { ESubmittalStatus, ErrorMessages } from "../../../constants";

const { Option } = Select;

const getAssigneeActualDateStates = () => {
  return [
    {
      label: "Assigned to Subcontractor on",
      id: "actual_milestone_1"
    },
    {
      label: "Submitted by Subcontractor on",
      id: "actual_milestone_2"
    },
    {
      label: "Submitted for Design Review on",
      id: "actual_milestone_3"
    },
    {
      label: "Design Review Completed on",
      id: "actual_milestone_4"
    },
    {
      label: "Submittal Distributed on",
      id: "actual_milestone_5"
    }
  ];
};

interface IDocumentObject {
  file: File;
  status: number;
}
interface IReviewStatusCard {
  gqlClientForProject: any;
  workflowOverride: {
    isWorkflowInOverrideMode: boolean;
    setIsWorkflowInOverrideMode: Function;
  };
  showReviewOption: boolean;
  assignDisabledTooltipMsg: string;
  submittalWorkflowResponses: any;
  isRevisionMode: boolean;
  cannotRespondToWorkflow: boolean;
  editableFields: Array<string>;
  updatedData: SubmittalLog;
  localUpdateChanges: (data: any) => void;
  submittalData: SubmittalLog;
  submittalViewState: {
    status: number;
    userType: string;
  };
  isLoggedInUserMainInWorkflow: boolean;
  showOverrideWorkflowBtn: boolean;
  isLoggedInUserGC: boolean;
  isLoggedInUserGcAdmin: boolean;
  loggedInUserId: string;
  isLoggedInUserOwner: boolean;
  isLoggedInUserSC: boolean;
  isLoggedInUserEngineer: boolean;
  isLoggedInUserArchitect: boolean;
  updateSubmittalOne: any;
  UploadAttachment: any;
  projectId: string;
  contentClassNames?: string;
  submittalHistory: any;
}
export default function ReviewStatesCard({
  gqlClientForProject,
  workflowOverride,
  showReviewOption,
  assignDisabledTooltipMsg,
  submittalWorkflowResponses,
  isRevisionMode,
  cannotRespondToWorkflow,
  editableFields,
  updatedData,
  submittalViewState,
  isLoggedInUserMainInWorkflow,
  showOverrideWorkflowBtn,
  isLoggedInUserGC,
  isLoggedInUserGcAdmin,
  loggedInUserId,
  submittalData,
  localUpdateChanges,
  isLoggedInUserOwner,
  isLoggedInUserSC,
  isLoggedInUserEngineer,
  isLoggedInUserArchitect,
  updateSubmittalOne,
  UploadAttachment,
  projectId,
  contentClassNames,
  submittalHistory
}: IReviewStatusCard) {
  const assigneeActualDateStates = useMemo(() => {
    return getAssigneeActualDateStates();
  }, []);

  const [reviewVal, setReviewVal] = useState(submittalWorkflowResponses[0]?.id);
  const [altAssignee, setAltAssignee] = useState({
    userName: "",
    userId: "",
    selected: false
  });

  const [currentActualDateState, setCurrentActualDateState] = useState({
    label: "",
    id: "",
    value: DateUtils.dateTimeObj(),
    previousDate: null as any
  });

  const { isWorkflowInOverrideMode, setIsWorkflowInOverrideMode } =
    workflowOverride;
  const [submittalAssignInProg, setSubmittalAssignInProg] = useState(false);
  const [commentVal, setCommentVal] = useState("");
  const [uploadedDocumentList, setUploadedDocumentList] = useState<
    Array<IDocumentObject>
  >([]);
  const [isAltAssignee, setIsAltAssigne] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const [openDistributeModel, setOpenDistributeSubmittalModel] =
    useState(false);
  let markAsDone = false;
  const showAdvAssignBtn =
    // submittalViewState.userType === EUserTypes[EUserTypes.SUBCONTRACTOR] &&
    loggedInUserId === submittalData.assignee;
  const updateSubmittalSuccessResp = [
    "Updated Successfully",
    "Ball In Court updated"
  ];
  const updateSubmittalErrResp = ["No Updates Allowed", "Update Failed"];

  useEffect(() => {
    if (submittalData?.date_block_submittals?.length > 0) {
      const getIndex = () => {
        if (submittalData.workflow_status === ESubmittalStatus.CREATE) return 0;
        if (submittalData.workflow_status === ESubmittalStatus.SUBMIT) return 1;
        if (submittalData.workflow_status === ESubmittalStatus.GC_REVIEW)
          return reviewVal > 4 ? 0 : 2;
        if (submittalData.workflow_status === ESubmittalStatus.EXTERNAL_REVIEW)
          return 3;
        if (
          submittalData.workflow_status ===
          ESubmittalStatus.READY_FOR_DISTRIBUTION
        )
          return reviewVal > 4 ? 0 : 4;
        return 0;
      };

      const currentStateIndex = getIndex();
      const dateBlock: any = submittalData.date_block_submittals[0];

      const previousDate =
        currentStateIndex > 0
          ? dateBlock[assigneeActualDateStates[currentStateIndex - 1].id]
          : null;

      setCurrentActualDateState({
        value: previousDate
          ? DateUtils.dateTimeObj(previousDate)
          : DateUtils.dateTimeObj(),
        previousDate,
        ...assigneeActualDateStates[currentStateIndex]
      });
    }
  }, [
    assigneeActualDateStates,
    reviewVal,
    submittalData?.date_block_submittals,
    submittalData.workflow_status
  ]);

  useEffect(() => {
    setIsAltAssigne(false);
    if (loggedInUserId === submittalData?.submittal_assignee?.id) {
      if (
        submittalData.workflow_status === ESubmittalStatus.SUBMIT &&
        submittalData?.submittal_assignee?.id !==
          submittalData?.submitter_user?.id
      ) {
        setIsAltAssigne(true);
      }

      if (
        submittalData.workflow_status === ESubmittalStatus.GC_REVIEW &&
        submittalData?.submittal_assignee?.id !== submittalData.gc_reviewer
      ) {
        setIsAltAssigne(true);
      }

      if (
        submittalData.workflow_status === ESubmittalStatus.EXTERNAL_REVIEW &&
        submittalData?.submittal_assignee?.id !== submittalData?.design_reviewer
      ) {
        setIsAltAssigne(true);
      }

      if (
        submittalViewState.status === ESubmittalStatus.READY_FOR_DISTRIBUTION &&
        submittalData?.submittal_assignee?.id !== submittalData?.gc_reviewer
      ) {
        setIsAltAssigne(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittalData]);
  try {
    markAsDone =
      isLoggedInUserGC &&
      submittalViewState.status === ESubmittalStatus.REVIEW &&
      updatedData.submittal_status_history_details[0].submittal_response_id &&
      updatedData.submittal_status_history_details[0].submittal_response_id <=
        4 &&
      updatedData.submittal_status_history_details[1].assignee ===
        updatedData.design_reviewer &&
      reviewVal <= 4;
  } catch (ex) {
    //
  }

  const WFResponses = useMemo(() => {
    if (!submittalWorkflowResponses) return null;

    const responseState = {
      hasDRRejected: false,
      responses: submittalWorkflowResponses
    };

    if (submittalData.workflow_status === ESubmittalStatus.GC_REVIEW) {
      const latestSubmittalWFHistoryItem: any = submittalHistory?.find(
        (history: any) => {
          return history.status !== null;
        }
      );
      const responseId = latestSubmittalWFHistoryItem?.submittal_response_id;

      if (responseId && responseId > 4) {
        responseState.hasDRRejected = true;
        responseState.responses = submittalWorkflowResponses.filter(
          (WFResponse: any) => {
            return WFResponse.id > 4;
          }
        );
      }
    }
    return responseState;
  }, [
    submittalWorkflowResponses,
    submittalHistory,
    submittalData.workflow_status
  ]);

  useEffect(() => {
    if (WFResponses) {
      setReviewVal(WFResponses.responses[0]?.id);
    }
  }, [WFResponses]);

  const getAttachBtnDisabledMsg = () => {
    if (cannotRespondToWorkflow) {
      return ErrorMessages.PermissionNotGranted;
    }

    if (isWorkflowInOverrideMode) return "";

    if (!editableFields.includes("attachment")) {
      return "Only the Ball In court user can currently add attachments";
    }

    return "";
  };
  const overrideWFConfirmDialog = () => {
    modal.confirm({
      content: "Are you sure you want override the workflow?",
      onOk() {
        setIsWorkflowInOverrideMode(true);
      },
      okText: "Confirm"
    });
  };

  const getNextAssignee = () => {
    if (isLoggedInUserGC) {
      if (
        // when submittal is just created
        submittalViewState.status === ESubmittalStatus.CREATE
      ) {
        if (!submittalData.submitter && !submittalData.submitter_unregistered)
          return {
            foundUser: null,
            userNameStr: ""
          };

        if (submittalData.submitter && submittalData.submitter_user) {
          return {
            type: "actual",
            foundUser: submittalData.submitter_user,
            userNameStr: `${submittalData.submitter_user.first_name} ${submittalData.submitter_user.last_name}`
          };
        }
        if (submittalData.submitter_unregistered) {
          return {
            type: "poc",
            foundUser: { id: submittalData.submitter_unregistered },
            userNameStr: submittalData.submitter_unregistered
          };
        }
      }
      // when assigned to a submitter
      if (
        submittalViewState.status === ESubmittalStatus.SUBMIT &&
        submittalData.gc_reviewer_user
      ) {
        return {
          type: "actual",
          foundUser: submittalData.gc_reviewer_user,
          userNameStr: `${submittalData.gc_reviewer_user.first_name} ${submittalData.gc_reviewer_user.last_name}`
        };
      }

      // when its assigned to GC User for Review and review status is positive, assign to design reviewer
      if (
        (submittalData.workflow_status === ESubmittalStatus.GC_REVIEW ||
          submittalData.workflow_status ===
            ESubmittalStatus.READY_FOR_DISTRIBUTION) &&
        reviewVal <= 4
      ) {
        if (submittalData.design_reviewer) {
          return {
            type: "actual",
            foundUser: submittalData.design_reviewer_user,
            userNameStr: `${submittalData.design_reviewer_user.first_name} ${submittalData.design_reviewer_user.last_name}`
          };
        }
        if (submittalData.design_reviewer_unregistered) {
          return {
            type: "poc",
            foundUser: { id: submittalData.design_reviewer_unregistered },
            userNameStr: submittalData.design_reviewer_unregistered
          };
        }
      }

      // when its assigned to GC User for Review and review status is negative, assign it back to submitter
      if (
        (submittalData.workflow_status === ESubmittalStatus.GC_REVIEW ||
          submittalData.workflow_status ===
            ESubmittalStatus.READY_FOR_DISTRIBUTION) &&
        reviewVal > 4
      ) {
        if (submittalData.submitter_user) {
          return {
            type: "actual",
            foundUser: submittalData.submitter_user,
            userNameStr: `${submittalData.submitter_user.first_name} ${submittalData.submitter_user.last_name}`
          };
        }

        if (submittalData.submitter_unregistered) {
          return {
            type: "poc",
            foundUser: { id: submittalData.submitter_unregistered },
            userNameStr: submittalData.submitter_unregistered
          };
        }
      }

      // when its assigned to DR User for Review and review status can be anything, always assign it GC
      if (
        submittalData.workflow_status === ESubmittalStatus.EXTERNAL_REVIEW &&
        submittalData.gc_reviewer
      ) {
        // console.log("in if: ", submittalData.gc_reviewer_user);
        return {
          foundUser: submittalData.gc_reviewer_user,
          userNameStr: `${submittalData.gc_reviewer_user.first_name} ${submittalData.gc_reviewer_user.last_name}`
        };
      }
    }

    if (
      (isLoggedInUserSC || isLoggedInUserOwner) &&
      submittalData.gc_reviewer
    ) {
      return {
        type: "actual",
        foundUser: submittalData.gc_reviewer_user,
        userNameStr: `${submittalData.gc_reviewer_user.first_name} ${submittalData.gc_reviewer_user.last_name}`
      };
    }

    if (
      (isLoggedInUserArchitect || isLoggedInUserEngineer) &&
      submittalData.gc_reviewer
    ) {
      return {
        type: "actual",
        foundUser: submittalData.gc_reviewer_user,
        userNameStr: `${submittalData.gc_reviewer_user.first_name} ${submittalData.gc_reviewer_user.last_name}`
      };
    }

    return {
      foundUser: null,
      userNameStr: ""
    };
  };

  const assignSubmittal = async () => {
    setSubmittalAssignInProg(true);

    const nextAssignee = getNextAssignee();

    const nextAssigneeUser = nextAssignee.foundUser;

    // console.log("nextAssignee ", nextAssignee);

    const updateSubmittalValues: any = {
      submittal_id: updatedData.id
    };
    if (isLoggedInUserGcAdmin) {
      updateSubmittalValues.actual_milestone_date =
        DateUtils.formatDateWithLunchTime(currentActualDateState.value);
    }

    const dateBlock = updatedData.date_block_submittals[0] as any;
    dateBlock[currentActualDateState.id] = isLoggedInUserGcAdmin
      ? updateSubmittalValues.actual_milestone_date
      : DateUtils.formatDateWithLunchTime(DateUtils.dateTimeObj());

    localUpdateChanges({ date_block_submittals: [dateBlock] });

    // check actual or poc
    if (nextAssignee.type === "actual") {
      updateSubmittalValues.assign_to =
        nextAssigneeUser.user_id || nextAssigneeUser.id;
    } else if (nextAssigneeUser) {
      updateSubmittalValues.assignee_unregistered =
        nextAssigneeUser.user_id || nextAssigneeUser.id;
    }

    if (commentVal.trim() !== "") {
      updateSubmittalValues.comment = commentVal.trim();
    }

    if (showReviewOption) {
      updateSubmittalValues.submittal_response_id = reviewVal;
    }

    if (isWorkflowInOverrideMode) {
      if (submittalData.assignee) {
        updateSubmittalValues.on_behalf_of = submittalData.assignee;
      }
      if (submittalData.assignee_unregistered) {
        updateSubmittalValues.on_behalf_of_unregistered =
          submittalData.assignee_unregistered;
      }
    }

    // console.log("updateSubmittalValues ", updateSubmittalValues);

    try {
      const updateResponse = await updateSubmittalOne(updateSubmittalValues);
      const cloneOfUploadedDocumentList = uploadedDocumentList.map(
        (docs: IDocumentObject) => ({
          file: docs.file,
          status: docs.status
        })
      );
      // eslint-disable-next-line no-restricted-syntax
      for (const index of Array.from(
        Array(uploadedDocumentList.length).keys()
      )) {
        cloneOfUploadedDocumentList[index].status = 2; // Loading state
        setUploadedDocumentList([...cloneOfUploadedDocumentList]);
        try {
          // eslint-disable-next-line no-await-in-loop
          await UploadAttachment({
            file: uploadedDocumentList[index].file,
            onSuccess: () => {}
          });
          cloneOfUploadedDocumentList[index].status = 3; // Success State
          setUploadedDocumentList([...cloneOfUploadedDocumentList]);
        } catch {
          cloneOfUploadedDocumentList[index].status = 4; // Fail State
          setUploadedDocumentList([...cloneOfUploadedDocumentList]);
        }
      }
      setUploadedDocumentList([]);
      setSubmittalAssignInProg(false);

      const updateResponseMsg =
        updateResponse.data.update_submittal_one.message;

      if (updateSubmittalSuccessResp.includes(updateResponseMsg)) {
        message.success(updateResponseMsg);
        setIsWorkflowInOverrideMode(false);
      }

      if (updateSubmittalErrResp.includes(updateResponseMsg)) {
        modal.error({ content: updateResponseMsg });
        return;
      }
    } catch (ex) {
      console.log(ex);
      modal.error({ content: "An error occured" });
      setSubmittalAssignInProg(false);
      return;
    }
    setCommentVal("");
    setUploadedDocumentList([]);
  };

  const onSelectAltAssign = (assigneeId: string, assigneeUser: string) => {
    setAltAssignee({
      userName: assigneeUser,
      userId: assigneeId,
      selected: true
    });
  };
  const clearAltAssignee = () => {
    setAltAssignee({
      userName: "",
      userId: "",
      selected: false
    });
  };

  const alternateAssignSubmittal = async (assigneeId: string) => {
    const postObj: any = {
      assignee: assigneeId
    };
    const comment = commentVal.trim();
    if (comment !== "") {
      postObj.comment = comment;
    }

    setSubmittalAssignInProg(true);
    try {
      const cloneOfUploadedDocumentList = uploadedDocumentList.map(
        (docs: IDocumentObject) => ({
          file: docs.file,
          status: docs.status
        })
      );
      // eslint-disable-next-line no-restricted-syntax
      for (const index of Array.from(
        Array(uploadedDocumentList.length).keys()
      )) {
        cloneOfUploadedDocumentList[index].status = 2; // Loading state
        setUploadedDocumentList([...cloneOfUploadedDocumentList]);
        try {
          // eslint-disable-next-line no-await-in-loop
          await UploadAttachment({
            file: uploadedDocumentList[index].file,
            onSuccess: () => {}
          });
          cloneOfUploadedDocumentList[index].status = 3; // Success State
          setUploadedDocumentList([...cloneOfUploadedDocumentList]);
        } catch {
          cloneOfUploadedDocumentList[index].status = 4; // Fail State
          setUploadedDocumentList([...cloneOfUploadedDocumentList]);
        }
      }
      setUploadedDocumentList([]);
      const updateResponse = await updateSubmittalOne(
        {
          submittal_id: submittalData.id,
          ...postObj
        },
        gqlClientForProject
      );
      const updateResponseMsg =
        updateResponse.data.update_submittal_one.message;

      if (updateSubmittalSuccessResp.includes(updateResponseMsg)) {
        message.success(updateResponseMsg);
        setCommentVal("");
      }

      if (updateSubmittalErrResp.includes(updateResponseMsg)) {
        modal.error({ content: updateResponseMsg });
        return;
      }
    } catch (ex: any) {
      modal.error({ content: ex.message });
    }
    clearAltAssignee();
    setSubmittalAssignInProg(false);
  };

  const showActionButton = () => {
    return (
      submittalData.workflow_status === ESubmittalStatus.CREATE ||
      isWorkflowInOverrideMode ||
      submittalData.assignee === loggedInUserId ||
      submittalData.assignee_unregistered === loggedInUserId
    );
  };
  // eslint-disable-next-line consistent-return
  const actionButtonLabel = () => {
    if (submittalData.workflow_status === ESubmittalStatus.CREATE) {
      return "Assign";
    }
    if (submittalData.workflow_status === ESubmittalStatus.SUBMIT) {
      return altAssignee.selected
        ? "Assigne"
        : reviewVal > 4
        ? "Return"
        : "Submit";
    }
    if (submittalData.workflow_status === ESubmittalStatus.GC_REVIEW) {
      return altAssignee.selected
        ? "Assigne"
        : reviewVal > 4
        ? "Return"
        : "Submit for Review";
    }
    if (submittalData.workflow_status === ESubmittalStatus.EXTERNAL_REVIEW) {
      return altAssignee.selected
        ? "Assigne"
        : reviewVal > 4
        ? "Return"
        : "Submit Review";
    }
    if (
      submittalData.workflow_status === ESubmittalStatus.READY_FOR_DISTRIBUTION
    ) {
      return altAssignee.selected
        ? "Assigne"
        : reviewVal > 4
        ? "Return"
        : "Close and Distribute";
    }
    return "Confirm";
  };
  const getAssigneeRow = () => {
    if (!altAssignee.selected) {
      return (
        <div className="flex items-center justify-between mb-3">
          <div className="review-action">
            {markAsDone ? (
              <b>Close and Distribute</b>
            ) : isAltAssignee ? (
              ""
            ) : (
              <span>
                <b>Assign To:</b> {getNextAssignee().userNameStr || "Submitter"}
              </span>
            )}
          </div>
          {showOverrideWorkflowBtn && !isWorkflowInOverrideMode && (
            <div>
              <Button onClick={overrideWFConfirmDialog}>
                Override Workflow
              </Button>
            </div>
          )}
          {submittalViewState.status !== ESubmittalStatus.DONE &&
            showAdvAssignBtn && (
              <>
                {!isAltAssignee ? <b>OR</b> : ""}
                <BtnAlternateAssign
                  disabled={
                    isRevisionMode ||
                    cannotRespondToWorkflow ||
                    submittalAssignInProg
                  }
                  tooltip={assignDisabledTooltipMsg}
                  projectId={projectId}
                  submittalData={submittalData}
                  onAssigneeSelect={onSelectAltAssign}
                  loading={submittalAssignInProg}
                />
              </>
            )}
        </div>
      );
    }
    return (
      <div className="alt-assignee-chip flex items-center justify-between py-1 px-3 mb-3 border-solid border border-neutral-200">
        <span>
          <b>Assign To: </b>
          {altAssignee.userName}
        </span>
        <CloseCircleOutlined onClick={clearAltAssignee} />
      </div>
    );
  };

  const isCommentDisabled =
    !isWorkflowInOverrideMode &&
    (isRevisionMode ||
      cannotRespondToWorkflow ||
      !editableFields.includes("comment") ||
      !updatedData.gc_reviewer ||
      (!updatedData.design_reviewer &&
        !updatedData.design_reviewer_unregistered) ||
      (!updatedData.submitter && !updatedData.submitter_unregistered));

  const isLoggedInUserWFGCUser = submittalData.gc_reviewer === loggedInUserId;
  const isCurrentAssigneeGCUser =
    submittalData.assignee === submittalData.gc_reviewer;

  const showDrRejectionMsgToGC =
    (isLoggedInUserGcAdmin &&
      isCurrentAssigneeGCUser &&
      isLoggedInUserWFGCUser &&
      WFResponses?.hasDRRejected) ||
    (isLoggedInUserGcAdmin &&
      isCurrentAssigneeGCUser &&
      !isLoggedInUserWFGCUser &&
      isWorkflowInOverrideMode &&
      WFResponses?.hasDRRejected);

  return (
    <Tooltip
      title={
        isRevisionMode
          ? "Go to the latest revision to respond to the workflow."
          : ""
      }
      arrowPointAtCenter
    >
      <Card className="card-block">
        <div className="pb-2 flex items-center justify-between">
          <span className="uppercase text-xs font-bold tracking-wider">
            WORKFLOW RESPONSE
          </span>
          {isWorkflowInOverrideMode && (
            <span className="text-sm text-amber-600">
              You are overriding the workflow
            </span>
          )}
        </div>
        <div className={`overflow-auto ${contentClassNames || ""}`}>
          <div>
            {showReviewOption && (
              <Tooltip title={assignDisabledTooltipMsg} placement="rightBottom">
                <Form.Item label="Response" className="!leading-none">
                  <Select
                    value={reviewVal}
                    onChange={(val) => {
                      setReviewVal(val);
                    }}
                    disabled={
                      !isWorkflowInOverrideMode &&
                      (isRevisionMode ||
                        cannotRespondToWorkflow ||
                        !editableFields.includes("review_status"))
                    }
                  >
                    {WFResponses?.responses?.map((response: any) => {
                      return (
                        <Option key={response.id} value={response.id}>
                          {response.response}
                        </Option>
                      );
                    })}
                  </Select>
                  {showDrRejectionMsgToGC && (
                    <span className="text-xs text-amber-600">
                      The submittal has been rejected by the Design Reviewer.
                      You have to reject and reassign the submittal to the
                      subcontractor.
                    </span>
                  )}
                </Form.Item>
              </Tooltip>
            )}
            <div>
              <Tooltip title={assignDisabledTooltipMsg} placement="rightBottom">
                <Form.Item label="Comment">
                  <TextArea
                    rows={2}
                    value={commentVal}
                    onChange={(event) => {
                      setCommentVal(event.target.value);
                    }}
                    disabled={isCommentDisabled}
                  />
                </Form.Item>
              </Tooltip>
            </div>
            <div>
              {uploadedDocumentList.map(
                (docs: IDocumentObject, index: number) => {
                  return (
                    <div className="border-solid border border-neutral-200 workflow-document-prereview">
                      {docs.status === 2 ? (
                        <LoadingOutlined className="mr-2" />
                      ) : docs.status === 3 ? (
                        <CheckCircleFilled className="mr-2" />
                      ) : (
                        <LinkOutlined className="mr-2" />
                      )}
                      <div className="workflow-document-prereview--title truncate">
                        {docs.file.name}
                      </div>
                      <DeleteOutlined
                        onClick={() => {
                          setUploadedDocumentList(
                            uploadedDocumentList.filter(
                              (_: IDocumentObject, itemIndex: number) =>
                                itemIndex !== index
                            )
                          );
                        }}
                      />
                    </div>
                  );
                }
              )}
            </div>
            {isLoggedInUserGcAdmin && !isCommentDisabled && (
              <div className="mb-1 flex py-1 space-x-1">
                <div>{currentActualDateState?.label}</div>
                <div className="font-semibold">
                  {DateUtils.format(currentActualDateState?.value)}
                </div>
                <DatePickerWithIcon
                  value={currentActualDateState?.value}
                  disabledDate={(current) => {
                    if (currentActualDateState?.previousDate) {
                      const previousDate = DateUtils.dateTimeObj(
                        currentActualDateState?.previousDate
                      );
                      return previousDate.isAfter(current);
                    }
                    return false;
                  }}
                  onChange={(val) => {
                    if (val)
                      setCurrentActualDateState((prev) => ({
                        ...prev,
                        value: val
                      }));
                  }}
                />
              </div>
            )}
            <div className="mb-2 flex justify-end">
              <Upload
                showUploadList={false}
                multiple
                customRequest={({ file }: any) => {
                  setUploadedDocumentList([
                    ...uploadedDocumentList,
                    {
                      file,
                      status: 1
                    }
                  ]);
                }}
              >
                {isAltAssignee ? (
                  <Button
                    size="small"
                    type="text"
                    className="workflow-document-upload"
                  >
                    Add Attachment
                  </Button>
                ) : (
                  <Button
                    size="small"
                    type="text"
                    className="workflow-document-upload"
                    title={getAttachBtnDisabledMsg()}
                    disabled={
                      !isWorkflowInOverrideMode &&
                      (submittalAssignInProg ||
                        isRevisionMode ||
                        (submittalViewState.status !==
                          ESubmittalStatus.CREATE &&
                          !isLoggedInUserMainInWorkflow) ||
                        cannotRespondToWorkflow ||
                        !editableFields.includes("assign") ||
                        !updatedData.gc_reviewer ||
                        (!updatedData.design_reviewer &&
                          !updatedData.design_reviewer_unregistered) ||
                        (!updatedData.submitter &&
                          !updatedData.submitter_unregistered) ||
                        submittalAssignInProg)
                    }
                  >
                    Add Attachment
                  </Button>
                )}
              </Upload>
            </div>
            {!(
              submittalViewState.status === ESubmittalStatus.DONE ||
              (submittalViewState.status === ESubmittalStatus.CREATE &&
                !(
                  updatedData.design_reviewer ||
                  updatedData.design_reviewer_unregistered
                ))
            ) ? (
              <>
                {getAssigneeRow()}
                {showActionButton() ? (
                  submittalViewState.status !== ESubmittalStatus.DONE ? (
                    <div className="flex justify-end">
                      {markAsDone ? (
                        <>
                          <Button
                            type="primary"
                            onClick={() => {
                              setOpenDistributeSubmittalModel(true);
                            }}
                            disabled={
                              !isWorkflowInOverrideMode &&
                              (loggedInUserId !== updatedData.gc_reviewer ||
                                submittalAssignInProg)
                            }
                          >
                            {actionButtonLabel()}
                          </Button>
                          <Modal
                            title="Submittal Summary"
                            width={500}
                            open={openDistributeModel}
                            onOk={() => {
                              setOpenDistributeSubmittalModel(false);
                              assignSubmittal();
                            }}
                            onCancel={() => {
                              setOpenDistributeSubmittalModel(false);
                            }}
                            okText="Close and Distribute"
                            destroyOnClose
                          >
                            <CloseAndDistributeSubmittal
                              submittalData={submittalData}
                              submittalWorkflowResponses={
                                submittalWorkflowResponses
                              }
                              reviewVal={reviewVal}
                              submittalViewState={submittalViewState}
                              commentVal={commentVal}
                            />
                          </Modal>
                        </>
                      ) : isAltAssignee ? (
                        <Button
                          type="primary"
                          onClick={() => {
                            if (altAssignee.selected) {
                              alternateAssignSubmittal(altAssignee.userId);
                            } else {
                              assignSubmittal();
                            }
                          }}
                          disabled={!altAssignee.selected}
                          loading={submittalAssignInProg}
                          title={assignDisabledTooltipMsg}
                        >
                          {actionButtonLabel()}
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          onClick={() => {
                            if (altAssignee.selected) {
                              alternateAssignSubmittal(altAssignee.userId);
                            } else {
                              assignSubmittal();
                            }
                          }}
                          disabled={
                            !isWorkflowInOverrideMode &&
                            (isRevisionMode ||
                              (submittalViewState.status !==
                                ESubmittalStatus.CREATE &&
                                !isLoggedInUserMainInWorkflow) ||
                              cannotRespondToWorkflow ||
                              !editableFields.includes("assign") ||
                              !updatedData.gc_reviewer ||
                              (!updatedData.design_reviewer &&
                                !updatedData.design_reviewer_unregistered) ||
                              (!updatedData.submitter &&
                                !updatedData.submitter_unregistered) ||
                              submittalAssignInProg)
                          }
                          loading={submittalAssignInProg}
                          title={assignDisabledTooltipMsg}
                        >
                          {actionButtonLabel()}
                        </Button>
                      )}
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        {contextHolder}
      </Card>
    </Tooltip>
  );
}
