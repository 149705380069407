function TitleDescriptionTooltip({
  title,
  description
}: {
  title: string;
  description: string;
}) {
  return (
    <div className="text-xs p-1">
      <div>{title}</div>
      <div className="text-[#707070] mt-1">{description}</div>
    </div>
  );
}

export default TitleDescriptionTooltip;
