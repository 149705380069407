import { useMemo } from "react";
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  ICellRendererParams
} from "ag-grid-community";
import { useParams } from "react-router";
import { CellValueRendererWithEditIcon } from "components/cell-renders";
import {
  featureStatusCellRenderer,
  twoRowCellRenderer,
  twoRowGetQuickFilterText
} from "../../../entity-app/shared-components/log-render-components/cell-renders";
import {
  coldefHiddenField,
  coldefNavigation,
  coldefSingleField,
  coldefUser
} from "../../../entity-app/shared-components/log-render-components/col-defs";

export const useDesignPackageAgGrid = (props: {
  featureKey: string;
  milestonesColumns: any;
  designPackageHeader: any;
  cannotWriteAccessOfDesignPackage: boolean;
}) => {
  const {
    featureKey,
    milestonesColumns,
    designPackageHeader,
    cannotWriteAccessOfDesignPackage
  } = props;
  const { projectId } = useParams() as any;

  const isGridCellEditable = (params: CellClassParams<any, any>) => {
    return !cannotWriteAccessOfDesignPackage && params?.colDef?.editable;
  };

  const defaultColDef = useMemo(
    () =>
      ({
        sortable: true,
        editable: false,
        filter: true,
        resizable: true,
        menuTabs: ["filterMenuTab"],
        minWidth: 100,
        cellRenderer: (params: ICellRendererParams) => {
          return CellValueRendererWithEditIcon(params, true);
        },
        cellEditorPopup: false
      } as ColDef<any>),
    []
  );

  const columnDefs = useMemo(
    () =>
      [
        coldefNavigation(
          {
            field: "auto_inc_value",
            headerName: designPackageHeader?.id?.toUpperCase(),
            pinned: "left",
            lockPosition: "left",
            checkboxSelection: true,
            width: 100,
            sort: "asc",
            lockVisible: true
          },
          projectId,
          featureKey
        ),
        coldefSingleField({
          field: "number",
          headerName: designPackageHeader?.number?.toUpperCase(),
          pinned: "left",
          lockPosition: "left",
          width: 120,
          editable: true
        }),
        coldefSingleField({
          field: "title",
          headerName: designPackageHeader?.title?.toUpperCase(),
          width: 220,
          pinned: "left",
          lockPosition: "left",
          cellRenderer: twoRowCellRenderer,
          cellRendererParams: {
            featureKey,
            projectId
          },
          getQuickFilterText: twoRowGetQuickFilterText,
          editable: true,
          lockVisible: true
        }),
        coldefHiddenField({
          field: "description",
          headerName: designPackageHeader?.description?.toUpperCase(),
          pinned: "left",
          lockPosition: "left"
        }),
        coldefSingleField({
          field: "milestone_state",
          headerName: designPackageHeader?.workflow_status?.toUpperCase(),
          lockVisible: true,
          width: 200,
          minWidth: 200,
          tooltipField: undefined,
          tooltipValueGetter: undefined,
          editable: true,
          cellRenderer: featureStatusCellRenderer
        }),
        coldefUser({
          def: {
            headerName:
              designPackageHeader?.responsible_designer_id?.toUpperCase() ||
              "RESPONSIBLE DESIGN FIRM",
            editable: true
          },
          user: {
            id: "design_firm_id",
            firstName: "design_firm_name",
            lastName: "",
            dropdownType: "RDF_dropdown"
          }
        }),
        coldefUser({
          def: {
            headerName: designPackageHeader?.design_firm_id?.toUpperCase(),
            editable: true
          },
          user: {
            id: "responsible_designer_id",
            firstName: "responsible_designer_first_name",
            lastName: "responsible_designer_last_name",
            statusId: "responsible_designer_status_id",
            dropdownType: "RD_dropdown",
            dependentId: "design_firm_id"
          }
        }),
        coldefUser({
          def: {
            headerName:
              designPackageHeader?.gc_representative_id?.toUpperCase(),
            editable: true
          },
          user: {
            id: "gc_representative_id",
            firstName: "gc_representative_first_name",
            lastName: "gc_representative_last_name"
          }
        }),
        ...milestonesColumns.allColumns
      ] as (ColDef<any> | ColGroupDef<any>)[],
    [projectId, featureKey, milestonesColumns.allColumns, designPackageHeader]
  );
  return {
    columnDefs,
    defaultColDef,
    milestonesColumns,
    isGridCellEditable
  };
};
