import { FileOutlined, InteractionOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { DATE_FORMAT_MMDDYYYY_HHMM_A, DateUtils } from "utils/dateutils";
import { ESubmittalStatus } from "../../constants";
import { FinalDeadlineHistoryDiv } from "./final-deadline-history";

const contextReviewersIds = createContext({
  gcReviewerId: null,
  designReviewerId: null
});

function WorkflowHistoryItem({ history }: any) {
  const reviewerIds = useContext(contextReviewersIds);
  // console.log("reviewerIds ", reviewerIds);
  let createdAt = "";
  try {
    createdAt = DateUtils.format(
      history.created_at,
      DATE_FORMAT_MMDDYYYY_HHMM_A
    );
  } catch (ex) {
    //
  }

  let assigneeUserType = null;
  if (
    (history.assignee || history.assignee_unregistered) ===
    reviewerIds.gcReviewerId
  ) {
    assigneeUserType = "GC";
  }
  if (
    (history.assignee || history.assignee_unregistered) ===
    reviewerIds.designReviewerId
  ) {
    assigneeUserType = "DR";
  }

  let approvalText = "";
  if (history.submittal_response_id && history.submittal_response_id <= 4) {
    approvalText = "approved";
  } else {
    approvalText = "rejected";
  }

  let actionText = "";
  if (!history.status || history.status === ESubmittalStatus.SUBMIT) {
    if (history.submittal_response_id !== null) {
      // when GC rejects a submittal and assigns to SC
      actionText = `${approvalText} the submittal.`;
    } else {
      // when GC assigns to SC for the first time
      actionText = "assigned the submittal to";
    }
  }
  // when SC submits to GC
  if (
    history.status === ESubmittalStatus.REVIEW &&
    assigneeUserType === "GC" &&
    history.submittal_response_id === null
  ) {
    actionText = "submitted the submittal to";
  }
  // when DR approves/rejects and assigns to GC
  if (
    history.status === ESubmittalStatus.REVIEW &&
    assigneeUserType === "GC" &&
    history.submittal_response_id !== null
  ) {
    actionText = `${approvalText} the submittal${
      !history.on_behalf_of && !history.on_behalf_of_unregistered ? "." : ""
    }`;
  }
  // when GC approves/rejects and assigns to DR
  if (history.status === ESubmittalStatus.REVIEW && assigneeUserType === "DR") {
    actionText = `${approvalText} the submittal.`;
  }

  const assigneeUserCompany = history?.assignee_user?.project_users[0]
    .subscription_vendor
    ? history?.assignee_user?.project_users[0].subscription_vendor.name
    : history.assignee_user?.subscription_users[0].subscription.name;

  const userCompany = history?.user?.project_users[0]?.subscription_vendor
    ? history?.user?.project_users[0]?.subscription_vendor?.name
    : history.user?.subscription_users[0]?.subscription?.name;

  const onBehalfOfUserCompany = history?.on_behalf_of_user?.project_users[0]
    .subscription_vendor
    ? history?.on_behalf_of_user?.project_users[0].subscription_vendor.name
    : history?.on_behalf_of_user?.subscription_users[0].subscription.name;

  // console.log("assigneeUserCompany ", assigneeUserCompany);
  // console.log("userCompany ", userCompany);

  return (
    <div className="leading-relaxed tracking-wide">
      <div className="flex justify-between">
        <InteractionOutlined />
        <div className="text-xs text-neutral-600">{createdAt}</div>
      </div>
      {history.status === ESubmittalStatus.CREATE && (
        <div>
          <span className="font-semibold">
            {history.user.first_name} {history.user.last_name}
          </span>
          <span className="text-neutral-600 text-xs">{`, ${userCompany}`}</span>{" "}
          created the submittal.
        </div>
      )}

      {((!history.status && !history.field) ||
        history.status === ESubmittalStatus.REVIEW ||
        history.status === ESubmittalStatus.SUBMIT) && (
        <div>
          <span className="font-semibold">
            {history.user.first_name} {history.user.last_name}
          </span>
          <span className="text-neutral-600 text-xs">{`, ${userCompany}`}</span>{" "}
          {actionText}{" "}
          {!history.submittal_response_id && (
            <span>
              {history.assignee_user ? (
                <>
                  <span className="font-semibold">
                    {history.assignee_user?.first_name}{" "}
                    {history.assignee_user?.last_name}
                  </span>
                  <span className="text-neutral-600 text-xs">
                    {`, ${assigneeUserCompany}`}
                    {!history.on_behalf_of &&
                      !history.on_behalf_of_unregistered &&
                      "."}
                  </span>
                </>
              ) : (
                <span className="font-semibold">
                  {history.assignee_unregistered}.
                </span>
              )}
            </span>
          )}
          {history.on_behalf_of && (
            <>
              <span>
                {" "}
                on behalf of{" "}
                <span className="font-semibold">
                  {history.on_behalf_of_user.first_name}{" "}
                  {history.on_behalf_of_user.last_name}
                </span>
              </span>
              <span className="text-neutral-600 text-xs">
                {`, ${onBehalfOfUserCompany}`}.
              </span>
            </>
          )}
          {history.on_behalf_of_unregistered && (
            <span>
              {" "}
              on behalf of{" "}
              <span className="font-semibold">
                {history.on_behalf_of_unregistered}.
              </span>
            </span>
          )}
          {history.submittal_response && (
            <div>
              <span className="font-bold">Response:</span>{" "}
              {history?.submittal_response?.response}
            </div>
          )}
        </div>
      )}

      {history.status === ESubmittalStatus.DONE && (
        <div>
          <span className="font-semibold">
            {history.user.first_name} {history.user.last_name}
          </span>
          <span className="text-neutral-600 text-xs">{`, ${userCompany}`}</span>{" "}
          <span>closed and distributed the submittal.</span>
        </div>
      )}
      {history?.field && history?.field === "Final Deadline" && (
        <FinalDeadlineHistoryDiv history={history} />
      )}
    </div>
  );
}

function FileAddedHistoryItem({ file }: any) {
  let createdAt = "";
  try {
    createdAt = DateUtils.format(file.created_at, DATE_FORMAT_MMDDYYYY_HHMM_A);
  } catch (ex) {
    //
  }
  return (
    <div className="leading-relaxed tracking-wide">
      <div className="flex justify-between">
        <FileOutlined />
        <div className="text-xs text-neutral-600">{createdAt}</div>
      </div>
      <span className="font-semibold">
        {file.created_by_user.first_name} {file.created_by_user.last_name}
      </span>
      <span className="text-neutral-600 text-xs">
        ({file.created_by_user.email})
      </span>{" "}
      added <span className="font-semibold">{file.file_name}</span>
      {file.on_behalf_of_user_id && (
        <>
          <span>
            {" "}
            on behalf of{" "}
            <span className="font-semibold">
              {file.attachments_user?.first_name}{" "}
              {file.attachments_user?.last_name}
            </span>
          </span>
          <span className="text-neutral-600 text-xs">
            ({file.attachments_user?.email})
          </span>
        </>
      )}
      {file.on_behalf_of && (
        <span>
          {" "}
          on behalf of{" "}
          <span className="font-semibold">{file.on_behalf_of}</span>
        </span>
      )}
    </div>
  );
}

function FileDeletedHistoryItem({ history }: any) {
  let createdAt = "";
  try {
    createdAt = DateUtils.format(
      history.created_at,
      DATE_FORMAT_MMDDYYYY_HHMM_A
    );
  } catch (ex) {
    //
  }
  return (
    <div className="leading-relaxed tracking-wide">
      <div className="flex justify-between">
        <FileOutlined />
        <div className="text-xs text-neutral-600">{createdAt}</div>
      </div>
      <span className="font-semibold">
        {history.assignee_user.first_name} {history.assignee_user.last_name}
      </span>
      <span className="text-neutral-600 text-xs">
        ({history.assignee_user.email})
      </span>{" "}
      deleted <span className="font-semibold">{history.comment}</span>
    </div>
  );
}

export function SubmittalHistory(props: any) {
  const {
    submittalHistory,
    allAttachments,
    gcReviewerId,
    designReviewerId,
    submittalFinalDeadlineHistory
  } = props;

  const [allHistory, setAllHistory] = useState<any>();

  useEffect(() => {
    if (submittalHistory && allAttachments && submittalFinalDeadlineHistory) {
      const mergedHistory = [
        ...submittalHistory,
        ...allAttachments,
        ...submittalFinalDeadlineHistory
      ];

      const sortedHistory = mergedHistory.sort((a, b) => {
        const dt1 = DateUtils.dateTimeObj(a.created_at);
        const dt2 = DateUtils.dateTimeObj(b.created_at);
        return dt2.diff(dt1);
      });

      setAllHistory(sortedHistory);
    }
  }, [submittalHistory, allAttachments, submittalFinalDeadlineHistory]);

  const getHistoryItemComponent = (history: any) => {
    if (history.file_name) return <FileAddedHistoryItem file={history} />;

    if (history.action && history.action.toLowerCase().includes("deleted"))
      return <FileDeletedHistoryItem history={history} />;

    return (
      <Tooltip
        title={history.comment ? `Comment: ${history.comment}` : ""}
        placement="left"
      >
        <div>
          <WorkflowHistoryItem history={history} />
        </div>
      </Tooltip>
    );
  };

  const reviewerIds = useMemo(() => {
    return { gcReviewerId, designReviewerId };
  }, [designReviewerId, gcReviewerId]);

  if (allHistory && allHistory.length) {
    return (
      <contextReviewersIds.Provider value={reviewerIds}>
        <div className="h-full overflow-auto">
          {allHistory.map((history: any) => {
            return (
              <div
                key={history.id}
                className="w-full mb-1 border border-solid border-[#00000033] text-three !text-black  px-2 py-2 bg-white"
              >
                {getHistoryItemComponent(history)}
              </div>
            );
          })}
        </div>
      </contextReviewersIds.Provider>
    );
  }

  return (
    <div className="h-full flex items-center justify-center">
      <span className="text-xs text-neutral-400">
        No submittal history is available.
      </span>
    </div>
  );
}
