import { v4 as uuidV4 } from "uuid";
import { TDateBlockType, TNewDateBlock } from "./models";

const canShowCurrentDelay = (inputDateBlock: TNewDateBlock) => {
  return (
    inputDateBlock?.calculation_details?.projecting_entity
      ?.delay_in_forwarded_date !== true
  );
};

export const transformSubmittalDateBlock = (inputDateBlock: any) => {
  const dateBlock: TDateBlockType = {
    mileStones: [],
    id: inputDateBlock.id,
    submittal_id: inputDateBlock.submittal_id,
    manual_entry: !!inputDateBlock.manual_entry_date,
    manual_entry_date: inputDateBlock.manual_entry_date,
    planned_float: inputDateBlock.pre_WF_start_float,
    actual_float: inputDateBlock.effective_float,
    current_delay: inputDateBlock.current_delay,
    today_date: inputDateBlock.today_date,
    final_deadline_date: inputDateBlock.final_deadline_date,
    workflow_status: inputDateBlock.workflow_status,
    risk_level: inputDateBlock.risk_level,
    can_show_current_delay: canShowCurrentDelay(inputDateBlock)
  };

  for (let index = 1; index <= 10; index += 1) {
    if (!inputDateBlock[`name_milestone_${index}`]) break;
    dateBlock.mileStones.push({
      key: uuidV4(),
      sequence_no: index,
      name_milestone: inputDateBlock[`name_milestone_${index}`],
      actual: inputDateBlock[`actual_milestone_${index}`],
      actualId: `actual_milestone_${index}`,
      planned: inputDateBlock[`planned_milestone_${index}`],
      plannedID: `planned_milestone_${index}`,
      name_offset: inputDateBlock[`name_offset_${index}`],
      offset: inputDateBlock[`offset_${index}`],
      offsetID: `offset_${index}`,
      projected: inputDateBlock[`projected_milestone_${index}`],
      projectedId: `projected_milestone_${index}`
    });
  }

  return dateBlock;
};

export const transformMaterialDateBlock = (inputDateBlock: any) => {
  const dateBlock: TDateBlockType = {
    mileStones: [],
    id: inputDateBlock.id,
    material_id: inputDateBlock.material_id,
    manual_entry: !!inputDateBlock.manual_entry_date,
    manual_entry_date: inputDateBlock.manual_entry_date,
    planned_float: inputDateBlock.pre_WF_start_float,
    actual_float: inputDateBlock.effective_float,
    wf_override_reason: inputDateBlock.wf_override_reason,
    current_delay: inputDateBlock.current_delay,
    today_date: inputDateBlock.today_date,
    final_deadline_date: inputDateBlock.final_deadline_date,
    workflow_status: inputDateBlock.workflow_status,
    risk_level: inputDateBlock.risk_level,
    can_show_current_delay: canShowCurrentDelay(inputDateBlock)
  };

  for (let index = 1; index <= 10; index += 1) {
    if (!inputDateBlock[`name_milestone_${index}`]) break;
    dateBlock.mileStones.push({
      key: uuidV4(),
      sequence_no: index,
      name_milestone: inputDateBlock[`name_milestone_${index}`],
      actual: inputDateBlock[`actual_milestone_${index}`],
      actualId: `actual_milestone_${index}`,
      planned: inputDateBlock[`planned_milestone_${index}`],
      plannedID: `planned_milestone_${index}`,
      name_offset: inputDateBlock[`name_offset_${index}`],
      offset: inputDateBlock[`offset_${index}`],
      offsetID: `offset_${index}`,
      projected: inputDateBlock[`projected_milestone_${index}`],
      projectedId: `projected_milestone_${index}`
    });
  }

  return dateBlock;
};
