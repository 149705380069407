import { Button } from "antd";

function ReportCard({
  icon,
  title,
  detail,
  onClick
}: {
  icon: any;
  title: string;
  detail: any;
  onClick: () => void;
}) {
  return (
    <div className="flex flex-col w-[309px] h-[380px] rounded border-solid border-[#F0F0F0] border mt-5">
      <div className="w-full bg-gray-100 font-medium text-sm items-center py-2 px-3">
        {title}
      </div>
      <div className="grow">
        <div className="h-full px-4">
          <div className="p-1 py-6 text-center">{icon}</div>
          <div className="px-1 text-sm">{detail}</div>
        </div>
      </div>
      <div className="p-4">
        <Button type="primary" className="h-8 w-full " onClick={onClick}>
          View
        </Button>
      </div>
    </div>
  );
}
export default ReportCard;
