import { gql } from "@apollo/client";

const SUBMITTAL_LIST_WF_PERF = gql`
  query SubmittalList($where: submittal_list_view_interface_bool_exp = {}) {
    submittal_list_wf_mode_func(order_by: { id: asc }, where: $where) {
      actual_assigned_date
      actual_ext_review_completed_date
      actual_ext_review_submitted_date
      actual_submittal_distributed_date
      actual_trade_partner_submitted_date
      assignee_unregistered
      assignee_user_id
      attachment_count
      assignee_status
      gc_reviewer_status
      submitter_status
      design_reviewer_status
      created_by
      description
      design_reviewer_unregistered
      design_reviewer_user_id
      gc_reviewer_user_id
      history
      id
      materials
      planned_ext_review_completed_date
      planned_submittal_distributed_date
      planned_trade_partner_submitted_date
      project_id
      responsible_contractor_id
      responsible_contractor_name
      responsible_contractor_unregistered
      revision
      snippet_coordinates
      snippet_file_key
      spec_name
      spec_no
      state
      state_numeric
      submittal_sequence_id
      submitter_unregistered
      submitter_user_id
      tasks
      title
      type
      workflow_status
      spec_section_file_key
      next_planned_deadline
      next_action
      due_date
      risk_level
      priority
      watchers
      created_at
      planned_float
      effective_float
      date_block_data
      submittal_number
    }
  }
`;

const SUBMITTAL_LIST_INTG_PERF = gql`
  query SubmittalList($where: submittal_list_view_interface_bool_exp = {}) {
    submittal_list_intg_mode_func(order_by: { id: asc }, where: $where) {
      actual_assigned_date
      actual_ext_review_completed_date
      actual_ext_review_submitted_date
      actual_submittal_distributed_date
      actual_trade_partner_submitted_date
      assignee_unregistered
      assignee_user_id
      attachment_count
      assignee_status
      gc_reviewer_status
      submitter_status
      design_reviewer_status
      created_by
      description
      design_reviewer_unregistered
      design_reviewer_user_id
      gc_reviewer_user_id
      history
      id
      materials
      planned_ext_review_completed_date
      planned_submittal_distributed_date
      planned_trade_partner_submitted_date
      project_id
      responsible_contractor_id
      responsible_contractor_name
      responsible_contractor_unregistered
      revision
      snippet_coordinates
      snippet_file_key
      spec_name
      spec_no
      state
      state_numeric
      submittal_sequence_id
      submitter_unregistered
      submitter_user_id
      tasks
      title
      type
      workflow_status
      revision_date_blocks
      spec_section_file_key
      next_planned_deadline
      next_action
      due_date
      risk_level
      priority
      watchers
      created_at
      planned_float
      effective_float
      date_block_data
      submittal_number
      redirect_url
    }
  }
`;

export { SUBMITTAL_LIST_WF_PERF, SUBMITTAL_LIST_INTG_PERF };
