import { Drawer } from "antd";
import { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import { DateUtils } from "utils/dateutils";
import UserAvatar from "components/user-avatar/user-avatar";
import { RequsetLeadTimeData } from "../type-definitions";

function MobileRequestDetailsSection({
  requestDetails
}: {
  requestDetails: RequsetLeadTimeData;
}) {
  const [open, setOpen] = useState(false);

  return (
    <div className="w-full max-w-full overflow-hidden">
      <button
        type="button"
        className="border-0 bg-transparent m-0 p-0 px-3 w-full py-1.5 font-semibold text-center"
        onClick={() => {
          setOpen(true);
        }}
        data-testid="mobile-request-details-section-button"
      >
        {requestDetails.project_name} | {requestDetails.gc_organization_name}
      </button>
      <Drawer
        title={
          <div className="w-full max-w-full flex justify-between items-center space-x-2 overflow-hidden">
            <div className="grow overflow-hidden">
              <div className="font-semibold truncate text-sm">
                {requestDetails.project_name} |{" "}
                {requestDetails.gc_organization_name}
              </div>
            </div>
            <button
              type="button"
              className="ciq-icon-button"
              onClick={() => {
                setOpen(false);
              }}
              data-testid="mobile-request-details-section-close-button"
            >
              <CloseOutlined />
            </button>
          </div>
        }
        placement="bottom"
        closable={false}
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        key="bottom"
        className="request-details-drawer"
        destroyOnClose
      >
        <div>
          <div className="font-semibold text-sm">
            General Contractor&apos;s Notes:
          </div>
          <div className="text-sm mt-1">
            <Paragraph title={requestDetails.notes} className="text-color-1">
              {requestDetails.notes}
            </Paragraph>
          </div>
        </div>
        <div className="px-5 py-3 background-color-2 rounded-md text-sm">
          <div className="text-color-8 font-semibold">Requested On</div>
          <div>
            {DateUtils.format(requestDetails.created_on, "MM-DD-YYYY")} at{" "}
            {DateUtils.format(requestDetails.created_on, "hh:mm A (z)")}
          </div>
          <div className="mt-4 text-color-8 font-semibold">Requested By</div>
          <div className="mt-2 md:mt-4 flex items-start space-x-2">
            <div>
              <UserAvatar
                url=""
                fullName={`${requestDetails.gc_first_name} ${requestDetails.gc_last_name}`}
                isRect={false}
              />
            </div>
            <div>
              <div className="text-sm font-semibold mb-1">
                {requestDetails.gc_organization_name}
              </div>
              <div className="text-sm text-color-1">
                {requestDetails.gc_first_name} {requestDetails.gc_last_name}
              </div>
              <div className="text-sm italic text-color-1">
                &lt;{requestDetails.gc_email}&gt;
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default MobileRequestDetailsSection;
