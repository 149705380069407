export default function MaterialCiqIcon() {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7153 8.86654L11.4166 9.28729C11.5546 9.37019 11.5994 9.54933 11.5166 9.68746C11.4919 9.72853 11.4576 9.76289 11.4166 9.7875L6.30015 12.8573C6.11541 12.9682 5.88464 12.9682 5.6999 12.8573L0.583546 9.7875C0.445419 9.70461 0.400624 9.52547 0.483504 9.38733C0.508133 9.34627 0.542491 9.31191 0.583546 9.28729L1.28477 8.86654L6.00002 11.6957L10.7153 8.86654ZM10.7153 6.12487L11.4166 6.54563C11.5546 6.62852 11.5994 6.80766 11.5166 6.94579C11.4919 6.98686 11.4576 7.02122 11.4166 7.04584L6.00002 10.2957L0.583546 7.04584C0.445419 6.96294 0.400624 6.7838 0.483504 6.64567C0.508133 6.6046 0.542491 6.57024 0.583546 6.54563L1.28477 6.12487L6.00002 8.95404L10.7153 6.12487ZM6.30015 0.763301L11.4166 3.83313C11.5546 3.916 11.5994 4.09516 11.5166 4.23329C11.4919 4.27434 11.4576 4.3087 11.4166 4.33333L6.00002 7.5832L0.583546 4.33333C0.445419 4.25046 0.400624 4.0713 0.483504 3.93317C0.508133 3.89211 0.542491 3.85776 0.583546 3.83313L5.6999 0.763301C5.88464 0.652467 6.11541 0.652467 6.30015 0.763301ZM6.00002 1.94379L2.4343 4.08323L6.00002 6.22269L9.56577 4.08323L6.00002 1.94379Z"
        fill="black"
        fillOpacity="0.6"
      />
    </svg>
  );
}
