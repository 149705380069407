import { Tag } from "antd";
// import "./user-tag.scss";

export default function UserTag(props: { label: string }) {
  const { label } = props;
  return (
    <div className="user-tag">
      <Tag
        color="#3B3B3B"
        style={{ lineHeight: 1, padding: "2px 4px" }}
        className=" text-[10px] font-medium"
      >
        {label}
      </Tag>
    </div>
  );
}

export function UserTagV2(props: { label: string }) {
  const { label } = props;
  return (
    <Tag
      color="#3B3B3B"
      style={{ lineHeight: 1, padding: "2px 4px" }}
      className="text-[10px] font-medium m-0"
    >
      {label}
    </Tag>
  );
}
