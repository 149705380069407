import { gql } from "@apollo/client";
import { Button, message, Modal, Tooltip } from "antd";
import CIQDatePicker from "components/custom-date-picker";
import DatePickerIcon from "components/svg-icons/datepicker-icon";
import GrayDatePickerIcon from "components/svg-icons/gray-datepicker-icon";
import { ProjectContext } from "context/ProjectProvider";
import { UPDATE_FEATURE_WORKFLOW_INSTANCE_MILESTONE } from "entity-app/graphQL/ciq-feature-mutations";
import { useCIQMutation } from "hooks/ciq-gql-hooks";
import { Moment } from "moment";
import { TMilestoneFeature } from "pages/preconstruction-log-page/model";
import { useCallback, useContext, useMemo, useState } from "react";
import { DATE_FORMAT_MMDDYYYY, DateUtils } from "utils/dateutils";

import "./feature-status.scss";

export default function FeatureLogSetStatusUI(props: {
  refetchLogData?: Function;
  data: {
    auto_inc_value: number;
    title: string;
    milestones: Array<TMilestoneFeature>;
  };
}) {
  const { data, refetchLogData } = props;
  const headerTitle = `${data.auto_inc_value}-${data.title}`;

  const [visible, setVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(DateUtils.dateTimeObj());
  const { gqlClientForProject } = useContext(ProjectContext);

  const milestoneInfo = useMemo(() => {
    const nextMilestone = data.milestones
      ?.sort((a, b) => a.sequence_no - b.sequence_no)
      .find((x) => !x.actual_date);

    let currentMilestone: TMilestoneFeature | undefined;

    if (nextMilestone && nextMilestone.sequence_no > 1) {
      const sequenceNo = nextMilestone.sequence_no - 1;
      currentMilestone = data.milestones.find(
        (x) => x.sequence_no === sequenceNo
      );
    }

    return { currentMilestone, nextMilestone };
  }, [data.milestones]);

  const [mutationUpdateFeatureWorkflowInstanceMilestone, { loading }] =
    useCIQMutation(gql(UPDATE_FEATURE_WORKFLOW_INSTANCE_MILESTONE), {
      client: gqlClientForProject
    });

  const disabledActualDate = useCallback(
    (current: Moment) => {
      const after = DateUtils.dateTimeObj(current).isAfter(
        DateUtils.dateTimeObj()
      );

      if (!milestoneInfo.currentMilestone?.actual_date) return after;

      const currDateFromPicker = DateUtils.dateTimeObj(current)
        .hours(13)
        .minutes(0)
        .seconds(0);
      const before = currDateFromPicker.isBefore(
        DateUtils.dateTimeObj(milestoneInfo.currentMilestone?.actual_date)
      );
      return before || after;
    },
    [milestoneInfo.currentMilestone]
  );

  const onConfirm = async () => {
    const saveDate = DateUtils.formatDateWithLunchTime(selectedDate);
    const set = { actual_date: saveDate };
    const variables = {
      id: milestoneInfo.nextMilestone?.id,
      set
    };

    const updateResponse = await mutationUpdateFeatureWorkflowInstanceMilestone(
      { variables }
    );

    if (updateResponse.success) {
      message.success("Updated successfully.");
      setVisible(false);
      if (refetchLogData) setTimeout(refetchLogData, 1000);
    } else {
      message.error("Failed to update.");
    }
  };

  return (
    <div className="flex ">
      <Tooltip
        title={
          !milestoneInfo.nextMilestone ? "Workflow completed" : "Update status"
        }
      >
        {!milestoneInfo.nextMilestone ? (
          <div className="flex w-[46px] justify-center">
            <GrayDatePickerIcon />
          </div>
        ) : (
          <Button
            className="flex space-x-2 items-center border-none bg-transparent disabled:bg-slate-600"
            onClick={() => setVisible(true)}
          >
            <DatePickerIcon />
          </Button>
        )}
      </Tooltip>
      <Modal
        title={
          <div className="px-3">{`Update Status of ${headerTitle ?? ""}`}</div>
        }
        bodyStyle={{ height: 330 }}
        open={visible}
        destroyOnClose
        onOk={onConfirm}
        okText="Confirm"
        okButtonProps={{ disabled: loading, loading }}
        cancelButtonProps={{ disabled: loading }}
        onCancel={() => {
          setVisible(false);
        }}
        width={391}
      >
        <div className="flex flex-col feature-status-popover-content justify-center space-y-1">
          <div className=" text-xs w-full text-left">
            <span className="font-semibold uppercase">
              {milestoneInfo.nextMilestone?.name}
            </span>{" "}
            on {DateUtils.format(selectedDate, DATE_FORMAT_MMDDYYYY)}
          </div>
          <CIQDatePicker
            value={selectedDate}
            className="w-full"
            onChange={(val: any) => {
              setSelectedDate(val);
            }}
            open
            getPopupContainer={(trigger: any) => {
              return trigger;
            }}
            showToday={false}
            disabledDate={disabledActualDate}
          />
        </div>
      </Modal>
    </div>
  );
}
