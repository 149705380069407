import { Button, Spin } from "antd";
import { DateUtils } from "utils/dateutils";
import { Link } from "react-router-dom";
import {
  DashboardAlerts,
  FilterNamesEnum,
  NavigationPageEnum
} from "../models";

function AlertMessageItem({ text, alertOn }: { text: any; alertOn?: string }) {
  return (
    <div className="bottom-border p-1 flex justify-between w-full items-center">
      <div className="grow px-1">{text}</div>
      {alertOn && (
        <div className="w-40 flex-none ">
          <div className="alert-message self-center">{alertOn}</div>
        </div>
      )}
    </div>
  );
}

function AlertDashboard({
  alerts,
  activities_modified,
  projectId,
  goToFiltered
}: {
  alerts: DashboardAlerts | undefined;
  activities_modified: number | undefined;
  projectId: string;
  goToFiltered: (
    page: NavigationPageEnum,
    filter: FilterNamesEnum,
    value?: any
  ) => void;
}) {
  return (
    <div className="box-border h-80 w-full">
      <div className="box-header">Alerts</div>
      <div className="overflow-auto h-[278px] pb-10">
        {!alerts ? (
          <div className="flex w-full h-full justify-center items-center">
            <Spin />
          </div>
        ) : (
          <div className="flex-col w-full h-full">
            {!alerts.schedule_version?.import_log_id &&
              !alerts.activities_modified_date &&
              (alerts.new_submittals.count === undefined ||
                alerts.new_submittals.count === 0) && (
                <div className="flex h-full items-center p-2 justify-center text-xs">
                  <div>There are no new alerts for today.</div>
                </div>
              )}

            {alerts.schedule_version?.import_log_id && (
              <AlertMessageItem
                text={
                  <div>
                    New schedule version
                    <Link
                      to={`/project/${projectId}/schedule/project-schedule?&selectedVersionId=${alerts.schedule_version?.import_log_id}`}
                      className="px-1"
                    >
                      {alerts.schedule_version.schedule_date}-
                      {alerts.schedule_version.schedule_name}
                    </Link>
                    is available.
                  </div>
                }
                alertOn={`Alerted on: ${DateUtils.format(
                  alerts.schedule_version.created_at
                )}`}
              />
            )}

            {alerts.schedule_version?.import_log_id &&
              alerts.activities_modified_date &&
              ((activities_modified ?? 0) > 0 ? (
                <AlertMessageItem
                  text={
                    <div>
                      {activities_modified} Activities related to the submittals
                      and/or materials have been modified
                      <Link
                        to={`/project/${projectId}/schedule/change-impact?&selectedVersionId=${alerts.schedule_version?.import_log_id}`}
                        className="px-1"
                      >
                        Click here
                      </Link>
                      to see the Change Impact.
                    </div>
                  }
                  alertOn={`Alerted on: ${DateUtils.format(
                    alerts.activities_modified_date
                  )}`}
                />
              ) : (
                activities_modified !== undefined && (
                  <AlertMessageItem text="No activities related to the submittals and/or materials have been modified." />
                )
              ))}

            {alerts.new_submittals &&
              alerts.new_submittals?.created_date &&
              alerts.new_submittals.count &&
              alerts.new_submittals.count > 0 && (
                <AlertMessageItem
                  text={
                    <div className="flex-col">
                      <div>
                        {alerts.new_submittals.count === 1 ? (
                          <div>
                            {alerts.new_submittals.count} new submittal was
                            added through ACC Build sync today.
                          </div>
                        ) : (
                          <div>
                            {alerts.new_submittals.count} new submittals were
                            added through ACC Build sync today.
                          </div>
                        )}
                      </div>
                      <Button
                        type="link"
                        className="p-0 h-fit"
                        onClick={() => {
                          goToFiltered(
                            NavigationPageEnum.SubmittalLog,
                            FilterNamesEnum.AlertNewSubmittals,
                            alerts?.new_submittals?.created_date
                          );
                        }}
                      >
                        Click here to create a plan for{" "}
                        {alerts.new_submittals.count === 1
                          ? "this submittal"
                          : "these submittals"}
                      </Button>
                    </div>
                  }
                  alertOn={`Alerted on: ${DateUtils.format(
                    alerts?.new_submittals?.created_date
                  )}`}
                />
              )}
          </div>
        )}
      </div>
    </div>
  );
}
export default AlertDashboard;
