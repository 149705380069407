import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Select,
  Switch
} from "antd";
import DistributionListField, {
  DistributionListFieldRef,
  groupIDsByType
} from "components/distribution-list-field/distribution-list-field";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  getCurrentTimeZoneInfo,
  convert24HourTo12Hour,
  getHoursAndMinutesInVariousFormats,
  convert12HourTo24Hour,
  AmPmType
} from "utils/dateutils";
import RedirectIcon from "components/svg-icons/redirect-icon";
import { useParams } from "react-router";
import { IAvailableDistributionLists } from "components/distribution-list-field/types";

import { IScheduleInput } from "./hooks/upcoming-milestones";

const processRecipients = (
  recipients: string[],
  availableDistributionLists: IAvailableDistributionLists | undefined
) => {
  const { distributionGroupIds, userIds } = groupIDsByType(
    recipients,
    availableDistributionLists
  );

  return [
    ...distributionGroupIds.map((id) => ({ distribution_group_id: id })),
    ...userIds.map((id) => ({ user_id: id }))
  ];
};

function ConfigureUpcomingMilestones({
  open,
  setOpen,
  upcomingMilestonesHook
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  upcomingMilestonesHook: any;
}) {
  const { projectId } = useParams() as any;

  const [form] = Form.useForm();
  const distributionListFieldRef = useRef<DistributionListFieldRef>(null);

  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const timeZoneInfo = getCurrentTimeZoneInfo();

  const {
    frequencyOptions,
    weekDaysOptions,
    setNotificationSchedule,
    isSubmitting,
    firstNotificationSchedule: configuredSchedule
  } = upcomingMilestonesHook;

  const timeValuesInVariousFormats = useMemo(() => {
    return getHoursAndMinutesInVariousFormats();
  }, []);

  const setIncomingFieldValues = useCallback(() => {
    const fieldValues: any = {
      frequency: "weekly",
      week_day: null,
      hour: null,
      amPm: null,
      recipients: []
    };

    if (configuredSchedule) {
      const scheduleInput = configuredSchedule.schedule_input as IScheduleInput;

      const { hour, amPm } = convert24HourTo12Hour(scheduleInput.hour);

      fieldValues.frequency = scheduleInput.frequency;
      fieldValues.week_day = scheduleInput.week_day;
      fieldValues.hour = hour;
      fieldValues.amPm = amPm;

      const recipientIds =
        configuredSchedule.notification_schedule_recipients.map(
          (recipient: any) => {
            if (recipient.distribution_group) {
              return recipient.distribution_group.id;
            }

            if (recipient.user) {
              return recipient.user.id;
            }

            return "";
          }
        );

      fieldValues.recipients = recipientIds;
    } else {
      // there is no configuration object, hence no schedule is configured, so set the switch to false
      setNotificationsEnabled(false);
    }

    distributionListFieldRef.current?.setRecipientIds(fieldValues.recipients);
    form.setFieldsValue(fieldValues);
  }, [form, configuredSchedule]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setNotificationsEnabled(configuredSchedule?.enabled);
        setIncomingFieldValues();
      });
    }
  }, [configuredSchedule?.enabled, open, setIncomingFieldValues]);

  useEffect(() => {
    if (open) {
      form.setFieldValue("enabled", notificationsEnabled ? "true" : "false");
    }
  }, [notificationsEnabled, form, open]);

  const onFinish = useCallback(
    async (values: {
      frequency: string;
      week_day: string;
      hour: string;
      amPm: AmPmType;
      recipients: string[];
      enabled: string;
    }) => {
      // console.log("onFinish values", values);
      const payloadRecipients: {
        distribution_group_id?: string;
        user_id?: string;
      }[] = processRecipients(
        values.recipients,
        distributionListFieldRef.current?.getAvailableOptions()
          .availableDistributionLists
      );

      const hourIn24Format = convert12HourTo24Hour(
        parseInt(values.hour, 10),
        values.amPm
      );

      const enabled = values.enabled === "true";

      // if the schedule is configured, then we need to include the id in the payload so that the existing schedule is updated
      const payload: any = {
        schedule: {
          enabled,
          frequency: values.frequency,
          hour: hourIn24Format,
          notification_type: "UPCOMING_MILESTONES",
          week_day: values.week_day,
          ...(configuredSchedule && { id: configuredSchedule.id })
        },
        recipients: payloadRecipients
      };

      // console.log("payload", payload);

      const result = await setNotificationSchedule(payload);

      const successMessage = payload.schedule.enabled
        ? "Email alerts enabled successfully"
        : "Email alerts disabled successfully";

      if (result.success) {
        message.success(successMessage);
        setOpen(false);
      }
    },
    [configuredSchedule, setNotificationSchedule, setOpen]
  );

  const onSwitchChange = useCallback(
    (checked: boolean) => {
      setNotificationsEnabled(checked);
      if (!checked) {
        if (!configuredSchedule?.enabled) {
          // Reset form if turning off without saving
          setIncomingFieldValues();
        } else {
          // Save disabled state if there was a previous configuration
          setTimeout(() => {
            form.validateFields().then(onFinish);
          });
        }
      }
    },
    [configuredSchedule?.enabled, form, onFinish, setIncomingFieldValues]
  );

  return (
    <Modal
      className="custom-drawer"
      title="Procurement Follow-Ups"
      width={420}
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ height: "calc(100vh - 92px)" }}
      footer={null}
      open={open}
      onCancel={() => setOpen(false)}
      destroyOnClose
      data-test-id="upcoming-milestones-report-configure-modal"
    >
      <div className="px-2">
        <div className="px-2">
          <div className="uppercase font-medium text-sm text-[#555555D9]">
            email notification
          </div>
          <div className="px-2">
            <div className="mt-2 mb-4 flex items-center justify-between">
              <div className="text-sm grow text-[#000000E3]">
                Send this report as a recurring email
              </div>
              <div>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={notificationsEnabled}
                  onChange={onSwitchChange}
                  disabled={isSubmitting}
                  data-test-id="upcoming-milestones-report-toggle-switch"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={`px-2 ${notificationsEnabled ? "" : "hidden"}`}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
            disabled={!notificationsEnabled || isSubmitting}
          >
            <div className="px-2">
              <Form.Item name="enabled" className="hidden">
                <Input type="hidden" />
              </Form.Item>
              <Form.Item label="Frequency" name="frequency">
                <Select
                  options={frequencyOptions}
                  disabled
                  data-test-id="upcoming-milestones-report-frequency-select"
                />
              </Form.Item>
              <div className="grid grid-cols-2 gap-x-4">
                <div>
                  <Form.Item
                    label="Day"
                    name="week_day"
                    required
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Select
                      className="w-full"
                      options={weekDaysOptions}
                      data-test-id="upcoming-milestones-report-day-select"
                      placeholder="Select a day"
                    />
                  </Form.Item>
                </div>
                <div>
                  <div className="grid grid-cols-8 gap-x-1">
                    <div className="col-span-5">
                      <Form.Item
                        label={`Time (${timeZoneInfo.code})`}
                        required
                        rules={[{ required: true, message: "Required" }]}
                        name="hour"
                      >
                        <Select
                          className="w-full"
                          options={
                            timeValuesInVariousFormats.options
                              .hoursIn12HourFormatOptions
                          }
                          data-test-id="upcoming-milestones-report-time-select"
                          showSearch
                          placeholder="Select a time"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-span-3">
                      <Form.Item
                        label="AM/PM"
                        required
                        rules={[{ required: true, message: "Required" }]}
                        name="amPm"
                        className="hide-field-label"
                      >
                        <Select
                          className="w-full"
                          options={[
                            { label: "AM", value: "AM" },
                            { label: "PM", value: "PM" }
                          ]}
                          data-test-id="upcoming-milestones-report-time-select"
                          placeholder="AM/PM"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Divider />
            <div>
              <div className="uppercase text-sm font-medium text-[#555555D9]">
                Recipients
              </div>
              <div className="mt-3 px-2">
                <Form.Item
                  name="recipients"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please select at least one distribution group or a user"
                    }
                  ]}
                >
                  <DistributionListField
                    ref={distributionListFieldRef}
                    onChange={(values) => {
                      console.log("values", values);
                      form.setFieldValue("recipients", values);
                    }}
                    dataTestId="upcoming-milestones-report-recipients-select"
                  />
                </Form.Item>
                <div className="flex justify-end">
                  <Button
                    className="text-sm flex items-center"
                    size="small"
                    onClick={() => {
                      window.open(
                        `/project/${projectId}/settings/general/distribution-groups`,
                        "_blank"
                      );
                    }}
                  >
                    <span className="mr-0.5">Manage Distribution Groups</span>
                    <RedirectIcon size={14} fill="#3B3B3BCC" />
                  </Button>
                </div>
              </div>
            </div>
            <Divider className="mb-3" />
            <div className="px-2 flex gap-x-4">
              <Button
                type="default"
                className="flex-1"
                onClick={() => {
                  setOpen(false);
                  setIncomingFieldValues();
                }}
                disabled={isSubmitting}
                data-test-id="upcoming-milestones-report-cancel-button"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className="flex-1"
                htmlType="submit"
                loading={isSubmitting}
                data-test-id="upcoming-milestones-report-save-button"
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
}

export default ConfigureUpcomingMilestones;
