export default function LinkIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M14.7143 12.3568L13.5357 11.1783L14.7143 9.99983C16.0161 8.69808 16.0161 6.58755 14.7143 5.2858C13.4126 3.98405 11.302 3.98405 10.0002 5.2858L8.82175 6.46431L7.64323 5.2858L8.82175 4.10729C10.7743 2.15467 13.9402 2.15467 15.8928 4.10729C17.8454 6.05991 17.8454 9.22575 15.8928 11.1783L14.7143 12.3568ZM12.3572 14.7139L11.1787 15.8924C9.22616 17.845 6.06031 17.845 4.10769 15.8924C2.15507 13.9397 2.15507 10.7739 4.10769 8.82133L5.28621 7.64282L6.46471 8.82133L5.28621 9.99983C3.98446 11.3016 3.98446 13.4122 5.28621 14.7139C6.58796 16.0157 8.6985 16.0157 10.0002 14.7139L11.1787 13.5354L12.3572 14.7139ZM12.3572 6.46431L13.5357 7.64282L7.64323 13.5354L6.46471 12.3568L12.3572 6.46431Z"
        fill="#3B3B3B"
        fillOpacity="0.6"
      />
    </svg>
  );
}
