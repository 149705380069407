export default function SubmittalCiqIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.49984 3.08333V1.33333C2.49984 1.01117 2.76101 0.75 3.08317 0.75H6.82479L7.99145 1.91667H11.2498C11.572 1.91667 11.8332 2.17784 11.8332 2.5V8.33333C11.8332 8.65551 11.572 8.91667 11.2498 8.91667H9.49984V10.6667C9.49984 10.9888 9.23868 11.25 8.9165 11.25H0.749837C0.427674 11.25 0.166504 10.9888 0.166504 10.6667V3.66667C0.166504 3.3445 0.427674 3.08333 0.749837 3.08333H2.49984ZM2.49984 4.25H1.33317V10.0833H8.33317V8.91667H2.49984V4.25ZM3.6665 1.91667V7.75H10.6665V3.08333H7.50822L6.34155 1.91667H3.6665Z"
        fill="black"
        fillOpacity="0.6"
      />
    </svg>
  );
}
