import axios from "axios";
import { BASE_URL } from "./endpoints";

export const getFileFromS3 = async (filePath: string) => {
  try {
    const response = await axios.post(`${BASE_URL}/file/download`, {
      file_keys: [filePath]
    });

    if (response?.data?.success?.urls) {
      const obj: any = response?.data?.success?.urls;
      if (Object.values(obj).length > 0) {
        const url: any = Object.values(obj)[0];
        const res = await axios.get(url);
        return res?.data;
      }
    }
    return [];
  } catch (error) {
    return { error };
  }
};

export const getScheduleFilesComparison = async (
  activeId: string,
  targetedId: string,
  projectToken: string
) => {
  const config = {
    headers: { Authorization: `Bearer ${projectToken}` }
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/v2/schedule/comparison`,
      {
        source_log_id: activeId,
        target_log_id: targetedId
      },
      config
    );

    return response;
  } catch (error) {
    return { error };
  }
};

export const getScheduleImpact = async (
  targetedId: string,
  projectToken: string,
  isMakingActive: boolean
) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${projectToken}` }
    };

    const payload = isMakingActive
      ? { import_log_id: targetedId, update: true }
      : { import_log_id: targetedId };
    const response = await axios.post(
      `${BASE_URL}/v2/scheduleImpact`,
      payload,
      config
    );

    return response;
  } catch (error) {
    return { error };
  }
};

export const getNewScheduleImpact = async (
  targetedId: string,
  projectToken: string,
  isMakingActive: boolean
) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${projectToken}` }
    };

    const payload = isMakingActive
      ? { import_log_id: targetedId, update: true }
      : { import_log_id: targetedId };
    const response = await axios.post(
      `${BASE_URL}/v3/newScheduleImpact`,
      payload,
      config
    );

    return response;
  } catch (error) {
    return { error };
  }
};

export const makeScheduleAsActive = async (
  schedule_Id: string,
  projectToken: string,
  abortSignal?: AbortSignal
) => {
  const config = {
    headers: { Authorization: `Bearer ${projectToken}` },
    signal: abortSignal
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/schedule/create`,
      {
        schedule_id: schedule_Id
      },
      config
    );

    return response;
  } catch (error) {
    return { error };
  }
};

/**
 * This method is going to proivide the schedule impact for two schedule with affected materials.
 *
 * @doc https://constructiviq.atlassian.net/wiki/spaces/Engineerin/pages/164986886/Proposed+Changes+short+term+-+Backend
 *
 * @param importLogId
 * @param sourceLogId
 * @param projectToken
 * @returns
 */
export const getScheduleImpactForMaterial = async (
  importLogId: string,
  sourceLogId: string,
  projectToken: string
) => {
  const config = {
    headers: { Authorization: `Bearer ${projectToken}` }
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/v3/newScheduleImpact`,
      {
        import_log_id: importLogId,
        source_log_id: sourceLogId
      },
      config
    );

    return response;
  } catch (error) {
    return { error };
  }
};
