import { PRIVACY_POLICY_URL } from "config/config";

function PiiConsent(props: Readonly<{ className?: string }>) {
  const { className } = props;
  return (
    <div className={className}>
      By logging in, you agree to ConstructivIQ&apos;s{" "}
      <a
        target="_blank"
        className="text-[#949492] hover:text-[#949492] underline underline-offset-2 hover:underline hover:underline-offset-2 focus:underline"
        href={PRIVACY_POLICY_URL ?? ""}
        rel="noreferrer"
      >
        Privacy Policy
      </a>
      .
    </div>
  );
}

export default PiiConsent;
