import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Checkbox } from "antd";
import CiqAgSearch from "components/ciq-ag-search";
import { GridLoadingIndicator } from "components/widgets";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

function CellWithCheckbox(params: any) {
  const { data, context } = params;
  const { linkedFeatures } = context;
  const isChecked = linkedFeatures.some(
    (feature: any) => feature.id === data.id
  );
  return (
    <div className="flex items-center gap-2">
      <Checkbox
        checked={isChecked}
        onChange={() => {
          context.onCheckBoxAction({
            checked: !isChecked,
            feature: data
          });
        }}
      />
      <span>{data.number}</span>
    </div>
  );
}

function FeaturesGrid({
  featuresList,
  onCheckBoxAction,
  linkedFeatures,
  isLoadingFeaturesList
}: {
  featuresList: any;
  onCheckBoxAction: (data: any) => void;
  linkedFeatures: any[];
  isLoadingFeaturesList: boolean;
}) {
  const gridRef = useRef<AgGridReact<any>>(null);

  const [isGridReady, setGridReady] = useState(false);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      filter: false,
      sortable: true,
      resizable: true,
      editable: false,
      menuTabs: []
    };
  }, []);

  const columnDefs = useMemo<ColDef[]>(() => {
    return [
      {
        headerName: "Number",
        field: "number",
        width: 250,
        suppressSizeToFit: true,
        cellRenderer: CellWithCheckbox
      },
      {
        headerName: "Name",
        field: "title"
      }
    ];
  }, []);

  const onFirstDataRendered = useCallback(() => {
    gridRef?.current?.api?.sizeColumnsToFit();
  }, []);

  useEffect(() => {
    if (isGridReady && gridRef.current && linkedFeatures) {
      gridRef.current.api.refreshCells({
        columns: ["number"],
        force: true
      });
    }
  }, [isGridReady, linkedFeatures]);

  return (
    <div className="h-full w-full overflow-hidden flex flex-col">
      <div className="py-2 uppercase text-xs flex items-center justify-between">
        <div>Link Bid Packages</div>
        <CiqAgSearch
          key="feature_activity_linking_search"
          gridRef={gridRef}
          placeholder="Search"
        />
      </div>
      <div className="ag-theme-alpine flex-1 relative">
        {!featuresList && !isLoadingFeaturesList && (
          <div className="absolute z-10 top-0 left-0 w-full h-full flex items-center justify-center text-[#3B3B3BCC] text-xs italic">
            No Bid Packages found
          </div>
        )}
        {!featuresList && isLoadingFeaturesList ? (
          <div className="absolute z-10 top-0 left-0 w-full h-full flex items-center justify-center">
            <GridLoadingIndicator />
          </div>
        ) : null}
        <AgGridReact<any>
          ref={gridRef}
          rowData={featuresList}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={40}
          onGridReady={() => {
            setGridReady(true);
            gridRef.current?.api.sizeColumnsToFit();
          }}
          suppressRowDrag
          suppressMovableColumns
          suppressCellFocus
          suppressDragLeaveHidesColumns
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          suppressLoadingOverlay
          suppressNoRowsOverlay
          onFirstDataRendered={onFirstDataRendered}
          context={{ onCheckBoxAction, linkedFeatures }}
        />
      </div>
    </div>
  );
}

export default FeaturesGrid;
