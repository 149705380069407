import { DateUtils } from "utils/dateutils";

export function FinalDeadlineHistoryDiv(props: { history: any }) {
  const { history } = props;
  const fieldDiv = <span className="font-semibold ml-1">Final Deadline</span>;
  const userDiv = (
    <>
      <span className="font-semibold">
        {`${history?.user_created?.first_name} ${history?.user_created?.last_name}`}
      </span>
      {`(${history?.user_created?.email})`}
    </>
  );

  // Adding New Final Deadline Date
  if (!history?.old_value) {
    return (
      <div>
        {userDiv}
        <span className="ml-1">
          has manually set the
          {fieldDiv} to
          <span className="font-semibold ml-1">
            {DateUtils.format(history?.new_value)}
          </span>
        </span>
      </div>
    );
  }

  // Updating existing Final Deadline Date
  if (history?.old_value && history?.new_value) {
    return (
      <div>
        {userDiv} has updated the {fieldDiv} from
        <span className="font-semibold ml-1">
          {DateUtils.format(history?.old_value)}
        </span>{" "}
        to{" "}
        <span className="font-semibold ml-1">
          {DateUtils.format(history?.new_value)}
        </span>
      </div>
    );
  }

  // Clearing existing Final Deadline Date
  if (history?.old_value && !history?.new_value) {
    return (
      <div>
        {userDiv} has cleared the manually set {fieldDiv}.
      </div>
    );
  }

  return <div />;
}
