import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Tooltip,
  TreeSelect,
  message
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useContext, useEffect, useMemo, useRef, useState } from "react";

import {
  MUTATION_BULK_UPDATE_MATERIAL_TEMPLATE,
  MUTATION_UPDATE_MATERIALS
} from "services/graphQL/mutations";

import { compareObjectPropertyValues } from "utils/utils";
import SpecNumberNameDropDown from "components/spec-number-name";
import {
  UnitType,
  getTreeFromMeasuremntList
} from "pages/material-details/material-details-tab/units-tree";
import { QUERY_MASTER_UNITS } from "services/graphQL/queries";
import { useProjectParticipants } from "hooks/project-participants";
import SelectSearchNotFoundContent from "components/widgets/select-search-notfound-content";
import CreateSpecSectionModal from "components/submittal-details/create-spec-section";
import { ProjectContext, TProjectContext } from "context/ProjectProvider";
import UserTag from "components/user-tag/user-tag";
import { useCIQMutation, useCIQQuery } from "hooks/ciq-gql-hooks";
import TextArea from "antd/lib/input/TextArea";
import { AcceptanceStatus, MaterialNotReleaseStatus } from "../../constants";
import DisplayNameListPopover from "./display-name-list";

interface SelectedRowItem {
  id: string;
  name: string;
  spec_section_no: string | null;
  spec_section_name: string | null;
  description: string | null;
  trade_partner: string | null;
  assignee: string | null;
  assignee_unregistered: string | null;
  gc_representative: string | null;
  material_tag: string | null;
  status: string;
  quantity: number | null;
  quantity_unit: string | null;
  material_size: string | null;
  material_size_unit: string | null;
  manufacturer: string | null;
  part_number: string;
  date_block_template_id: string;
  float: number;
}

type TEditMaterial = {
  spec_section_id: any | undefined;
  spec_section_name: any | undefined;
  spec_section_no: any | undefined;
  quantity: string | undefined;
  quantity_unit_id: any | undefined;
  trade_partner: string | undefined | null;
  assignee: string | undefined | null;
  assignee_unregistered: string | undefined | null;
  gc_representative: string | undefined | null;
  workflow_template_id: string | undefined | null;
  float: any | undefined;
  description: string | undefined;
  tag_name: string | undefined;
  manufacturer: string | undefined;
  material_size: string | undefined;
  size_unit_id: string | undefined;
  part_number: string | undefined;
};

export type EditMaterialLogs = {
  onUpdateDone: any;
  onCancelClick: any;
  selectedRows: Array<SelectedRowItem>;
  gqlClient: any;
  showEdit: boolean;
  setShowEdit: any;
  projectId: string;
  MDBTemplates: any;
};

function BulkEditMaterialDetails(props: Readonly<EditMaterialLogs>) {
  const {
    onUpdateDone,
    onCancelClick,
    selectedRows,
    gqlClient,
    showEdit,
    setShowEdit,
    projectId,
    MDBTemplates
  } = props;
  const [form] = useForm();
  const createSpecRef = useRef<any>(null);

  const [isDiff, setIsDiff] = useState(false);
  const [initialData, setInitialData] = useState<TEditMaterial>();
  const [materialListIds, setMaterialListIds] = useState<{
    NotStarted: Array<{ id: string; name: string }>;
    Started: Array<{ id: string; name: string }>;
  }>({
    NotStarted: [],
    Started: []
  });
  const { Option } = Select;

  const [editMaterialData, setEditMaterialData] = useState<TEditMaterial>({
    spec_section_id: undefined,
    spec_section_name: undefined,
    spec_section_no: undefined,
    quantity: undefined,
    quantity_unit_id: undefined,
    trade_partner: undefined,
    assignee: undefined,
    assignee_unregistered: undefined,
    gc_representative: undefined,
    workflow_template_id: undefined,
    float: undefined,
    description: undefined,
    tag_name: undefined,
    manufacturer: undefined,
    material_size: undefined,
    size_unit_id: undefined,
    part_number: undefined
  });

  const [inProgress, setInProgress] = useState(false);
  const {
    columnHeaders: { materialHeaderMap }
  }: TProjectContext = useContext(ProjectContext);

  const { data: allUnits } = useCIQQuery<{
    material_quantity_unit: Array<UnitType>;
    material_size_unit: Array<UnitType>;
  }>(QUERY_MASTER_UNITS, {
    client: gqlClient,
    skip: !gqlClient
  });

  const { projectParticipants } = useProjectParticipants();
  const [showCreateSpecSectionModal, setShowCreateSpecSectionModal] =
    useState(false);

  const [updateNewDateBlock] = useCIQMutation(
    MUTATION_BULK_UPDATE_MATERIAL_TEMPLATE,
    {
      client: gqlClient
    }
  );

  const [updateMaterialsMutation] = useCIQMutation(MUTATION_UPDATE_MATERIALS, {
    client: gqlClient
  });

  const quantityUnitTreeOptions = useMemo(
    () =>
      allUnits
        ? getTreeFromMeasuremntList(allUnits.material_quantity_unit)
        : [],
    [allUnits]
  );

  useEffect(() => {
    if (initialData) {
      const compare = compareObjectPropertyValues(
        [initialData, editMaterialData],
        Object.getOwnPropertyNames(initialData).map((x) => ({
          field: x
        }))
      );

      setIsDiff(compare.diffFoundSummary);
    }
  }, [initialData, editMaterialData]);

  const onFinish = async () => {
    setInProgress(true);
    try {
      const compare = compareObjectPropertyValues(
        [initialData, editMaterialData],
        Object.getOwnPropertyNames(initialData).map((x) => ({
          field: x
        }))
      );
      const formData = editMaterialData as any;
      const setForNotStarted = {} as any;
      const setForStarted = {} as any;
      const setForOffset = {} as any;
      const fieldsForStartedOnly = [
        "spec_section_id",
        "spec_section_name",
        "spec_section_no",
        "quantity",
        "quantity_unit_id",
        "description",
        "tag_name",
        "manufacturer",
        "material_size",
        "size_unit_id",
        "part_number"
      ];

      const fieldsForDateBlock = ["float", "workflow_template_id"];

      compare.fieldsAsArray.forEach((x) => {
        if (x.diffFound) {
          if (fieldsForDateBlock.includes(x.field)) {
            setForOffset[x.field] = formData[x.field];
          } else {
            setForNotStarted[x.field] = formData[x.field];
            if (fieldsForStartedOnly.includes(x.field)) {
              setForStarted[x.field] = formData[x.field];
            }
          }
        }
      });

      const submittalsResponse = [];
      if (
        materialListIds.Started.length > 0 &&
        Object.keys(setForStarted).length > 0
      ) {
        const variables = {
          where: {
            id: {
              _in: materialListIds.Started.map((x) => x.id)
            }
          },
          set: setForStarted
        };
        submittalsResponse.push(updateMaterialsMutation({ variables }));
      }

      if (materialListIds.NotStarted.length > 0) {
        if (Object.keys(setForNotStarted).length > 0) {
          const variables = {
            where: {
              id: {
                _in: materialListIds.NotStarted.map((x) => x.id)
              }
            },
            set: setForNotStarted
          };

          submittalsResponse.push(updateMaterialsMutation({ variables }));
        }
        if (Object.keys(setForOffset).length > 0) {
          const matIds: any[] = materialListIds.NotStarted.map((x) => x.id);
          const variables = {
            workflowTemplateId: setForOffset?.workflow_template_id,
            materialIds: matIds
          };
          submittalsResponse.push(updateNewDateBlock({ variables }));
        }
      }

      const res = await Promise.all(submittalsResponse);
      if (res.every((r) => r.data)) {
        message.success("Materials updated successfully");
        setTimeout(() => {
          onUpdateDone();
        }, 0);
      }
      if (res.some((r) => r.errors)) {
        const errors = res.find((r) => r.errors)?.errors;
        if (errors) message.error(errors[0].message);
      }
    } catch (err) {
      console.error(err);
      message.error(err);
    }
    setInProgress(false);
  };

  useEffect(() => {
    if (!selectedRows.length) {
      setShowEdit(false);
      return;
    }

    const compareFields = [
      "spec_section_name",
      "spec_section_no",
      "quantity",
      "quantity_unit",
      "trade_partner",
      "assignee",
      "assignee_unregistered",
      "gc_representative",
      "date_block_template_id",
      "float",
      "description",
      "material_tag",
      "manufacturer",
      "material_size",
      "material_size_unit",
      "part_number"
    ];

    const firstElement = {} as any;
    compareFields.forEach((field) => {
      firstElement[field] = selectedRows[0][field as keyof SelectedRowItem];
    });

    if (selectedRows.length > 1) {
      const compare = compareObjectPropertyValues(
        selectedRows,
        compareFields.map((x) => ({
          field: x,
          label: x
        }))
      );
      compare.fieldsAsArray.forEach((x) => {
        if (x.diffFound) firstElement[x.field] = undefined;
      });
    }

    const gcRepresentative = projectParticipants?.gcReviewers?.length
      ? firstElement?.gc_representative
      : "";

    const intialValue = {
      spec_section_id:
        firstElement.spec_section_name && firstElement.spec_section_no
          ? `${firstElement.spec_section_no} - ${firstElement.spec_section_name}`
          : undefined,
      spec_section_name: undefined,
      spec_section_no: undefined,
      quantity: firstElement.quantity,
      quantity_unit_id: firstElement.quantity_unit,
      trade_partner: firstElement.trade_partner,
      assignee: firstElement.assignee,
      assignee_unregistered: firstElement.assignee_unregistered,
      gc_representative: gcRepresentative,
      workflow_template_id: firstElement.date_block_template_id,
      float: firstElement.float,
      description: firstElement.description,
      tag_name: firstElement.material_tag,
      manufacturer: firstElement.manufacturer,
      material_size: firstElement.material_size,
      size_unit_id: firstElement.material_size_unit,
      part_number: firstElement.part_number
    } as TEditMaterial;

    setInitialData(intialValue);
    setEditMaterialData(intialValue);

    const NotStartedStatus = MaterialNotReleaseStatus.toLowerCase();
    const workflowStartedSubmittals = selectedRows
      .filter((x: any) => x?.status?.toLowerCase() !== NotStartedStatus)
      .map((x) => ({ id: x.id, name: x.name }))
      .sort((a: any, b: any) => a.name.localeCompare(b.name));
    const workflowNotStartedSubmittals = selectedRows
      .filter((x: any) => x?.status?.toLowerCase() === NotStartedStatus)
      .map((x) => ({ id: x.id, name: x.name }))
      .sort((a: any, b: any) => a.name.localeCompare(b.name));

    setMaterialListIds({
      NotStarted: workflowNotStartedSubmittals,
      Started: workflowStartedSubmittals
    });
  }, [selectedRows, projectParticipants, setShowEdit]);

  const MDBTemplatesDropdownOptions = useMemo(() => {
    if (!MDBTemplates) return null;

    return MDBTemplates.filter((template: any) => {
      return !template.disabled;
    }).map((template: any) => {
      return {
        label: template.name,
        value: template.id
      };
    });
  }, [MDBTemplates]);

  const materialTemplateSelectField = useMemo(() => {
    const notStartedList = materialListIds?.NotStarted || [];
    const startedList = materialListIds?.Started || [];

    const allMaterials = [...notStartedList, ...startedList].sort((a, b) =>
      (a?.name || "").localeCompare(b?.name || "")
    );

    const wfStartedMessage = (
      <div className="text-xs mb-2">
        This field will only be updated in
        <DisplayNameListPopover list={notStartedList} placement="left">
          {notStartedList.length}
        </DisplayNameListPopover>
        out of
        <DisplayNameListPopover list={allMaterials} placement="left">
          {allMaterials.length}
        </DisplayNameListPopover>
        Materials for which Workflow has not started.
      </div>
    );

    return (
      <div>
        {materialListIds.NotStarted.length > 0 && (
          <div className="space-y-3">
            <Form.Item
              label={materialHeaderMap?.date_block_template_name?.toUpperCase()}
              data-testid="workflow-template-form-item"
            >
              {materialListIds.Started.length > 0 &&
                materialListIds.NotStarted.length > 0 &&
                wfStartedMessage}
              <Select
                optionFilterProp="label"
                value={editMaterialData.workflow_template_id}
                onChange={(value) => {
                  setEditMaterialData((pre) => ({
                    ...pre,
                    workflow_template_id: value
                  }));
                }}
                options={MDBTemplatesDropdownOptions}
                data-testid="workflow-template-dropdown"
              />
            </Form.Item>
          </div>
        )}
      </div>
    );
  }, [
    MDBTemplatesDropdownOptions,
    editMaterialData.workflow_template_id,
    materialHeaderMap?.date_block_template_name,
    materialListIds.NotStarted,
    materialListIds.Started
  ]);

  const optionLabel = (user: any, selectedUserId: any) => {
    if (
      user.status_id === AcceptanceStatus.DEACTIVATED &&
      user.id !== selectedUserId
    )
      return "";

    const selectedAndInactive =
      user.status_id === AcceptanceStatus.DEACTIVATED &&
      user.id === selectedUserId;

    return (
      <Option
        key={user.id}
        value={user.id}
        label={`${user.first_name} ${user.last_name}`}
        className={selectedAndInactive ? "hidden" : ""}
      >
        {user.status_id === AcceptanceStatus.DEACTIVATED && (
          <div className="absolute flex w-full items-center justify-end pr-3 -mt-[1px]">
            <UserTag label="Inactive" />
          </div>
        )}
        <div>
          {user.first_name} {user.last_name}
        </div>
        <div className="text-sm text-gray-500">{user.company.name}</div>
      </Option>
    );
  };

  return (
    <Modal
      title="BULK EDIT DETAILS"
      className="custom-drawer with-body-scroll"
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ minHeight: "calc(100vh - 148px)" }}
      width={420}
      footer={[
        <div className="flex w-full space-x-3 justify-end" key={100}>
          <Button
            onClick={onCancelClick}
            key={101}
            disabled={inProgress}
            data-testid="cancel-button"
          >
            Cancel
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            disabled={!isDiff || inProgress}
            loading={inProgress}
            onClick={onFinish}
            key={102}
            data-testid="apply-button"
          >
            Apply
          </Button>
        </div>
      ]}
      open={showEdit}
      onCancel={() => {
        setShowEdit(false);
      }}
      destroyOnClose
    >
      <div className="body-content-wrapper">
        <div
          className=" mb-3 px-2 font-semibold"
          data-testid="selected-materials-count"
        >
          <span
            className="tracking-wide"
            data-testid="selected-materials-count-text"
          >
            Selected Materials:
          </span>{" "}
          {selectedRows.length}
        </div>
        <Form
          layout="vertical"
          preserve
          form={form}
          className="space-y-3 min-h-full px-2"
          disabled={showCreateSpecSectionModal}
          data-testid="bulk-edit-form"
        >
          <div className="flex-col overflow-y overflow-x-hidden h-full px-1">
            <Form.Item
              label={materialHeaderMap?.spec_section_no?.toUpperCase()}
              data-testid="spec-section-no-form-item"
            >
              <SpecNumberNameDropDown
                allowCreate
                currentSpecSection={{
                  specSectionId: editMaterialData.spec_section_id
                    ? editMaterialData.spec_section_id
                    : undefined,
                  enable: false
                }}
                onChange={(
                  id: string | null,
                  number: string | null,
                  name: string | null
                ) => {
                  setEditMaterialData((pre) => ({
                    ...pre,
                    spec_section_id: id,
                    spec_section_no: number,
                    spec_section_name: name
                  }));
                }}
                onCreateSpecSectionClick={(showSpecSectionView) => {
                  setShowCreateSpecSectionModal(showSpecSectionView);
                  setTimeout(() => {
                    if (createSpecRef.current)
                      createSpecRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "end"
                      });
                  }, 100);
                }}
              />
            </Form.Item>
            <Form.Item
              label={materialHeaderMap?.tag_name?.toUpperCase()}
              data-testid="tag-name-form-item"
            >
              <Input
                placeholder="Enter Material Tag"
                value={editMaterialData.tag_name}
                onChange={(event: any) => {
                  setEditMaterialData((prev: any) => {
                    return {
                      ...prev,
                      tag_name: event.target.value
                    };
                  });
                }}
                data-testid="tag-name-input"
              />
            </Form.Item>
            <Form.Item
              label={materialHeaderMap?.description?.toUpperCase()}
              data-testid="description-form-item"
            >
              <TextArea
                rows={4}
                placeholder="Enter material description"
                value={editMaterialData.description}
                onChange={(event: any) => {
                  setEditMaterialData((prev: any) => {
                    return {
                      ...prev,
                      description: event.target.value
                    };
                  });
                }}
                data-testid="description-input"
              />
            </Form.Item>
            {materialTemplateSelectField}
            <Form.Item
              label={materialHeaderMap?.trade_partner?.toUpperCase()}
              data-testid="trade-partner-form-item"
            >
              <Select
                showSearch
                value={editMaterialData.trade_partner}
                optionFilterProp="children"
                className="constructionSelect"
                onChange={(value) => {
                  setEditMaterialData((pre) => ({
                    ...pre,
                    trade_partner: value,
                    assignee: null,
                    assignee_unregistered: ""
                  }));
                }}
                notFoundContent={
                  <SelectSearchNotFoundContent
                    notFoundMsg={
                      projectParticipants.materialTradePartners?.length > 0
                        ? "Company not found. To add, go to "
                        : "No companies added to this project. To add, go to "
                    }
                    linkTitle="Project Companies"
                    linkPath={`/project/${projectId}/settings/general/project-companies`}
                  />
                }
                data-testid="trade-partner-dropdown"
                options={projectParticipants.materialTradePartners.map(
                  (item: any) => ({
                    value: item.vendor_id,
                    label: item.subscription_vendor.name
                  })
                )}
              />
            </Form.Item>
            <Tooltip
              title={
                editMaterialData.trade_partner
                  ? ""
                  : "First select a Responsible Contractor"
              }
              placement="rightBottom"
            >
              <Form.Item
                label={materialHeaderMap?.assignee?.toUpperCase()}
                data-testid="assignee-form-item"
              >
                <Select
                  data-testid="assignee-dropdown"
                  showSearch
                  optionFilterProp="label"
                  className="constructionSelect"
                  value={
                    editMaterialData.assignee ||
                    editMaterialData.assignee_unregistered
                  }
                  onChange={(value) => {
                    const selectedUser =
                      projectParticipants?.submitterUsers.find(
                        (user: any) => user.id === value
                      );

                    if (selectedUser.type === "actual") {
                      setEditMaterialData((pre: any) => ({
                        ...pre,
                        assignee: value,
                        assignee_unregistered: ""
                      }));
                    } else {
                      setEditMaterialData((pre: any) => ({
                        ...pre,
                        assignee: null,
                        assignee_unregistered: value
                      }));
                    }
                  }}
                  disabled={!editMaterialData.trade_partner}
                >
                  {projectParticipants?.submitterUsers
                    .filter(
                      (user: any) =>
                        user.company.vendor_id ===
                        editMaterialData.trade_partner
                    )
                    .map((user: any) =>
                      optionLabel(user, editMaterialData.assignee)
                    )}
                </Select>
              </Form.Item>
            </Tooltip>
            <Form.Item
              label={materialHeaderMap?.gc_representative?.toUpperCase()}
              data-testid="gc-representative-form-item"
            >
              <Select
                data-testid="gc-representative-dropdown"
                showSearch
                filterOption
                optionFilterProp="label"
                value={editMaterialData.gc_representative}
                onChange={(value) => {
                  setEditMaterialData((pre) => ({
                    ...pre,
                    gc_representative: value
                  }));
                }}
                notFoundContent="User not found. Go to Project Settings to add users"
              >
                {projectParticipants?.gcReviewers.map((user: any) =>
                  optionLabel(user, editMaterialData.gc_representative)
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label={materialHeaderMap?.manufacturer?.toUpperCase()}
              data-testid="manufacturer-form-item"
            >
              <Input
                placeholder="Enter Manufacturer"
                value={editMaterialData.manufacturer}
                onChange={(event: any) => {
                  setEditMaterialData((prev: any) => {
                    return {
                      ...prev,
                      manufacturer: event.target.value
                    };
                  });
                }}
                data-testid="manufacturer-input"
              />
            </Form.Item>
            <Form.Item
              label={materialHeaderMap?.part_number?.toUpperCase()}
              data-testid="part-number-form-item"
            >
              <Input
                placeholder="Enter part number"
                value={editMaterialData.part_number}
                onChange={(event: any) => {
                  setEditMaterialData((prev: any) => {
                    return {
                      ...prev,
                      part_number: event.target.value
                    };
                  });
                }}
                data-testid="part-number-input"
              />
            </Form.Item>
            <div className="grid grid-cols-2 gap-x-2">
              <Form.Item
                label={materialHeaderMap?.material_size?.toUpperCase()}
                data-testid="material-size-form-item"
              >
                <Input
                  placeholder="Enter Material Size"
                  className="w-full"
                  value={editMaterialData.material_size}
                  onChange={(event: any) => {
                    setEditMaterialData((prev: any) => {
                      return {
                        ...prev,
                        material_size: event.target.value
                      };
                    });
                  }}
                  data-testid="material-size-input"
                />
              </Form.Item>
              <Form.Item
                label={materialHeaderMap?.size_unit_id?.toUpperCase()}
                data-testid="size-unit-form-item"
              >
                <TreeSelect
                  data-testid="size-unit-dropdown"
                  placeholder="Enter Material Size Unit"
                  value={editMaterialData.size_unit_id}
                  treeData={
                    allUnits
                      ? getTreeFromMeasuremntList(allUnits.material_size_unit)
                      : []
                  }
                  onChange={(value: any) => {
                    const data = allUnits?.material_size_unit.find(
                      (x) => x.id === value
                    );
                    setEditMaterialData((prev: any) => ({
                      ...prev,
                      size_unit_id: data?.id
                    }));
                  }}
                  treeDefaultExpandAll
                />
              </Form.Item>
            </div>
            <div className="grid grid-cols-2 gap-x-2">
              <Form.Item
                label={materialHeaderMap?.quantity?.toUpperCase()}
                data-testid="quantity-form-item"
              >
                <Input
                  type="number"
                  value={editMaterialData.quantity}
                  min={0}
                  placeholder="Enter Quantity"
                  className="w-full"
                  onChange={(e) => {
                    setEditMaterialData((pre) => ({
                      ...pre,
                      quantity: e.target.value
                    }));
                  }}
                  data-testid="quantity-input"
                />
              </Form.Item>
              <Form.Item
                label={materialHeaderMap?.quantity_unit_id?.toUpperCase()}
                data-testid="quantity-unit-form-item"
              >
                <TreeSelect
                  data-testid="quantity-unit-dropdown"
                  treeNodeLabelProp="label"
                  placeholder="Enter Quantity Unit"
                  value={editMaterialData.quantity_unit_id}
                  treeData={quantityUnitTreeOptions}
                  onChange={(value: any) => {
                    setEditMaterialData((prev: any) => ({
                      ...prev,
                      quantity_unit_id: value
                    }));
                  }}
                  treeDefaultExpandAll
                />
              </Form.Item>
            </div>
          </div>
        </Form>
        {showCreateSpecSectionModal && (
          <div className="mb-3" ref={createSpecRef}>
            <Divider style={{ margin: "15px 0" }} />
            <div>
              <CreateSpecSectionModal
                isModalOpen={showCreateSpecSectionModal}
                onDoneCb={(
                  newSpecSectionId: string,
                  name: any,
                  number: any
                ) => {
                  if (newSpecSectionId) {
                    setEditMaterialData((pre) => ({
                      ...pre,
                      spec_section_id: newSpecSectionId,
                      spec_section_no: number,
                      spec_section_name: name
                    }));
                    setShowCreateSpecSectionModal(false);
                  } else {
                    setShowCreateSpecSectionModal(false);
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
export default BulkEditMaterialDetails;
