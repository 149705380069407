/* eslint-disable no-nested-ternary */
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Select, message } from "antd";
import { GridLoadingIndicator } from "components/widgets";
import { useContext, useEffect, useMemo, useState } from "react";
import { ProjectContext } from "context/ProjectProvider";
import { useHistory, useLocation, useParams } from "react-router";
import { useSubscription } from "@apollo/client";
import { SUBSCRIPTION_GET_SCHEDULE_VERSIONS } from "services/graphQL/subscriptions";
import { getNewScheduleImpact } from "services/schedule-versions-services";
import { DateUtils } from "utils/dateutils";
import SearchInput from "components/search-input";
import { Link } from "react-router-dom";

import { TScheduleChange } from "../../../../context/ScheduleChangeContext";
import CompareScheduleV3 from "../compare-schedule-3";

function CompareSchedule() {
  const { Option } = Select;
  const [scheduleList, setScheduleList] = useState([] as Array<any>);
  const [activeVersion, setActiveVersion] = useState("");
  const [selectedVersion, setSelectedVersion] = useState<string>();
  const [loadedCompareVersion, setLoadedCompareVersion] = useState("");

  const loadingCompareData = false;

  const [isMakingActiveLoading, setMakingActiveLoading] = useState(false);
  const [isMakeScheduleActiveModalOpen, setMakeScheduleActiveModalOpen] =
    useState(false);
  const [skipSubscribe, setSkipSubscribe] = useState(true);
  const { tokenRetrievalState, gqlClientForProject } =
    useContext(ProjectContext);

  const [scheduleChange, setScheduleChange] = useState<TScheduleChange>({
    params: {
      importLogId: selectedVersion,
      sourceLogId: activeVersion,
      projectToken: tokenRetrievalState?.token
    },
    searchText: ""
  });
  const [isLoadingData, setIsLoadingData] = useState({ current: false });
  const { search } = useLocation();
  const history = useHistory();

  const { data: scheduleListData, loading } = useSubscription(
    SUBSCRIPTION_GET_SCHEDULE_VERSIONS,
    {
      client: gqlClientForProject,
      skip: skipSubscribe
    }
  );

  useEffect(() => {
    setTimeout(() => {
      // CIQ-292
      setSkipSubscribe(false);
    }, 10);
  }, []);

  const selectedVersionId = new URLSearchParams(search).get(
    "selectedVersionId"
  );

  const { projectId } = useParams<{ projectId: string }>();

  useEffect(() => {
    if (scheduleListData) {
      setScheduleList(scheduleListData.schedule_import_details);
      const activeVersionTemp = scheduleListData.schedule_import_details.find(
        (e: any) => {
          return e.is_active;
        }
      );
      if (activeVersionTemp) {
        setActiveVersion(activeVersionTemp.import_log.id);
        // If already selected version then no need to set again
        if (selectedVersionId) {
          setSelectedVersion(selectedVersionId);
        } else {
          setSelectedVersion((prev) => prev || activeVersionTemp.import_log.id);
        }
      }
    }
  }, [scheduleListData, selectedVersionId]);

  const loadComparisonData = () => {
    if (!selectedVersion) return;
    setLoadedCompareVersion(selectedVersion);
    const toDispatch = {
      params: {
        importLogId: selectedVersion,
        sourceLogId: activeVersion,
        projectToken: tokenRetrievalState?.token
      },
      searchText: ""
    };

    setScheduleChange(toDispatch);
  };

  const resetToInitialState = () => {
    setLoadedCompareVersion("");
  };

  const fullSelectedScheduleName = useMemo(() => {
    const selectedScheduleDetails = scheduleList.find((schedule: any) => {
      return schedule.import_log.id === selectedVersion;
    });
    return selectedScheduleDetails?.schedule_date
      ? `${DateUtils.format(selectedScheduleDetails?.schedule_date)}-${
          selectedScheduleDetails?.schedule_name
        }`
      : selectedScheduleDetails?.schedule_name;
  }, [scheduleList, selectedVersion]);

  const handleProceedTosetActive = async () => {
    if (!selectedVersion) return;
    setMakingActiveLoading(true);

    const scheduleImpactResponse: any = await getNewScheduleImpact(
      selectedVersion,
      tokenRetrievalState?.token,
      true
    );

    if (scheduleImpactResponse?.data?.success) {
      message.success(`${fullSelectedScheduleName} is now the active version`);
      resetToInitialState();
      setMakeScheduleActiveModalOpen(false);
      history.replace(`/project/${projectId}/schedule/change-impact`);
    } else {
      message.error("Failed to make active version");
    }
    setMakingActiveLoading(false);
  };

  useEffect(() => {
    if (activeVersion && selectedVersionId && scheduleListData) {
      loadComparisonData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVersionId, activeVersion, scheduleListData]);

  const onSearch = (value: string) => {
    setScheduleChange((pre) => ({ ...pre, searchText: value }));
  };

  const notFoundContent = (
    <div className="text-center">
      There are no schedules to compare with.
      <Link to={`/project/${projectId}/schedule/project-schedule`}>
        {" "}
        Click here{" "}
      </Link>
      to upload more schedule versions.
    </div>
  );

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex items-center">
          <div>
            Select another version:
            <Select
              className="w-[400px] ml-1"
              loading={loading}
              value={selectedVersion}
              onChange={(e: string) => {
                setSelectedVersion(e);
                if (selectedVersionId) {
                  history.push(`/project/${projectId}/schedule/change-impact`);
                }
              }}
              notFoundContent={notFoundContent}
            >
              {scheduleList.map((version: any) => {
                const scheduleName = version?.schedule_date
                  ? `${DateUtils.format(version?.schedule_date)}-${
                      version?.schedule_name
                    }`
                  : version?.schedule_name || "";
                return (
                  <Option
                    key={version?.import_log?.id}
                    value={version?.import_log?.id}
                    placeholder="Select Schedule Version"
                    disabled={version?.is_active}
                  >
                    <div className="flex space-x-2">
                      <span className="grow-flex truncate" title={scheduleName}>
                        {scheduleName}
                      </span>
                      <span className="text-[6px] font-semibold -mt-0.5">
                        {version?.is_active && "ACTIVE"}
                      </span>
                    </div>
                  </Option>
                );
              })}
            </Select>
          </div>
          {activeVersion &&
          selectedVersion &&
          activeVersion !== selectedVersion ? (
            <div className="flex px-2 space-x-2 items-center">
              {loadedCompareVersion !== selectedVersion ? (
                <Button onClick={loadComparisonData} className="mx-2">
                  Compare
                </Button>
              ) : (
                <Button
                  className="mx-2"
                  onClick={() => {
                    setMakeScheduleActiveModalOpen(true);
                  }}
                  loading={isLoadingData.current}
                >
                  Make this the active version
                </Button>
              )}
            </div>
          ) : (
            ""
          )}
        </div>

        {loadedCompareVersion === selectedVersion && (
          <div className="font-weight-bold flex space-x-2 items-center">
            <SearchInput placeholder="Search" onChange={onSearch} />
          </div>
        )}
      </div>
      <div
        className="mt-2"
        style={{
          height: "calc(100vh - 170px)"
        }}
      >
        {loading ? (
          <div className="w-full h-full flex items-center justify-center">
            <GridLoadingIndicator />
          </div>
        ) : loadedCompareVersion !== selectedVersion ? (
          <div className="w-full h-full flex items-center justify-center">
            {scheduleList.length > 0
              ? " Please select another version to compare to"
              : notFoundContent}
          </div>
        ) : loadingCompareData ? (
          <div className="w-full h-full flex items-center justify-center">
            <GridLoadingIndicator />
          </div>
        ) : (
          <CompareScheduleV3
            scheduleChange={scheduleChange}
            isLoadingData={isLoadingData}
            setIsLoadingData={setIsLoadingData}
          />
        )}
      </div>

      <Modal
        open={isMakeScheduleActiveModalOpen}
        onOk={handleProceedTosetActive}
        okText="Proceed"
        closable={false}
        onCancel={() => {
          setMakeScheduleActiveModalOpen(false);
        }}
        okButtonProps={{
          disabled: isMakingActiveLoading,
          loading: isMakingActiveLoading
        }}
        cancelButtonProps={{ disabled: isMakingActiveLoading }}
        width="420px"
        destroyOnClose
        maskClosable={!isMakingActiveLoading}
      >
        <div className="p-2">
          <div className="flex flex-row space-x-3">
            <div>
              <ExclamationCircleOutlined className="text-[#faad14] text-2xl" />
            </div>
            <div>
              By making{" "}
              <span className="font-semibold">{fullSelectedScheduleName} </span>
              the active version, you are accepting the changes to the submittal
              and material schedules as shown in this page. Do you want to
              proceed?
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CompareSchedule;
