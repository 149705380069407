import { Tooltip } from "antd";
import CriticalActivityBorderedIcon from "components/svg-icons/critical-activity-bordered-icon";

export default function CriticalActivityIndicator() {
  return (
    <div className="flex justify-center items-center">
      <Tooltip title="Critical Activity">
        <CriticalActivityBorderedIcon />
      </Tooltip>
    </div>
  );
}
