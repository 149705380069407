import { InfoCircleOutlined } from "@ant-design/icons";
import { Divider, Modal } from "antd";

export default function ViewSelectedItemsDrawer(props: {
  items: Array<{ name: string; pre?: string }>;
  instruction: string;
  setOpen: (open: boolean) => void;
  isOpen: boolean;
  title: string;
}) {
  const { items, instruction, setOpen, isOpen, title } = props;

  return (
    <Modal
      className="custom-drawer"
      title="Selection Details"
      width={420}
      style={{
        right: 0,
        bottom: 0,
        top: 40,
        padding: 0,
        position: "absolute"
      }}
      bodyStyle={{ minHeight: "calc(100vh - 92px)" }}
      footer={null}
      open={isOpen}
      onCancel={() => {
        setOpen(false);
      }}
      destroyOnClose
    >
      <div className="flex pt-2 items-center justify-center text-xs">
        <InfoCircleOutlined className="pr-1" /> {instruction}
      </div>
      <Divider />
      <div className="">
        <div className="px-4 uppercase pb-4 text-[#555555D9] font-medium">
          {title} ({items.length})
        </div>
        <ul
          className="list-disc space-y-6 pr-10"
          style={{ overflowY: "auto", height: "calc(100vh - 260px)" }}
        >
          {items.map((item) => (
            <li className="text-opacity-10" key={item.pre + item.name}>
              <span>{item.name}</span> <br />
              <span className="text-[#3b3b3bcc] italic">{item.pre}</span>
            </li>
          ))}
        </ul>
      </div>
    </Modal>
  );
}
