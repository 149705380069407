import { Form, FormRule, message, Spin } from "antd";
import { useContext, useEffect, useState } from "react";
import { ProjectContext } from "context/ProjectProvider";
import { useMutation } from "@apollo/client";
import { MUTATION_PROJECT_DEFAULT_CONFIG } from "services/graphQL/mutations";
import TextArea from "antd/lib/input/TextArea";
import { FeatureTypes } from "entity-app/constants";
import {
  DateBlockInfoAction,
  DateBlockInfoType
} from "components/date-block/models";
import { DATEBLOCK_ACTIONS } from "services/graphQL/ciq-queries";
import { useCIQQuerySubscription } from "hooks/ciq-gql-hooks";
import { headerView } from "./header-view";

function MilestoneActionSetting({
  disabled,
  featureId
}: {
  disabled: boolean;
  featureId: FeatureTypes;
}) {
  const [form] = Form.useForm();

  const { gqlClientForProject } = useContext(ProjectContext);

  const [isFieldsEditable, setFieldsEditable] = useState<boolean>(false);
  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);
  const [showWarningMsg, setShowWarningMsg] = useState(false);
  const [dateBlockActions, setDateBlockActions] =
    useState<DateBlockInfoAction[]>();

  const cancelAction = () => {
    form.resetFields();
    setFieldsEditable(false);
    setSubmitInProgress(false);
  };

  const { data: dateBlockInfoFeatureData } = useCIQQuerySubscription<{
    project_feature_configurations: Array<DateBlockInfoType>;
  }>(DATEBLOCK_ACTIONS, {
    client: gqlClientForProject,
    skip: !gqlClientForProject,
    variables: { where: { feature_id: { _eq: featureId } } }
  });

  useEffect(() => {
    const actions =
      dateBlockInfoFeatureData?.project_feature_configurations?.find(
        (x) => x.feature_id === featureId
      )?.date_block_action || [];
    setDateBlockActions(actions);
    form.setFieldsValue({
      actions
    });
  }, [
    dateBlockInfoFeatureData?.project_feature_configurations,
    featureId,
    form
  ]);

  const [updateProjectConfigMutation] = useMutation<any>(
    MUTATION_PROJECT_DEFAULT_CONFIG,
    {
      client: gqlClientForProject
    }
  );

  const updateDateBlockActions = (data: any) => {
    const variables = {
      feature_id: featureId,
      field_mapping: null,
      date_block_action: data.actions
    };

    const updateResponse = updateProjectConfigMutation({
      variables
    });

    return updateResponse;
  };

  const onFinish = async (data: any) => {
    setSubmitInProgress(true);
    const updateResponse = await updateDateBlockActions(data);
    if (updateResponse.errors) {
      message.error(updateResponse.errors[0]?.message);
    } else {
      message.success("Default Settings are updated successfully.");
    }
    setFieldsEditable(false);
    setSubmitInProgress(false);
  };

  const milestoneActionsRules: FormRule[] = [
    {
      required: true,
      message: "Please enter milestone action",
      validator: async (_, value) => {
        if (value?.trim() === "") {
          setShowWarningMsg(true);
          return Promise.reject(new Error("Please enter milestone action"));
        }
        setShowWarningMsg(false);
        return Promise.resolve();
      }
    }
  ];

  const mileStoneActionsInfo = (
    <div className="flex items-center w-full info-label pl-4">
      <div className="flex text-justify">
        Define the actions to be taken to complete the below milestones.
      </div>
    </div>
  );

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{ actions: dateBlockActions }}
      onFinish={onFinish}
      className="flex flex-col h-full"
      validateTrigger={["onSubmit", "onChange"]}
    >
      {headerView({
        title: "MILESTONE ACTIONS",
        disabled,
        cancelAction,
        isFieldsEditable,
        setFieldsEditable,
        showWarningMsg,
        submitInProgress
      })}
      {mileStoneActionsInfo}
      <div className="flex justify-center w-full mt-10 overflow-y overflow-x-hidden max-h-[calc(100vh-250px)]">
        {dateBlockActions ? (
          <div className="grid grid-cols-2 py-2 gap-x-10 w-[95%]">
            <Form.List name="actions">
              {(fields) => (
                <>
                  {fields.map((field, index) => {
                    const header = `${
                      dateBlockActions[index].step_name || ""
                    }`.replace("(Actual)", "");
                    return (
                      <div className="grid grid-cols-3 justify-between items-start">
                        <p className="col-span-1 mr-1 titles">{header}</p>

                        <Form.Item
                          {...field}
                          className="col-span-2"
                          key="caption_en"
                          name={[field.name, "action"]}
                          rules={milestoneActionsRules}
                        >
                          <TextArea
                            className="actionsLbl"
                            disabled={!isFieldsEditable}
                            rows={4}
                          />
                        </Form.Item>
                      </div>
                    );
                  })}
                </>
              )}
            </Form.List>
          </div>
        ) : (
          <Spin />
        )}
      </div>
    </Form>
  );
}
export default MilestoneActionSetting;
