/* eslint-disable react-hooks/exhaustive-deps */
import { message } from "antd";
import jwtDecode from "jwt-decode";
import { useState, useEffect, useCallback } from "react";
import {
  getCurrentSubscriptionId,
  getTokenForProject,
  getTokenForSubscription,
  logout
} from "services/auth";
import { getGraphQLClient } from "services/graphQL";

export const useGetGQLClientForProject = (projectId?: string) => {
  const [idValues, setIdValues] = useState<{
    projectId?: string;
  }>({
    projectId
  });
  const [isAboutToExpired, setIsAboutToExpired] = useState(true);
  const [gqlClientForProject, setGqlClientForProject] = useState<any>(null);
  const [projectToken, setProjectToken] = useState<any>(null);
  const [wsLink, setwsLink] = useState<any>(null);
  const [tokenRetrievalState, setTokenRetrievalState] = useState<any>({
    inProgress: false,
    success: null,
    error: false,
    msg: "",
    token: null
  });

  const disposeSubscription = useCallback(() => {
    try {
      if (wsLink) {
        wsLink.stop();
        wsLink.subscriptionClient.close();

        setwsLink(null);
      }
    } catch (ex) {
      //
    }
  }, [wsLink]);

  useEffect(() => {
    // console.log("About to create new GRPAHQL instance");
    async function createClient() {
      setIsAboutToExpired(false);
      setTokenRetrievalState({
        inProgress: true,
        success: null,
        error: false,
        msg: "",
        token: null
      });

      const setTokenResponse: any = await getTokenForProject({
        projectId: idValues.projectId as string
      });

      if (setTokenResponse.data) {
        setProjectToken(setTokenResponse.data.success.token);
        // console.log(setTokenResponse.data.success.token);
        const graphQlInstance = getGraphQLClient({
          token: setTokenResponse.data.success.token
        });
        setGqlClientForProject(graphQlInstance.GQLClient);
        setwsLink(graphQlInstance.wsLink);
        setTokenRetrievalState({
          inProgress: false,
          success: true,
          error: false,
          msg: "",
          token: setTokenResponse.data.success.token
        });
        const jwt: any = jwtDecode(setTokenResponse.data.success.token);
        const exp = (jwt && jwt.exp && jwt.exp * 1000) || null;
        if (exp) {
          const expiringIn = exp - 60000 - Date.now();
          setTimeout(() => {
            setIsAboutToExpired(true);
          }, expiringIn);
        }
      }

      if (setTokenResponse.error) {
        setTokenRetrievalState({
          inProgress: false,
          success: false,
          error: true,
          msg: setTokenResponse.error.message
        });
      }
    }

    if (idValues.projectId && isAboutToExpired) {
      disposeSubscription();
      createClient();
    }

    return () => {
      disposeSubscription();
    };
  }, [idValues, isAboutToExpired]);

  return {
    gqlClientForProject,
    tokenRetrievalState,
    projectToken,
    setIdValues
  };
};

export const useGetGQLClientForSubscription = () => {
  const subscriptionId = getCurrentSubscriptionId();
  const [graphQlInstance, setGraphQlInstance] = useState<any>();
  const [gqlClient, setGqlClient] = useState(graphQlInstance?.GQLClient);
  const [wsLink, setwsLink] = useState<any>(graphQlInstance?.wsLink);
  const [isAboutToExpired, setIsAboutToExpired] = useState(true);

  const createSubscriptionLoginToken = async () => {
    setIsAboutToExpired(false);
    try {
      const subscriptionResponse: any = await getTokenForSubscription({
        subscriptionId
      });

      const { token } = subscriptionResponse.data.success;

      const graphQlIns = getGraphQLClient({
        token
      });
      setGraphQlInstance(graphQlIns);
      setGqlClient(graphQlIns.GQLClient);
      setwsLink(graphQlIns.wsLink);

      const jwt: any = jwtDecode(token);
      const exp = (jwt && jwt.exp && jwt.exp * 1000) || null;
      if (exp) {
        console.log("Token Will Expired at ", new Date(exp));
        const beforeTimeOfExpired = 1000 * 90; // 90 seconds
        const expiringIn = exp - beforeTimeOfExpired - Date.now();
        setTimeout(() => {
          setIsAboutToExpired(true);
        }, expiringIn);
      }
    } catch (err) {
      console.error(err);
      message.error("Session expired, redirecting to login");
      logout();
    }
  };

  useEffect(() => {
    if (subscriptionId && isAboutToExpired) {
      createSubscriptionLoginToken();
    }
  }, [subscriptionId, isAboutToExpired]);

  return { gqlClient, wsLink };
};
