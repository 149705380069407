import { Tabs } from "antd";
import { useContext, useState } from "react";
import "./module-settings.scss";
import {
  ProjectContext,
  isPermissionNotGrantted
} from "context/ProjectProvider";
import { useHistory, useParams } from "react-router";
import DemoTextIcon from "components/svg-icons/demo-icon";
import { ProjectPermissionEnum } from "../../../constants";

import MaterialSettings from "./material-settings/material-settings";
import SubmittalSettings from "./submittal-settings/submittal-settings";
import ProjectBidPackageSettings from "./bid-package-settings/project-bid-package-settings";
import DesignPackageSettings from "./design-package-settings/design-package-settings";

function ModuleSettings() {
  const {
    tokenContents,
    columnHeaders: {
      submittalHeaders,
      materialHeaders,
      bidPackageHeaders,
      designPackageHeaders
    },
    isFeatureFlagEnabled
  } = useContext(ProjectContext);

  const history = useHistory();
  const { projectId, tab2Id } = useParams() as any;
  const isBuyoutEnabled = isFeatureFlagEnabled("BUYOUT");
  const [tabIndex, setTabIndex] = useState<string>(
    tab2Id || (isBuyoutEnabled ? "design-packages" : "submittals")
  );

  const tabItems = [
    ...(isBuyoutEnabled
      ? [
          {
            label: (
              <span>
                <DemoTextIcon /> Design Packages
              </span>
            ),
            key: "design-packages",
            children: (
              <DesignPackageSettings
                designPackageHeader={designPackageHeaders}
                disabled={isPermissionNotGrantted(
                  ProjectPermissionEnum.EditProjectDetail,
                  tokenContents?.role!
                )}
              />
            )
          }
        ]
      : []),
    ...(isBuyoutEnabled
      ? [
          {
            label: (
              <span>
                <DemoTextIcon /> Bid Packages
              </span>
            ),
            key: "bid-packages",
            children: (
              <ProjectBidPackageSettings
                bidPackageHeader={bidPackageHeaders}
                disabled={isPermissionNotGrantted(
                  ProjectPermissionEnum.EditProjectDetail,
                  tokenContents?.role!
                )}
              />
            )
          }
        ]
      : []),
    {
      label: "Submittals",
      key: "submittals",
      children: (
        <SubmittalSettings
          projectId={projectId}
          submittalheader={submittalHeaders}
          disabled={isPermissionNotGrantted(
            ProjectPermissionEnum.EditProjectDetail,
            tokenContents?.role!
          )}
        />
      )
    },
    {
      label: "Materials",
      key: "materials",
      children: (
        <MaterialSettings
          materialheader={materialHeaders}
          disabled={isPermissionNotGrantted(
            ProjectPermissionEnum.EditProjectDetail,
            tokenContents?.role!
          )}
        />
      )
    }
  ];

  return (
    <div className="w-full py-1 h-full">
      <Tabs
        onChange={(activeKey) => {
          history.push(
            `/project/${projectId}/settings/modules/${activeKey.toString()}`
          );
          setTabIndex(activeKey);
        }}
        activeKey={tabIndex}
        items={tabItems}
        destroyInactiveTabPane
      />
    </div>
  );
}
export default ModuleSettings;
