import { MenuOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classNames from "classnames";
import AppLogoIcon from "components/svg-icons/app-logo-icon";
import UserAvatar from "components/user-avatar/user-avatar";

function MaterialPlanningNavbar(props: {
  userName: string;
  companyName: string;
  onMenuClick: () => void;
  showHamburgerMenu: boolean;
}) {
  const { userName, companyName, onMenuClick, showHamburgerMenu } = props;
  return (
    <div className="w-full h-[45px] flex items-stretch justify-center md:justify-between bg-white px-2">
      <div className="md:hidden w-1/3 flex items-center justify-start overflow-hidden">
        <Button
          type="text"
          icon={<MenuOutlined />}
          size="middle"
          onClick={onMenuClick}
          className={classNames({
            hidden: !showHamburgerMenu
          })}
        />
      </div>
      <div className="w-1/3 md:w-auto flex items-center justify-center">
        <div className="w-[120px] min-w-[120px] flex items-center justify-center">
          <AppLogoIcon />
        </div>
      </div>
      <div className="md:hidden w-1/3">&nbsp;</div>
      <div className="items-center justify-center gap-2.5 hidden md:flex md:items-center md:justify-center">
        <div className="py-0.5">
          <div className="text-color-3 text-sm leading-tight font-semibold">
            {userName}
          </div>
          <div className="text-color-1 text-xs leading-tight font-semibold">
            {companyName}
          </div>
        </div>
        <div>
          <UserAvatar url="" fullName={userName} isRect={false} />
        </div>
      </div>
    </div>
  );
}

export default MaterialPlanningNavbar;
