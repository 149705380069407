import { IMaterial } from "./type-definitions";
import { LandingPageListItem } from "./widgets";

function LandingSection({
  materials,
  onMaterialClick
}: {
  materials: IMaterial[];
  onMaterialClick: (index: number) => void;
}) {
  return (
    <div className="w-full">
      <div className="p-4 text-sm font-semibold text-color-1 shrink-0">
        Requested Materials ({materials?.length})
      </div>
      <div className="flex flex-col gap-y-3 px-6 pb-4">
        {materials?.map((material, index) => (
          <LandingPageListItem
            key={material.id}
            material={material}
            onClick={() => {
              onMaterialClick(index);
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default LandingSection;
