import { ColGroupDef } from "ag-grid-community";
import {
  dueDateCellRenderer,
  riskCellRenderer,
  submittalDueDateHeaderComponent
} from "components/cell-renders";
import { InnerIdLinkSubmittalRenderer } from "pages/reports-menu/components/cellRenderer";
import { submittalSequenceComparator } from "pages/submittals-log-page/utils";
import { TLinkedSubmittal } from "../models";
import {
  getActivityValueGetterData,
  getLinkedMaterialGetterData,
  valueGetterSubmittalBallInCourt,
  valueGetterSubmittalDueDate,
  valueGetterSubmittalId,
  valueGetterSubmittalRisk,
  valueGetterSubmittalSpecSection
} from "../utils/value-getters";
import { voidSubmittalCellRenderer } from "../cell-render/void-status-cell-render";

export const submittalColDefs = (params?: { projectDetails?: any }) =>
  [
    {
      headerName: "Linked Submittals",
      children: [
        {
          colId: "submittal_id",
          field: "submittal_id",
          headerName: "SUBMITTAL ID",
          headerTooltip: "SUBMITTAL ID",
          cellRenderer: "agGroupCellRenderer",
          cellRendererParams: {
            innerRenderer: InnerIdLinkSubmittalRenderer
          },
          valueGetter: valueGetterSubmittalId,
          tooltipValueGetter: ({ value }) => value,
          getQuickFilterText: (param) => {
            const { data, value } = param;
            let allValues = [value];
            if (data.parentActivity) {
              allValues = allValues.concat(
                getActivityValueGetterData(data.parentActivity)
              );
            }
            if (data.parentMaterial) {
              allValues = allValues.concat(
                getLinkedMaterialGetterData({ data: data.parentMaterial })
              );

              if (data.parentMaterial.parentActivity) {
                allValues = allValues.concat(
                  getActivityValueGetterData(data.parentMaterial.parentActivity)
                );
              }
            }
            return allValues.join(" ");
          },
          comparator: (valueA, valueB, nodeA, nodeB) =>
            submittalSequenceComparator(
              nodeA.data?.submittal_id,
              nodeB.data?.submittal_id,
              nodeA,
              nodeB,
              params?.projectDetails?.spec_section
            )
        },
        {
          colId: "specsection",
          headerName: "Spec Section",
          headerTooltip: "Spec Section",
          valueGetter: valueGetterSubmittalSpecSection,
          tooltipValueGetter: ({ value }) => value
        },
        {
          colId: "title",
          field: "title",
          headerName: "TITLE",
          headerTooltip: "TITLE",
          tooltipField: "title"
        },
        {
          colId: "workflow_status",
          field: "workflow_status",
          headerName: "STATUS",
          headerTooltip: "STATUS",
          tooltipField: "workflow_status",
          cellRenderer: voidSubmittalCellRenderer
        },
        {
          colId: "assignee",
          headerName: "BALL IN COURT",
          headerTooltip: "BALL IN COURT",
          valueGetter: valueGetterSubmittalBallInCourt,
          tooltipValueGetter: ({ value }) => value
        },
        {
          colId: "due_date",
          headerName: "DUE DATE",
          headerTooltip: "DUE DATE",
          headerComponentParams: submittalDueDateHeaderComponent,
          valueGetter: valueGetterSubmittalDueDate,
          sort: "asc",
          cellRenderer: dueDateCellRenderer,
          tooltipValueGetter: ({ value }) => value
        },
        {
          colId: "risk",
          headerName: "RISK",
          headerTooltip: "RISK",
          valueGetter: valueGetterSubmittalRisk,
          cellRenderer: riskCellRenderer,
          tooltipValueGetter: ({ value }) => value
        }
      ]
    }
  ] as ColGroupDef<TLinkedSubmittal>[];
