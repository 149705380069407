import { gql } from "@apollo/client";

const MATERIAL_LIST_PERF = gql`
  query MaterialLogs(
    $where: material_list_view_interface_bool_exp = { implicit: { _neq: true } }
  ) {
    material_list_func(where: $where, order_by: { id: asc }) {
      id
      material_id
      name
      spec_section_no
      spec_section_name
      description
      trade_partner
      trade_partner_name
      assignee
      assignee_unregistered
      assignee_first_name
      assignee_last_name
      assignee_organization_name
      assignee_vendor_name
      gc_representative
      gc_representative_first_name
      gc_representative_last_name
      actual_ROJ
      actual_fabrication_start_date
      actual_material_release_date
      actual_shipment_date
      planned_ROJ
      tasks
      submittals
      material_tag
      status
      delta
      quantity
      quantity_unit
      material_PO_fabrication_offset
      fabrication_duration_offset
      lead_time_offset
      on_site_storage_offset
      material_size
      material_size_unit
      manufacturer
      serial_number
      part_number
      warranty
      next_planned_deadline
      next_action
      due_date
      risk_level
      created_at
      date_block_template_name
      date_block_template_id
      planned_float
      effective_float
      assignee_status
      project_engineer_status
      gc_representative_status
      date_block_data
      material_released_early
      invite_status
    }
  }
`;

export { MATERIAL_LIST_PERF };
