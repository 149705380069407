import { Form, FormRule, Input, message, Spin } from "antd";
import { useContext, useEffect, useState } from "react";
import { ProjectContext } from "context/ProjectProvider";
import { useMutation, useSubscription } from "@apollo/client";
import { MUTATION_UPDATE_NEW_PROJECT_DEFAULT_OFFSET_PK } from "services/graphQL/mutations";
import { FeatureTypes } from "entity-app/constants";
import { SUBSCRIPTION_NEW_PROJECT_OFFSET_DEFAULT } from "services/graphQL/subscriptions";
import { headerView } from "./header-view";
import { WorkflowDefaultData } from "../../model";

function DateBlockDurationSetting({
  disabled,
  featureId
}: {
  disabled: boolean;
  featureId: FeatureTypes;
}) {
  const [form] = Form.useForm();

  const { gqlClientForProject } = useContext(ProjectContext);

  const [isFieldsEditable, setFieldsEditable] = useState<boolean>(false);
  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);
  const [showWarningMsg, setShowWarningMsg] = useState(false);

  const [workflowOffsets, setWorkflowOffsets] =
    useState<Array<WorkflowDefaultData>>();

  const [workflowOffsetMap, setWorkflowOffsetMap] = useState<any>();

  const { data: workflowDefaults } = useSubscription<{
    workflow_defaults: Array<{
      feature_id: FeatureTypes;
      float: number;
      workflow_data: Array<WorkflowDefaultData>;
    }>;
  }>(SUBSCRIPTION_NEW_PROJECT_OFFSET_DEFAULT, {
    client: gqlClientForProject,
    skip: !gqlClientForProject,
    variables: {
      where: { feature_id: { _eq: featureId } }
    }
  });

  useEffect(() => {
    const defaultsMap = {} as any;
    workflowDefaults?.workflow_defaults?.forEach((wf_offset: any) => {
      const subOffsetsArr: Array<WorkflowDefaultData> = wf_offset.workflow_data
        ? [...wf_offset.workflow_data]
        : [];

      subOffsetsArr.push({
        offset_name: "sub_float",
        offset_value: wf_offset.float,
        milestone_name: "Submittal Float"
      });
      setWorkflowOffsets(subOffsetsArr);

      subOffsetsArr?.forEach((sub_offset: WorkflowDefaultData) => {
        const key = sub_offset.offset_name.replaceAll(" ", "_");
        defaultsMap[key] = sub_offset.offset_value;
      });
    });

    form.setFieldsValue(defaultsMap);
    setWorkflowOffsetMap(defaultsMap);
  }, [form, workflowDefaults?.workflow_defaults]);

  const cancelAction = () => {
    form.resetFields();
    setFieldsEditable(false);
    setSubmitInProgress(false);
  };

  const [updateDefaultNewProjectOffsetMutation] = useMutation(
    MUTATION_UPDATE_NEW_PROJECT_DEFAULT_OFFSET_PK,
    {
      client: gqlClientForProject
    }
  );

  const updateDateBlockDuration = async (data: any) => {
    const submittalOffsetsValue: any[] = [];
    workflowOffsets?.forEach((sub_offset: WorkflowDefaultData) => {
      const offset = sub_offset;

      if (offset.offset_name !== "On-site Preparation") {
        offset.offset_value = Number(
          data[offset.offset_name.replaceAll(" ", "_")]
        );
      }
      if (offset.offset_name !== "sub_float") {
        submittalOffsetsValue.push(offset);
      }
    });

    const submittalOffsetMap = {
      feature_id: 1,
      float: Number(data.sub_float),
      workflow_data: submittalOffsetsValue
    };

    const updateResponse = await updateDefaultNewProjectOffsetMutation({
      variables: submittalOffsetMap
    });
    return updateResponse;
  };

  const onFinish = async (data: any) => {
    setSubmitInProgress(true);
    const updateResponse = await updateDateBlockDuration(data);
    if (updateResponse.errors) {
      message.error(updateResponse.errors[0]?.message);
    } else {
      message.success("Default Settings are updated successfully.");
    }
    setFieldsEditable(false);
    setSubmitInProgress(false);
  };

  const defaultoffsetRules: FormRule[] = [
    {
      validator: async (_, value) => {
        const regex = /^[0-9\b]+$/;
        if (!regex.test(value)) {
          setShowWarningMsg(true);
          return Promise.reject(
            new Error("Please enter duration in numbers only.")
          );
        }
        setShowWarningMsg(false);
        return Promise.resolve();
      }
    }
  ];

  const dateBlockDurationInfo = (
    <div className="flex items-center w-full info-label pl-4 ">
      <div className="flex text-justify">
        Set the default durations of the Submittal workflow
      </div>
    </div>
  );

  return (
    <Form
      layout="vertical"
      initialValues={workflowOffsetMap}
      form={form}
      onFinish={onFinish}
      className="flex flex-col h-full"
      validateTrigger={["onSubmit", "onChange"]}
    >
      <div className="flex-col bg-white">
        {headerView({
          title: "DATE BLOCK DURATION",
          disabled,
          cancelAction,
          isFieldsEditable,
          setFieldsEditable,
          showWarningMsg,
          submitInProgress
        })}
        {dateBlockDurationInfo}
        <div className="flex justify-center w-full bg-white mt-10">
          {workflowOffsets ? (
            <div className="grid grid-cols-2 gap-x-20 w-3/5">
              {workflowOffsets?.map((wf: WorkflowDefaultData) => {
                if (wf.offset_name === "On-site Preparation") return "";
                return (
                  <Form.Item
                    name={wf?.offset_name?.replaceAll(" ", "_")}
                    label={
                      wf.offset_name === "sub_float"
                        ? "SUBMITTAL PLANNED FLOAT"
                        : wf.offset_name?.toUpperCase()
                    }
                    rules={defaultoffsetRules}
                    key={wf?.offset_name}
                  >
                    <Input
                      type="text"
                      disabled={!isFieldsEditable}
                      data-testid={wf?.offset_name}
                    />
                  </Form.Item>
                );
              })}
            </div>
          ) : (
            <Spin />
          )}
        </div>
      </div>
    </Form>
  );
}

export default DateBlockDurationSetting;
