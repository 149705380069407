import { RequsetLeadTimeData } from "../type-definitions";
import DesktopRequestDetailsSection from "./desktop-request-details-section";
import MobileRequestDetailsSection from "./mobile-request-details-section";

function RequestDetailsSection({
  requestDetails
}: {
  requestDetails: RequsetLeadTimeData;
}) {
  return (
    <div className="bg-[#F7F6F4] max-h-full">
      <div className="md:hidden">
        <MobileRequestDetailsSection requestDetails={requestDetails} />
      </div>
      <div className="hidden md:block">
        <DesktopRequestDetailsSection requestDetails={requestDetails} />
      </div>
    </div>
  );
}

export default RequestDetailsSection;
