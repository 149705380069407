/* eslint-disable no-plusplus */
import { Form, message, Select } from "antd";
import { useMutation } from "@apollo/client";
import {
  isPermissionNotGrantted,
  ProjectContext,
  TProjectContext
} from "context/ProjectProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import {
  MUTATION_INSERT_SUBMITTAL_DISTRIBUTION_GROUP,
  MUTATION_UPDATE_SUBMITTAL_DISTRIBUTION_GROUP
} from "services/graphQL/mutations";
import { AcceptanceStatus, ProjectPermissionEnum } from "constants/index";
import { getSubscriptionId } from "services/auth";
import dlTagRenderer from "components/dl-tag-renderer/dl-tag-renderer";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import {
  QUERY_ACTIVE_PROJECT_USERS,
  QUERY_DISTRIBUTION_GROUP_LIST,
  QUERY_GET_SUBMITTAL_DL_USERS
} from "services/graphQL/queries";

function DistributionList(props: { submittalId: string; disabled: boolean }) {
  const { submittalId, disabled } = props;
  const { projectId } = useParams() as any;

  const { gqlClientForProject, tokenContents, eventLogs }: TProjectContext =
    useContext(ProjectContext);
  const subscriptionId = getSubscriptionId();

  const { data: usersData, refetch: refetchProjectUsersData } = useCIQQuery(
    QUERY_ACTIVE_PROJECT_USERS,
    {
      client: gqlClientForProject,
      variables: {
        where: {
          project_id: { _eq: projectId },
          status_id: { _neq: 1 },
          subscription_id: { _eq: subscriptionId }
        }
      },
      skip: !subscriptionId || !projectId
    }
  );

  const [insertDLToSubmittal] = useMutation<any>(
    MUTATION_INSERT_SUBMITTAL_DISTRIBUTION_GROUP,
    {
      client: gqlClientForProject
    }
  );

  const [updateDLToSubmittal] = useMutation<any>(
    MUTATION_UPDATE_SUBMITTAL_DISTRIBUTION_GROUP,
    {
      client: gqlClientForProject
    }
  );

  const { data: distributionGroupData, refetch: refetchDistructionGroup } =
    useCIQQuery(QUERY_DISTRIBUTION_GROUP_LIST, {
      client: gqlClientForProject,
      variables: {
        where: {
          subscription: { project_users: { project_id: { _eq: projectId } } }
        }
      }
    });

  const { data: dldata, refetch: refetchSubmittalDlUserData } = useCIQQuery(
    QUERY_GET_SUBMITTAL_DL_USERS,
    {
      client: gqlClientForProject,
      variables: {
        where: { submittal_id: { _eq: submittalId } }
      },
      skip: !submittalId
    }
  );

  const [selectedDLIds, setselectedGroupIds] = useState<any>([]);

  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    const tempProjectUsers: any = [];

    const groupUsrArr: any = [];
    const usrArr: any = [];

    const selectedgroupIds: any = [];
    const selecteduserIds: any = [];

    for (let k = 0; k < dldata?.submittal_distribution_list?.length; k++) {
      const element = dldata?.submittal_distribution_list[k];
      if (element.dl_id) {
        selectedgroupIds.push(element.dl_id);
      }
      if (element.user_id) {
        selecteduserIds.push(element.user_id);
      }

      if (distributionGroupData) {
        if (element.dl_id) {
          const groups: any = distributionGroupData?.distribution_group?.filter(
            (grp: any) => grp?.id === element.dl_id
          );
          if (groups?.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-loop-func, array-callback-return
            groups[0].distribution_group_user?.map((user: any) => {
              tempProjectUsers.push(user?.user?.id);
              groupUsrArr.push(user?.user?.email);
            });
          }
        }
        if (element?.user_id) {
          const users: any = usersData?.project_users?.filter(
            (usr: any) => usr?.user?.id === element.user_id
          );

          if (users?.length > 0) {
            usrArr.push(users[0].user?.email);
          }
        }
      }
    }

    const filterSelectedUserIds = selecteduserIds.filter((usr_id: any) => {
      return !tempProjectUsers.find((userid: any) => {
        return usr_id === userid;
      });
    });

    const filterUserArray: [] = usersData?.project_users?.filter(
      (oldItem: any) => {
        return !tempProjectUsers.find((userid: any) => {
          return oldItem?.user?.id === userid;
        });
      }
    );

    const userArr: any = [];

    filterUserArray?.forEach((item: any) => {
      if (item?.status_id === AcceptanceStatus.ACTIVE) {
        userArr.push({
          label: `${item?.user?.first_name} ${item?.user?.last_name}`,
          value: item?.user?.id,
          email: item?.user?.email,
          status_id: item?.status_id
        });
      }
    });

    const grpArr: any = [];

    distributionGroupData?.distribution_group?.forEach((item: any) => {
      grpArr.push({
        label: item.name,
        value: item.id,
        users: item?.distribution_group_user
      });
    });

    const grpOption = { label: "Groups", options: grpArr };
    const userOption = { label: "Users", options: userArr };
    setOptions([grpOption, userOption]);
    setselectedGroupIds([...selectedgroupIds, ...filterSelectedUserIds]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    distributionGroupData,
    dldata?.submittal_distribution_list,
    usersData?.project_users
  ]);

  const previousEventLogs = useRef(eventLogs);
  useEffect(() => {
    if (eventLogs.length && previousEventLogs.current !== eventLogs) {
      if (eventLogs.some((log) => log.data_source === "distribution_group")) {
        refetchDistructionGroup();
      }

      if (eventLogs.some((log) => log.data_source === "project_users")) {
        refetchProjectUsersData();
      }

      if (
        eventLogs.some(
          (log) =>
            log.data_source === "submittal_distribution_list" &&
            log.info.submittal_ids?.includes(submittalId)
        )
      ) {
        refetchSubmittalDlUserData();
      }
    }
    previousEventLogs.current = eventLogs;
  }, [
    eventLogs,
    refetchDistructionGroup,
    refetchProjectUsersData,
    refetchSubmittalDlUserData,
    submittalId
  ]);

  async function onDeSelect(deselectedId: any, isGroupId: boolean) {
    const deleteArr: any = [];
    if (isGroupId) {
      deleteArr.push({
        dl_id: [deselectedId],
        user_id: []
      });
    } else {
      deleteArr.push({
        dl_id: [],
        user_id: [deselectedId]
      });
    }

    if (deleteArr?.length > 0) {
      const updateDLToSubmittalResponse = await updateDLToSubmittal({
        variables: {
          where: {
            _or: [
              { dl_id: { _in: deleteArr[0].dl_id } },
              { user_id: { _in: deleteArr[0].user_id } }
            ],
            submittal_id: { _eq: submittalId }
          }
        }
      });

      if (updateDLToSubmittalResponse.data) {
        message.success("Distribution list updated.");
      }

      if (updateDLToSubmittalResponse.errors) {
        message.error("Failed to update distribution list.");
      }
    }
  }

  async function onSelect(selctedId: any, isGroupId: boolean) {
    const addArr: any = [];
    if (isGroupId) {
      addArr.push({
        dl_id: selctedId,
        user_id: null,
        submittal_id: submittalId
      });
    } else {
      addArr.push({
        dl_id: null,
        user_id: selctedId,
        submittal_id: submittalId
      });
    }
    if (addArr.length > 0) {
      const insertDLToSubmittalResponse = await insertDLToSubmittal({
        variables: {
          data: addArr
        }
      });
      if (insertDLToSubmittalResponse.data) {
        message.success("Distribution list updated.");
      }

      if (insertDLToSubmittalResponse.errors) {
        message.error("Failed to update distribution list.");
      }
    }
  }

  return (
    <Form.Item
      label="Distribution list"
      className={`distribution-list-input ${
        !selectedDLIds || !selectedDLIds.length ? "hide-input" : ""
      }`}
    >
      <span className="distribution-list-na">NA</span>
      <Select
        disabled={
          disabled ||
          isPermissionNotGrantted(
            ProjectPermissionEnum.EditDistributionGroup,
            tokenContents?.role!
          )
        }
        placeholder="Select distribution groups"
        style={{ width: "100%" }}
        virtual={false}
        options={options}
        mode="multiple"
        value={selectedDLIds}
        filterOption
        optionFilterProp="label"
        onChange={(groupIds: any) => {
          setselectedGroupIds(groupIds);
        }}
        onSelect={(e: any) => {
          const arr: [] = distributionGroupData?.distribution_group?.filter(
            (group: any) => e === group?.id
          );
          if (arr.length > 0) {
            onSelect(e, true);
          } else {
            onSelect(e, false);
          }
        }}
        onDeselect={(e: any) => {
          const arr: [] = distributionGroupData?.distribution_group?.filter(
            (group: any) => e === group?.id
          );
          if (arr.length > 0) {
            onDeSelect(e, true);
          } else {
            onDeSelect(e, false);
          }
        }}
        tagRender={(prop) =>
          dlTagRenderer(
            prop,
            usersData?.project_users ?? [],
            options.length > 0 ? options[0]?.options : null
          )
        }
      />
    </Form.Item>
  );
}

export default DistributionList;
