import { useContext } from "react";
import { ProjectContext } from "context/ProjectProvider";
import DistributionGroup from "pages/distribution-group";

function DistribuionGroupsSettings() {
  const { gqlClientForProject, tokenContents } = useContext(ProjectContext);

  const distribuionGroupsInfo = (
    <div className="flex items-center w-full info-label pl-4">
      <div className="flex text-justify">
        Create Distribution Groups required to be added across modules.
      </div>
    </div>
  );

  return (
    <div className="flex-col">
      <div className="flex w-full items-center h-[40px] py-[2px] px-4">
        <div className=" text-[#3B3B3B] font-semibold text-sm">
          DISTRIBUTION GROUPS
        </div>
      </div>

      {distribuionGroupsInfo}
      <DistributionGroup
        gqlClientForProject={gqlClientForProject}
        tokenContents={tokenContents}
      />
    </div>
  );
}

export default DistribuionGroupsSettings;
